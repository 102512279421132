<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">年度经营数据</span>
        <span class="title_right"
          ><SearchUserDate
            :type="'YearManage'"
            :title="'统计季度：'"
            :introductionType="'quarter'"
            :clearable="true"
            :list="quarterGenerate"
            :yearValue="sectionInfo.yearManageYear"
            v-model="sectionInfo.yearManageQuarter"
            @change="onYearManageChange"
            @onChangeLoading="yearManageLoading = $event"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'YearManage'"
            :introductionType="'quarter'"
            :yearIsShow="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.yearManageQuarter"
            v-model="sectionInfo.yearManageYear"
            @change="onYearManageChange"
            @onChangeLoading="yearManageLoading = $event"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onYearManageImg"
          >数据来源说明</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-printer"
          class="title_right"
          :loading="exportingLoading"
          size="small"
          @click="onYearManageExporting"
          >导出</el-button
        >
        <el-radio-group
          v-model="currentView"
          size="small"
          class="title_right"
          @change="handleViewChange"
        >
          <el-radio-button v-for="view in presetViews" :key="view.name" :label="view.name">
            {{ view.name }}
          </el-radio-button>
        </el-radio-group>

        <el-popover placement="bottom" width="300" trigger="click" class="title_right">
          <div class="column-setting-container">
            <div class="column-groups">
              <div v-for="group in columnGroups" :key="group.name" class="column-group">
                <div class="group-title">
                  <el-checkbox
                    :indeterminate="group.indeterminate"
                    v-model="group.checkAll"
                    @change="handleCheckAllChange(group)"
                  >
                    {{ group.label }}
                  </el-checkbox>
                </div>
                <div class="group-items">
                  <el-checkbox-group v-model="checkedColumns" @change="handleColumnChange">
                    <el-checkbox v-for="col in group.columns" :key="col.prop" :label="col.prop">
                      {{ col.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="column-setting-footer">
              <el-button size="small" @click="resetColumns">重置</el-button>
            </div>
          </div>
          <el-button slot="reference" size="small">
            <i class="el-icon-setting"></i> 列设置
          </el-button>
        </el-popover>
      </div>
      <el-table
        ref="yearManageTable"
        :key="tableKey"
        :summary-method="getSummaries"
        v-loading="yearManageLoading"
        show-summary
        max-height="450"
        border
        :data="yearManageList"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>

        <template v-for="col in visibleColumns">
          <el-table-column :key="col.prop" v-bind="col">
            <template slot="header" slot-scope="{}">
              <span>{{ col.label }}</span>
              <el-tooltip v-if="col.tooltip" class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px" />
                <div slot="content" v-html="col.tooltip"></div>
              </el-tooltip>
            </template>

            <template slot-scope="scope">
              <template v-if="col.dict">
                {{ scope.row[col.prop] | dict(dictData[col.dict]) }}
              </template>
              <template v-else-if="col.prop === 'actualExpenditure'">
                <el-tooltip
                  effect="light"
                  placement="top"
                  popper-class="actual-expenditure-tooltip"
                >
                  <div slot="content" v-if="scope.row.actualExpenditureDataVo">
                    <div class="cost-breakdown">
                      <div class="cost-item">
                        <span class="cost-label">商务费用</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.businessCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">项目费用</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.projectCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">公共费用</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.publicCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">实际人工成本</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.laborCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">其它成本</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.otherCost | applyAmount
                        }}</span>
                      </div>
                      <!-- <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">采购实际付款金额</span>
                        <span class="cost-value">{{ scope.row.payments | applyAmount }}</span>
                      </div> -->
                      <div class="operator">=</div>
                      <div class="cost-item total">
                        <span class="cost-label">实际支出</span>
                        <span class="cost-value">{{
                          (scope.row.actualExpenditureDataVo.businessCost +
                            scope.row.actualExpenditureDataVo.projectCost +
                            scope.row.actualExpenditureDataVo.publicCost +
                            scope.row.actualExpenditureDataVo.laborCost +
                            scope.row.actualExpenditureDataVo.otherCost)
                            | applyAmount
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <a style="color: blue; text-decoration: underline" href="javascript:void(0)">
                    {{ scope.row.actualExpenditure | applyAmount }}
                  </a>
                </el-tooltip>
              </template>
              <template v-else-if="col.isAmount">
                {{ scope.row[col.prop] | applyAmount }}
              </template>
              <template v-else>
                {{ scope.row[col.prop] }}
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </el-card>
    <el-card class="box-card" style="margin-top: 8px">
      <div slot="header" class="clearfix">
        <span class="title_fw">公司月度财务数据</span>
        <span class="title_right"
          ><SearchUserDate
            :type="'MonthFinance'"
            :introductionType="'month'"
            :clearable="true"
            :list="monthGenerate"
            :yearValue="sectionInfo.monthFinanceYear"
            v-model="sectionInfo.monthFinanceMonth"
            @change="onMonthFinanceChange"
            @onChangeLoading="monthFinanceLoading = $event"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'MonthFinance'"
            :introductionType="'month'"
            :yearIsShow="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.monthFinanceMonth"
            v-model="sectionInfo.monthFinanceYear"
            @change="onMonthFinanceChange"
            @onChangeLoading="monthFinanceLoading = $event"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onMonthFinanceImg"
          >数据来源说明</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-printer"
          class="title_right"
          :loading="exportingLoading"
          size="small"
          @click="onMonthFinanceExporting"
          >导出</el-button
        >
        <el-radio-group
          v-model="monthFinanceCurrentView"
          size="small"
          class="title_right"
          @change="handleMonthFinanceViewChange"
        >
          <el-radio-button
            v-for="view in monthFinancePresetViews"
            :key="view.name"
            :label="view.name"
          >
            {{ view.name }}
          </el-radio-button>
        </el-radio-group>

        <el-popover placement="bottom" width="300" trigger="click" class="title_right">
          <div class="column-setting-container">
            <div class="column-groups">
              <div v-for="group in monthFinanceColumnGroups" :key="group.name" class="column-group">
                <div class="group-title">
                  <el-checkbox
                    :indeterminate="group.indeterminate"
                    v-model="group.checkAll"
                    @change="handleMonthFinanceCheckAllChange(group)"
                  >
                    {{ group.label }}
                  </el-checkbox>
                </div>
                <div class="group-items">
                  <el-checkbox-group
                    v-model="monthFinanceCheckedColumns"
                    @change="handleMonthFinanceColumnChange"
                  >
                    <el-checkbox v-for="col in group.columns" :key="col.prop" :label="col.prop">
                      {{ col.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="column-setting-footer">
              <el-button size="small" @click="resetMonthFinanceColumns">重置</el-button>
            </div>
          </div>
          <el-button slot="reference" size="small">
            <i class="el-icon-setting"></i> 列设置
          </el-button>
        </el-popover>
      </div>
      <el-table
        ref="monthFinanceTable"
        :key="monthFinanceTableKey"
        :summary-method="getMnthFinance"
        v-loading="monthFinanceLoading"
        show-summary
        max-height="450"
        border
        :data="monthFinanceList"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>

        <template v-for="col in monthFinanceVisibleColumns">
          <el-table-column :key="col.prop" v-bind="col">
            <template slot="header" slot-scope="{}">
              <span>{{ col.label }}</span>
              <el-tooltip v-if="col.tooltip" class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px" />
                <div slot="content" v-html="col.tooltip"></div>
              </el-tooltip>
            </template>

            <template slot-scope="scope">
              <template v-if="col.dict">
                {{ scope.row[col.prop] | dict(dictData[col.dict]) }}
              </template>
              <template v-else-if="col.prop === 'actualExpenditure'">
                <el-tooltip
                  effect="light"
                  placement="top"
                  popper-class="actual-expenditure-tooltip"
                >
                  <div slot="content" v-if="scope.row.actualExpenditureDataVo">
                    <div class="cost-breakdown">
                      <div class="cost-item">
                        <span class="cost-label">商务费用</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.businessCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">项目费用</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.projectCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">公共费用</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.publicCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">实际人工成本</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.laborCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">其它成本</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.otherCost | applyAmount
                        }}</span>
                      </div>
                      <!-- <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">采购实际付款金额</span>
                        <span class="cost-value">{{ scope.row.payments | applyAmount }}</span>
                      </div> -->
                      <div class="operator">=</div>
                      <div class="cost-item total">
                        <span class="cost-label">实际支出</span>
                        <span class="cost-value">
                          {{
                            (scope.row.actualExpenditureDataVo.businessCost +
                              scope.row.actualExpenditureDataVo.projectCost +
                              scope.row.actualExpenditureDataVo.publicCost +
                              scope.row.actualExpenditureDataVo.laborCost +
                              scope.row.actualExpenditureDataVo.otherCost)
                              | applyAmount
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <a style="color: blue; text-decoration: underline" href="javascript:void(0)">
                    {{ scope.row.actualExpenditure | applyAmount }}
                  </a>
                </el-tooltip>
              </template>
              <template v-else-if="col.isAmount">
                {{ scope.row[col.prop] | applyAmount }}
              </template>
              <template v-else>
                {{ scope.row[col.prop] }}
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </el-card>
    <el-card class="box-card" style="margin-top: 8px">
      <div slot="header" class="clearfix">
        <span class="title_fw">季度新增商务数据表</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'AddBusiness'"
            :yearIsShow="true"
            :list="dictData.yearList"
            v-model="sectionInfo.addBusinessYear"
            @change="onAddBusinessChange"
            @onChangeLoading="addBusinessLoading = $event"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onAddBusinessImg"
          >数据来源说明</el-button
        >
      </div>
      <el-table max-height="450" v-loading="addBusinessLoading" border :data="addBusinessList">
        <el-table-column
          prop="quarters"
          label="季度"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="newBusinessCount"
          min-width="130"
          label="新增商务数量"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.newBusinessCount }}
          </template></el-table-column
        >
        <el-table-column prop="newProjectCount" min-width="120" align="center">
          <template slot-scope="{}" slot="header">
            <span>新增项目数量</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>新商务已创建的项目数量合计</div>
              </div>
            </el-tooltip></template
          ><template slot-scope="scope">
            {{ scope.row.newProjectCount }}
          </template>
        </el-table-column>
        <el-table-column prop="totalProjectAmount" min-width="120" align="right">
          <template slot-scope="{}" slot="header">
            <span>新增项目总金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>新商务已创建的项目，项目收入合计（即公司应收合计）</div>
              </div>
            </el-tooltip></template
          ><template slot-scope="scope">
            {{ scope.row.totalProjectAmount | applyAmount }}
          </template>
        </el-table-column>

        <el-table-column prop="growthRate" min-width="120" align="right">
          <template slot-scope="{}" slot="header">
            <span>环比增长率</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>当前期与上一期相比的增长率。</div>
                <div>（当前期数 - 上一期数） / 上一期数 * 100</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope"> {{ scope.row.growthRate }}% </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          v-show="imgUrl == '经营统计-季度新增商务数据表'"
          src="@/assets/经营统计-季度新增商务数据表.png"
          alt="数据来源说明"
          width="100%"
        />
        <img
          v-show="imgUrl == '经营统计-年度经营数据'"
          src="@/assets/经营统计-年度经营数据.png"
          alt="数据来源说明"
          width="100%"
        />
        <img
          v-show="imgUrl == '经营统计-公司月度财务数据'"
          src="@/assets/经营统计-公司月度财务数据.png"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const COLUMN_DEFINITIONS = {
  // 基本信息列
  basic: [
    {
      prop: 'company',
      label: '公司',
      width: '100',
      fixed: 'left',
      align: 'center',
      dict: 'companyType',
    },
    {
      prop: 'projectCount',
      label: '项目数量',
      width: '100',
      sortable: true,
      align: 'center',
      tooltip: '今年所实施的项目数量',
    },
  ],
  // 销售信息列
  amount: [
    {
      prop: 'salesRevenue',
      label: '销售合同金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目详情里的合同实际金额（销售合同）',
    },
    {
      prop: 'invoicedAmount',
      label: '开票金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所创建的项目，<br/>项目收入中的已开票金额（销售合同）',
    },
    {
      prop: 'receivedAmount',
      label: '回款金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所创建的项目，<br/>项目收入中的已收款金额（销售合同）',
    },
  ],
  // 采购信息列
  purchase: [
    {
      prop: 'procurement',
      label: '采购合同金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目详情里的合同实际金额带出（采购合同）',
    },
    {
      prop: 'receivedInvoices',
      label: '采购发票金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所创建的项目，<br/>项目收入中的已开票金额（采购合同）',
    },
    {
      prop: 'payments',
      label: '采购实际付款金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所创建的项目，<br/>项目收入中的已收款金额（采购合同）',
    },
  ],
  // 报销信息列
  reimbursement: [
    {
      prop: 'businessExpend',
      label: '商务报销',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务费用报销汇总',
    },
    {
      prop: 'projectExpend',
      label: '项目报销',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目费用报销汇总',
    },
    {
      prop: 'publicSpendingExpend',
      label: '公共报销',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公共费用报销汇总',
    },
    {
      prop: 'reimbursements',
      label: '总报销',
      minWidth: '150',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务报销 + 项目报销 + 公共报销',
    },
  ],
  // // 实际收款列
  // income: [
  // ],
  // 成本信息列
  cost: [
    {
      prop: 'laborCost',
      label: '实际人工成本',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公司人员成本合计，<br/>截至上周的累计人工',
    },
    {
      prop: 'projectManhourCost',
      label: '项目工时成本',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公司人员的项目级工时成本合计，<br/>截至上周的累计人工',
    },
    {
      prop: 'businessManhourCost',
      label: '组织工时成本',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公司人员的组织级工时合计，<br/>截至上周的累计人工',
    },
    {
      prop: 'hourCost',
      label: '工时成本合计',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目工时成本 + 组织工时成本',
    },
  ],
  // // 支出信息列
  // expense: [
  // ],
  // 利润信息列
  profit: [
    {
      prop: 'actualReceipts',
      label: '实际收款',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目收入中收款金额',
    },
    {
      prop: 'actualExpenditure',
      label: '实际支出',
      minWidth: '150',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务费用 + 项目费用 + 公共费用 + 实际人工成本 + 税费  + 设备折旧 ',
    },
    {
      prop: 'fundBalance',
      label: '现金流',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '实际收款 - 实际支出',
    },
    // {
    //   prop: 'totalRevenue',
    //   label: '总收入',
    //   minWidth: '150',
    //   sortable: true,
    //   align: 'right',
    //   isAmount: true,
    // },
    // {
    //   prop: 'totalCost',
    //   label: '总成本',
    //   minWidth: '150',
    //   sortable: true,
    //   align: 'right',
    //   isAmount: true,
    // },
    // {
    //   prop: 'totalProfit',
    //   label: '总利润',
    //   minWidth: '150',
    //   sortable: true,
    //   align: 'right',
    //   isAmount: true,
    // },
  ],
}

const MONTH_FINANCE_COLUMNS = {
  // 基本信息列
  basic: [
    {
      prop: 'company',
      label: '公司',
      width: '100',
      fixed: 'left',
      align: 'center',
      dict: 'companyType',
    },
  ],
  // 销售信息列
  sales: [
    {
      prop: 'salesRevenue',
      label: '销售合同金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目详情里的合同实际金额（销售合同）',
    },
    {
      prop: 'receivedAmount',
      label: '回款金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所创建的项目，<br/>项目收入中的已收款金额',
    },
    {
      prop: 'invoicedAmount',
      label: '开票金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所创建的项目，<br/>项目收入中的已开票金额',
    },
  ],
  // 采购信息列
  purchase: [
    {
      prop: 'procurement',
      label: '采购合同金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目详情里的合同实际金额带出（采购合同）',
    },
    // { prop: 'receivedInvoices', label: '收票金额', minWidth: '130', sortable: true, align: 'right', isAmount: true, tooltip: '商务所创建的项目，<br/>项目收入中的已开票金额（采购合同）' },
    // { prop: 'payments', label: '付款金额', minWidth: '130', sortable: true, align: 'right', isAmount: true, tooltip: '商务所创建的项目，<br/>项目收入中的已收款金额（采购合同）' }
  ],
  // 费用信息列
  reimbursement: [
    {
      prop: 'businessExpend',
      label: '商务报销',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务费用报销汇总',
    },
    {
      prop: 'projectExpend',
      label: '项目报销',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目费用报销汇总',
    },
    {
      prop: 'publicSpendingExpend',
      label: '公共报销',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公共费用报销汇总',
    },
    {
      prop: 'reimbursements',
      label: '总报销',
      minWidth: '150',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务报销 + 项目报销 + 公共报销',
    },
    {
      prop: 'receivedInvoices',
      label: '应付金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '按发起时间算起的所有报销 + 付款申请金额',
    },
  ],
  cost: [
    {
      prop: 'laborCost',
      label: '实际人工成本',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公司人员成本合计，<br/>截至上周的累计人工',
    },
    {
      prop: 'projectManhourCost',
      label: '项目工时成本',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公司人员的项目级工时成本合计，<br/>截至上周的累计人工',
    },
    {
      prop: 'businessManhourCost',
      label: '组织工时成本',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公司人员的组织级工时合计，<br/>截至上周的累计人工',
    },
    {
      prop: 'hourCost',
      label: '工时成本合计',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目工时成本 + 组织工时成本',
    },
  ],
  // 实际支出
  expense: [
    {
      prop: 'actualExpenditure',
      label: '实际支出',
      minWidth: '150',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务费用 + 项目费用 + 公共费用 + 实际人工成本 + 税费  + 设备折旧 ',
    },
  ],
}

export default {
  components: {
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      dictData: {
        yearList: [],
        companyType: [],
      },
      yearManageList: [], // 年度经营数据
      monthFinanceList: [], // 公司月度财务数据
      addBusinessList: [], // 季度新增商务数据表
      sectionInfo: {
        // 条件筛选
        yearManageYear: null, // 年份
        monthFinanceYear: null,
        addBusinessYear: null,
        yearManageQuarter: '', // 季度
        monthFinanceMonth: null, // 月份
      },
      titleYear: '统计时间：',
      isDialog: false,
      imgUrl: '',
      projectPaymentAmount: 0,
      projectPaymentAmountC: 0,
      projectPaymentAmountMnth: 0,
      projectPaymentAmountCMnth: 0,
      columnGroups: [
        {
          name: 'basic',
          label: '基本信息',
          columns: COLUMN_DEFINITIONS.basic.slice(1), // 不包含公司列
        },
        {
          name: 'amount',
          label: '销售信息',
          columns: COLUMN_DEFINITIONS.amount,
        },
        {
          name: 'purchase',
          label: '采购信息',
          columns: COLUMN_DEFINITIONS.purchase,
        },
        {
          name: 'reimbursement',
          label: '报销信息',
          columns: COLUMN_DEFINITIONS.reimbursement,
        },
        {
          name: 'cost',
          label: '成本信息',
          columns: COLUMN_DEFINITIONS.cost,
        },
        // {
        //   name: 'income',
        //   label: '收款信息',
        //   columns: COLUMN_DEFINITIONS.income,
        // },
        // {
        //   name: 'expense',
        //   label: '支出信息',
        //   columns: COLUMN_DEFINITIONS.expense,
        // },
        {
          name: 'profit',
          label: '利润信息',
          columns: COLUMN_DEFINITIONS.profit,
        },
      ],
      checkedColumns: [], // 默认显示的列
      currentView: '全部',
      presetViews: [
        {
          name: '销售信息',
          getColumns: () => [...COLUMN_DEFINITIONS.basic, ...COLUMN_DEFINITIONS.amount],
        },
        {
          name: '采购信息',
          getColumns: () => [...COLUMN_DEFINITIONS.basic, ...COLUMN_DEFINITIONS.purchase],
        },
        {
          name: '报销信息',
          getColumns: () => [...COLUMN_DEFINITIONS.basic, ...COLUMN_DEFINITIONS.reimbursement],
        },
        {
          name: '成本信息',
          getColumns: () => [...COLUMN_DEFINITIONS.basic, ...COLUMN_DEFINITIONS.cost],
        },
        {
          name: '利润信息',
          getColumns: () => [...COLUMN_DEFINITIONS.basic, ...COLUMN_DEFINITIONS.profit],
        },
        {
          name: '全部',
          getColumns: () => Object.values(COLUMN_DEFINITIONS).flat(),
        },
      ],
      tableHeight: 'calc(100vh - 190px)', // 固定表格高度
      tableKey: 0, // 用于强制刷新表格

      // 月度财务数据相关配置
      monthFinanceColumnGroups: [
        {
          name: 'sales',
          label: '销售信息',
          columns: MONTH_FINANCE_COLUMNS.sales,
        },
        {
          name: 'purchase',
          label: '采购信息',
          columns: MONTH_FINANCE_COLUMNS.purchase,
        },
        {
          name: 'reimbursement',
          label: '报销信息',
          columns: MONTH_FINANCE_COLUMNS.reimbursement,
        },
        {
          name: 'cost',
          label: '成本信息',
          columns: MONTH_FINANCE_COLUMNS.cost,
        },
        {
          name: 'expense',
          label: '支出信息',
          columns: MONTH_FINANCE_COLUMNS.expense,
        },
      ],
      monthFinanceCheckedColumns: [], // 默认显示的列
      monthFinanceCurrentView: '全部',
      monthFinancePresetViews: [
        {
          name: '销售信息',
          getColumns: () => [...MONTH_FINANCE_COLUMNS.basic, ...MONTH_FINANCE_COLUMNS.sales],
        },
        {
          name: '报销信息',
          getColumns: () => [
            ...MONTH_FINANCE_COLUMNS.basic,
            ...MONTH_FINANCE_COLUMNS.reimbursement,
          ],
        },
        {
          name: '成本信息',
          getColumns: () => [...MONTH_FINANCE_COLUMNS.basic, ...MONTH_FINANCE_COLUMNS.cost],
        },
        {
          name: '全部',
          getColumns: () => Object.values(MONTH_FINANCE_COLUMNS).flat(),
        },
      ],
      monthFinanceTableKey: 0, // 用于强制刷新月度财务数据表格
      yearManageLoading: false,
      monthFinanceLoading: false,
      addBusinessLoading: false,
      exportingLoading: false,
    }
  },
  provide() {
    return {}
  },
  computed: {
    quarterGenerate() {
      const arr = []
      for (let index = 0; index < 4; index++) {
        arr.push({ id: index + 1, dictName: `第${index + 1}季度`, dictVal: `Q${index + 1}` })
      }
      arr.unshift({
        id: '0',
        dictName: '全部',
        dictVal: '',
      })
      return arr
    },
    monthGenerate() {
      const arr = []
      for (let index = 0; index < 12; index++) {
        arr.push({ id: index + 1, dictName: `${index + 1}月`, dictVal: `${index + 1}` })
      }
      return arr
    },
    visibleColumns() {
      const currentView = this.presetViews.find(v => v.name === this.currentView)
      if (!currentView) return []

      if (this.currentView === '全部') {
        // 公司列始终显示
        const alwaysShowColumns = COLUMN_DEFINITIONS.basic.slice(0, 1)

        // 其他基本信息列根据选择显示
        const otherBasicColumns = COLUMN_DEFINITIONS.basic
          .slice(1)
          .filter(col => this.checkedColumns.includes(col.prop))

        // 构建最终的列数组
        const resultColumns = [...alwaysShowColumns, ...otherBasicColumns]

        // 添加其他组的列
        const otherGroups = ['amount', 'purchase', 'reimbursement', 'cost', 'profit']
        otherGroups.forEach(groupName => {
          const groupColumns = COLUMN_DEFINITIONS[groupName].filter(col =>
            this.checkedColumns.includes(col.prop)
          )
          resultColumns.push(...groupColumns)
        })

        return resultColumns
      }

      return currentView.getColumns()
    },
    monthFinanceVisibleColumns() {
      const currentView = this.monthFinancePresetViews.find(
        v => v.name === this.monthFinanceCurrentView
      )
      if (!currentView) return []

      if (this.monthFinanceCurrentView === '全部') {
        // 公司列始终显示
        const alwaysShowColumns = MONTH_FINANCE_COLUMNS.basic

        // 构建最终的列数组
        const resultColumns = [...alwaysShowColumns]

        // 添加其他组的列
        const otherGroups = ['sales', 'purchase', 'reimbursement', 'cost', 'expense']
        otherGroups.forEach(groupName => {
          const groupColumns = MONTH_FINANCE_COLUMNS[groupName].filter(col =>
            this.monthFinanceCheckedColumns.includes(col.prop)
          )
          resultColumns.push(...groupColumns)
        })

        return resultColumns
      }

      return currentView.getColumns()
    },
  },
  created() {
    this.getType()

    // 初始化年度经营数据的列配置
    this.checkedColumns = this.columnGroups.flatMap(group => group.columns).map(col => col.prop)
    this.updateGroupCheckStatus()
    // 从localStorage读取年度经营数据的列显示偏好
    // const savedColumns = localStorage.getItem('yearManage-columns')
    // if (savedColumns) {
    //   this.checkedColumns = JSON.parse(savedColumns)
    //   this.updateGroupCheckStatus()
    // }

    // 初始化月度财务数据的列配置
    this.monthFinanceCheckedColumns = this.monthFinanceColumnGroups
      .flatMap(group => group.columns)
      .map(col => col.prop)
    this.updateMonthFinanceGroupCheckStatus()
    // 从localStorage读取月度财务数据的列显示偏好
    // const savedMonthFinanceColumns = localStorage.getItem('monthFinance-columns')
    // if (savedMonthFinanceColumns) {
    //   this.monthFinanceCheckedColumns = JSON.parse(savedMonthFinanceColumns)
    //   this.updateMonthFinanceGroupCheckStatus()
    // }
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    // 年度
    async onYearManageExporting() {
      this.exportingLoading = true
      const obj = { date: this.sectionInfo.yearManageYear }
      if (this.sectionInfo.yearManageQuarter) {
        obj.quarter = this.sectionInfo.yearManageQuarter
      }
      try {
        const res = await this.$api.statistics.executiveDashExecutiveData(obj)
        const fileName = '年度经营数据.xlsx'
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        this.downloadFile(blob, fileName)
        this.$message.success('导出成功')
      } catch (err) {
        console.error('导出失败:', err)
        this.$message.error('导出失败，请重试')
      } finally {
        this.exportingLoading = false
      }
    },
    // 月度
    async onMonthFinanceExporting() {
      this.exportingLoading = true
      const obj = { date: this.sectionInfo.monthFinanceYear }
      if (this.sectionInfo.monthFinanceMonth) {
        obj.month = this.sectionInfo.monthFinanceMonth
      }
      try {
        const res = await this.$api.statistics.executiveDashFinanceData(obj)
        const fileName = '月度财务数据.xlsx'
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        this.downloadFile(blob, fileName)
        this.$message.success('导出成功')
      } catch (err) {
        console.error('导出失败:', err)
        this.$message.error('导出失败，请重试')
      } finally {
        this.exportingLoading = false
      }
    },
    // 提取出下载文件的逻辑
    downloadFile(blob, fileName) {
      if (navigator.msSaveBlob) {
        // 针对 IE
        navigator.msSaveBlob(blob, fileName)
      } else {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
      }
    },
    init(val) {
      this.loading = true
      Promise.all([
        this.$api.statistics.queryAnnualExecutiveData(`date=${val}`),
        this.$api.statistics.queryMonthlyFinanceReport(`date=${val}`),
        this.$api.statistics.queryNewBusinessDataQuarterly(`date=${val}`),
      ])
        .then(res => {
          // 年度经营数据
          this.yearManageList = res[0].data.annualExecutiveDataView.annualExecutiveDataVoList || []
          this.yearManageList.forEach(v => {
            v.hourCost = v.projectManhourCost + v.businessManhourCost
          })
          this.projectPaymentAmount = res[0].data.annualExecutiveDataView.projectPaymentAmount || 0
          this.projectPaymentAmountC =
            res[0].data.annualExecutiveDataView.projectPaymentAmountC || 0
          this.monthFinanceList = res[1].data.monthlyFinanceDataView.monthlyFinanceDataVoList || []
          this.monthFinanceList.forEach(v => {
            v.hourCost = v.projectManhourCost + v.businessManhourCost
          })
          this.projectPaymentAmountMnth =
            res[1].data.monthlyFinanceDataView.projectPaymentAmount || 0
          this.projectPaymentAmountCMnth =
            res[1].data.monthlyFinanceDataView.projectPaymentAmountC || 0
          // 由前端根据选择年份去过滤数据
          const arr = res[2].data.quarterlyBusinessDataVoList || []
          this.addBusinessList = arr.filter(v => v.year == val)

          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    onAddBusinessImg() {
      this.imgUrl = '经营统计-季度新增商务数据表'
      this.isDialog = true
    },
    onYearManageImg() {
      this.imgUrl = '经营统计-年度经营数据'
      this.isDialog = true
    },
    onMonthFinanceImg() {
      this.imgUrl = '经营统计-公司月度财务数据'
      this.isDialog = true
    },
    onAddBusinessChange(arr) {
      this.addBusinessList = arr
    },
    onYearManageChange(obj) {
      this.yearManageList = obj.annualExecutiveDataVoList || []
      this.yearManageList.forEach(v => {
        v.hourCost = v.projectManhourCost + v.businessManhourCost
      })
      this.projectPaymentAmount = obj.projectPaymentAmount || 0
      this.projectPaymentAmountC = obj.projectPaymentAmountC || 0
    },
    onMonthFinanceChange(obj) {
      this.monthFinanceList = obj.monthlyFinanceDataVoList || []
      this.monthFinanceList.forEach(v => {
        v.hourCost = v.projectManhourCost + v.businessManhourCost
      })
      this.projectPaymentAmountMnth = obj.projectPaymentAmount || 0
      this.projectPaymentAmountCMnth = obj.projectPaymentAmountC || 0
    },
    getMnthFinance(param) {
      const { columns, data } = param
      const sums = []

      // 需要计算合计的列属性名
      const calculableProps = [
        'salesRevenue',
        'invoicedAmount',
        'receivedAmount',
        'procurement',
        'receivedInvoices',
        'payments',
        'businessExpend',
        'projectExpend',
        'publicSpendingExpend',
        'reimbursements',
        'laborCost',
        'projectManhourCost',
        'businessManhourCost',
        'hourCost',
        'actualExpenditure',
      ]

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }

        const property = column.property
        if (calculableProps.includes(property)) {
          const values = data.map(item => Number(item[property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              }
              return prev
            }, 0)

            // 特殊处理某些列的合计值
            if (property === 'salesRevenue') {
              sums[index] = Number(this.projectPaymentAmountMnth.toFixed(2)).toLocaleString()
            } else if (property === 'procurement') {
              sums[index] = Number(this.projectPaymentAmountCMnth.toFixed(2)).toLocaleString()
            } else {
              sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
            }
          } else {
            sums[index] = ''
          }
        } else {
          sums[index] = '--'
        }
      })

      return sums
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []

      // 需要计算合计的列属性名
      const calculableProps = [
        'projectCount',
        'salesRevenue',
        'invoicedAmount',
        'receivedAmount',
        'procurement',
        'receivedInvoices',
        'payments',
        'businessExpend',
        'projectExpend',
        'publicSpendingExpend',
        'reimbursements',
        'laborCost',
        'projectManhourCost',
        'businessManhourCost',
        'hourCost',
        'actualReceipts',
        'actualExpenditure',
        'fundBalance',
        'totalRevenue',
        'totalCost',
        'totalProfit',
      ]

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }

        const property = column.property
        if (calculableProps.includes(property)) {
          const values = data.map(item => Number(item[property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              }
              return prev
            }, 0)

            // 特殊处理某些列的合计值
            if (property === 'salesRevenue') {
              sums[index] = Number(this.projectPaymentAmount.toFixed(2)).toLocaleString()
            } else if (property === 'procurement') {
              sums[index] = Number(this.projectPaymentAmountC.toFixed(2)).toLocaleString()
            } else {
              sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
            }
          } else {
            sums[index] = ''
          }
        } else {
          sums[index] = '--'
        }
      })

      return sums
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.yearManageYear = dictVal
          this.sectionInfo.monthFinanceYear = dictVal
          this.sectionInfo.addBusinessYear = dictVal
          this.init(dictVal)
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleViewChange(viewName) {
      if (viewName === '全部') {
        const savedColumns = localStorage.getItem('yearManage-columns')
        this.checkedColumns = savedColumns
          ? JSON.parse(savedColumns)
          : this.columnGroups.flatMap(group => group.columns.map(col => col.prop))
      }
      this.updateGroupCheckStatus()

      // 使用 nextTick 确保 DOM 更新后再重新计算表格
      this.$nextTick(() => {
        this.refreshTable()
      })
    },
    handleCheckAllChange(group) {
      const groupColumns = group.columns.map(col => col.prop)
      if (group.checkAll) {
        this.checkedColumns = [...new Set([...this.checkedColumns, ...groupColumns])]
      } else {
        this.checkedColumns = this.checkedColumns.filter(col => !groupColumns.includes(col))
      }
      this.handleColumnChange(this.checkedColumns)
    },
    handleColumnChange(value) {
      this.currentView = '全部'
      this.checkedColumns = value
      this.updateGroupCheckStatus()
      localStorage.setItem('yearManage-columns', JSON.stringify(value))

      // 使用 nextTick 确保 DOM 更新后再重新计算表格
      this.$nextTick(() => {
        this.refreshTable()
      })
    },
    updateGroupCheckStatus() {
      this.columnGroups.forEach(group => {
        const groupColumns = group.columns.map(col => col.prop)
        const checkedCount = groupColumns.filter(col => this.checkedColumns.includes(col)).length
        group.checkAll = checkedCount === group.columns.length
        group.indeterminate = checkedCount > 0 && checkedCount < group.columns.length
      })
    },
    resetColumns() {
      this.currentView = '全部'
      this.checkedColumns = this.getDefaultColumns()
      this.updateGroupCheckStatus()
      localStorage.setItem('yearManage-columns', JSON.stringify(this.checkedColumns))

      this.$message({
        type: 'success',
        message: '已重置为默认显示',
      })

      // 使用 nextTick 确保 DOM 更新后再重新计算表格
      this.$nextTick(() => {
        this.refreshTable()
      })
    },
    getDefaultColumns() {
      return this.columnGroups.flatMap(group => group.columns).map(col => col.prop)
    },
    // 刷新表格方法
    refreshTable() {
      if (this.$refs.yearManageTable) {
        this.$refs.yearManageTable.doLayout()
      }
    },
    handleMonthFinanceColumnChange(value) {
      this.monthFinanceCurrentView = '全部'
      this.monthFinanceCheckedColumns = value
      this.updateMonthFinanceGroupCheckStatus()
      localStorage.setItem('monthFinance-columns', JSON.stringify(value))

      this.$nextTick(() => {
        this.refreshMonthFinanceTable()
      })
    },
    handleMonthFinanceCheckAllChange(group) {
      const groupColumns = group.columns.map(col => col.prop)
      if (group.checkAll) {
        this.monthFinanceCheckedColumns = [
          ...new Set([...this.monthFinanceCheckedColumns, ...groupColumns]),
        ]
      } else {
        this.monthFinanceCheckedColumns = this.monthFinanceCheckedColumns.filter(
          col => !groupColumns.includes(col)
        )
      }
      this.handleMonthFinanceColumnChange(this.monthFinanceCheckedColumns)
    },
    updateMonthFinanceGroupCheckStatus() {
      this.monthFinanceColumnGroups.forEach(group => {
        const groupColumns = group.columns.map(col => col.prop)
        const checkedCount = groupColumns.filter(col =>
          this.monthFinanceCheckedColumns.includes(col)
        ).length
        group.checkAll = checkedCount === group.columns.length
        group.indeterminate = checkedCount > 0 && checkedCount < group.columns.length
      })
    },
    handleMonthFinanceViewChange(viewName) {
      if (viewName === '全部') {
        const savedColumns = localStorage.getItem('monthFinance-columns')
        this.monthFinanceCheckedColumns = savedColumns
          ? JSON.parse(savedColumns)
          : this.monthFinanceColumnGroups.flatMap(group => group.columns.map(col => col.prop))
      }
      this.updateMonthFinanceGroupCheckStatus()

      this.$nextTick(() => {
        this.refreshMonthFinanceTable()
      })
    },
    resetMonthFinanceColumns() {
      this.monthFinanceCurrentView = '全部'
      this.monthFinanceCheckedColumns = this.getMonthFinanceDefaultColumns()
      this.updateMonthFinanceGroupCheckStatus()
      localStorage.setItem('monthFinance-columns', JSON.stringify(this.monthFinanceCheckedColumns))

      this.$message({
        type: 'success',
        message: '已重置为默认显示',
      })

      this.$nextTick(() => {
        this.refreshMonthFinanceTable()
      })
    },
    getMonthFinanceDefaultColumns() {
      return this.monthFinanceColumnGroups.flatMap(group => group.columns).map(col => col.prop)
    },
    refreshMonthFinanceTable() {
      if (this.$refs.monthFinanceTable) {
        this.$refs.monthFinanceTable.doLayout()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.column-setting-container {
  .column-groups {
    max-height: 400px;
    overflow-y: auto;
  }

  .column-group {
    margin-bottom: 16px;

    .group-title {
      margin-bottom: 8px;
    }

    .group-items {
      padding-left: 24px;

      .el-checkbox {
        display: block;
        margin-left: 0;
        margin-bottom: 8px;
      }
    }
  }

  .column-setting-footer {
    text-align: center;
    margin-top: 16px;
    border-top: 1px solid #ebeef5;
    padding-top: 16px;
  }
}
.actual-expenditure-tooltip {
  .el-tooltip__popper {
    max-width: none !important;
    padding: 16px 20px !important;
    border-radius: 8px !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
    background: #fff !important;
    border: none !important;
  }

  .cost-breakdown {
    display: flex;
    align-items: center;
    white-space: nowrap;
    background: linear-gradient(to right, rgba(64, 158, 255, 0.05), rgba(64, 158, 255, 0.1));
    padding: 12px 16px;
    border-radius: 6px;

    .cost-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 120px;
      padding: 8px 12px;
      border-radius: 4px;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
      }

      .cost-label {
        color: #606266;
        font-size: 13px;
        margin-bottom: 6px;
        font-weight: 500;
      }

      .cost-value {
        color: #303133;
        font-size: 15px;
        font-weight: 600;
        font-family: Monaco, monospace;
      }

      &.total {
        background: rgba(64, 158, 255, 0.1);
        border: 1px dashed #409eff;

        &:hover {
          background: rgba(64, 158, 255, 0.15);
        }

        .cost-label {
          color: #409eff;
        }
        .cost-value {
          color: #409eff;
          font-weight: 700;
        }
      }
    }

    .operator {
      margin: 0 8px;
      color: #909399;
      font-size: 18px;
      font-weight: bold;
      padding-top: 10px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
