<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            
            
            <!-- billType -->
            <!-- <div class="search_center"> 发票类型：
              <el-select v-model="parameter.billType" class="ipt_width" placeholder="请选择发票类型" clearable>
                <el-option v-for="item in dictData.combinedBillTypes" :key="item.id" :label="item.dictName"
                  :value="item.dictVal" />
              </el-select>
            </div> -->
            <div class="search_center"> 发票号码：
              <el-input v-model="parameter.invoceNo" class="ipt_width" placeholder="请输入发票号码" clearable>
              </el-input>
            </div>
            <div class="search_center"> 发票代码：
              <el-input v-model="parameter.invoiceCode" class="ipt_width" placeholder="请输入发票代码" clearable>
              </el-input>
            </div>
            
            <div class="search_center">
              发票名称
              <!-- <el-select v-model="parameter.invoiceTypeOrg" class="ipt_width" placeholder="请选择发票名称" clearable>
                <el-option v-for="item in invoiceTypeOrgList" :key="item.value" :label="item.label"
                  :value="item.value" />
              </el-select> -->
              <el-input class="ipt_width" clearable v-model="parameter.invoiceTypeOrg" placeholder="请输入发票名称"></el-input>
            </div>
            <div class="search_center"> 发票细分类型：
              <el-select v-model="parameter.invoiceTag" class="ipt_width" placeholder="请选择发票细分类型" clearable>
                <el-option v-for="item in dictData.invoiceTagList" :key="item" :label="item"
                  :value="item" />
              </el-select>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <!-- 同步发票 -->
          <el-button type="warning " @click="handleSync">同步发票</el-button>
          <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button>

        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table v-loading="loading" ref="multipleTable" tooltip-effect="dark" height="string" border
            :data="tableData">
            <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
            <el-table-column align="center" prop="invoiceNum" label="发票号码" width="180"></el-table-column>
            <el-table-column align="center" prop="invoiceCode" label="发票代码" width="180"></el-table-column>
            <el-table-column align="center" prop="invoiceTypeOrg" label="发票名称" width="180"></el-table-column>
            <el-table-column align="center" prop="invoiceTag" label="增值税发票的细分类型" width="180"></el-table-column>
            <el-table-column align="center" prop="invoiceDate" label="开票日期" width="120">
              <!-- 格式化日期显示 -->
              <template slot-scope="scope">
                {{ formatDate(scope.row.invoiceDate) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="purchaserName" label="购方名称" width="180"></el-table-column>
            <el-table-column align="center" prop="purchaserRegisterNum" label="购方纳税人识别号" width="180"></el-table-column>
            <el-table-column align="center" prop="sellerName" label="销售方名称" width="180"></el-table-column>
            <el-table-column align="center" prop="sellerRegisterNum" label="销售方纳税人识别号" width="180"></el-table-column>
            <el-table-column align="center" prop="totalAmount" label="合计金额" width="180"></el-table-column>
            <el-table-column align="center" prop="totalTax" label="合计税额" width="180"></el-table-column>
            <el-table-column align="center" prop="amountInFiguers" label="价税合计(小写)" width="180"></el-table-column>
            <el-table-column align="center" prop="commodityName" label="货物名称" width="180"></el-table-column>
            <el-table-column align="center" prop="commodityAmount" label="金额" width="180"></el-table-column>
            <el-table-column align="center" prop="commodityTaxRate" label="税率" width="180"></el-table-column>
            <el-table-column align="center" prop="commodityTax" label="税额" width="180"></el-table-column>
            <el-table-column align="center" prop="remarks" label="备注" width="200"></el-table-column>
            <el-table-column label="操作" fixed="right" align="center" min-width="180">
              <template slot-scope="scope">
                <el-button type="text" size="mini" class="text_Details_Bgc" @click.stop="handleDetails(scope.row)">
                  详情
                </el-button>
                <!-- 编辑 -->
                <el-button type="warning" size="mini" class="text_Edit_Bgc" @click.stop="handleEdit(scope.row)">
                  编辑
                </el-button>
                <!-- 删除 -->
                <el-button type="text" size="mini" class="text_Remove_Bgc" @click.stop="handleDelete(scope.row)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination prev-text="上一页" next-text="下一页" layout="total, prev, pager, next, slot, jumper"
            :current-page="parameter.pageNow" :page-size="parameter.pageSize" :total="parameter.total"
            @size-change="handleSizeChange" @current-change="pageChangeHandler">
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <Edit v-if="editShow" :isShow.sync="editShow" :getProjectList="projectList" :options="options"></Edit>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('./edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  data() {
    return {
      loading: false, // 表格 加载
      // 查询条件
      parameter: {
        billStatus: "",
        billType: "",
        invoceNo: "",
        invoiceBillNo: "",
        invoiceCode: "",
        invoiceType: "",
        invoiceTypeOrg: "",
        invoiceTag: "",
        order: "",
        orderRule: "",
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      invoiceTypeOrgList: [],
      pageSize: 0,
      tableData: [], // 表格数据
      companyType: [],
      invoiceLines: [],
      offsetStatus: [],
      projectList: [], // 项目数据
      agreementList: [], // 合同数据
      editShow: false, // "编辑 -- 组件" 隐藏 或 显示
      deptList: [], // 部门 列表
      options: {},
      dictData: {
        combinedBillTypes: [],
        invoiceTagList: [],
      }
    }
  },
  provide() {
    return {
      re: this.closeSubgroup,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getType()
    this.getProjectList() /** 加载 "项目/合同" 数据invoice **/
    this.getDeptList() /** 加载 "部门" 列表数据 **/
    this.getTableData() /** 加载 "发票申请" 数据 **/
  },
  watch: {
    'parameter.applyType': {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.parameter.pageNow = 1
          this.getTableData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  methods: {
    handleAdd() {
      this.options = {}
      this.options.disabled = false
      this.options.isAddAuditor = true
      this.options.title = '发票详情'
      this.editShow = true
    },
    handleSync() {
      this.$api.invoice.asynUpdateInvoiceManagementView()
        .then(res => {
          this.$message.success('同步成功')
        }).catch(err => {
          console.log(err)
        })
    },
    formatDate(date) {
      // 格式化日期，这里假设date是Date对象
      if (!date) return ''
      const d = new Date(date);
      return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
    },
    getType() {
      Promise.all([
        this.$api.dict.listSysDictData('bill_type_purchase', true),
        this.$api.dict.listSysDictData('bill_type_sales', true)
      ]).then(responses => {
        // responses 是一个包含两个请求结果的数组
        this.dictData.billTypesPurchase = responses[0].data;
        this.dictData.billTypesSale = responses[1].data;
        // 合并两个数组
        this.dictData.combinedBillTypes = [...this.dictData.billTypesPurchase, ...this.dictData.billTypesSale];
      }).catch(err => {
        console.log(err);
      });
      this.$api.invoice.queryInvoiceType().then(res=>{
        this.dictData.invoiceTagList = res.data
      }).catch(err=>{
        console.log(err)
      })
    },
    /** 加载 "项目/合同" 数据 **/
    getProjectList() {
      this.$api.dict
        .listSysDictData('INVOICE_LINES', true)
        .then(res => {
          this.invoiceLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('OFFSET_STATUS', true)
        .then(res => {
          this.offsetStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE_FULL', true)
        .then(res => {
          this.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.project
        .projectList({
          pageSize: 10000,
          pageNow: 1,
        })
        .then(res => {
          this.projectList = res.data ? res.data : []
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.agreement
        .listByProjectByStaffToNam({
          pageSize: 10000,
          pageNow: 1,
        })
        .then(res => {
          this.agreementList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.billStatus = null
      this.parameter.billType = null
      this.parameter.invoceNo = null
      this.parameter.invoiceBillNo = null
      this.parameter.invoiceCode = null
      this.parameter.invoiceType = null
      this.parameter.invoiceTypeOrg = null
      this.parameter.invoiceTag = null
      this.parameter.pageNow = 1

      this.getTableData() /** 加载 "发票" 数据 **/
    },
    getTableData() {
      this.loading = true

      this.$api.invoice
        .queryInvoiceList(this.parameter)
        .then(res => {
          this.tableData = res.data?.records
          this.parameter.total = res.data?.total
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /**
     * 点击 "表格 -- 详情" 操作
     * @param item      当前数据
     */
    handleDetails(item) {
      this.options = item
      this.options.disabled = true
      this.options.isAddAuditor = true
      this.options.title = '发票详情'
      this.editShow = true
    },
    handleEdit(item) {
      this.options = item
      this.options.disabled = false
      this.options.isAddAuditor = true
      this.options.title = '发票详情'
      this.editShow = true
    },
    handleDelete(item) {
      this.$confirm('请确认是否删除当前发票？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.invoice.deleteInvoice(item.id).then(res => {
            this.$message.success('删除成功')
            this.getTableData()
          }).catch(err => {
            console.log(err)
          })
        })

    },

    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    pageChangeHandler(page) {
      this.parameter.pageNow = page
      this.getTableData() /** 加载 "发票申请" 数据 **/
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getTableData()
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getTableData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /** 关闭 "编辑" 组件 **/
    closeSubgroup(isRefresh = false) {
      this.editShow = false
      if (isRefresh === true) {
        this.getTableData() /** 加载 "发票申请" 数据 **/
      }
    },
  },
}
</script>

<style scoped>
/* .mainList .mainList_content .mainList_content_sub .el-table {
  height: calc(100% - 93px);
} */
</style>
