<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <!-- <el-input placeholder="请输入项目名称" class="ipt_width" clearable v-model="parameter.projectName">
                  </el-input> -->
          <span
            >项目名称：
            <el-select
              v-model="parameter.projectId"
              @change="dataUpdate"
              clearable
              filterable
              placeholder="请输入或选择项目名称"
              class="ipt_width"
            >
              <el-option
                v-for="item in projectList"
                :key="item.id"
                :label="item.projectName"
                :value="item.id"
              >
              </el-option> </el-select
          ></span>
          <span v-if="status == 1 || this.status == 2 || status == 3"
            >员工名称：
            <el-input
              placeholder="请输入员工名称"
              clearable
              v-model="parameter.userName"
              class="ipt_width"
            >
            </el-input
          ></span>
          <span
            >工时日期：
            <el-date-picker
              class="date_width"
              v-model="createdDate"
              :pickerOptions="pickerOptions"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker
          ></span>
          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <!-- <el-button type="primary" icon="el-icon-edit" @click="clear_serach()">清空</el-button> -->
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          <el-button
            type="primary"
            icon="el-icon-left"
            style="width: 120px"
            v-if="status == 1 || status == 2"
            @click="$router.back()"
            >返回上一页</el-button
          >
          <el-button
            type="primary"
            style="width: 120px"
            icon="el-icon-left"
            v-if="status == 3"
            @click="onReturnFn"
            >返回上一页</el-button
          >
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :key="Math.random()"
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="deptName"
              v-if="status == 2"
              label="部门"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="userName"
              v-if="status == 1 || this.status == 2 || status == 3"
              label="姓名"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="projectName"
              v-if="status != 2"
              label="项目"
            ></el-table-column>
            <!-- <el-table-column align="left" v-if="status == 3" prop="userName" label="用户名"></el-table-column>                     -->
            <el-table-column align="center" prop="sumhour" label="总填报工时"></el-table-column>
            <el-table-column
              align="center"
              prop="sumAuditHour"
              label="总审核工时"
            ></el-table-column>
            <el-table-column prop="title" align="center" fixed="right" width="70" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="detail(scope.row)"
                  >详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { encrypt, decrypt } from '@/util/jsencrypt'
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        userName: '',
      },
      pageSize: 0,
      form: {},
      tableData: [],
      loading: false,
      status: '', //1从个人汇总工时详情进来，2从项目汇总工时详情进来,3从部门汇总工时详情进来
      deptId: '',
      userId: '',
      projectId: '',
      createdDate: [],
      projectList: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },

  watch: {
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.getProject()
  },
  methods: {
    getData() {
      this.loading = true
      if (this.$route.query.deptId) {
        this.deptId = decrypt(this.$route.query.deptId)
        this.status = 2
        this.parameter.deptId = this.deptId
      }

      if (this.$route.query.createdDate && this.$route.query.createdDate != []) {
        this.createdDate = JSON.parse(this.$route.query.createdDate)
      }

      if (this.createdDate && this.createdDate != []) {
        this.parameter.beginDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
        // this.$route.query.createdDate = JSON.stringify(this.createdDate)
      }

      if (this.$route.query.userId) {
        //个人工时汇总点详情进来
        this.userId = decrypt(this.$route.query.userId)
        this.status = 1
        this.parameter.userId = this.userId
        this.$api.hour
          .getAllMahhoursByUserAndProject(this.parameter)
          .then(res => {
            this.loading = false
            this.tableData = res.data.records
            this.parameter.total = res.data.total
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      } else if (this.status == 2) {
        this.$api.hour
          .getAllMahhoursByDeptAndUser(this.parameter)
          .then(res => {
            this.loading = false
            this.tableData = res.data.records
            this.parameter.total = res.data.total
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      } else {
        this.$api.hour
          .getAllMahhoursByProject(this.parameter)
          .then(res => {
            this.loading = false
            this.tableData = res.data.records
            this.parameter.total = res.data.total
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
    },
    getData2() {
      this.loading = true
      this.$api.hour
        .getAllMahhoursByUserAndProject(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data.records
          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      if (this.parameter.projectId && this.status == 3) {
        this.getData2()
      } else {
        this.getData()
      }
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    detail(row) {
      if (this.userId) {
        //个人工时汇总点详情进来
        this.status = 1
        if (row.projectId == null) {
          row.manhourType = 1
        }
        this.$router.push({
          path: '/hour/byUser',
          query: {
            userId: encrypt(this.userId),
            projectId: encrypt(row.projectId),
            createdDate: JSON.stringify(this.createdDate),
            manhourType: row.manhourType,
            empty: 'empty',
          },
        })
      } else if (this.status == 3) {
        //员工工时汇总点详情进来
        if (row.projectId == null) {
          row.manhourType = 1
        }
        this.$router.push({
          path: '/hour/byUser',
          query: {
            userId: encrypt(row.userId),
            projectId: encrypt(row.projectId),
            createdDate: JSON.stringify(this.createdDate),
            manhourType: row.manhourType,
            empty: 'empty',
          },
        })
      }
      // else if(this.deptId){//部门工时汇总点详情进来
      //   this.status == 2
      //   this.$router.push({path: "/hour/byUser", query:{userId: encrypt(row.userId),deptId: encrypt(this.deptId),projectId:encrypt(row.projectId) ,createdDate: JSON.stringify(this.createdDate)}})
      // }
      else {
        //项目工时汇总点详情进来
        this.projectId = row.projectId
        this.parameter.projectId = row.projectId
        this.parameter.pageNow = 1
        this.status = 3
        this.getData2()
      }
    },
    handleReset() {
      this.createdDate = []
      this.$route.query.createdDate = ''
      this.parameter = {
        pageNow: 1,
        pageSize: this.parameter.pageSize,
        total: 0,
        userName: '',
        projectId: '',
        deptId: encrypt(this.deptId),
        userId: encrypt(this.userId),
      }
      if (this.status == 3) {
        this.parameter.projectId = this.projectId
        this.getData2()
      } else {
        this.getData()
      }
    },
    onReturnFn() {
      this.status = ''
      this.createdDate = []
      this.parameter = {
        pageNow: 1,
        pageSize: this.parameter.pageSize,
        total: 0,
        beginDate: '',
        endDate: '',
        projectId: '',
        userName: '',
      }
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    dataUpdate(row) {
      this.$forceUpdate()
    },
    getProject() {
      this.$api.project
        .getProjectList({ pageSize: 10000 })
        .then(res => {
          this.projectList = res.data ? res.data : []
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
