<template>
  <div>
    {{ title }}
    <el-select
      v-model="searchValue"
      :placeholder="placeholder"
      :filterable="filterable"
      :clearable="clearable"
      :multiple="multiple"
      :collapse-tags="multiple"
      :size="size"
      @clear="onClear"
      @change="onChange"
      :style="{ width: width + 'px' }"
    >
      <el-option v-for="item in searchArr" :key="item.id" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },
    width: {
      type: [String, Number],
      default: '130',
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    value: {
      type: [String, Array],
      default: '',
    },
    introductionType: {
      type: String,
      default: '',
    },
    twoIntroductionType: {
      type: String,
      default: '',
    },
    yearValue: {
      type: [String, Array],
      default: () => {
        return null
      },
    },
    twoValue: {
      type: [String, Array],
      default: () => {
        return null
      },
    },
    threeValue: {
      type: [String, Array],
      default: () => {
        return null
      },
    },
    yearIsShow: {
      type: Boolean,
      default: false,
    },
    threeIsShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchArr: [],
      searchValue: null,
      dictData: {
        businessLines: [],
        yearList: [],
      },
    }
  },
  created() {
    this.getType()
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        this.searchValue = val
      },
    },
    list: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.searchArr = val.map(v => {
          return { id: v.id, label: v.dictName, value: v.dictVal }
        })
      },
    },
  },
  methods: {
    async onChange(value) {
      this.$emit('onChangeLoading', true)
      if (this.yearIsShow && value.length == 0) {
        value = [this.dictData.yearList[0].dictVal]
      }
      this.$emit('input', value)
      if (!this.type) return
      let res = {}
      let arr = []
      let projectPaymentAmountObj = {}
      let queryParams = `date=${this.yearIsShow ? value : this.yearValue}`
      const addParam = (param, value) => {
        if (value) {
          queryParams += `&${param}=${value}`
        }
      }
      // 父项切换
      if (this.yearIsShow) {
        if (this.twoValue) {
          addParam(this.introductionType, this.twoValue)
          if (this.threeValue) {
            addParam(this.twoIntroductionType, this.threeValue)
          }
        } else if (this.threeValue) {
          addParam(this.twoIntroductionType, this.threeValue)
        }
      } else {
        // 子项切换
        // 正常二级关联，没有三级
        if (value && !this.threeIsShow && !this.threeValue) {
          addParam(this.introductionType, value)
        } else {
          // 三级关联
          if (value && !this.twoValue && this.threeIsShow) {
            addParam(this.twoIntroductionType, value)
          }
          if (this.twoValue && this.threeIsShow) {
            addParam(this.introductionType, this.twoValue)
            if (value) {
              addParam(this.twoIntroductionType, value)
            }
          } else if (this.threeValue && !this.threeIsShow) {
            if (value) {
              addParam(this.introductionType, value)
            }
            addParam(this.twoIntroductionType, this.threeValue)
          }
        }
      }
      if (this.type === 'yearStatistics') {
        // 年度经营数据
        res = await this.$api.statistics.financiaAnnualExecutiveData(queryParams)
        projectPaymentAmountObj = res.data.annualExecutiveDataView || {}
        this.$emit('change', projectPaymentAmountObj)
      } else if (this.type === 'monthStatistics') {
        // 月度财务数据
        res = await this.$api.statistics.financiaMonthlyFinanceReport(queryParams)
        projectPaymentAmountObj = res.data.monthlyFinanceDataView || {}
        this.$emit('change', projectPaymentAmountObj)
      } else if (this.type === 'MoneyHistogram') {
        // 查询公司收支趋势图
        res = await this.$api.statistics.financiaCompanyRevenueExpenseTrend(queryParams)
        const { companyExpenseResponseVo = {} } = res.data
        const nameMapping = {
          actualReceiptsList: '收款',
          actualExpenditureList: '支出',
          fundBalanceList: '现金流',
        }
        arr = Object.keys(companyExpenseResponseVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map((key, index) => ({
            name: nameMapping[key], // 根据键获取名称
            data: companyExpenseResponseVo[key],
          }))
        const MoneyHistogram = companyExpenseResponseVo.contractPaymentListVoList || []
        this.$emit('change', arr)
        this.$emit('changeData', MoneyHistogram)
      } else if (this.type === 'ProfitHistogram') {
        // 查询项目利润趋势图
        res = await this.$api.statistics.financiaProjectProfitTrend(queryParams)
        const { projectExpenseResponseVo = {} } = res.data
        const nameMapping = {
          actualReceiptsList: '收入',
          actualExpenditureList: '成本',
          fundBalanceList: '利润',
        }
        arr = Object.keys(projectExpenseResponseVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map((key, index) => ({
            name: nameMapping[key], // 根据键获取名称
            data: projectExpenseResponseVo[key],
          }))
        const ProfitHistogram = projectExpenseResponseVo.contractPaymentListVoList || []
        this.$emit('change', arr)
        this.$emit('changeData', ProfitHistogram)
      } else if (this.type === 'sellAgreement') {
        // 销售合同回款计划统计
        res = await this.$api.statistics.financiaSalesContractPaymentSummary(queryParams)
        const { salesContractPaymentStatisticsVo = {} } = res.data
        const nameMapping = {
          contractAmount: '合同金额',
          paymentPlanAmount: '回款计划金额',
          invoicedAmount: '开票金额',
          actualReceivedAmount: '实际回款金额',
        }
        arr = Object.keys(salesContractPaymentStatisticsVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map((key, index) => ({
            name: nameMapping[key], // 根据键获取名称
            data: salesContractPaymentStatisticsVo[key],
          }))
        const sellAgreement = salesContractPaymentStatisticsVo.contractPaymentListVoList || []
        this.$emit('change', arr)
        this.$emit('changeData', sellAgreement)
      } else if (this.type === 'purchaseAgreement') {
        // 采购合同回款计划统计
        res = await this.$api.statistics.financiaPurchasingContractPaymentSummary(queryParams)
        const { purchasingContractPaymentStatisticsVo = {} } = res.data
        const nameMapping = {
          contractAmount: '合同金额',
          paymentPlanAmount: '付款计划金额',
          invoicedAmount: '开票金额',
          actualReceivedAmount: '实际付款金额',
        }
        arr = Object.keys(purchasingContractPaymentStatisticsVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map((key, index) => ({
            name: nameMapping[key], // 根据键获取名称
            data: purchasingContractPaymentStatisticsVo[key],
          }))
        const purchaseAgreement =
          purchasingContractPaymentStatisticsVo.contractPaymentListVoList || []
        this.$emit('change', arr)
        this.$emit('changeData', purchaseAgreement)
      } else if (this.type === 'sellInvoice') {
        // 销项票开具趋势统计
        res = await this.$api.statistics.financiaInvoiceIssuanceTrend(queryParams)
        const { salesInvoiceTrendVo = {} } = res.data
        const nameMapping = {
          invoiceAmount: '月度开票额',
          lastYearInvoiceAmount: '去年同期',
        }
        arr = Object.keys(salesInvoiceTrendVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map((key, index) => ({
            name: nameMapping[key], // 根据键获取名称
            data: salesInvoiceTrendVo[key],
          }))
        const sellInvoice = salesInvoiceTrendVo.contractPaymentListVoList || []
        this.$emit('change', arr)
        this.$emit('changeData', sellInvoice)
      } else if (this.type === 'advanceInvoice') {
        // 进项票开具趋势统计
        res = await this.$api.statistics.financiaPurchaseInvoiceTrend(queryParams)
        const { purchaseInvoiceTrendVo = {} } = res.data
        const nameMapping = {
          invoiceAmount: '月度收票额',
          lastYearInvoiceAmount: '去年同期',
        }
        arr = Object.keys(purchaseInvoiceTrendVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map((key, index) => ({
            name: nameMapping[key], // 根据键获取名称
            data: purchaseInvoiceTrendVo[key],
          }))
        const advanceInvoice = purchaseInvoiceTrendVo.contractPaymentListVoList || []

        this.$emit('change', arr)
        this.$emit('changeData', advanceInvoice)
      } else if (this.type === 'returnedMoney') {
        // 回款趋势统计
        res = await this.$api.statistics.financiaPaymentCollectionTrend(queryParams)
        const { paymentInvoiceTrendVo = {} } = res.data
        const nameMapping = {
          invoiceAmount: '月度回款额',
          lastYearInvoiceAmount: '去年同期',
        }
        arr = Object.keys(paymentInvoiceTrendVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map((key, index) => ({
            name: nameMapping[key], // 根据键获取名称
            data: paymentInvoiceTrendVo[key],
          }))

        const returnedMoney = paymentInvoiceTrendVo.contractPaymentListVoList || []

        this.$emit('change', arr)
        this.$emit('changeData', returnedMoney)
      } else if (this.type === 'payment') {
        // 付款趋势统计
        res = await this.$api.statistics.financiaAccountsPayableTrend(queryParams)
        const { accountsInvoiceTrendVo = {} } = res.data
        const nameMapping = {
          invoiceAmount: '月度付款额',
          lastYearInvoiceAmount: '去年同期',
        }
        arr = Object.keys(accountsInvoiceTrendVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map((key, index) => ({
            name: nameMapping[key], // 根据键获取名称
            data: accountsInvoiceTrendVo[key],
          }))

        const payment = accountsInvoiceTrendVo.contractPaymentListVoList || []

        this.$emit('change', arr)
        this.$emit('changeData', payment)
      } else if (this.type === 'costStatistics') {
        // 费用报销趋势图统计
        res = await this.$api.statistics.financiaExpensesPayableTrend(queryParams)
        const { expensesInvoiceTrendVo = {} } = res.data
        const nameMapping = {
          invoiceAmount: '月度费用报销额',
          lastYearInvoiceAmount: '去年同期',
        }
        arr = Object.keys(expensesInvoiceTrendVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map((key, index) => ({
            name: nameMapping[key], // 根据键获取名称
            data: expensesInvoiceTrendVo[key],
          }))

        const costStatistics = expensesInvoiceTrendVo.contractPaymentListVoList || []

        this.$emit('change', arr)
        this.$emit('changeData', costStatistics)
      } else if (this.type === 'QuantityYear') {
        // 年度总览头部数据
        res = await this.$api.statistics.financialBalancesData(queryParams)
        const { financialDataVo = {} } = res.data
        const keyMappings = {
          unpaidInvoice: '开票未回款',
          unpaidNationalInvoice: '回款未开票',
          unpaidReceivedInvoice: '收票未付款',
          unreceivedPayment: '付款未收票',
          unreturnedBidDeposit: '未退投标保证金',
          overdueBidDeposit: '超期未退投标保证金',
          unreturnedPerformanceDeposit: '未退履约保证金',
          overduePerformanceDeposit: '超期未退履约保证金',
          unreturnedDeposit: '未退押金',
          overdueDeposit: '超期未退押金',
          unpaidLoan: '未还借款',
        }
        arr = Object.keys(financialDataVo)
          .filter(key => keyMappings.hasOwnProperty(key)) // 只处理有映射的键
          .map(key => ({
            name: keyMappings[key], // 根据键获取名称
            value: financialDataVo[key],
          }))

        this.$emit('change', arr)
      }
      this.$emit('onChangeLoading', false)
    },

    onClear(value) {
      this.$emit('input', value)
    },
    getType() {
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.fr {
  float: right;
  padding: 3px 0;
}
</style>
