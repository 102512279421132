<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">资金风险监控</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'CapitalRisk'"
            :yearIsShow="true"
            :multiple="true"
            :list="dictData.yearList"
            v-model="sectionInfo.capitalRiskYear"
            @change="onCapitalRiskChange"
        /></span>
        <!-- <el-button class="title_right" type="primary" size="small" @click="onCapitalRiskImg"
          >数据来源说明</el-button
        > -->
      </div>
      <CapitalRisk :capitalRiskList="capitalRiskList" />
    </el-card>
    <el-card class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <span class="title_fw">资金风险监控统计</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'CapitalRiskData'"
            :introductionType="'fundingRiskType'"
            :yearIsShow="true"
            :multiple="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.capitalRisk"
            v-model="sectionInfo.capitalRiskDataYear"
            @change="onCapitalRiskDataChange"
            @onChangeLoading="capitalRiskDataLoading = $event"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :title="'资金风险：'"
            :width="200"
            :type="'CapitalRiskData'"
            :introductionType="'fundingRiskType'"
            :list="dictData.capitalRisk"
            :yearValue="sectionInfo.capitalRiskDataYear"
            :twoValue="sectionInfo.capitalRisk"
            v-model="sectionInfo.capitalRisk"
            @change="onCapitalRiskDataChange"
            @onChangeLoading="capitalRiskDataLoading = $event"
        /></span>
        <!-- <el-button class="title_right" type="primary" size="small" @click="onCapitalRiskDataImg"
          >数据来源说明</el-button
        > -->
      </div>
      <el-table
        height="330px"
        :summary-method="getSummaries"
        v-loading="capitalRiskDataLoading"
        show-summary
        border
        ref="table"
        :data="capitalRiskData"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="fundingRiskSource"
          label="监控来源"
          align="center"
          min-width="240"
        ></el-table-column>
        <el-table-column
          prop="fundingRiskName"
          width="240"
          label="资金风险监控类型"
          sortable
          align="center"
        >
        </el-table-column>
        <el-table-column prop="cost" width="240" label="金额" sortable align="right">
          <template slot-scope="scope">
            {{ scope.row.cost | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          v-show="imgUrl == '商务统计-商务类型'"
          src="@/assets/商务统计-商务类型.png"
          alt="数据来源说明"
          width="100%"
        />
        <img
          v-show="imgUrl == '商务统计-商务类型统计'"
          src="@/assets/商务统计-商务类型统计.png"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    CapitalRisk: () => import('./capitalRisk.vue'),
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      dictData: {
        yearList: [],
        businessType: [],
        capitalRisk: [],
      },
      capitalRiskList: [], // 资金风险监控
      capitalRiskData: [],
      sectionInfo: {
        // 条件筛选 // 年份
        capitalRiskYear: [],
        capitalRiskDataYear: [],
        capitalRisk: '',
      },
      titleYear: '统计时间',
      isDialog: false,
      imgUrl: '',
      capitalRiskDataLoading: false,
    }
  },
  provide() {
    return {}
  },
  computed: {},
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout()
    })
  },
  methods: {
    onCapitalRiskImg() {
      this.imgUrl = '商务统计-商务类型'
      this.isDialog = true
    },
    onCapitalRiskDataImg() {
      this.imgUrl = '商务统计-商务类型统计'
      this.isDialog = true
    },
    onCapitalRiskDataChange(arr) {
      this.capitalRiskData = arr
    },
    onCapitalRiskChange(arr) {
      this.capitalRiskList = arr
    },
    async getType() {
      this.loading = true
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(async res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.capitalRiskYear = [dictVal]
          this.sectionInfo.capitalRiskDataYear = [dictVal]

          const queryFundRiskMonitoring = await this.$api.statistics.queryFundRiskMonitoring(
            `date=${dictVal}`
          )
          const queryFundRiskMonitoringList =
            await this.$api.statistics.queryFundRiskMonitoringList(`date=${dictVal}`)

          this.capitalRiskList = queryFundRiskMonitoring.data?.fundingRiskTypeStatisticsVoList.map(
            item => {
              return {
                value: item.cost,
                percentage: Number((item.percentage * 100).toFixed(2)),
                typeCount: item.typeCount,
                name: item.fundingRiskName,
              }
            }
          )
          this.capitalRiskData =
            queryFundRiskMonitoringList?.data?.fundingRiskTypeStatisticsVoLists || []
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('CAPITAL_RISK', true)
        .then(res => {
          this.dictData.capitalRisk = res.data
          const item = this.dictData.capitalRisk.some(v => v.id === '1')
          if (!item) {
            this.dictData.capitalRisk.unshift({
              id: '1',
              dictName: '全部',
              dictVal: '',
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index > 2) {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        } else {
          sums[index] = '--'
        }
      })

      return sums
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
