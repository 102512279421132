<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">项目数量统计</span>
        <span class="title_right"
          ><SearchUserDate
            :clearable="true"
            :type="'projectQuantit'"
            :introductionType="'month'"
            :list="monthGenerate"
            :yearValue="sectionInfo.quantityYear"
            v-model="sectionInfo.quantityMonth"
            @change="onQuantitChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :type="'projectQuantit'"
            :introductionType="'month'"
            :yearIsShow="true"
            :title="titleYear"
            :list="dictData.yearList"
            :twoValue="sectionInfo.quantityMonth"
            v-model="sectionInfo.quantityYear"
            @change="onQuantitChange"
        /></span>
      </div>
      <div class="project_individual" v-for="item in quantityList" :key="item.name">
        <div class="project_individual_title">
          {{ item.name }}
        </div>
        <div class="project_individual_center">{{ item.value }}</div>
      </div></el-card
    >
    <el-card class="box-card statistics" style="margin-top: 8px">
      <div slot="header" class="clearfix">
        <span class="title_fw"> 项目统计表 </span>
        <span class="title_right"
          ><SearchUserDate
            :type="'projectStatistics'"
            :introductionType="'projectManager'"
            :twoIntroductionType="'projectId'"
            :threeIntroductionType="'deptId'"
            :fourIntroductionType="'projectNewStatus'"
            :sixIntroductionType="'flag'"
            :sixValue="statisticsView"
            :yearIsShow="true"
            :multiple="true"
            :title="titleYear"
            :list="dictData.yearList"
            :twoValue="sectionInfo.projectStatisticsManager"
            :threeValue="sectionInfo.projectStatisticsId"
            :fourValue="sectionInfo.deptId"
            :fiveValue="sectionInfo.projectNewStatus"
            v-model="sectionInfo.statisticsYear"
            @change="onStatisticsChange"
            @onChangeLoading="statisticsDataLoading = $event"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :title="'项目经理：'"
            :type="'projectStatistics'"
            :introductionType="'projectManager'"
            :twoIntroductionType="'projectId'"
            :threeIntroductionType="'deptId'"
            :fourIntroductionType="'projectNewStatus'"
            :sixIntroductionType="'flag'"
            :sixValue="statisticsView"
            :clearable="true"
            :filterable="true"
            :list="projectManagerlist"
            :yearValue="sectionInfo.statisticsYear"
            :twoValue="sectionInfo.projectStatisticsManager"
            :threeValue="sectionInfo.projectStatisticsId"
            :fourValue="sectionInfo.deptId"
            :fiveValue="sectionInfo.projectNewStatus"
            v-model="sectionInfo.projectStatisticsManager"
            @change="onStatisticsChange"
            @onChangeLoading="statisticsDataLoading = $event"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :title="'项目名称：'"
            :width="'250'"
            :type="'projectStatistics'"
            :introductionType="'projectManager'"
            :twoIntroductionType="'projectId'"
            :threeIntroductionType="'deptId'"
            :fourIntroductionType="'projectNewStatus'"
            :sixIntroductionType="'flag'"
            :sixValue="statisticsView"
            :threeIsShow="true"
            :clearable="true"
            :filterable="true"
            :list="projectList"
            :yearValue="sectionInfo.statisticsYear"
            :twoValue="sectionInfo.projectStatisticsManager"
            :threeValue="sectionInfo.projectStatisticsId"
            :fourValue="sectionInfo.deptId"
            :fiveValue="sectionInfo.projectNewStatus"
            v-model="sectionInfo.projectStatisticsId"
            @change="onStatisticsChange"
            @onChangeLoading="statisticsDataLoading = $event"
        /></span>
        <span class="title_right"
          >部门：
          <DeptCascader
            :placeholder="'请选择部门'"
            :deptList="dictData.deptList"
            :size="'small'"
            :deptIdArr="sectionInfo.deptIdArr"
            v-model="sectionInfo.deptId"
            @changeValueArr="sectionInfo.deptIdArr = $event"
          />
        </span>
        <span class="title_right">
          <SearchUserDate
            :title="'项目状态：'"
            :type="'projectStatistics'"
            :introductionType="'projectManager'"
            :twoIntroductionType="'projectId'"
            :threeIntroductionType="'deptId'"
            :fourIntroductionType="'projectNewStatus'"
            :sixIntroductionType="'flag'"
            :sixValue="statisticsView"
            :clearable="true"
            :filterable="true"
            :fiveIsShow="true"
            :list="dictData.projectNewStatus"
            :yearValue="sectionInfo.statisticsYear"
            :twoValue="sectionInfo.projectStatisticsManager"
            :threeValue="sectionInfo.projectStatisticsId"
            :fourValue="sectionInfo.deptId"
            :fiveValue="sectionInfo.projectNewStatus"
            v-model="sectionInfo.projectNewStatus"
            @change="onStatisticsChange"
            @onChangeLoading="statisticsDataLoading = $event"
          />
        </span>
        <span class="title_right" style="display: none">
          <SearchUserDate
            :type="'ProjectStatisticsDeptId'"
            :introductionType="'projectManager'"
            :twoIntroductionType="'projectId'"
            :threeIntroductionType="'deptId'"
            :fourIntroductionType="'projectNewStatus'"
            :sixIntroductionType="'flag'"
            :sixValue="statisticsView"
            :fourIsShow="true"
            :list="dictData.deptAllList"
            :yearValue="sectionInfo.statisticsYear"
            :twoValue="sectionInfo.projectStatisticsManager"
            :threeValue="sectionInfo.projectStatisticsId"
            :fourValue="sectionInfo.deptId"
            :fiveValue="sectionInfo.projectNewStatus"
            v-model="sectionInfo.deptId"
            @change="onStatisticsChange"
            @onChangeLoading="statisticsDataLoading = $event"
          />
        </span>
        <el-button class="title_right" type="primary" size="small" @click="isDialog = true"
          >数据来源说明</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-printer"
          class="title_right"
          :loading="exportingLoading"
          size="small"
          @click="onProjectStatisticsExporting"
          >导出</el-button
        >
        <div style="margin-left: 6px; font-size: 12px; color: #409eff">
          以项目实际开始时间来统计(点击项目名称即可查看该项目收入月度统计)
        </div>
      </div>
      <div style="display: flex; justify-content: space-between; width: 100%">
        <div>
          <el-radio-group
            v-model="statisticsView"
            @input="onStatisticsViewChange"
            size="small"
            class="title_left"
          >
            <el-radio-button label="1">当前年份</el-radio-button>
            <el-radio-button label="0">其他年份</el-radio-button></el-radio-group
          >
        </div>
        <div>
          <el-radio-group
            v-model="currentView"
            size="small"
            class="title_right"
            @change="handleViewChange"
          >
            <el-radio-button v-for="view in presetViews" :key="view.name" :label="view.name">
              {{ view.name }}
            </el-radio-button>
          </el-radio-group>

          <el-popover placement="bottom" width="300" trigger="click" class="title_right">
            <div class="column-setting-container">
              <div class="column-groups">
                <div v-for="group in columnGroups" :key="group.name" class="column-group">
                  <div class="group-title">
                    <el-checkbox
                      :indeterminate="group.indeterminate"
                      v-model="group.checkAll"
                      @change="handleCheckAllChange(group)"
                    >
                      {{ group.label }}
                    </el-checkbox>
                  </div>
                  <div class="group-items">
                    <el-checkbox-group v-model="checkedColumns" @change="handleColumnChange">
                      <el-checkbox v-for="col in group.columns" :key="col.prop" :label="col.prop">
                        {{ col.label }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
              </div>
              <div class="column-setting-footer">
                <el-button size="small" @click="resetColumns">重置</el-button>
              </div>
            </div>
            <el-button slot="reference" size="small">
              <i class="el-icon-setting"></i> 列设置
            </el-button>
          </el-popover>
        </div>
      </div>
      <el-table
        :summary-method="getSummaries"
        v-loading="statisticsDataLoading"
        height="calc(100vh - 400px)"
        ref="statisticsDataRef"
        show-summary
        border
        :cell-class-name="setCellClass"
        @cell-click="onCellClickFn"
        :data="statisticsData"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          v-for="col in visibleColumns"
          :key="`${col.prop}-${col.dict || ''}`"
          :prop="col.prop"
          :label="col.label"
          :min-width="col.width"
          :fixed="col.fixed"
          :sortable="col.sortable"
          :align="col.align"
        >
          <template slot="header" slot-scope="{}">
            <span>{{ col.label }}</span>
            <el-tooltip v-if="col.tooltip" class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content" v-html="col.tooltip"></div>
            </el-tooltip>
          </template>

          <template slot-scope="scope">
            <template v-if="col.isAmount">
              {{ scope.row[col.prop] | applyAmount }}
            </template>
            <template v-else-if="col.dict">
              {{ scope.row[col.prop] | dict(dictData[col.dict]) }}
            </template>
            <template v-else-if="col.dateFormat">
              <template v-if="scope.row.projectName == '内部项目'"> -- </template>
              <template v-else>
                {{ scope.row[col.prop] | dateFormat }}
              </template>
            </template>
            <template v-else-if="col.tag">
              <template v-if="col.prop === 'contractSigned'">
                <el-tag v-if="scope.row.contractSigned == 1" type="" effect="dark">已签署</el-tag>
                <el-tag v-else-if="scope.row.contractSigned == 0" type="danger" effect="dark"
                  >未签署</el-tag
                >
                <template v-else>{{ scope.row.contractSigned }}</template>
              </template>
              <template v-else-if="col.prop === 'paymentStatus'">
                <el-tag v-if="scope.row.paymentStatus == '已收齐'" type="" effect="dark">
                  {{ scope.row.paymentStatus }}
                </el-tag>
                <el-tag
                  v-else-if="scope.row.paymentStatus == '未收齐'"
                  type="danger"
                  effect="dark"
                  >{{ scope.row.paymentStatus }}</el-tag
                >

                <template v-else> -- </template>
              </template>
            </template>
            <template v-else-if="col.isLargePackage">
              {{ scope.row.isLargePackage == 1 ? '（大）' : ''
              }}{{ scope.row[col.prop] | applyAmount }}
            </template>
            <template v-else-if="col.prop == 'projectName'">
              <template v-if="scope.row[col.prop] == '内部项目'">
                {{ scope.row[col.prop] }}
              </template>
              <a style="color: blue; text-decoration: underline" v-else href="javascript:void(0)">
                {{ scope.row[col.prop] }}
              </a>
            </template>
            <template v-else>
              {{ scope.row[col.prop] }}
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img src="@/assets/项目统计-项目统计表.png" alt="数据来源说明" width="100%" />
      </div>
    </el-dialog>
    <ProjectMoneyMonthDialog
      v-if="isShowProject"
      :isShow.sync="isShowProject"
      :projectId="projectMoneyPorjectId"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

const COLUMN_DEFINITIONS = {
  // 基本信息列
  basic: [
    { prop: 'projectName', label: '项目名称', width: '240', fixed: 'left', align: 'center' },
    { prop: 'hostDepartment', label: '主办部门', width: '100', sortable: true, align: 'center' },
    { prop: 'projectManager', label: '项目经理', width: '100', fixed: 'left', align: 'center' },
    {
      prop: 'projectSource',
      label: '项目性质',
      width: '100',
      sortable: true,
      align: 'center',
      dict: 'projectQuality',
    },
    {
      prop: 'projectSource',
      label: '项目来源',
      width: '100',
      sortable: true,
      align: 'center',
      dict: 'projectSource',
    },
    {
      prop: 'businessType',
      label: '业务种类',
      width: '100',
      sortable: true,
      align: 'center',
      dict: 'businessLines',
    },
    {
      prop: 'contractingCompany',
      label: '承接公司',
      width: '100',
      sortable: true,
      align: 'center',
      dict: 'companyType',
    },
    {
      prop: 'projectNewStatus',
      label: '项目状态',
      width: '90',
      sortable: true,
      align: 'center',
      tag: true,
      dict: 'projectNewStatus',
    },
    {
      prop: 'contractSigned',
      label: '是否签署合同',
      width: '90',
      sortable: true,
      align: 'center',
      tag: true,
    },
  ],
  // 金额信息列
  amount: [
    {
      prop: 'predictContractAmount',
      label: '我方合同金额',
      width: '130',
      tooltip: '项目中的我方合同金额',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
    {
      prop: 'actualAmount',
      label: '项目实际金额',
      width: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
    {
      prop: 'contractAmount',
      label: '合同实际金额',
      width: '150',
      tooltip: '金额前面有（大），该项目即为大包合同子项<br/>项目详情里的合同实际金额',
      sortable: true,
      align: 'right',
      isAmount: true,
      isLargePackage: true,
    },
  ],
  // 收款信息列
  payment: [
    {
      prop: 'paymentStatus',
      label: '项目收款情况',
      width: '90',
      sortable: true,
      align: 'center',
      tag: true,
    },
    {
      prop: 'invoiceAmount',
      label: '已开票金额',
      width: '120',
      tooltip: '项目收入中的已开票金额',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
    {
      prop: 'repaymentAmount',
      label: '已收款金额',
      width: '120',
      tooltip: '项目收入中的已收款金额',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
  ],
  // 绩效成本列
  cost: [
    {
      prop: 'productionPerformance',
      label: '实际生产绩效',
      width: '120',
      tooltip: '项目结算中的实际生产绩效',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
    {
      prop: 'resourceAllocation',
      label: '项目投入人力',
      width: '80',
      tooltip: '项目实际投入人力合计',
      sortable: true,
      align: 'right',
    },
    {
      prop: 'laborCost',
      label: '项目工时成本',
      width: '120',
      tooltip: '项目人员的项目级工时，<br/>截至上周的累计人工',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
    {
      prop: 'otherCost',
      label: '其他成本',
      width: '120',
      tooltip: '项目产生的项目费用、外协生产费用、设备折旧费用合计',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
    {
      prop: 'totalCost',
      label: '总成本',
      width: '150',
      tooltip: '项目工时成本+其他成本',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
  ],
  // 时间信息列
  time: [
    {
      prop: 'actualStartDate',
      label: '实际开始时间',
      width: '120',
      tooltip: '项目实际开始时间',
      sortable: true,
      align: 'center',
      dateFormat: true,
    },
    {
      prop: 'actualEndDate',
      label: '实际结束时间',
      width: '120',
      tooltip: '项目实际结束时间',
      sortable: true,
      align: 'center',
      dateFormat: true,
    },
  ],
}

export default {
  components: {
    SearchUserDate: () => import('../searchUserDate.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
    ProjectMoneyMonthDialog: () => import('./projectMoneyMonthDialog.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      projectList: [], // 项目数组
      dictData: {
        yearList: [],
        businessLines: [],
        projectNewStatus: [],
        projectSource: [],
        businessType: [],
        companyType: [],
        projectQuality: [],
        deptList: [],
        deptAllList: [],
      },
      quantityList: [], // 项目数量统计
      statisticsList: [], // 项目统计表-今年
      oldStatisticsList: [], // 项目统计表-往年
      statisticsView: '1',
      sectionInfo: {
        // 条件筛选
        quantityYear: null, // 年份
        statisticsYear: [],
        quantityMonth: null,
        projectStatisticsId: null, // 项目统计项目名称
        projectStatisticsManager: '', // 项目统计项目经理
        projectNewStatus: null, // 项目状态
        deptId: null,
        deptIdArr: [],
      },
      projectManagerlist: [], //项目经理
      titleYear: '统计时间：',
      isDialog: false,
      contractAmount: 0,
      isShowProject: false,
      projectMoneyPorjectId: '',
      columnGroups: [
        // {
        //   name: 'basic',
        //   label: '基本信息',
        //   columns: COLUMN_DEFINITIONS.basic.slice(3)
        // },
        {
          name: 'payment',
          label: '收款信息',
          columns: COLUMN_DEFINITIONS.payment,
        },
        {
          name: 'cost',
          label: '绩效成本',
          columns: COLUMN_DEFINITIONS.cost.slice(0, -1), // 不包含总成本
        },
      ],
      checkedColumns: [], // 默认显示的列
      presetViews: [
        {
          name: '收款信息',
          getColumns: () => [
            ...COLUMN_DEFINITIONS.basic.slice(0, 3),
            ...COLUMN_DEFINITIONS.amount,
            ...COLUMN_DEFINITIONS.payment,
          ],
        },
        {
          name: '绩效成本',
          getColumns: () => [
            ...COLUMN_DEFINITIONS.basic.slice(0, 3),
            ...COLUMN_DEFINITIONS.amount,
            ...COLUMN_DEFINITIONS.cost,
          ],
        },
        {
          name: '全部',
          getColumns: () => Object.values(COLUMN_DEFINITIONS).flat(),
        },
      ],
      currentView: '全部', // 当前选中的视图
      statisticsDataLoading: false,
      exportingLoading: false,
    }
  },
  provide() {
    return {}
  },
  computed: {
    statisticsData() {
      return this.statisticsView == '1' ? this.statisticsList : this.oldStatisticsList
    },
    monthGenerate() {
      const arr = []
      for (let index = 0; index < 12; index++) {
        arr.push({ id: index + 1, dictName: `${index + 1}月`, dictVal: `${index + 1}` })
      }
      return arr
    },
    visibleColumns() {
      const currentView = this.presetViews.find(v => v.name === this.currentView)
      if (!currentView) return []

      // 获取当前视图下应该显示的所有列
      const allColumns = currentView.getColumns()

      if (this.currentView === '全部') {
        // 其他基本信息列需要根据选择显示
        const otherBasicColumns = COLUMN_DEFINITIONS.basic
        // 创建一个映射来存储每个组的列和其对应的合计列
        const groupedColumns = {
          payment: {
            detail: COLUMN_DEFINITIONS.payment,
            total: null,
          },
          cost: {
            detail: COLUMN_DEFINITIONS.cost.slice(0, -1),
            total: COLUMN_DEFINITIONS.cost[COLUMN_DEFINITIONS.cost.length - 1],
          },
        }

        // 构建最终的列数组
        const resultColumns = [
          ...otherBasicColumns,
          ...COLUMN_DEFINITIONS.amount, // 金额信息列始终显示
        ]

        // 按顺序处理每个组
        Object.values(groupedColumns).forEach(group => {
          // 添加用户选中的详细列
          const selectedDetails = group.detail.filter(col => this.checkedColumns.includes(col.prop))
          if (selectedDetails.length > 0) {
            resultColumns.push(...selectedDetails)
            if (group.total) {
              resultColumns.push(group.total)
            }
          } else if (group.total) {
            resultColumns.push(group.total)
          }
        })

        // 添加时间信息列
        resultColumns.push(...COLUMN_DEFINITIONS.time)
        return resultColumns
      }

      // 其他视图直接返回预设的列
      return allColumns
    },
  },
  created() {
    this.getType()

    // 初始化选中所有列
    this.checkedColumns = this.columnGroups.flatMap(group => group.columns).map(col => col.prop)

    // 从localStorage读取用户的列显示偏好
    // const savedColumns = localStorage.getItem('projectStatistics-columns')
    // if (savedColumns) {
    //   this.checkedColumns = JSON.parse(savedColumns)
    this.updateGroupCheckStatus()
    // }
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  updated() {
    this.$nextTick(() => {
      this.$refs.statisticsDataRef.doLayout()
    })
  },
  methods: {
    // 项目统计
    async onProjectStatisticsExporting() {
      this.exportingLoading = true
      const obj = { date: this.sectionInfo.statisticsYear.join(', '), flag: this.statisticsView }
      if (this.sectionInfo.deptId) {
        obj.deptId = this.sectionInfo.deptId
      }
      if (this.sectionInfo.projectStatisticsId) {
        obj.projectId = this.sectionInfo.projectStatisticsId
      }
      if (this.sectionInfo.projectNewStatus) {
        obj.projectNewStatus = this.sectionInfo.projectNewStatus
      }
      if (this.sectionInfo.projectStatisticsManager) {
        obj.projectManager = this.sectionInfo.projectStatisticsManager
      }
      try {
        const res = await this.$api.statistics.exportProjectSummary(obj)
        const fileName = '项目统计.xlsx'
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        if (navigator.msSaveBlob) {
          // 针对 IE
          navigator.msSaveBlob(blob, fileName)
        } else {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          URL.revokeObjectURL(link.href)
        }
        this.$message.success('导出成功')
      } catch (err) {
        console.error('导出失败:', err)
        this.$message.error('导出失败，请重试')
      } finally {
        this.exportingLoading = false
      }
    },
    async onStatisticsViewChange(val) {
      this.statisticsDataLoading = true
      let queryParams = `date=${this.sectionInfo.statisticsYear}&flag=${val}`
      if (this.sectionInfo.projectStatisticsId) {
        queryParams += `&projectId=${this.sectionInfo.projectStatisticsId}`
      }
      if (this.sectionInfo.projectStatisticsManager) {
        queryParams += `&projectManager=${this.sectionInfo.projectStatisticsManager}`
      }
      if (this.sectionInfo.projectNewStatus) {
        queryParams += `&projectNewStatus=${this.sectionInfo.projectNewStatus}`
      }
      if (this.sectionInfo.deptId) {
        queryParams += `&deptId=${this.sectionInfo.deptId}`
      }
      const res = await this.$api.statistics.queryProjectSummary(queryParams)
      const projectStatisticsView = res.data?.projectStatisticsView || {}
      this.statisticsList = projectStatisticsView.projectStatisticsThisYearVoList || []
      this.oldStatisticsList = projectStatisticsView.projectStatisticsFormerYearVoList || []
      this.contractAmount = projectStatisticsView.projectPaymentAmount || 0
      this.statisticsDataLoading = false
    },
    init(val) {
      this.loading = true
      Promise.all([
        this.$api.statistics.queryProjectCountSummary(`date=${val}`),
        this.$api.statistics.queryProjectSummary(`date=${val}&flag=${this.statisticsView}`),
      ])
        .then(res => {
          // 项目数量统计
          const { projectCountStatisticsVo = {} } = res[0].data
          const keyMappings = {
            notStarted: '未启动',
            inprogress: '正常实施中',
            delayed: '进度滞后',
            maintenance: '运维服务期(质保期)',
            temporarilyPaused: '临时暂停',
            terminatedPendingPayment: '终止(待收款)',
            terminatedNoPayment: '终止(无待收款)',
            completedPaid: '已完结已收款',
            completedPendingPayment: '已完结待收款',
            uncompletedPaid: '未完结已收款',
            archived: '归档',
          }
          this.quantityList = Object.keys(projectCountStatisticsVo)
            .filter(key => keyMappings.hasOwnProperty(key)) // 只处理有映射的键
            .map(key => ({
              name: keyMappings[key], // 根据键获取名称
              value: projectCountStatisticsVo[key],
            }))

          this.statisticsList =
            res[1].data?.projectStatisticsView?.projectStatisticsThisYearVoList || []
          this.oldStatisticsList =
            res[1].data?.projectStatisticsView?.projectStatisticsFormerYearVoList || []
          this.contractAmount = res[1].data?.projectStatisticsView?.projectPaymentAmount || 0
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 新增只获取数量统计的方法
    initQuantityOnly(val) {
      this.loading = true
      this.$api.statistics
        .queryProjectCountSummary(`date=${val}`)
        .then(res => {
          // 项目数量统计
          const { projectCountStatisticsVo = {} } = res.data
          const keyMappings = {
            notStarted: '未启动',
            inprogress: '正常实施中',
            delayed: '进度滞后',
            maintenance: '运维服务期(质保期)',
            temporarilyPaused: '临时暂停',
            terminatedPendingPayment: '终止(待收款)',
            terminatedNoPayment: '终止(无待收款)',
            completedPaid: '已完结已收款',
            completedPendingPayment: '已完结待收款',
            uncompletedPaid: '未完结已收款',
            archived: '归档',
          }
          this.quantityList = Object.keys(projectCountStatisticsVo)
            .filter(key => keyMappings.hasOwnProperty(key))
            .map(key => ({
              name: keyMappings[key],
              value: projectCountStatisticsVo[key],
            }))
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    onQuantitChange(arr) {
      this.quantityList = arr
    },
    onStatisticsChange(obj) {
      this.statisticsList = obj?.projectStatisticsThisYearVoList || []
      this.oldStatisticsList = obj?.projectStatisticsFormerYearVoList || []
      this.contractAmount = obj?.projectPaymentAmount || 0
    },
    onCellClickFn(row, column, cell, event) {
      if (column.property === 'projectName' && row.projectName != '内部项目') {
        this.projectMoneyPorjectId = row.id
        this.isShowProject = true
      }
    },
    setCellClass({ column }) {
      if (column.property === 'projectName') {
        return ['clickable']
      }
      return []
    },

    // 表格合计
    getSummaries(param) {
      const { columns, data } = param
      const sums = []

      // 需要进行数值合计的列属性名
      const numericColumns = [
        'predictContractAmount', // 我方合同金额
        'actualAmount', // 项目实际金额
        'contractAmount', // 合同实际金额
        'invoiceAmount', // 已开票金额
        'repaymentAmount', // 已收款金额
        'productionPerformance', // 实际生产绩效
        'resourceAllocation', // 项目投入人力
        'laborCost', // 人工成本
        'otherCost', // 其他成本
        'totalCost', // 总成本
      ]

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }

        const property = column.property
        if (numericColumns.includes(property)) {
          // 对数值列进行合计
          const values = data.map(item => Number(item[property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)

            // 合同实际金额使用 contractAmount
            if (property === 'contractAmount' && this.statisticsView == '1') {
              sums[index] = Number(this.contractAmount.toFixed(2)).toLocaleString()
            } else {
              sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
            }
          } else {
            sums[index] = '' // 如果列的值有一项不是数字，就显示空字符串
          }
        } else {
          sums[index] = '--' // 非数值列显示 --
        }
      })

      return sums
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.quantityYear = dictVal
          this.sectionInfo.statisticsYear = [dictVal]
          const { data = {} } = this.$route.params
          if (data.statisticsYear && data.statisticsYear.length > 0) {
            this.sectionInfo.statisticsYear = data.statisticsYear
            this.sectionInfo.deptId = data.deptId
            this.sectionInfo.deptIdArr = data.deptIdArr
            this.statisticsView = data.isThisYear == '当前年份' ? '1' : '0'
            this.initQuantityOnly(data.statisticsYear)
          } else {
            this.init(dictVal)
          }
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_NEW_STATUS', true)
        .then(res => {
          this.dictData.projectNewStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_NATURE', true)
        .then(res => {
          this.dictData.projectQuality = res.data
            .filter(v => v.dictVal)
            .flatMap(v =>
              v.dictVal.split(',').map((value, index) => ({
                dictName: v.dictName,
                dictVal: value,
                id: Date.now() + index,
              }))
            )
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.dictData.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.project
        .getProjectInfoByStaffId()
        .then(res => {
          this.projectList = res.data?.projectNameRespList.map(v => ({
            id: v.id,
            dictName: v.projectName,
            dictVal: v.id,
          }))
          this.projectManagerlist = res.data?.projectManagerNameRespList.map(v => ({
            id: v.id,
            dictName: v.userName,
            dictVal: v.id,
          }))
          this.projectManagerlist.unshift({
            id: '1',
            dictName: '全部',
            dictVal: '',
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleColumnChange(value) {
      this.currentView = '全部'
      this.checkedColumns = value
      this.updateGroupCheckStatus()
      localStorage.setItem('projectStatistics-columns', JSON.stringify(value))
    },

    handleCheckAllChange(group) {
      const groupColumns = group.columns.map(col => col.prop)
      if (group.checkAll) {
        this.checkedColumns = [...new Set([...this.checkedColumns, ...groupColumns])]
      } else {
        this.checkedColumns = this.checkedColumns.filter(col => !groupColumns.includes(col))
      }
      this.handleColumnChange(this.checkedColumns)
    },

    updateGroupCheckStatus() {
      this.columnGroups.forEach(group => {
        const groupColumns = group.columns.map(col => col.prop)
        const checkedCount = groupColumns.filter(col => this.checkedColumns.includes(col)).length
        group.checkAll = checkedCount === group.columns.length
        group.indeterminate = checkedCount > 0 && checkedCount < group.columns.length
      })
    },

    handleViewChange(viewName) {
      if (viewName === '全部') {
        const savedColumns = localStorage.getItem('projectStatistics-columns')
        this.checkedColumns = savedColumns
          ? JSON.parse(savedColumns)
          : this.columnGroups.flatMap(group => group.columns.map(col => col.prop))
      }
      this.updateGroupCheckStatus()
    },

    resetColumns() {
      this.currentView = '全部'
      this.checkedColumns = this.getDefaultColumns()
      this.updateGroupCheckStatus()
      localStorage.setItem('projectStatistics-columns', JSON.stringify(this.checkedColumns))

      this.$message({
        type: 'success',
        message: '已重置为默认显示',
      })
    },

    getDefaultColumns() {
      return this.columnGroups.flatMap(group => group.columns).map(col => col.prop)
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.title_left {
  float: left;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.project_individual {
  display: inline-block;
  text-align: center;
  width: 130px;
  font-size: 18px;
  .project_individual_title {
    font-size: 15px;
  }
  .project_individual_center {
    line-height: 35px;
    margin-bottom: 10px;
    font-weight: 900;
    color: #5c5b87;
  }
}
.text_Details_Bgc {
  //详情
  background-color: #409eff;
  color: #fff;
  padding: 5px 5px;
}
/deep/.el-card__header {
  border-bottom: 0px;
}
/deep/.clickable {
  cursor: pointer; /* 鼠标移入时显示手形光标 */
}

.clickable:hover {
  background-color: #dcdcdc; /* 鼠标悬停时更改背景色 */
}
.el-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 400px;
  overflow-y: auto;
}
.column-groups {
  .column-group {
    margin-bottom: 16px;

    .group-title {
      margin-bottom: 8px;
      font-weight: bold;
    }

    .group-items {
      padding-left: 24px;

      .el-checkbox {
        display: block;
        margin-bottom: 8px;
      }
    }
  }
}

.el-checkbox-group {
  display: flex;
  flex-direction: column;

  .el-checkbox {
    margin-left: 0;
    margin-bottom: 8px;
  }
}

.el-radio-group {
  margin-right: 8px;

  .el-radio-button__inner {
    padding: 5px 15px;
  }
}

.column-setting-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .column-groups {
    flex: 1;
    overflow-y: auto;
  }

  .column-setting-footer {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #ebeef5;
    text-align: right;
  }
}
.statistics {
  /deep/.el-card__body {
    padding-top: 0px;
  }
  /deep/ .el-card__header {
    padding-bottom: 10px;
  }
}
</style>
