import Axios from '@/axios'
import { Message } from 'element-ui'
import store from '@/store'

export const list = function (cond = {}) {
  return Axios.post('/agreement/list', cond)
}

export const del = function (id) {
  return Axios.post('/agreement/delete/' + id)
}

export const selectDetailById = function (id) {
  return Axios.post('/agreement/selectDetailById/' + id)
}

//审批通过编辑
export const edit = function (cond = {}) {
  return Axios.post('/agreement/edit', cond)
}

//新增
export const creation = function (cond = {}) {
  return Axios.post('/agreement/creation', cond)
}

//暂存状态编辑
export const undeterminedEdit = function (cond = {}) {
  return Axios.post('/agreement/undeterminedEdit', cond)
}
//暂存状态编辑并提交
export const undeterminedEditOrSubmit = function (cond = {}) {
  return Axios.post('/agreement/undeterminedEditOrSubmit', cond)
}
//添加并提交
export const creationOrSubmit = function (cond = {}) {
  return Axios.post('/agreement/creationOrSubmit', cond)
}

//审批通过
export const approvePass = function (cond = {}) {
  return Axios.post('/agreement/approvePass', cond)
}
//审批不通过通过
export const approveReject = function (cond = {}) {
  return Axios.post('/agreement/approveReject', cond)
}

//合同收款保存提交
export const editPaymentInformation = function (cond = {}) {
  return Axios.post('/agreementStagePlan/editPaymentInformation', cond)
}

// //公司所属信息
// export const selectAgreementCompany = function(cond = {}) {
//   return Axios.post("/agreement/selectAgreementCompany", cond);
// };

//添加收款信息
export const addPayment = function (cond = {}) {
  return Axios.post('/agreementStagePlan/addPayment', cond)
}

//编辑阶段细项信息--付款
export const editTask = function (cond = {}) {
  return Axios.post('/agreementStagePlan/editTaskPayment', cond)
}

//开票明细添加收款信息
export const perfectionPayment = function (cond = {}) {
  return Axios.post('/agreementStagePlan/perfectionPayment', cond)
}

//完善开票信息
export const invoicePayment = function (cond = {}) {
  return Axios.post('/agreementStagePlan/invoicePayment', cond)
}

//添加开票信息
export const addInvoice = function (cond = {}) {
  return Axios.post('/agreementStagePlan/addInvoice', cond)
}

//删除阶段细项--开票
export const deleteTask = function (id) {
  return Axios.post('/agreementStagePlan/deleteTask/' + id)
}
//删除阶段细项--付款
export const deleteTaskPayment = function (id) {
  return Axios.post('/agreementStagePlan/deleteTaskPayment/' + id)
}

//冲红
export const offset = function (id) {
  return Axios.post('/agreementStagePlan/offset/' + id)
}

//合同列表-根据项目查询关联的合同
export const listByProject = function (cond) {
  return Axios.post('/agreement/listByProject', cond)
}
//合同统计金额
export const listAgreementSumAmount = function (cond) {
  return Axios.post('/agreement/listAgreementSumAmount', cond)
}

//合同导出
export const agreementExport = function (cond = {}) {
  return Axios({
    url: '/agreement/export',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

//根据id查询合同阶段
export const listByAgreementId = function (cond) {
  return Axios.post('agreementStagePlan/listByAgreementId', cond)
}

//发票-获取合同列表
export const listByProjectByStaffToNam = function (cond) {
  return Axios.post('agreement/listByProjectByStaffToName', cond)
}

//合同-执行完毕
export const executionCompleted = function (cond) {
  return Axios.post('/agreement/executionCompleted', cond)
}

// 根据项目id获取费用结算与付款方式计划
export const selectAgreementStageListByProjectId = function (cond) {
  return Axios.post('/agreement/selectAgreementStageListByProjectId/' + cond)
}

//项目收入-编辑阶段
export const agreementStagePlanEdit = function (cond = {}) {
  return Axios.post('/agreementStagePlan/edit', cond)
}

//项目收入-新增/编辑阶段
export const noInvoicePayment = function (cond = {}) {
  return Axios.post('/agreementStagePlan/noInvoicePayment', cond)
}

//项目收入-删除阶段
export const deletePaymentDetail = function (cond) {
  return Axios.post('/agreementStagePlan/deletePaymentDetail/' + cond)
}

//合同收款-编辑阶段
export const editStagePlan = function (cond = {}) {
  return Axios.post('/agreementStagePlan/editStagePlan', cond)
}

//合同收款-编辑开票明细/新增收款
export const editStageTask = function (cond = {}) {
  return Axios.post('/agreementStagePlan/editStageTask', cond)
}

// 合同收款-编辑备注
export const editStagePlanPayment = function (cond = {}) {
  return Axios.post('/agreementStagePlan/editRemark', cond)
}

// 发票管理-合同列表
export const invoiceList = function (cond = {}) {
  return Axios.post('/agreement/inoviceList', cond)
}

// 所有采购合同
export const allListAgreement = function (cond = {}) {
  return Axios.post('/agreement/allListAgreement', cond)
}

