<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">费用报销趋势图统计</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'costStatistics'"
            :yearIsShow="true"
            :list="dictData.yearList"
            v-model="sectionInfo.costStatisticsYear"
            @change="onHistogramChange"
            @changeData="onHistogramDataChange"
        /></span>
        <!-- <el-button class="title_right" type="primary" size="small" @click="onHistogramImg"
          >数据来源说明</el-button
        > -->
      </div>
      <Histogram :costStatisticsList="costStatisticsList" />
      <el-table height="150px" border :data="costStatisticsData">
        <el-table-column
          prop="contractAmountName"
          label="金额类型"
          align="center"
          fixed="left"
          min-width="170"
        ></el-table-column>
        <el-table-column prop="januaryAmount" min-width="120" label="一月" align="right">
          <template slot-scope="scope">
            {{ scope.row.januaryAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="februaryAmount" min-width="120" label="二月" align="right">
          <template slot-scope="scope">
            {{ scope.row.februaryAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="marchAmount" min-width="120" label="三月" align="right">
          <template slot-scope="scope">
            {{ scope.row.marchAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="aprilAmount" min-width="120" label="四月" align="right">
          <template slot-scope="scope">
            {{ scope.row.aprilAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="mayAmount" min-width="120" label="五月" align="right">
          <template slot-scope="scope">
            {{ scope.row.mayAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="juneAmount" min-width="120" label="六月" align="right">
          <template slot-scope="scope">
            {{ scope.row.juneAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="julyAmount" min-width="120" label="七月" align="right">
          <template slot-scope="scope">
            {{ scope.row.julyAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="augustAmount" min-width="120" label="八月" align="right">
          <template slot-scope="scope">
            {{ scope.row.augustAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="septemberAmount" min-width="120" label="九月" align="right">
          <template slot-scope="scope">
            {{ scope.row.septemberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="octoberAmount" min-width="120" label="十月" align="right">
          <template slot-scope="scope">
            {{ scope.row.octoberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="novemberAmount" min-width="120" label="十一月" align="right">
          <template slot-scope="scope">
            {{ scope.row.novemberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="decemberAmount" min-width="120" label="十二月" align="right">
          <template slot-scope="scope">
            {{ scope.row.decemberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="accumulative"
          min-width="130"
          fixed="right"
          label="累计"
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.accumulative | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          :src="`https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/${imgUrl}.png?${new Date().getTime()}`"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Histogram: () => import('./histogram.vue'),
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      dictData: {
        yearList: [],
      },
      costStatisticsList: [],
      costStatisticsData: [],
      sectionInfo: {
        // 条件筛选// 年份
        costStatisticsYear: null,
      },
      titleYear: '统计时间',
      isDialog: false,
      imgUrl: '',
    }
  },
  provide() {
    return {}
  },
  computed: {},
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    init(val) {
      this.loading = true
      this.$api.statistics
        .financiaExpensesPayableTrend(`date=${val}`)
        .then(res => {
          const { expensesInvoiceTrendVo = {} } = res.data
          const nameMapping = {
            invoiceAmount: '月度费用报销额',
            lastYearInvoiceAmount: '去年同期',
          }
          this.costStatisticsList = Object.keys(expensesInvoiceTrendVo)
            .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
            .map(key => ({
              name: nameMapping[key], // 根据键获取名称
              data: expensesInvoiceTrendVo[key],
            }))
          this.costStatisticsData = expensesInvoiceTrendVo.contractPaymentListVoList || []
          this.costStatisticsData.forEach(item => {
            item.accumulative = Object.values(item)
              .filter(value => typeof value === 'number' && !isNaN(value)) // 仅累加数值类型的数据
              .reduce((acc, value) => acc + value, 0)
          })
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    onHistogramImg() {
      this.imgUrl = '合同、回款计划、开票及回款统计'
      this.isDialog = true
    },
    onHistogramChange(arr) {
      this.costStatisticsList = arr
    },
    onHistogramDataChange(arr) {
      this.costStatisticsData = arr
      this.costStatisticsData.forEach(item => {
        item.accumulative = Object.values(item)
          .filter(value => typeof value === 'number' && !isNaN(value)) // 仅累加数值类型的数据
          .reduce((acc, value) => acc + value, 0)
      })
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.costStatisticsYear = dictVal
          this.init(dictVal)
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
</style>
