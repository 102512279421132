import Axios from '@/axios'

// 添加付款
export const add = function (cond = {}) {
  return Axios.post('/agreementPayment/add', cond)
}

// 删除付款
export const del = function (id) {
  return Axios.post('/agreementPayment/delete/' + id)
}

// 编辑付款
export const edit = function (cond = {}) {
  return Axios.post('/agreementPayment/edit', cond)
}
