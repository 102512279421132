<template>
  <div id="histogramId" style="width: 100%; height: 600px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'Histogram',
  props: {
    advanceInvoiceList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {}
  },
  computed: {},

  watch: {
    advanceInvoiceList: {
      deep: true,
      immediate: true,
      handler: function (list) {
        if (list.length > 0) {
          this.init(list)
        } else {
          this.init([])
        }
      },
    },
  },
  async created() {},
  mounted() {},
  methods: {
    init(arr = []) {
      this.$nextTick(() => {
        function truncateToOneDecimal(num) {
          // 将数字转换为字符串，找到小数点的位置
          let str = num.toString()
          let dotIndex = str.indexOf('.')
          // 如果没有小数点，直接返回字符串
          if (dotIndex === -1) {
            return str
          }
          // 截取小数点后一位
          return str.slice(0, dotIndex + 2) // +2 是包括小数点和一位数字
        }
        var chartDom = document.getElementById('histogramId')
        var myChart = echarts.init(chartDom)
        var option
        const nameArr = arr.map(v => v.name)
        option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999',
              },
            },
            formatter: function (params) {
              const { data = [] } = arr[1]
              // 获取当前月份
              const currentMonth = params[0].name // 假设第一个参数的 name 是月份
              // 定义提示框的内容
              let tooltipContent = currentMonth + '<br/>' // 添加当前月份
              params.forEach(function (item) {
                if (item.seriesType == 'line') {
                  item.data = data[item.dataIndex]
                }
                const value = Number(item.data).toLocaleString()
                tooltipContent += item.seriesName + ': ' + value + '元' + '<br/>'
              })
              return tooltipContent || '无数据' // 如果没有要显示的数据，则返回“无数据”
            },
          },
          legend: {
            data: nameArr,
          },
          xAxis: [
            {
              type: 'category',
              data: [
                '1月',
                '2月',
                '3月',
                '4月',
                '5月',
                '6月',
                '7月',
                '8月',
                '9月',
                '10月',
                '11月',
                '12月',
              ],
              axisPointer: {
                type: 'shadow',
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              min: 0,
              interval: 100000,
              max: 1000000,
              axisLabel: {
                formatter: val => {
                  if (val >= 10000) {
                    return val / 10000 + 'W'
                  }
                  return val
                },
              },
            },
          ],
          series: [
            {
              name: arr[0]?.name,
              type: 'bar',
              color: '#0064fe',
              label: {
                show: true,
                fontSize: 16,
                fontWeight: 'bold',
                position: 'top',
                formatter: function (params) {
                  const value = params.value
                  if (value >= 100000000) {
                    return truncateToOneDecimal(value / 100000000) + '亿'
                  } else if (value >= 10000) {
                    return truncateToOneDecimal(value / 10000) + 'W'
                  } else if (value < 0 && value <= -100000000) {
                    return '-' + truncateToOneDecimal(-value / 100000000) + '亿'
                  } else if (value < 0 && value <= -10000) {
                    return '-' + truncateToOneDecimal(-value / 10000) + 'W'
                  }
                  return value
                },
              },
              data: arr[0]?.data,
            },
            {
              name: arr[1]?.name,
              type: 'line',
              color: '#f8d347',
              smooth: true, // 平滑折线
              data: arr[1]?.data?.map(value => (value <= 0 ? value : value)),
            },
          ],
        }

        option && myChart.setOption(option)
        myChart.setOption(option, true) // 第二个参数设置为 true，表示完全更新配置项
        //监听窗口变化
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      })
    },
  },
}
</script>
<style scoped></style>
