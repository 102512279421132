import Axios from '@/axios'
import { Message } from 'element-ui'
import store from '@/store'

export const uploadFile = function (cond = {}) {
  return Axios.post('/common/uploadFile', cond)
}

// 修改密码
export const updatePassword = function (cond = {}) {
  return Axios.post('/sysStaff/updatePassword', cond)
}

// 修改密码
export const saveStaffPageSizeLog = function (cond = {}) {
  return Axios.post('/sysStaff/saveStaffPageSizeLog', cond)
}

// 意见列表
export const opinionRecord = function (cond = {}) {
  return Axios.post('/opinionRecord/list', cond)
}

// 文件压缩下载
export const exportFileZip = function (data = {}) {
  return Axios({
    url: '/common/exportFileZip',
    method: 'post',
    responseType: 'blob',
    data,
  })
}

// 发票附件
export const uploadFileToinvoice = function (cond = {}) {
  return Axios.post('common/uploadFileToinvoice', cond)
}

// 发票附件-后端操作接口
export const uploadFileToinvoices = function (cond = {}) {
  return Axios.post('/common/uploadFileToinvoices', cond)
}
