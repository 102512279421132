import Axios from '@/axios'

// 商务-数据总览 date=
export const businessDataOverview = function (cond = '') {
  return Axios.get('/businessDashboard/businessDataOverview?' + cond)
}

// 商务-合同回款计划
export const queryContractPaymentSummary = function (cond = '') {
  return Axios.get('/businessDashboard/queryContractPaymentSummary?' + cond)
}

// 商务-业务种类
export const queryBusinessCategorySummary = function (cond = '') {
  return Axios.get('/businessDashboard/queryBusinessCategorySummary?' + cond)
}

// 商务-业务种类表
export const queryBusinessCategorySummaryList = function (cond = '') {
  return Axios.get('/businessDashboard/queryBusinessCategorySummaryList?' + cond)
}

// 商务-商务类型
export const queryBusinessTypeSummary = function (cond = '') {
  return Axios.get('/businessDashboard/queryBusinessTypeSummary?' + cond)
}

// 商务-商务类型表
export const queryBusinessTypeSummaryList = function (cond = '') {
  return Axios.get('/businessDashboard/queryBusinessTypeSummaryList?' + cond)
}

// 商务-项目来源
export const businessProjectSourceSummary = function (cond = '') {
  return Axios.get('/businessDashboard/queryProjectSourceSummary?' + cond)
}

// 商务-项目来源表
export const businessProjectSourceSummaryList = function (cond = '') {
  return Axios.get('/businessDashboard/queryProjectSourceSummaryList?' + cond)
}

// 商务-商务漏斗
export const queryFunnelStatisticsSummary = function (cond = '') {
  return Axios.get('/businessDashboard/queryFunnelStatisticsSummary?' + cond)
}

// 商务-商务费用分析
export const queryBusinessCostAnalysisSummary = function (cond = '') {
  return Axios.get('/businessDashboard/queryBusinessCostAnalysisSummary?' + cond)
}

// 商务-前期面上沟通商务
export const businessSpecialCost = function (cond = '') {
  return Axios.get('/businessDashboard/businessSpecialCost?' + cond)
}

// 商务总览导出
export const exportBusinessDataOverview = function (cond = {}) {
  return Axios({
    url: '/businessDashboard/exportBusinessDataOverview',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

// 商务费用分析导出
export const exportBusinessCostAnalysisSummary = function (cond = {}) {
  return Axios({
    url: '/businessDashboard/exportBusinessCostAnalysisSummary',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

// 商务前期面上沟通导出
export const exportBusinessSpecialCost = function (cond = {}) {
  return Axios({
    url: '/businessDashboard/exportBusinessSpecialCost',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}
// ===============================================================================================================

// 项目-项目数量统计
export const queryProjectCountSummary = function (cond = '') {
  return Axios.get('/projectDashboard/queryProjectCountSummary?' + cond)
}

// 项目-项目统计表
export const queryProjectSummary = function (cond = '') {
  return Axios.get('/projectDashboard/queryProjectSummary?' + cond)
}

// 项目-项目收入统计
export const queryProjectIncomeStatistics = function (cond = '') {
  return Axios.get('/projectDashboard/queryProjectIncomeStatistics?' + cond)
}

// 项目-商务类型分析统计
export const projecBusinessTypeSummary = function (cond = '') {
  return Axios.get('/projectDashboard/queryBusinessTypeSummary?' + cond)
}

// 项目-项目来源分析统计
export const queryProjectSourceSummary = function (cond = '') {
  return Axios.get('/projectDashboard/queryProjectSourceSummary?' + cond)
}

// 项目-业务种类统计
export const projectBusinessCategorySummary = function (cond = '') {
  return Axios.get('/projectDashboard/queryBusinessCategorySummary?' + cond)
}

// 项目-项目工时统计
export const queryProjectWorkHourSummary = function (cond = '') {
  return Axios.get('/projectDashboard/queryProjectWorkHourSummary?' + cond)
}

// 项目-个人项目工时统计
export const queryPersonalProjectWorkHourSummary = function (cond = '') {
  return Axios.get('/projectDashboard/queryPersonalProjectWorkHourSummary?' + cond)
}

// 项目-项目投入统计
export const queryProjectInputSummary = function (cond = '') {
  return Axios.get('/projectDashboard/queryProjectInputSummary?' + cond)
}

// 项目-项目来源分析统计列表
export const projectQueryProjectSourceSummaryList = function (cond = '') {
  return Axios.get('/projectDashboard/queryProjectSourceSummaryList?' + cond)
}

// 项目-商务类型分析统计列表
export const projectQueryBusinessTypeSummaryList = function (cond = '') {
  return Axios.get('/projectDashboard/queryBusinessTypeSummaryList?' + cond)
}

// 项目-业务种类统计列表
export const projectQueryBusinessCategorySummaryList = function (cond = '') {
  return Axios.get('/projectDashboard/queryBusinessCategorySummaryList?' + cond)
}

// 项目-项目月度收入统计
export const queryMonthlyProjectIncomeStatistics = function (cond = '') {
  return Axios.get('/projectDashboard/queryMonthlyProjectIncomeStatistics?' + cond)
}

// 项目-项目总览
export const queryProjectCountData = function (cond = '') {
  return Axios.get('/projectDashboard/queryProjectCountData?' + cond)
}

// 项目总览导出
export const exportProjectCountData = function (cond = {}) {
  return Axios({
    url: '/projectDashboard/exportProjectCountData',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

// 项目统计导出
export const exportProjectSummary = function (cond = {}) {
  return Axios({
    url: '/projectDashboard/exportProjectSummary',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}
// ===============================================================================================================

// 经营-年度经营数据
export const queryAnnualExecutiveData = function (cond = '') {
  return Axios.get('/executiveDashboard/queryAnnualExecutiveData?' + cond)
}

// 经营-查询公司月度财务数据
export const queryMonthlyFinanceReport = function (cond = '') {
  return Axios.get('/executiveDashboard/queryMonthlyFinanceReport?' + cond)
}

// 经营-查询季度新增商务数据表
export const queryNewBusinessDataQuarterly = function (cond = '') {
  return Axios.get('/executiveDashboard/queryNewBusinessDataQuarterly?' + cond)
}

// 经营-查询项目类型分析
export const queryProjectCategorySummary = function (cond = '') {
  return Axios.get('/executiveDashboard/queryProjectCategorySummary?' + cond)
}

// 经营-查询业务种类统计列表 projectManager businessCategory
export const queryProjectCategorySummaryList = function (cond = '') {
  return Axios.get('/executiveDashboard/queryProjectCategorySummaryList?' + cond)
}

// 经营-查询项目状态分析统计
export const queryProjectStatusSummary = function (cond = '') {
  return Axios.get('/executiveDashboard/queryProjectStatusSummary?' + cond)
}

// 经营-查询项目状态分析列表 	projectStatus
export const queryProjectStatusSummaryList = function (cond = '') {
  return Axios.get('/executiveDashboard/queryProjectStatusSummaryList?' + cond)
}

// 经营-查询资金风险监控
export const queryFundRiskMonitoring = function (cond = '') {
  return Axios.get('/executiveDashboard/queryFundRiskMonitoring?' + cond)
}

// 经营-查询资金风险监控列表 fundingRiskType
export const queryFundRiskMonitoringList = function (cond = '') {
  return Axios.get('/executiveDashboard/queryFundRiskMonitoringList?' + cond)
}

// 经营-查询公司收支趋势图
export const queryCompanyRevenueExpenseTrend = function (cond = '') {
  return Axios.get('/executiveDashboard/queryCompanyRevenueExpenseTrend?' + cond)
}

// 经营-查询项目利润趋势图
export const queryProjectProfitTrend = function (cond = '') {
  return Axios.get('/executiveDashboard/queryProjectProfitTrend?' + cond)
}

// 经营年度导出
export const executiveDashExecutiveData = function (cond = {}) {
  return Axios({
    url: '/executiveDashboard/exportAnnualExecutiveData',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

// 经营月度导出
export const executiveDashFinanceData = function (cond = {}) {
  return Axios({
    url: '/executiveDashboard/exportMonthlyFinanceData',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

// ===============================================================================================================

// 财务-查询财务驾驶舱标题数据
export const financialBalancesData = function (cond = '') {
  return Axios.get('/financialDashboard/queryFinancialBalancesData?' + cond)
}

// 财务-查询年度经营数据 	quarter
export const financiaAnnualExecutiveData = function (cond = '') {
  return Axios.get('/financialDashboard/queryAnnualExecutiveData?' + cond)
}

// 财务-查询公司月度财务数据  	month
export const financiaMonthlyFinanceReport = function (cond = '') {
  return Axios.get('/financialDashboard/queryMonthlyFinanceReport?' + cond)
}

// 财务-查询应收统计数据  pageSize	pageNow
export const queryReceivablesReport = function (cond = {}) {
  return Axios.post('/financialDashboard/queryReceivablesReport', cond)
}

// 财务-查询应付统计数据  pageSize	pageNow
export const queryPaymentReport = function (cond = {}) {
  return Axios.post('/financialDashboard/queryPaymentReport', cond)
}

// 财务-查询公司收支趋势图 	companyType
export const financiaCompanyRevenueExpenseTrend = function (cond = '') {
  return Axios.get('/financialDashboard/queryCompanyRevenueExpenseTrend?' + cond)
}

// 财务-查询项目利润趋势图
export const financiaProjectProfitTrend = function (cond = '') {
  return Axios.get('/financialDashboard/queryProjectProfitTrend?' + cond)
}

// 财务-查询项目(销售)合同、回款计划、开票及回款对比统计
export const financiaSalesContractPaymentSummary = function (cond = '') {
  return Axios.get('/financialDashboard/querySalesContractPaymentSummary?' + cond)
}

// 财务-查询项目(采购)合同、回款计划、开票及回款对比统计
export const financiaPurchasingContractPaymentSummary = function (cond = '') {
  return Axios.get('/financialDashboard/queryPurchasingContractPaymentSummary?' + cond)
}

// 财务-查询销项票开具趋势图
export const financiaInvoiceIssuanceTrend = function (cond = '') {
  return Axios.get('/financialDashboard/queryInvoiceIssuanceTrend?' + cond)
}

// 财务-查询进项票开具趋势图
export const financiaPurchaseInvoiceTrend = function (cond = '') {
  return Axios.get('/financialDashboard/queryPurchaseInvoiceTrend?' + cond)
}

// 财务-查询回款趋势图
export const financiaPaymentCollectionTrend = function (cond = '') {
  return Axios.get('/financialDashboard/queryPaymentCollectionTrend?' + cond)
}

// 财务-查询付款趋势图
export const financiaAccountsPayableTrend = function (cond = '') {
  return Axios.get('/financialDashboard/queryAccountsPayableTrend?' + cond)
}

// 财务-查询费用报销趋势图
export const financiaExpensesPayableTrend = function (cond = '') {
  return Axios.get('/financialDashboard/queryExpensesPayableTrend?' + cond)
}

// 财务年度导出
export const financialDashExecutiveData = function (cond = {}) {
  return Axios({
    url: '/financialDashboard/exportAnnualExecutiveData',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

// 财务月度导出
export const financialDashFinanceData = function (cond = {}) {
  return Axios({
    url: '/financialDashboard/exportMonthlyFinanceData',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

// 财务应收导出
export const exportReceivablesData = function (cond = {}) {
  return Axios({
    url: '/financialDashboard/exportReceivablesData',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}
