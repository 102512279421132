<template>
  <div id="histogramMonthId" style="width: 100%; height: 400px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'HistogramMonth',
  props: {
    incomeList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {}
  },
  computed: {},

  watch: {
    incomeList: {
      deep: true,
      immediate: true,
      handler: function (list) {
        if (list.length > 0) {
          this.init(list)
        } else {
          this.init([])
        }
      },
    },
  },
  async created() {},
  mounted() {},
  methods: {
    // 项目月度收入统计
    init(arr = []) {
      this.$nextTick(() => {
        function transition(num) {
          // 将数字转换为字符串，找到小数点的位置
          let str = num.toString()
          let dotIndex = str.indexOf('.')
          // 如果没有小数点，直接返回字符串
          if (dotIndex === -1) {
            return str
          }
          // 截取小数点后一位
          return Number(str.slice(0, dotIndex + 2)) // +2 是包括小数点和一位数字
        }

        var chartDom = document.getElementById('histogramMonthId')
        var myChart = echarts.init(chartDom)
        var option
        const nameArr = arr.map(v => v.name)
        option = {
          tooltip: {
            show: true,
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            formatter: function (params) {
              // 获取当前月份
              const currentMonth = params[0].name // 假设第一个参数的 name 是月份
              // 定义提示框的内容
              let tooltipContent = currentMonth + '<br/>' // 添加当前月份
              params.forEach(function (item) {
                const value = Number(item.data).toLocaleString()
                tooltipContent += item.seriesName + ': ' + value + '元' + '<br/>'
              })
              return tooltipContent || '无数据' // 如果没有要显示的数据，则返回“无数据”
            },
          },

          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          legend: {
            data: nameArr,
          },
          xAxis: [
            {
              type: 'category',
              data: [
                '1月',
                '2月',
                '3月',
                '4月',
                '5月',
                '6月',
                '7月',
                '8月',
                '9月',
                '10月',
                '11月',
                '12月',
              ],
              axisPointer: {
                type: 'shadow', // 鼠标悬停时显示的指示线类型
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              min: 10000, // 设置y轴最小值
              interval: 100000, // 设置每个间隔
              max: 2000000,
              axisLabel: {
                formatter: val => {
                  if (val === 1) {
                    return 0
                  } else if (val >= 100000000) {
                    return val / 100000000 + '亿'
                  } else if (val >= 10000) {
                    return val / 10000 + 'W'
                  }
                  return val // 其他情况下直接返回原数值
                },
              },
            },
            // {
            //   type: 'log',
            //   min: 10000, // 设置y轴最小值
            //   interval: 1000000, // 设置每个间隔
            //   max: 10000000,
            //   axisLabel: {
            //     formatter: val => {
            //       // if (val === 1) {
            //       //   return 0
            //       // } else
            //       if (val >= 100000000) {
            //         return val / 100000000 + '亿'
            //       } else if (val >= 10000) {
            //         return val / 10000 + 'W'
            //       } else if (val >= 1000) {
            //         return val / 1000 + 'K'
            //       }
            //       return val // 其他情况下直接返回原数值
            //     },
            //   },
            // },
          ],
          series: [
            {
              name: nameArr[0],
              type: 'bar',
              barGap: '0%', // 中间不间隔
              data: arr[0]?.data,
              label: {
                show: true, // 显示标签
                position: 'top',
                color: '#000', // 标签文字颜色
                fontSize: 12, // 标签文字大小
                formatter: function (params) {
                  if (params.value >= 100000000) {
                    return transition(params.value / 100000000) + '亿'
                  } else if (params.value >= 10000) {
                    return transition(params.value / 10000) + 'W'
                  } else if (params.value >= 1000) {
                    return transition(params.value / 1000) + 'K'
                  } else {
                    return params.value
                  }
                },
              },
            },
            {
              name: nameArr[1],
              type: 'bar',
              barGap: '0%', // 中间不间隔
              data: arr[1]?.data,
              label: {
                show: true, // 显示标签
                position: 'top',
                color: '#000', // 标签文字颜色
                fontSize: 12, // 标签文字大小
                formatter: function (params) {
                  if (params.value >= 100000000) {
                    return transition(params.value / 100000000) + '亿'
                  } else if (params.value >= 10000) {
                    return transition(params.value / 10000) + 'W'
                  } else if (params.value >= 1000) {
                    return transition(params.value / 1000) + 'K'
                  } else {
                    return params.value
                  }
                },
              },
            },
          ],
        }
        option && myChart.setOption(option)
        myChart.setOption(option, true) // 第二个参数设置为 true，表示完全更新配置项
        //监听窗口变化
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      })
    },
  },
}
</script>
<style scoped></style>
