<template>
  <div>
    <div class="mainList">
      <div class="fl department_div">
        <div class="role_search">
          角色：<el-input placeholder="输入角色关键字进行过滤" size="small" v-model="roleFilter">
          </el-input>
        </div>
        <el-tree
          ref="roleTree"
          default-expand-all
          node-key="id"
          highlight-current
          :data="roleTree"
          :props="defaultProps"
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
          :filter-node-method="filterNode"
        >
        </el-tree>
      </div>
      <div class="table_right">
        <!-- <div class="mainList_operation clearfloat">
          <div class="mainList_operation_inline_block">
            <div class="mainList_operation_search">
              <el-input
                placeholder="请输入内容"
                clearable
                v-model="parameter.val"
                class="input-with-select project_width"
              >
                <span slot="prepend">用户名</span>
                <el-button
                  slot="append"
                  style="width: 35px; line-height: 30px"
                  icon="el-icon-search"
                  @click="pageChangeHandler(1)"
                ></el-button>
              </el-input>
            </div>
          </div>
          <div class="fr">
            <el-button type="primary" icon="el-icon-plus" @click="onAdd">新增</el-button>
          </div>
        </div> -->
        <div class="mainList_content">
          <div class="mainList_content_sub">
            <el-table
              ref="multipleTable"
              border
              :data="tableData"
              tooltip-effect="dark"
              height="string"
              :highlight-current-row="true"
              v-loading="loading"
              :key="Math.random()"
            >
              <el-table-column
                label="序号"
                type="index"
                align="center"
                width="70"
                :show-overflow-tooltip="false"
              ></el-table-column>
              <el-table-column
                align="center"
                width="110"
                prop="userName"
                label="姓名"
              ></el-table-column>
              <el-table-column prop="deptNames" align="center" label="部门"></el-table-column>
              <el-table-column prop="roleNames" align="center" label="关联角色"></el-table-column>

              <!-- <el-table-column label="操作" align="center" width="90">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    class="text_Remove_Bgc"
                    @click="onRoleDel(scope.row.id)"
                    >删除</el-button
                  >
                  <el-button
                      type="text"
                      size="small"
                      class="text_Edit_Bgc"
                      v-if="permission(['EDIT_USER'])"
                      @click.stop="edit(scope.row)"
                      >编辑</el-button
                    >
                </template>
              </el-table-column> -->
            </el-table>
            <el-pagination
              @current-change="pageChangeHandler"
              @size-change="handleSizeChange"
              :current-page="parameter.pageNow"
              :page-size="parameter.pageSize"
              prev-text="上一页"
              next-text="下一页"
              :total="parameter.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <EditDialog width="600px" :isShow.sync="isRole" @submit="onRoleUserFn">
      <div slot="title" class="title">角色与用户关联</div>
      <div slot="content" class="content">
        <el-form label-width="4em" :model="roleUsrInfo" ref="roleUsrInfo">
          <el-form-item label="角色" prop="purposeDepartment" ref="purposeDepartment">
            <el-select
              filterable
              :disabled="parameter.roleId ? true : false"
              v-model="roleUsrInfo.roleId"
              placeholder="请选择角色"
            >
              <el-option
                v-for="item in roleArr"
                :key="item.id"
                :label="item.roleName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户" prop="purposeDepartment" ref="purposeDepartment">
            <el-select
              ref="select"
              multiple
              v-model="roleUsrInfo.userList"
              @visible-change="visibleChange"
              placeholder="请选择用户"
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.userName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </EditDialog>

    <el-dialog
      :visible.sync="checkBoxDialogVisable"
      :before-close="cancelSelect"
      append-to-body
      width="65%"
    >
      <div slot="title" style="color: #409eff">选择用户</div>
      <div class="mainList_operation_search">
        <el-input
          class="mainList_operation_search_Name"
          style="width: 200px; margin: 0 8px 8px 8px"
          placeholder="姓名"
          clearable
          v-model="checkBoxDialogParameter.userName"
        ></el-input>
        <el-button
          type="primary"
          plain
          icon="el-icon-search"
          @click="checkBoxDialogPageChangeHandler(1)"
          >搜索</el-button
        >
      </div>
      <el-table
        :data="checkBoxDialogList"
        style="width: 100%"
        max-height="400"
        border
        id="selectedTable"
        :row-key="getRowKey"
        @selection-change="handleCurrentChange"
        @row-click="handRowClick"
        :row-style="rowClass"
        v-loading="checkBoxDialogLoading"
        ref="selectedTable"
      >
        <el-table-column
          type="selection"
          label="选择"
          :reserve-selection="true"
          :selectable="selectableStatus"
          width="60"
        >
        </el-table-column>
        <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
        <el-table-column align="center" prop="deptNames" label="部门"> </el-table-column>
      </el-table>
      <div style="float: right">
        <el-pagination
          @size-change="checkBoxDialogHandleSizeChange"
          @current-change="checkBoxDialogPageChangeHandler"
          :current-page="checkBoxDialogParameter.pageNow"
          :page-size="checkBoxDialogParameter.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="total, prev, pager, next, slot, jumper"
          :total="checkBoxDialogParameter.total"
        >
          <span class="el-pagination__jump e_a_pagination">
            <el-input size="mini" v-model.number="checkBoxDialogParameter.pageSize"></el-input>
            <span style="padding-top: 1px">条/页</span>
          </span>
        </el-pagination>
      </div>
      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button type="success" style="min-width: 120px; margin: 0 25px" @click="saveSelectedFn"
          >确 定</el-button
        >
        <el-button
          type="warning"
          style="min-width: 120px; margin: 0 25px"
          plain
          @click="cancelSelect"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'

export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        roleId: null,
      },
      roleTree: [], //角色树形列表
      //角色树形对象 结构
      defaultProps: {
        children: 'children',
        label: 'roleName',
      },
      tableData: [],
      roleFilter: '',
      roleUsrInfo: {
        roleId: '',
        userList: [],
      },
      userList: [],
      roleArr: [],
      isRole: false,
      isUserShow: false,
      checkBoxDialogParameter: {
        userName: '',
        pageNow: 1,
        total: 0,
        pageSize: 50,
      },
      checkBoxDialogLoading: false,
      checkBoxDialogVisable: false,
      checkBoxDialogList: [],
      temporaryList: [],
      selectRow: [], //控制选中行变色
    }
  },
  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
    temporaryList: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.selectRow = []
        if (val.length > 0) {
          this.selectRow = val.map(v => v.id)
        }
      },
    },
    roleFilter: {
      handler: function (val) {
        this.$refs.roleTree.filter(val)
        if (!val) {
          this.parameter.roleId = null
          this.getData()
        }
      },
    },
  },
  created() {
    this.getRoleTree()
    this.getData()
  },
  methods: {
    filterNode(value, row) {
      if (!value) return true
      return row.roleName.indexOf(value) !== -1
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    getData() {
      this.loading = true
      if (this.conttll == 'userName') {
        this.parameter.userName = this.parameter.val
        this.parameter.loginName = ''
      } else {
        this.parameter.loginName = this.parameter.val
        this.parameter.userName = ''
      }

      this.$api.user
        .listStaff(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data.records) {
            res.data.records.forEach(item => {
              item.labelList = item.labelList ? item.labelList : []
            })
            this.tableData = res.data.records
          }
          this.parameter.total = ~~res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    onRoleDel(id) {
      this.$confirm('此操作将删除该用户与角色之间关联, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.user
            .del(id)
            .then(res => {
              this.$message.success('删除成功！')

              var hasLastPage =
                this.parameter.pageSize * this.parameter.pageNow >= this.parameter.total
              var lastData = this.tableData.length < 2
              if (lastData && hasLastPage && this.parameter.pageNow > 1) {
                this.parameter.pageNow--
              }
              this.getData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    async onAdd() {
      this.roleUsrInfo.roleId = this.parameter.roleId
      const { data = [] } = await this.$api.user.listStaff({ pageNow: 1, pageSize: 99999 })
      this.userList = data.records
      this.checkBoxDialogList = data.records.map(v => {
        v.roleList = v.roleList ? v.roleList : []
        v.disabled = v.roleList.some(id => id == this.roleUsrInfo.roleId)
        return v
      })
      this.checkBoxDialogParameter.total = data.total
      this.isRole = true
    },
    onRoleUserFn() {},
    /**
     * 点击 "角色" 树形列表
     * @param row      点击获取数据
     */
    handleNodeClick(row) {
      this.parameter.roleId = row.id
      this.getData()
    },
    getRoleTree() {
      this.$api.role
        .listRole()
        .then(res => {
          this.roleTree = initTreeData(res.data)
          this.roleArr = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    visibleChange() {
      // 关闭选择下拉框，跳出弹框
      this.$refs.select.blur()
      this.checkBoxDialogVisable = true

      this.$nextTick(() => {
        this.checkBoxDialogList.forEach(item => {
          this.$refs.selectedTable.toggleRowSelection(item, item.disabled)
        })
      })
    },

    selectableStatus(row, rowIndex) {
      // 如果行数据中有disabled属性，则返回false禁用多选
      return !row.disabled
    },
    getRowKey(row) {
      return row.id
    },
    handleCurrentChange(val) {
      this.temporaryList = val
    },
    // 对所选行进行样式设置
    rowClass({ row, rowIndex }) {
      if (this.selectRow.includes(row.id)) {
        return { 'background-color': '#ede88c' }
      }
    },
    // 点击行选中
    handRowClick(row) {
      if (!row.disabled) {
        this.$refs.selectedTable.toggleRowSelection(row)
      }
    },
    async getSelectedList() {
      this.checkBoxDialogLoading = true
      const { data = [] } = await this.$api.user.listStaff(this.checkBoxDialogParameter)
      this.checkBoxDialogList = data.records.map(v => {
        v.roleList = v.roleList ? v.roleList : []
        const isDisabled = v.roleList.some(id => id == this.roleUsrInfo.roleId)
        v.disabled = isDisabled
        return v
      })
      this.$nextTick(() => {
        this.checkBoxDialogList.forEach(item => {
          this.$refs.selectedTable.toggleRowSelection(item, item.disabled)
          if (this.selectRow.indexOf(item.id) != -1 && !item.disabled) {
            this.$refs.selectedTable.toggleRowSelection(item)
          }
        })
      })
      this.checkBoxDialogLoading = false

      this.checkBoxDialogParameter.total = data.total
    },
    checkBoxDialogPageChangeHandler(val) {
      this.checkBoxDialogParameter.pageNow = val
      this.getSelectedList()
    },
    checkBoxDialogHandleSizeChange(val) {
      this.checkBoxDialogParameter.pageSize = val
      this.checkBoxDialogParameter.pageNow = 1
      this.getSelectedList()
    },
    cancelSelect() {
      this.checkBoxDialogVisable = false
      this.checkBoxDialogParameter = {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        userName: '',
      }
      this.temporaryList = []
    },
    saveSelectedFn() {
      this.temporaryList.forEach(v => {
        if (!v.disabled) {
          this.roleUsrInfo.userList.push(v.id)
        }
      })
      this.cancelSelect()
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.mainList {
  display: block;
}
.department_div {
  width: 306px;
  height: 100%;
  background-color: #ffffff;
  margin-right: 10px;
  padding: 10px 0px;
  overflow: auto;
}

.table_right {
  float: right;
  width: calc(100% - 316px);
  height: 100%;
  // height: calc(100% - 90px);
}

.tran_style {
  white-space: nowrap;
  padding: 10px;
}
// 样式部分
.flex_square {
  display: flex;
  align-items: center;
  .tree-tranfer {
    width: 37%;
    height: 350px;
    border: 1px solid #ebeef5;
    padding: 10px;
    overflow-y: auto;
  }
  .transfer-btns {
    padding: 0px 30px;
  }
  .tree-tranfer-tite {
    font-size: 16px;
    background-color: #f5f7fa;
    text-align: center;
  }
}
/deep/#selectedTable {
  .tableRowClassName {
    background-color: #ede88c;
  }

  tbody tr:hover > td {
    background-color: unset !important; //修改成自己想要的颜色即可
  }
}
.multipleTable {
  overflow: auto;
}
.text_Edit_Bgc {
  background-color: #e6a23c;
  color: #fff;
  padding: 5px 5px;
}
.text_Remove_Bgc {
  background-color: #f78888;
  color: #fff;
  padding: 5px 5px;
}

#selectedTable {
  /deep/.el-table__header {
    .el-checkbox {
      display: none;
    }
  }
}
.role_search {
  display: flex;
  align-items: center;
  color: #000;
  .el-input {
    width: 220px;
  }
}
</style>
