<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">项目状态分析</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'ProjectSource'"
            :yearIsShow="true"
            :multiple="true"
            :list="dictData.yearList"
            v-model="sectionInfo.projectStatusYear"
            @change="onProjectSourceChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onprojectStatusImg"
          >数据来源说明</el-button
        >
      </div>
      <div class="statistics_top">
        <el-card style="width: 49.7%"
          ><span class="title_fw">我方合同金额</span>
          <ProjectStatus :projectStatusList="projectStatusList" />
        </el-card>
        <el-card style="width: 49.7%"
          ><span class="title_fw">回款金额</span>
          <PaymentProjectSource :projectStatusList="projectStatusList" />
        </el-card>
      </div>
    </el-card>
    <el-card class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <span class="title_fw">项目状态分析统计</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'projectStatusData'"
            :introductionType="'projectStatus'"
            :yearIsShow="true"
            :multiple="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.projectStatus"
            v-model="sectionInfo.projectStatusDataYear"
            @change="onProjectStatusDataChange"
            @onChangeLoading="projectStatusDataLoading = $event"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :title="'项目状态：'"
            :type="'projectStatusData'"
            :introductionType="'projectStatus'"
            :width="180"
            :list="dictData.projectStatus"
            :yearValue="sectionInfo.projectStatusDataYear"
            :twoValue="sectionInfo.projectStatus"
            v-model="sectionInfo.projectStatus"
            @change="onProjectStatusDataChange"
            @onChangeLoading="projectStatusDataLoading = $event"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onProjectStatusDataImg"
          >数据来源说明</el-button
        >
      </div>
      <el-table
        height="330px"
        :summary-method="getSummaries"
        v-loading="projectStatusDataLoading"
        show-summary
        border
        ref="table"
        :data="projectStatusData"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="projectName"
          label="项目名称"
          align="center"
          fixed="left"
          min-width="240"
        ></el-table-column>
        <el-table-column
          prop="projectManager"
          label="项目经理"
          align="center"
          fixed="left"
          min-width="90"
        ></el-table-column>
        <el-table-column prop="contractingCompany" min-width="110" sortable align="center">
          <template slot-scope="{}" slot="header">
            <span>承接公司</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情中的承接项目公司</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.contractingCompany | dict(dictData.companyType) }}
          </template>
        </el-table-column>
        <el-table-column prop="projectStatus" min-width="110" sortable align="center">
          <template slot-scope="{}" slot="header">
            <span>项目状态</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情中的项目状态</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.projectStatus | dict(dictData.projectStatus) }}
          </template>
        </el-table-column>
        <el-table-column prop="predictContractAmount" min-width="140" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>我方合同金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情中的我方合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.predictContractAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="contractAmount" min-width="180" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>合同实际金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>金额前面有（大），该项目即为大包合同子项</div>
                <div>项目详情里的合同实际金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.isLargePackage == 1 ? '（大）' : '' }}
            {{ scope.row.contractAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="actualAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>项目实际金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情中的项目实际金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="invoiceAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>已开票金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里项目收入中的已开票金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.invoiceAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="repaymentAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>已收款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里项目收入中的已收款金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.repaymentAmount | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          v-show="imgUrl == '经营统计-项目状态'"
          src="@/assets/经营统计-项目状态.png"
          alt="数据来源说明"
          width="100%"
        />
        <img
          v-show="imgUrl == '经营统计-项目状态统计'"
          src="@/assets/经营统计-项目状态统计.png"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    ProjectStatus: () => import('./projectStatus.vue'),
    PaymentProjectSource: () => import('./paymentProjectSource.vue'),
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      dictData: {
        yearList: [],
        projectStatus: [],
        companyType: [],
      },
      projectStatusList: [], // 项目状态分析
      projectStatusData: [],
      sectionInfo: {
        // 条件筛选 // 年份
        projectStatusYear: [],
        projectStatusDataYear: [],
        projectStatus: '',
      },
      titleYear: '统计时间',
      isDialog: false,
      imgUrl: '',
      contractAmount: 0,
      projectStatusDataLoading: false,
    }
  },
  provide() {
    return {}
  },
  computed: {},
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout()
    })
  },
  methods: {
    onprojectStatusImg() {
      this.imgUrl = '经营统计-项目状态'
      this.isDialog = true
    },
    onProjectStatusDataImg() {
      this.imgUrl = '经营统计-项目状态统计'
      this.isDialog = true
    },
    onProjectStatusDataChange(obj) {
      this.projectStatusData = obj?.projectStatusResponseLists || []
      this.contractAmount = obj?.projectPaymentAmount || 0
    },
    onProjectSourceChange(arr) {
      this.projectStatusList = arr
    },
    async getType() {
      const projectStatus = await this.$api.dict.listSysDictData('PROJECT_NEW_STATUS', true)
      this.dictData.projectStatus = projectStatus.data

      const item = this.dictData.projectStatus.some(v => v.id === '1')
      if (!item) {
        this.dictData.projectStatus.unshift({
          id: '1',
          dictName: '全部',
          dictVal: '',
        })
      }
      this.loading = true
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(async res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.projectStatusYear = [dictVal]
          this.sectionInfo.projectStatusDataYear = [dictVal]

          const queryProjectStatusSummary = await this.$api.statistics.queryProjectStatusSummary(
            `date=${dictVal}`
          )
          const queryprojectStatusSummaryList =
            await this.$api.statistics.queryProjectStatusSummaryList(`date=${dictVal}`)

          this.projectStatusList =
            queryProjectStatusSummary.data?.projectStatusStatisticsVoList.map(item => {
              const projectStatusItem = projectStatus.data.find(
                v => v.dictVal === item.projectStatus
              )
              return {
                projectPaymentAmount: item.projectPaymentAmount,
                paymentPercentage: Number((item.paymentPercentage * 100).toFixed(2)),
                projectAmount: item.projectAmount,
                projectPercentage: Number((item.projectPercentage * 100).toFixed(2)),
                projectStatusCount: item.projectStatusCount,
                name: projectStatusItem ? projectStatusItem.dictName : item.projectStatus, // 如果找不到则使用原名称
              }
            })
          this.projectStatusData =
            queryprojectStatusSummaryList?.data?.projectStatusStatisticsView
              ?.projectStatusResponseLists || []
          this.contractAmount =
            queryprojectStatusSummaryList?.data?.projectStatusStatisticsView
              ?.projectPaymentAmount || 0
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index > 4) {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            if (index === 6) {
              sums[index] = Number(this.contractAmount.toFixed(2)).toLocaleString()
            } else {
              sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
            }
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        } else {
          sums[index] = '--'
        }
      })

      return sums
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
