<template>
  <div>
    <el-dialog
      :visible.sync="isDialog"
      append-to-body
      :width="width + '%'"
      :before-close="cancellationFn"
      v-loading="loading"
    >
      <div slot="title" style="color: #409eff">
        <div class="business-center">
          <div class="before-change">更改前</div>
          <div class="after-the-alteration">更改后</div>
        </div>
      </div>
      <div class="business-center">
        <div class="business-left" v-if="form.oldData">
          <el-descriptions title="商务基本信息" direction="vertical" :column="3" border>
            <el-descriptions-item
              :span="3"
              :contentStyle="
                form.changeFieldList.includes('businessName')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <template slot="label"> 商务名称 </template>

              <div>
                {{ form.oldData.businessName }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('initiatorId')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <template slot="label"> 商务创建人 </template>
              <div class="input_style">
                <div>{{ getLinshi_initiator(form.oldData.initiatorId) }}</div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="信息提供人"
              :contentStyle="
                form.changeFieldList.includes('provideId') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <template slot="label"> 信息提供人 </template>
              <div>
                {{ getLinshi_initiator(form.oldData.provideId) }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('companyType')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <template slot="label">项目承接公司 </template>
              <div>
                {{ form.oldData.companyType | dict(dictData.companyType) }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :span="1"
              label="商务实际开始时间"
              :contentStyle="
                form.changeFieldList.includes('businessStartDate')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>{{ form.oldData.businessStartDate | dateFormat }}</div>
            </el-descriptions-item>
            <el-descriptions-item
              :span="2"
              label="业主单位"
              :contentStyle="
                form.changeFieldList.includes('ownerUnit') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <div>{{ form.oldData.ownerUnit }}</div>
            </el-descriptions-item>

            <el-descriptions-item
              label="经办人"
              :contentStyle="
                form.changeFieldList.includes('contacts') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <div>{{ form.oldData.contacts }}</div>
            </el-descriptions-item>
            <el-descriptions-item
              label="经办人职务"
              :contentStyle="
                form.changeFieldList.includes('post') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <div>
                {{ form.oldData.post }}
              </div></el-descriptions-item
            >
            <el-descriptions-item
              label="经办人电话"
              :contentStyle="
                form.changeFieldList.includes('contactsPhone')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.contactsPhone }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="决策人"
              :contentStyle="
                form.changeFieldList.includes('decisionMaker')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.decisionMaker }}
              </div></el-descriptions-item
            >
            <el-descriptions-item
              label="决策人职务"
              :contentStyle="
                form.changeFieldList.includes('decisionMakerPost')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.decisionMakerPost }}
              </div></el-descriptions-item
            >
            <el-descriptions-item
              label="决策人电话"
              :contentStyle="
                form.changeFieldList.includes('decisionMakerContactsPhone')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.decisionMakerContactsPhone }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('bidWay') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <template slot="label"> 招标方式 </template>
              <div>
                {{ form.oldData.bidWay | dict(dictData.bidwayOptions) }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="招投标费用(元)"
              :contentStyle="
                form.changeFieldList.includes('tenderFee') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <div>
                {{ form.oldData.tenderFee | applyAmount }}
              </div></el-descriptions-item
            >
            <el-descriptions-item label="招投标费用大写"
              >{{ form.oldData.tenderFee | numberParseChina }}
            </el-descriptions-item>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('predictMoney')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <template slot="label"> 预计合同金额(元) </template>
              <div>
                {{ form.oldData.predictMoney | applyAmount }}
              </div></el-descriptions-item
            >
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('ourContractAmount')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <template slot="label"> 我方合同金额(元) </template>
              <div>
                {{ form.oldData.ourContractAmount | applyAmount }}
              </div></el-descriptions-item
            >
            <!-- <el-descriptions-item label="我方合同金额大写"
              >{{ form.oldData.ourContractAmount | numberParseChina }}
            </el-descriptions-item> -->
          </el-descriptions>

          <el-descriptions direction="vertical" :column="1" border>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('content') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <template slot="label"> 商务说明 </template>
              <div>
                {{ form.oldData.content }}
              </div>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions
            style="margin-top: 10px"
            title="税率"
            direction="vertical"
            :column="5"
            border
          >
            <el-descriptions-item
              label="我方合同金额(元)"
              :span="1"
              :contentStyle="
                form.changeFieldList.includes('ourContractAmount')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>{{ form.oldData.ourContractAmount | applyAmount }}</div></el-descriptions-item
            >
            <el-descriptions-item
              :span="1"
              :contentStyle="
                form.changeFieldList.includes('taxType') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <template slot="label"> 税费类型 </template>
              <div>
                {{ form.oldData.taxType | dict(dictData.taxType) }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="增值税类型"
              :span="2"
              :contentStyle="
                form.changeFieldList.includes('vatType') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <div>
                {{ form.oldData.vatType | transitionType(zzsList) }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="增值税类型详细"
              :span="1"
              :contentStyle="
                form.changeFieldList.includes('vatData') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <div>
                {{ form.oldData.vatData | transitionData(dictData.oldVatData) }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('vatRate') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <template slot="label"> 增值税税率(%) </template>
              <div>
                {{ form.oldData.vatRate }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="增值税"
              :contentStyle="
                form.changeFieldList.includes('actualVat') ? { 'background-color': '#bff0de' } : ''
              "
            >
              {{ form.oldData.actualVat | applyAmount }} （元）
            </el-descriptions-item>
            <el-descriptions-item label="附加税税率">12%</el-descriptions-item>
            <el-descriptions-item
              label="附加税"
              :contentStyle="
                form.changeFieldList.includes('actualAdditionalTax')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              {{ form.oldData.actualAdditionalTax | applyAmount }} （元）
            </el-descriptions-item>
            <el-descriptions-item label="印花税税率">0.03%</el-descriptions-item>
            <el-descriptions-item
              label="印花税"
              :contentStyle="
                form.changeFieldList.includes('actualStampTax')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              {{ form.oldData.actualStampTax | applyAmount }} （元）
            </el-descriptions-item>
            <el-descriptions-item
              label="税费"
              :contentStyle="
                form.changeFieldList.includes('taxesFee') ? { 'background-color': '#bff0de' } : ''
              "
            >
              {{ form.oldData.taxesFee | applyAmount }} （元）
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions title="业务类型" direction="vertical" :column="3" border>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('businessType')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <template slot="label"> 商务类型 </template>
              <div>
                {{ form.oldData.businessType | dict(dictData.businessType) }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('businessLines')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <template slot="label"> 业务种类 </template>
              <div>
                {{ form.oldData.businessLines | dict(dictData.businessLines) }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="项目种类"
              :contentStyle="
                form.changeFieldList.includes('projectLines')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              {{ form.oldData.projectLines | dict(dictData.projectLines) }}
            </el-descriptions-item>
            <el-descriptions-item
              :span="3"
              :contentStyle="
                form.changeFieldList.includes('projectSource')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <template slot="label"> 项目来源 </template>
              <div>
                <el-radio-group v-model="form.oldData.projectSource">
                  <div class="radio-group">
                    <div class="radio-label">生产类型:</div>
                    <div class="radio-item">
                      <el-radio
                        :disabled="true"
                        v-for="item in dictData.produceList"
                        :key="item.id"
                        :label="item.dictVal"
                        >{{ item.dictName }}</el-radio
                      >
                    </div>
                  </div>
                  <div class="radio-group">
                    <div class="radio-label">非生产类型:</div>
                    <div class="radio-item">
                      <el-radio
                        :disabled="true"
                        v-for="item in dictData.unProduceList"
                        :key="item.id"
                        :label="item.dictVal"
                        >{{ item.dictName }}</el-radio
                      >
                    </div>
                  </div>
                </el-radio-group>
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="合作费比例(%)"
              v-if="form.oldData.projectSource == 'zi_zhi_he_zuo'"
              :contentStyle="
                form.changeFieldList.includes('collaborationCostsRatio')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>{{ form.oldData.collaborationCostsRatio }}</div>
            </el-descriptions-item>
            <el-descriptions-item
              label="外部合作者"
              :span="2"
              :contentStyle="
                form.changeFieldList.includes('externalPartnere')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                <el-radio :disabled="true" v-model="form.oldData.externalPartnere" label="NOT"
                  >无外部合作者</el-radio
                >
                <el-radio :disabled="true" v-model="form.oldData.externalPartnere" label="HAVE"
                  >有外部合作者</el-radio
                >
              </div>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions
            v-if="form.oldData.externalPartnere == 'HAVE'"
            style="margin-top: 10px"
            title="外部协作信息"
            direction="vertical"
            :column="2"
            border
          >
            <el-descriptions-item
              label="费用结算方式"
              :contentStyle="
                form.changeFieldList.includes('contentCooperationType')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.contentCooperationType == 1 ? '按具体金额' : '按比例' }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="预计外部协作费用金额(元)"
              v-if="form.oldData.contentCooperationType == 1"
              :contentStyle="
                form.changeFieldList.includes('contentCooperationMoney')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <template slot="label"> 预计外部协作费用金额(元) </template>
              <div>
                {{ form.oldData.contentCooperationMoney | applyAmount }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="预计外部协作费用比例(%)"
              v-if="form.oldData.contentCooperationType == 2"
              :contentStyle="
                form.changeFieldList.includes('contentCooperationRatio')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.contentCooperationRatio }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="外部协作内容"
              :span="2"
              :contentStyle="
                form.changeFieldList.includes('contentCooperationContent')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>{{ form.oldData.contentCooperationContent }}</div>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions
            style="margin-top: 10px"
            title="角色分配"
            direction="vertical"
            :column="4"
            border
          >
            <el-descriptions-item
              label="建议商务负责人"
              :contentStyle="
                form.changeFieldList.includes('suggestionPrincipalUserId')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ getLinshi_initiator(form.oldData.suggestionPrincipalUserId) }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('principalUserId')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <template slot="label"> 实际商务负责人 </template>
              <div>
                {{ getLinshi_initiator(form.oldData.principalUserId) }}
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>

        <div class="business-right" v-if="form.newData">
          <el-descriptions title="商务基本信息" direction="vertical" :column="3" border>
            <el-descriptions-item
              :span="3"
              :contentStyle="
                form.changeFieldList.includes('businessName')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <template slot="label"> 商务名称 </template>

              <div>
                {{ form.newData.businessName }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('initiatorId')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <template slot="label"> 商务创建人 </template>
              <div class="input_style">
                <div>{{ getLinshi_initiator(form.newData.initiatorId) }}</div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="信息提供人"
              :contentStyle="
                form.changeFieldList.includes('provideId') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <template slot="label"> 信息提供人 </template>
              <div>
                {{ getLinshi_initiator(form.newData.provideId) }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('companyType')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <template slot="label">项目承接公司 </template>
              <div>
                {{ form.newData.companyType | dict(dictData.companyType) }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :span="1"
              label="商务实际开始时间"
              :contentStyle="
                form.changeFieldList.includes('businessStartDate')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>{{ form.newData.businessStartDate | dateFormat }}</div>
            </el-descriptions-item>
            <el-descriptions-item
              :span="2"
              label="业主单位"
              :contentStyle="
                form.changeFieldList.includes('ownerUnit') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <div>{{ form.newData.ownerUnit }}</div>
            </el-descriptions-item>

            <el-descriptions-item
              label="经办人"
              :contentStyle="
                form.changeFieldList.includes('contacts') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <div>{{ form.newData.contacts }}</div>
            </el-descriptions-item>
            <el-descriptions-item
              label="经办人职务"
              :contentStyle="
                form.changeFieldList.includes('post') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <div>
                {{ form.newData.post }}
              </div></el-descriptions-item
            >
            <el-descriptions-item
              label="经办人电话"
              :contentStyle="
                form.changeFieldList.includes('contactsPhone')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.contactsPhone }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="决策人"
              :contentStyle="
                form.changeFieldList.includes('decisionMaker')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.decisionMaker }}
              </div></el-descriptions-item
            >
            <el-descriptions-item
              label="决策人职务"
              :contentStyle="
                form.changeFieldList.includes('decisionMakerPost')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.decisionMakerPost }}
              </div></el-descriptions-item
            >
            <el-descriptions-item
              label="决策人电话"
              :contentStyle="
                form.changeFieldList.includes('decisionMakerContactsPhone')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.decisionMakerContactsPhone }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('bidWay') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <template slot="label"> 招标方式 </template>
              <div>
                {{ form.newData.bidWay | dict(dictData.bidwayOptions) }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="招投标费用(元)"
              :contentStyle="
                form.changeFieldList.includes('tenderFee') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <div>
                {{ form.newData.tenderFee | applyAmount }}
              </div></el-descriptions-item
            >
            <el-descriptions-item label="招投标费用大写"
              >{{ form.newData.tenderFee | numberParseChina }}
            </el-descriptions-item>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('predictMoney')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <template slot="label"> 预计合同金额(元) </template>
              <div>
                {{ form.newData.predictMoney | applyAmount }}
              </div></el-descriptions-item
            >
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('ourContractAmount')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <template slot="label"> 我方合同金额(元) </template>
              <div>
                {{ form.newData.ourContractAmount | applyAmount }}
              </div></el-descriptions-item
            >
            <!-- <el-descriptions-item label="我方合同金额大写"
              >{{ form.newData.ourContractAmount | numberParseChina }}
            </el-descriptions-item> -->
          </el-descriptions>

          <el-descriptions direction="vertical" :column="1" border>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('content') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <template slot="label"> 商务说明 </template>
              <div>
                {{ form.newData.content }}
              </div>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions
            style="margin-top: 10px"
            title="税率"
            direction="vertical"
            :column="5"
            border
          >
            <el-descriptions-item
              label="我方合同金额(元)"
              :span="1"
              :contentStyle="
                form.changeFieldList.includes('ourContractAmount')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>{{ form.newData.ourContractAmount | applyAmount }}</div></el-descriptions-item
            >
            <el-descriptions-item
              :span="1"
              :contentStyle="
                form.changeFieldList.includes('taxType') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <template slot="label"> 税费类型 </template>
              <div>
                {{ form.newData.taxType | dict(dictData.taxType) }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="增值税类型"
              :span="2"
              :contentStyle="
                form.changeFieldList.includes('vatType') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <div>
                {{ form.newData.vatType | transitionType(zzsList) }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="增值税类型详细"
              :span="1"
              :contentStyle="
                form.changeFieldList.includes('vatData') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <div>
                {{ form.newData.vatData | transitionData(dictData.newVatData) }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('vatRate') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <template slot="label"> 增值税税率(%) </template>
              <div>
                {{ form.newData.vatRate }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="增值税"
              :contentStyle="
                form.changeFieldList.includes('actualVat') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              {{ form.newData.actualVat | applyAmount }} （元）
            </el-descriptions-item>
            <el-descriptions-item label="附加税税率">12%</el-descriptions-item>
            <el-descriptions-item
              label="附加税"
              :contentStyle="
                form.changeFieldList.includes('actualAdditionalTax')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              {{ form.newData.actualAdditionalTax | applyAmount }} （元）
            </el-descriptions-item>
            <el-descriptions-item label="印花税税率">0.03%</el-descriptions-item>
            <el-descriptions-item
              label="印花税"
              :contentStyle="
                form.changeFieldList.includes('actualStampTax')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              {{ form.newData.actualStampTax | applyAmount }} （元）
            </el-descriptions-item>
            <el-descriptions-item
              label="税费"
              :contentStyle="
                form.changeFieldList.includes('taxesFee') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              {{ form.newData.taxesFee | applyAmount }} （元）
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions title="业务类型" direction="vertical" :column="3" border>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('businessType')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <template slot="label"> 商务类型 </template>
              <div>
                {{ form.newData.businessType | dict(dictData.businessType) }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('businessLines')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <template slot="label"> 业务种类 </template>
              <div>
                {{ form.newData.businessLines | dict(dictData.businessLines) }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="项目种类"
              :contentStyle="
                form.changeFieldList.includes('projectLines')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              {{ form.newData.projectLines | dict(dictData.projectLines) }}
            </el-descriptions-item>
            <el-descriptions-item
              :span="3"
              :contentStyle="
                form.changeFieldList.includes('projectSource')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <template slot="label"> 项目来源 </template>
              <div>
                <el-radio-group v-model="form.newData.projectSource">
                  <div class="radio-group">
                    <div class="radio-label">生产类型:</div>
                    <div class="radio-item">
                      <el-radio
                        :disabled="true"
                        v-for="item in dictData.produceList"
                        :key="item.id"
                        :label="item.dictVal"
                        >{{ item.dictName }}</el-radio
                      >
                    </div>
                  </div>
                  <div class="radio-group">
                    <div class="radio-label">非生产类型:</div>
                    <div class="radio-item">
                      <el-radio
                        :disabled="true"
                        v-for="item in dictData.unProduceList"
                        :key="item.id"
                        :label="item.dictVal"
                        >{{ item.dictName }}</el-radio
                      >
                    </div>
                  </div>
                </el-radio-group>
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="合作费比例(%)"
              v-if="form.newData.projectSource == 'zi_zhi_he_zuo'"
              :contentStyle="
                form.changeFieldList.includes('collaborationCostsRatio')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>{{ form.newData.collaborationCostsRatio }}</div>
            </el-descriptions-item>
            <el-descriptions-item
              label="外部合作者"
              :span="2"
              :contentStyle="
                form.changeFieldList.includes('externalPartnere')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                <el-radio :disabled="true" v-model="form.newData.externalPartnere" label="NOT"
                  >无外部合作者</el-radio
                >
                <el-radio :disabled="true" v-model="form.newData.externalPartnere" label="HAVE"
                  >有外部合作者</el-radio
                >
              </div>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions
            v-if="form.newData.externalPartnere == 'HAVE'"
            style="margin-top: 10px"
            title="外部协作信息"
            direction="vertical"
            :column="2"
            border
          >
            <el-descriptions-item
              label="费用结算方式"
              :contentStyle="
                form.changeFieldList.includes('contentCooperationType')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.contentCooperationType == 1 ? '按具体金额' : '按比例' }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="预计外部协作费用金额(元)"
              v-if="form.newData.contentCooperationType == 1"
              :contentStyle="
                form.changeFieldList.includes('contentCooperationMoney')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <template slot="label"> 预计外部协作费用金额(元) </template>
              <div>
                {{ form.newData.contentCooperationMoney | applyAmount }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="预计外部协作费用比例(%)"
              v-if="form.newData.contentCooperationType == 2"
              :contentStyle="
                form.changeFieldList.includes('contentCooperationRatio')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.contentCooperationRatio }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="外部协作内容"
              :span="2"
              :contentStyle="
                form.changeFieldList.includes('contentCooperationContent')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>{{ form.newData.contentCooperationContent }}</div>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions
            style="margin-top: 10px"
            title="角色分配"
            direction="vertical"
            :column="4"
            border
          >
            <el-descriptions-item
              label="建议商务负责人"
              :contentStyle="
                form.changeFieldList.includes('suggestionPrincipalUserId')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ getLinshi_initiator(form.newData.suggestionPrincipalUserId) }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('principalUserId')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <template slot="label"> 实际商务负责人 </template>
              <div>
                {{ getLinshi_initiator(form.newData.principalUserId) }}
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>

      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button
          type="primary"
          style="min-width: 120px; margin: 0 25px"
          plain
          :loading="loading"
          @click="cancellationFn"
          >返 回</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
    VatDictionary: () => import('@/components/VatDictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 80,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      isDialog: false,
      loading: false,
      zzsList: [], //增值税类型
      dictData: {
        bidwayOptions: [], //招标方式
        projectSource: [], //项目来源
        produceList: [], //生产类
        unProduceList: [], //非生产类
        companyType: [], //项目承接
        oldVatData: [], //增值税详情
        newVatData: [], //增值税详情
        businessType: [], //商务类型
        businessLines: [], //业务种类
        projectLines: [], //项目种类
        taxType: [], //税费类型
      },
      form: {
        changeFieldList: [], //变更字段
        newData: {}, //	新数据
        oldData: {}, //	旧数据
      },
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.getData()
        }
        this.isDialog = newVal
      },
    },
  },
  computed: {
    ...mapState({
      CompanyMembers: state => state.business.CompanyMembers,
    }),
  },
  filters: {
    dateFormat(value) {
      if (value) {
        value = Number(value)
        return new Date(value).format('yyyy-MM-dd')
      } else {
        return ''
      }
    },
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return 0
      }
    },
    transitionType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        str = item?.vatName
      }
      return str
    },
    transitionData(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatData == val)
        str = item?.vatName
      }
      return str
    },
  },
  created() {
    this.getType()
  },
  mounted() {},
  methods: {
    getData() {
      this.loading = true
      this.$api.log
        .selectDetailById(this.options.id)
        .then(res => {
          if (res.data) {
            this.form = res.data
          }

          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    getLinshi_initiator(v) {
      let i = ''
      this.CompanyMembers.forEach(e => {
        if (e.id == v) {
          var arr = e.departmentName.split(',')
          i = e.userName + ' - ' + arr[0]
        }
      })
      return i
    },
    getType() {
      this.$api.dict
        .listSysDictData(this.form.businessLines, true)
        .then(res => {
          this.dictData.projectLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.vat
        .getVatDataList({ vatType: this.form.oldData.vatType, pageNow: 1, pageSize: 999 })
        .then(res => {
          if (res.data?.records) {
            this.dictData.oldVatData = res.data.records
          }
        })
      this.$api.vat
        .getVatDataList({ vatType: this.form.newData.vatType, pageNow: 1, pageSize: 999 })
        .then(res => {
          if (res.data?.records) {
            this.dictData.newVatData = res.data.records
          }
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.vat
        .getVatTypeList()
        .then(res => {
          this.zzsList = res?.data?.records
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_TOUBIAO', true)
        .then(res => {
          this.dictData.bidwayOptions = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('TAX_TYPE', true)
        .then(res => {
          this.dictData.taxType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
          const produceList = []
          const unProduceList = []
          this.dictData.projectSource.forEach(item => {
            switch (item.dictVal) {
              case 'yi_shou_zhi_qian':
                produceList.push(item)
                break
              case 'zi_zhi_he_zuo':
                produceList.push(item)
                break
              case 'fen_bao_zhuan_bao':
                produceList.push(item)
                break
              case 'zi_zhi_ti_gong':
                unProduceList.push(item)
                break
              case 'zheng_ti_wai_xie':
                unProduceList.push(item)
                break
              case 'shang_wu_ju_jian':
                unProduceList.push(item)
                break
            }
          })
          this.dictData.produceList = produceList
          this.dictData.unProduceList = unProduceList
        })
        .catch(err => {
          console.log(err)
        })
    },
    cancellationFn() {
      this.loading = true
      this.$emit('update:isShow', false)
      this.loading = false
    },
    changeMoneyToChinese(money) {
      //汉字的数字
      var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
      //基本单位
      var cnIntRadice = ['', '拾', '佰', '仟']
      //对应整数部分扩展单位
      var cnIntUnits = ['', '万', '亿', '兆']
      //对应小数部分单位
      var cnDecUnits = ['角', '分', '毫', '厘']
      //整数金额时后面跟的字符
      var cnInteger = '整'
      //整型完以后的单位
      var cnIntLast = '圆'
      //最大处理的数字
      var maxNum = 999999999999999.9999
      //金额整数部分
      var integerNum
      //金额小数部分
      var decimalNum
      //输出的中文金额字符串
      var chineseStr = ''
      //分离金额后用的数组，预定义
      var parts
      if (money == '') {
        return ''
      }
      money = parseFloat(money)
      if (money >= maxNum) {
        //超出最大处理数字
        return ''
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger
        return chineseStr
      }
      //转换为字符串
      money = money.toString()
      if (money.indexOf('.') == -1) {
        integerNum = money
        decimalNum = ''
      } else {
        parts = money.split('.')
        integerNum = parts[0]
        decimalNum = parts[1].substr(0, 4)
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0
        var IntLen = integerNum.length
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1)
          var p = IntLen - i - 1
          var q = p / 4
          var m = p % 4
          if (n == '0') {
            zeroCount++
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0]
            }
            //归零
            zeroCount = 0
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q]
          }
        }
        chineseStr += cnIntLast
      }
      //小数部分
      if (decimalNum != '') {
        var decLen = decimalNum.length
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1)
          if (n != '0') {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i]
          }
        }
      }
      if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger
      } else if (decimalNum == '') {
        chineseStr += cnInteger
      }
      return chineseStr
    },
  },
}
</script>

<style lang="scss" scoped>
.business-center {
  display: flex;
  justify-content: flex-start;
  max-height: 500px;
  overflow-y: auto;
  .business-left {
    margin-right: 10px;
    width: 50%;
  }
  .business-right {
    margin-left: 10px;
    width: 50%;
  }

  .radio-group {
    display: flex;
    align-items: center;
    height: 45px;
  }
  .radio-label {
    width: 100px;
    font-size: 14px;
  }
}
.before-change {
  font-weight: 900;
  font-size: 18px;
  margin-right: 47%;
  color: blue;
}
.after-the-alteration {
  font-weight: 900;
  font-size: 18px;
  color: red;
  // #eef9b2
}
</style>
