<template>
  <div class="annex">
    <div v-if="introduce == 'project'" class="project-jincome-top">
      <div>
        项目名称： <span class="project-jincome-fw">{{ projectInformation.projectName }}</span>
      </div>
      <div v-if="projectInformation.requireAccomplishDate">
        项目截止时间：<span class="project-jincome-fw">{{
          projectInformation.requireAccomplishDate | dateFormat
        }}</span>
        {{ isExceed ? '超出' : '剩余' }}：<span
          :class="
            isExceed
              ? 'deadline-color-red project-jincome-fw'
              : 'deadline-color-green project-jincome-fw'
          "
          >{{ deadline }}</span
        >天
      </div>
    </div>
    <div class="footer-statistics">
      <span class="footer-statistics-total">合计：</span>
      <span
        >项目实际金额： <span style="color: #000">{{ projectInformation.actualAmount }}</span></span
      >
      <template v-if="projectInformation.projectCategory != 'NO_CONTRACT_NO_INVOICE'">
        <span
          class="footer-statistics-ccenter"
          v-if="projectInformation.projectCategory != 'NO_CONTRACT_INVOICE'"
          >实际合同金额： <span style="color: #00ff00">{{ agreement | thousands }}</span></span
        >
        <span class="footer-statistics-ccenter"
          >已开票金额： <span style="color: #4846a5"> {{ Invoiced | thousands }}</span></span
        >
      </template>
      <span class="footer-statistics-ccenter"
        >已收款金额： <span style="color: #0c7605"> {{ Received | thousands }}</span></span
      >
      <template v-if="projectInformation.projectCategory != 'NO_CONTRACT_NO_INVOICE'">
        <span class="footer-statistics-ccenter"
          >已开票未收款：
          <span style="color: #ef0808"> {{ InvoicedUncollected | thousands }}</span></span
        >
        <span class="footer-statistics-ccenter"
          >未开票已收款：
          <span style="color: #e5aa25"> {{ notInvoicedReceived | thousands }}</span></span
        >
        <span class="footer-statistics-ccenter"
          >未开票金额：
          <span style="color: #ef2969"> {{ notInvoicedMoney | thousands }}</span></span
        >
        <span class="footer-statistics-ccenter"
          >总税费： <span style="color: #81d2e8"> {{ taxesFee | thousands }}</span></span
        >
      </template>
    </div>
    <el-row style="margin-bottom: 10px; color: #303133"
      >金额单位：<span style="color: red"> 元</span>；
      <span
        class="leftTable"
        v-if="
          projectInformation.projectCategory != 'NO_CONTRACT_INVOICE' &&
          projectInformation.projectCategory != 'NO_CONTRACT_NO_INVOICE'
        "
        >合同阶段
        <div class="colorTable tableOneClassName"></div
      ></span>
      <span class="leftTable" v-if="projectInformation.projectCategory != 'NO_CONTRACT_NO_INVOICE'"
        >开票明细
        <div class="colorTable tableTwoClassName"></div
      ></span>
      <span class="leftTable"
        >收款明细
        <div class="colorTable tableThreeClassName"></div
      ></span>
      <span class="leftTable" v-if="projectInformation.projectCategory != 'NO_CONTRACT_NO_INVOICE'"
        >冲红数据
        <div class="colorTable tableRowClassName"></div
      ></span>
    </el-row>
    <!-- 大包合同子项目/单合同项目 -->
    <template
      v-if="
        projectInformation.projectCategory == 'SINGLE_CONTRACT' ||
        projectInformation.projectCategory == 'LARGE_PACKAGE_SUB_PROJECT'
      "
    >
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        row-key="id"
        default-expand-all
        @cell-click="handleCellClick"
        :header-row-class-name="tableOneClassName"
      >
        <el-table-column type="expand" width="30">
          <template slot-scope="props">
            <el-table
              ref="tree"
              :data="props.row.agreementStageTaskList"
              tooltip-effect="dark"
              size="mini"
              default-expand-all
              row-key="id"
              @cell-click="handleCellClick"
              :row-class-name="tableRowClassName"
              :header-row-class-name="tableTwoClassName"
              style="width: 98%; margin-bottom: 40px; margin-top: 0px; margin-left: 2%"
            >
              <el-table-column type="expand" width="30">
                <template slot-scope="propsPayment">
                  <el-table
                    ref="tree"
                    :data="propsPayment.row.agreementStagePaymentList"
                    tooltip-effect="dark"
                    size="mini"
                    default-expand-all
                    @cell-click="handleCellClick"
                    :header-row-class-name="tableThreeClassName"
                    style="width: 94.7%; margin-bottom: 0px; margin-top: 0px; margin-left: 5.3%"
                  >
                    <el-table-column align="center" type="index" label="序号" width="50">
                      <template slot-scope="scope">
                        {{ props.$index + 1 }}.{{ propsPayment.$index + 1 }}. {{ scope.$index + 1 }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="proceedsDate"
                      align="center"
                      label="收款时间"
                      min-width="110"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.proceedsDate | dateFormat }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="proceedsAmount"
                      align="center"
                      label="收款金额"
                      min-width="110"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.proceedsAmount | thousands }}
                      </template>
                    </el-table-column>

                    <el-table-column
                      prop="fileList"
                      align="center"
                      label="收款附件"
                      min-width="160"
                    >
                      <template slot-scope="scope">
                        <template v-for="(n, i) in scope.row.fileList">
                          <div :key="i" style="margin-top: 5px">
                            <a
                              class="fj-text"
                              target="_blank"
                              style="text-decoration: revert; color: #409eff; padding-right: 8px"
                              :href="`${filepath}${n.filePath}`"
                            >
                              {{ n.fileName }}
                            </a>
                          </div>
                        </template>
                      </template>
                    </el-table-column>
                    <el-table-column prop="remark" min-width="120" align="center" label="备注">
                    </el-table-column>
                    <!-- <el-table-column
                      align="center"
                      label="操作"
                      width="120"
                      v-if=" props.row.proceedsStatus == 10"
                    >
                      <template slot-scope="scope">
                        <el-button
                          type="text"
                          @click="editPaymentFn(scope.row, propsPayment.row)"
                          size="mini"
                          class="Edit_Bgc operate"
                          >编辑</el-button
                        >
                        <el-button
                          type="text"
                          v-if="propsPayment.row.proceedsAmount != propsPayment.row.invoiceAmount"
                          @click="delPaymentFn(scope.row, propsPayment.row)"
                          class="emove_Bgc operate"
                          size="mini"
                          >删除</el-button
                        >
                      </template>
                    </el-table-column> -->
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column align="center" label="序号" width="50">
                <template slot-scope="scope">
                  {{ props.$index + 1 }}.{{ scope.$index + 1 }}
                </template>
              </el-table-column>

              <el-table-column prop="invoiceStatus" align="center" min-width="100" label="开票状态">
                <template slot-scope="scope">
                  {{ scope.row.invoiceStatus | dict(invoiceStatus) }}
                </template>
              </el-table-column>
              <el-table-column prop="invoiceDate" align="center" min-width="100" label="开票时间">
                <template slot-scope="scope">
                  {{ scope.row.invoiceDate | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column prop="invoiceAmount" align="center" min-width="100" label="开票金额">
                <template slot-scope="scope">
                  {{ scope.row.invoiceAmount | thousands }}
                </template>
              </el-table-column>
              <el-table-column prop="proceedsDate" align="center" label="收款时间" min-width="100">
                <template slot-scope="scope">
                  {{ scope.row.proceedsDate | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column
                prop="proceedsAmount"
                align="center"
                label="收款金额"
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.proceedsAmount | thousands }}
                </template>
              </el-table-column>

              <el-table-column prop="proceedsStatus" align="center" label="收款状态" min-width="80">
                <template slot-scope="scope">
                  {{ scope.row.proceedsStatus | dict(paymentStatus) }}
                </template>
              </el-table-column>

              <el-table-column prop="taxesFee" align="center" label="总税费" min-width="110">
                <template slot-scope="scope">
                  {{ scope.row.taxesFee | thousands }}
                </template>
              </el-table-column>
              <el-table-column prop="" align="center" label="开票附件" min-width="160">
                <template slot-scope="scope">
                  <template v-for="(n, i) in scope.row.fileList">
                    <div :key="i" style="margin-top: 5px">
                      <a
                        class="fj-text"
                        target="_blank"
                        style="text-decoration: revert; color: #409eff; padding-right: 8px"
                        :href="`${filepath}${n.filePath}`"
                      >
                        {{ n.fileName }}
                      </a>
                    </div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column prop="" align="center" label="收款总附件" min-width="160">
                <template slot-scope="scope">
                  <template v-for="(n, i) in scope.row.allFileList">
                    <div :key="i" style="margin-top: 5px">
                      <a
                        class="fj-text"
                        target="_blank"
                        style="text-decoration: revert; color: #409eff; padding-right: 8px"
                        :href="`${filepath}${n.filePath}`"
                      >
                        {{ n.fileName }}
                      </a>
                    </div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column prop="remark" align="center" label="备注"> </el-table-column>
              <!-- <el-table-column
                align="center"
                label="操作"
                width="130"
                v-if=" props.row.proceedsStatus == 10"
              >
                <template slot-scope="scope">
                  <template
                    v-if="
                      (
                        scope.row.proceedsAmount == 0 &&
                        scope.row.invoiceAmount == 0) ||
                      (
                        scope.row.proceedsAmount != scope.row.invoiceAmount)
                    "
                  >
                    <el-button
                      type="text"
                      v-if="scope.row.id && scope.row.invoiceAmount >= 0"
                      class="proceeds_Bgc operate"
                      @click="perfectFn(scope.row)"
                      size="mini"
                      >添加收款</el-button
                    >
                    <el-button
                      type="text"
                      v-if="scope.row.invoiceStatus == 10"
                      @click="delFn(scope.row)"
                      class="emove_Bgc operate"
                      size="mini"
                      >删除</el-button
                    >
                  </template>
                </template>
              </el-table-column> -->
            </el-table>
          </template>
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>

        <el-table-column prop="projectProgress" align="center" label="项目进度" min-width="100">
          <template slot-scope="scope">
            {{ scope.row.projectProgress ? scope.row.projectProgress : 0 }}%
          </template>
        </el-table-column>
        <el-table-column prop="stageName" align="center" label="阶段" min-width="134">
        </el-table-column>
        <el-table-column prop="paymentStandard" min-width="230" align="center" label="阶段内容">
        </el-table-column>
        <el-table-column
          prop="paymentAmount"
          :show-overflow-tooltip="false"
          align="center"
          label="合同阶段金额"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.paymentAmount | thousands }}
          </template>
        </el-table-column>
        <el-table-column prop="invoiceAmount" align="center" label="已开票金额" min-width="110">
          <template slot-scope="scope">
            {{ scope.row.invoiceAmount | thousands }}
          </template>
        </el-table-column>
        <el-table-column prop="proceedsAmount" align="center" label="已收款金额" min-width="110">
          <template slot-scope="scope">
            {{ scope.row.proceedsAmount | thousands }}
          </template>
        </el-table-column>

        <el-table-column prop="proceedsDate" align="center" label="约定收款时间" min-width="110">
          <template slot-scope="scope">
            {{ scope.row.proceedsDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column
          prop="actualProceedsDate"
          label="实际收款时间"
          align="center"
          min-width="110"
        >
          <template slot-scope="scope">
            {{ scope.row.actualProceedsDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="taxesFee" align="center" label="总税费" min-width="110">
          <template slot-scope="scope">
            {{ scope.row.taxesFee | thousands }}
          </template>
        </el-table-column>
        <el-table-column prop="proceedsStatus" align="center" label="收款状态" min-width="80">
          <template slot-scope="scope">
            {{ scope.row.proceedsStatus | dict(paymentStatus) }}
          </template>
        </el-table-column>
        <el-table-column prop="proceedsRemark" min-width="200" label="备注"> </el-table-column>
        <!-- <el-table-column
          align="center"
          label="操作"
          width="220"
          v-if="originalForm.isProjectIncomeEditMonitoring"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.proceedsStatus == 10">
              <el-button
                @click="addFn(scope.row)"
                type="text"
                class="addProceeds_Bgc operate"
                size="mini"
                >增加收款</el-button
              >
            </template>
            <el-button
              type="text"
              class="isProceeds_Bgc operate"
              @click="handleClick(scope.row)"
              :disabled="
                !permission(['AGREEMENT_INCOME_EDIT']) ||
                scope.row.proceedsStatus != 10 ||
                options.ProjectDisable != 5
              "
              size="small"
            >
              <span v-if="scope.row.proceedsStatus == 10"> 确认收款 </span>
              <span v-else> 已收款 </span>
            </el-button>
            <el-button
              type="text"
              class="isProceeds_Bgc operate"
              @click="handleClick(scope.row)"
              size="small"
              >编辑
            </el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </template>
    <!-- 不签合同要开票项目 -->
    <template v-if="projectInformation.projectCategory == 'NO_CONTRACT_INVOICE'">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        row-key="id"
        default-expand-all
        @cell-click="handleCellClick"
        :header-row-class-name="tableOneClassName"
      >
        <el-table-column type="expand" width="30">
          <template slot-scope="props">
            <el-table
              ref="tree"
              :data="props.row.agreementStageTaskList"
              tooltip-effect="dark"
              size="mini"
              default-expand-all
              row-key="id"
              @cell-click="handleCellClick"
              :row-class-name="tableRowClassName"
              :header-row-class-name="tableTwoClassName"
              style="width: 98%; margin-bottom: 40px; margin-top: 0px; margin-left: 2%"
            >
              <el-table-column type="expand" width="30">
                <template slot-scope="propsPayment">
                  <el-table
                    ref="tree"
                    :data="propsPayment.row.agreementStagePaymentList"
                    tooltip-effect="dark"
                    size="mini"
                    default-expand-all
                    @cell-click="handleCellClick"
                    :header-row-class-name="tableThreeClassName"
                    style="width: 94.7%; margin-bottom: 0px; margin-top: 0px; margin-left: 5.3%"
                  >
                    <el-table-column align="center" type="index" label="序号" width="50">
                      <template slot-scope="scope">
                        {{ props.$index + 1 }}.{{ propsPayment.$index + 1 }}. {{ scope.$index + 1 }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="proceedsDate"
                      align="center"
                      label="收款时间"
                      min-width="110"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.proceedsDate | dateFormat }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="proceedsAmount"
                      align="center"
                      label="收款金额"
                      min-width="110"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.proceedsAmount | thousands }}
                      </template>
                    </el-table-column>

                    <el-table-column
                      prop="fileList"
                      align="center"
                      label="收款附件"
                      min-width="160"
                    >
                      <template slot-scope="scope">
                        <template v-for="(n, i) in scope.row.fileList">
                          <div :key="i" style="margin-top: 5px">
                            <a
                              class="fj-text"
                              target="_blank"
                              style="text-decoration: revert; color: #409eff; padding-right: 8px"
                              :href="`${filepath}${n.filePath}`"
                            >
                              {{ n.fileName }}
                            </a>
                          </div>
                        </template>
                      </template>
                    </el-table-column>
                    <el-table-column prop="remark" min-width="120" align="center" label="备注">
                    </el-table-column>
                    <el-table-column
                      align="center"
                      label="操作"
                      width="120"
                      v-if="props.row.proceedsStatus == 10"
                    >
                      <template slot-scope="scope">
                        <el-button
                          type="text"
                          @click="editPaymentFn(scope.row, propsPayment.row)"
                          size="mini"
                          class="Edit_Bgc operate"
                          >编辑</el-button
                        >
                        <el-button
                          type="text"
                          v-if="propsPayment.row.proceedsAmount != propsPayment.row.invoiceAmount"
                          @click="delPaymentFn(scope.row, propsPayment.row)"
                          class="emove_Bgc operate"
                          size="mini"
                          >删除</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column align="center" label="序号" width="50">
                <template slot-scope="scope">
                  {{ props.$index + 1 }}.{{ scope.$index + 1 }}
                </template>
              </el-table-column>

              <el-table-column prop="invoiceStatus" align="center" min-width="100" label="开票状态">
                <template slot-scope="scope">
                  {{ scope.row.invoiceStatus | dict(invoiceStatus) }}
                </template>
              </el-table-column>
              <el-table-column prop="invoiceDate" align="center" min-width="100" label="开票时间">
                <template slot-scope="scope">
                  {{ scope.row.invoiceDate | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column prop="invoiceAmount" align="center" min-width="100" label="开票金额">
                <template slot-scope="scope">
                  {{ scope.row.invoiceAmount | thousands }}
                </template>
              </el-table-column>
              <el-table-column prop="proceedsDate" align="center" label="收款时间" min-width="100">
                <template slot-scope="scope">
                  {{ scope.row.proceedsDate | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column
                prop="proceedsAmount"
                align="center"
                label="收款金额"
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.proceedsAmount | thousands }}
                </template>
              </el-table-column>

              <el-table-column prop="proceedsStatus" align="center" label="收款状态" min-width="80">
                <template slot-scope="scope">
                  {{ scope.row.proceedsStatus | dict(paymentStatus) }}
                </template>
              </el-table-column>

              <el-table-column prop="taxesFee" align="center" label="总税费" min-width="110">
                <template slot-scope="scope">
                  {{ scope.row.taxesFee | thousands }}
                </template>
              </el-table-column>
              <el-table-column prop="" align="center" label="开票附件" min-width="160">
                <template slot-scope="scope">
                  <template v-for="(n, i) in scope.row.fileList">
                    <div :key="i" style="margin-top: 5px">
                      <a
                        class="fj-text"
                        target="_blank"
                        style="text-decoration: revert; color: #409eff; padding-right: 8px"
                        :href="`${filepath}${n.filePath}`"
                      >
                        {{ n.fileName }}
                      </a>
                    </div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column prop="" align="center" label="收款总附件" min-width="160">
                <template slot-scope="scope">
                  <template v-for="(n, i) in scope.row.allFileList">
                    <div :key="i" style="margin-top: 5px">
                      <a
                        class="fj-text"
                        target="_blank"
                        style="text-decoration: revert; color: #409eff; padding-right: 8px"
                        :href="`${filepath}${n.filePath}`"
                      >
                        {{ n.fileName }}
                      </a>
                    </div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column prop="remark" align="center" label="备注"> </el-table-column>
              <el-table-column
                align="center"
                label="操作"
                width="130"
                v-if="props.row.proceedsStatus == 10"
              >
                <template slot-scope="scope">
                  <template
                    v-if="
                      (scope.row.proceedsAmount == 0 && scope.row.invoiceAmount == 0) ||
                      scope.row.proceedsAmount != scope.row.invoiceAmount
                    "
                  >
                    <el-button
                      type="text"
                      v-if="scope.row.id && scope.row.invoiceAmount >= 0"
                      class="proceeds_Bgc operate"
                      @click="perfectFn(scope.row)"
                      size="mini"
                      >添加收款</el-button
                    >
                    <el-button
                      type="text"
                      v-if="scope.row.invoiceStatus == 10"
                      @click="delFn(scope.row)"
                      class="emove_Bgc operate"
                      size="mini"
                      >删除</el-button
                    >
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>

        <el-table-column prop="projectProgress" align="center" label="项目进度" min-width="100">
          <template slot-scope="scope">
            {{ scope.row.projectProgress ? scope.row.projectProgress : 0 }}%
          </template>
        </el-table-column>

        <!-- <el-table-column prop="stageName" align="center" label="阶段" width="134"> </el-table-column>
      <el-table-column prop="paymentStandard" min-width="230" align="center" label="阶段内容">
      </el-table-column>
      <el-table-column
        prop="paymentAmount"
        :show-overflow-tooltip="false"
        align="center"
        label="合同阶段金额"
        width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.paymentAmount | thousands }}
        </template>
      </el-table-column> -->
        <el-table-column prop="invoiceAmount" align="center" label="已开票金额" min-width="110">
          <template slot-scope="scope">
            {{ scope.row.invoiceAmount | thousands }}
          </template>
        </el-table-column>
        <el-table-column prop="proceedsAmount" align="center" label="已收款金额" min-width="110">
          <template slot-scope="scope">
            {{ scope.row.proceedsAmount | thousands }}
          </template>
        </el-table-column>

        <!-- <el-table-column prop="proceedsDate" align="center" label="约定收款时间" width="110">
        <template slot-scope="scope">
          {{ scope.row.proceedsDate | dateFormat }}
        </template>
      </el-table-column> -->
        <el-table-column
          prop="actualProceedsDate"
          label="实际收款时间"
          align="center"
          min-width="110"
        >
          <template slot-scope="scope">
            {{ scope.row.actualProceedsDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="taxesFee" align="center" label="总税费" min-width="110">
          <template slot-scope="scope">
            {{ scope.row.taxesFee | thousands }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="proceedsStatus" align="center" label="收款状态" width="80">
        <template slot-scope="scope">
          {{ scope.row.proceedsStatus | dict(paymentStatus) }}
        </template>
      </el-table-column> -->
        <el-table-column align="center" prop="proceedsRemark" label="备注"> </el-table-column>
        <el-table-column label="操作" width="220" v-if="originalForm.isProjectIncomeEditMonitoring">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="isProceeds_Bgc operate"
              @click="onProceedsFn(scope.row)"
              :disabled="!permission(['AGREEMENT_INCOME_EDIT']) || scope.row.proceedsStatus != 10"
              size="small"
            >
              <span v-if="scope.row.proceedsStatus == 10"> 确认收款 </span>
              <span v-else> 已收款 </span>
            </el-button>
            <template v-if="scope.row.proceedsStatus == 10">
              <el-button
                @click="addFn(scope.row)"
                type="text"
                class="addProceeds_Bgc operate"
                size="mini"
                >增加收款</el-button
              >
              <el-button
                type="text"
                class="Edit_Bgc operate"
                @click="editClick(scope.row)"
                size="small"
                >编辑
              </el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- 不签合同不开票项目 -->
    <template v-else-if="projectInformation.projectCategory == 'NO_CONTRACT_NO_INVOICE'">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        row-key="id"
        @cell-click="handleCellClick"
        :header-row-class-name="tableOneClassName"
      >
        <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>

        <el-table-column prop="projectProgress" align="center" label="项目进度" min-width="100">
          <template slot-scope="scope">
            {{ scope.row.projectProgress ? scope.row.projectProgress : 0 }}%
          </template>
        </el-table-column>
        <el-table-column prop="proceedsAmount" align="center" label="已收款金额" min-width="110">
          <template slot-scope="scope">
            {{ scope.row.proceedsAmount | thousands }}
          </template>
        </el-table-column>

        <el-table-column
          prop="actualProceedsDate"
          label="实际收款时间"
          align="center"
          min-width="110"
        >
          <template slot-scope="scope">
            {{ scope.row.actualProceedsDate | dateFormat }}
          </template>
        </el-table-column>

        <el-table-column prop="proceedsRemark" label="备注"> </el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="200"
          v-if="originalForm.isProjectIncomeEditMonitoring"
        >
          <template slot="header">
            <i class="iconfont iconadd-circle" @click="addStageFn"></i>
          </template>

          <template slot-scope="scope">
            <el-button
              type="text"
              class="isProceeds_Bgc operate"
              @click="onProceedsFn(scope.row)"
              :disabled="!permission(['AGREEMENT_INCOME_EDIT']) || scope.row.proceedsStatus != 10"
              size="small"
            >
              <span v-if="scope.row.proceedsStatus == 10"> 确认收款 </span>
              <span v-else> 已收款 </span>
            </el-button>
            <el-button
              type="text"
              v-if="scope.row.proceedsStatus == 10"
              class="Edit_Bgc operate"
              @click="addStageFn(scope.row)"
              size="small"
              >编辑
            </el-button>
            <el-button
              type="text"
              v-if="scope.row.proceedsStatus == 10"
              class="emove_Bgc operate"
              @click="deleteStageFn(scope.row)"
              size="small"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>

    <!-- 多合同项目 -->
    <template v-else-if="projectInformation.projectCategory == 'MULTI_CONTRACT'">
      <template v-if="originalForm.agreementList">
        <div 
          v-for="item in originalForm.agreementList"
          :key="item.id">
          <div
            v-if="item.agreementAttribute !== 'cai_gou_he_tong'"
            style="margin-bottom: 15px; color: #000"
          >
            <div>
              合同名称：<span class="project-jincome-fw">{{ item.agreementName }}</span>
            </div>
            <el-table
              :data="item.agreementStageList"
              border
              style="width: 100%"
              row-key="id"
              default-expand-all
              @cell-click="handleCellClick"
              :header-row-class-name="tableOneClassName"
            >
              <el-table-column type="expand" width="30">
                <template slot-scope="props">
                  <el-table
                    ref="tree"
                    :data="props.row.agreementStageTaskList"
                    tooltip-effect="dark"
                    size="mini"
                    default-expand-all
                    row-key="id"
                    @cell-click="handleCellClick"
                    :row-class-name="tableRowClassName"
                    :header-row-class-name="tableTwoClassName"
                    style="width: 98%; margin-bottom: 40px; margin-top: 0px; margin-left: 2%"
                  >
                    <el-table-column type="expand" width="30">
                      <template slot-scope="propsPayment">
                        <el-table
                          ref="tree"
                          :data="propsPayment.row.agreementStagePaymentList"
                          tooltip-effect="dark"
                          size="mini"
                          default-expand-all
                          @cell-click="handleCellClick"
                          :header-row-class-name="tableThreeClassName"
                          style="width: 94.7%; margin-bottom: 0px; margin-top: 0px; margin-left: 5.3%"
                        >
                          <el-table-column align="center" type="index" label="序号" width="50">
                            <template slot-scope="scope">
                              {{ props.$index + 1 }}.{{ propsPayment.$index + 1 }}.
                              {{ scope.$index + 1 }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="proceedsDate"
                            align="center"
                            label="收款时间"
                            min-width="110"
                          >
                            <template slot-scope="scope">
                              {{ scope.row.proceedsDate | dateFormat }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="proceedsAmount"
                            align="center"
                            label="收款金额"
                            min-width="110"
                          >
                            <template slot-scope="scope">
                              {{ scope.row.proceedsAmount | thousands }}
                            </template>
                          </el-table-column>

                          <el-table-column
                            prop="fileList"
                            align="center"
                            label="收款附件"
                            min-width="160"
                          >
                            <template slot-scope="scope">
                              <template v-for="(n, i) in scope.row.fileList">
                                <div :key="i" style="margin-top: 5px">
                                  <a
                                    class="fj-text"
                                    target="_blank"
                                    style="
                                      text-decoration: revert;
                                      color: #409eff;
                                      padding-right: 8px;
                                    "
                                    :href="`${filepath}${n.filePath}`"
                                  >
                                    {{ n.fileName }}
                                  </a>
                                </div>
                              </template>
                            </template>
                          </el-table-column>
                          <el-table-column prop="remark" min-width="120" align="center" label="备注">
                          </el-table-column>
                          <!-- <el-table-column
                        align="center"
                        label="操作"
                        width="120"
                        v-if=" props.row.proceedsStatus == 10"
                      >
                        <template slot-scope="scope">
                          <el-button
                            type="text"
                            @click="editPaymentFn(scope.row, propsPayment.row)"
                            size="mini"
                            class="Edit_Bgc operate"
                            >编辑</el-button
                          >
                          <el-button
                            type="text"
                            v-if="propsPayment.row.proceedsAmount != propsPayment.row.invoiceAmount"
                            @click="delPaymentFn(scope.row, propsPayment.row)"
                            class="emove_Bgc operate"
                            size="mini"
                            >删除</el-button
                          >
                        </template>
                      </el-table-column> -->
                        </el-table>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="序号" width="50">
                      <template slot-scope="scope">
                        {{ props.$index + 1 }}.{{ scope.$index + 1 }}
                      </template>
                    </el-table-column>

                    <el-table-column
                      prop="invoiceStatus"
                      align="center"
                      min-width="100"
                      label="开票状态"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.invoiceStatus | dict(invoiceStatus) }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="invoiceDate"
                      align="center"
                      min-width="100"
                      label="开票时间"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.invoiceDate | dateFormat }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="invoiceAmount"
                      align="center"
                      min-width="100"
                      label="开票金额"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.invoiceAmount | thousands }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="proceedsDate"
                      align="center"
                      label="收款时间"
                      min-width="100"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.proceedsDate | dateFormat }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="proceedsAmount"
                      align="center"
                      label="收款金额"
                      min-width="100"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.proceedsAmount | thousands }}
                      </template>
                    </el-table-column>

                    <el-table-column
                      prop="proceedsStatus"
                      align="center"
                      label="收款状态"
                      min-width="80"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.proceedsStatus | dict(paymentStatus) }}
                      </template>
                    </el-table-column>

                    <el-table-column prop="taxesFee" align="center" label="总税费" min-width="110">
                      <template slot-scope="scope">
                        {{ scope.row.taxesFee | thousands }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="" align="center" label="开票附件" min-width="160">
                      <template slot-scope="scope">
                        <template v-for="(n, i) in scope.row.fileList">
                          <div :key="i" style="margin-top: 5px">
                            <a
                              class="fj-text"
                              target="_blank"
                              style="text-decoration: revert; color: #409eff; padding-right: 8px"
                              :href="`${filepath}${n.filePath}`"
                            >
                              {{ n.fileName }}
                            </a>
                          </div>
                        </template>
                      </template>
                    </el-table-column>
                    <el-table-column prop="" align="center" label="收款总附件" min-width="160">
                      <template slot-scope="scope">
                        <template v-for="(n, i) in scope.row.allFileList">
                          <div :key="i" style="margin-top: 5px">
                            <a
                              class="fj-text"
                              target="_blank"
                              style="text-decoration: revert; color: #409eff; padding-right: 8px"
                              :href="`${filepath}${n.filePath}`"
                            >
                              {{ n.fileName }}
                            </a>
                          </div>
                        </template>
                      </template>
                    </el-table-column>
                    <el-table-column prop="remark" align="center" label="备注"> </el-table-column>
                    <!-- <el-table-column
                  align="center"
                  label="操作"
                  width="130"
                  v-if=" props.row.proceedsStatus == 10"
                >
                  <template slot-scope="scope">
                    <template
                      v-if="
                        (
                          scope.row.proceedsAmount == 0 &&
                          scope.row.invoiceAmount == 0) ||
                        (
                          scope.row.proceedsAmount != scope.row.invoiceAmount)
                      "
                    >
                      <el-button
                        type="text"
                        v-if="scope.row.id && scope.row.invoiceAmount >= 0"
                        class="proceeds_Bgc operate"
                        @click="perfectFn(scope.row)"
                        size="mini"
                        >添加收款</el-button
                      >
                      <el-button
                        type="text"
                        v-if="scope.row.invoiceStatus == 10"
                        @click="delFn(scope.row)"
                        class="emove_Bgc operate"
                        size="mini"
                        >删除</el-button
                      >
                    </template>
                  </template>
                </el-table-column> -->
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>

              <el-table-column prop="projectProgress" align="center" label="项目进度" min-width="100">
                <template slot-scope="scope">
                  {{ scope.row.projectProgress ? scope.row.projectProgress : 0 }}%
                </template>
              </el-table-column>
              <el-table-column prop="stageName" align="center" label="阶段" min-width="134">
              </el-table-column>
              <el-table-column prop="paymentStandard" min-width="230" align="center" label="阶段内容">
              </el-table-column>
              <el-table-column
                prop="paymentAmount"
                :show-overflow-tooltip="false"
                align="center"
                label="合同阶段金额"
                min-width="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.paymentAmount | thousands }}
                </template>
              </el-table-column>
              <el-table-column prop="invoiceAmount" align="center" label="已开票金额" min-width="110">
                <template slot-scope="scope">
                  {{ scope.row.invoiceAmount | thousands }}
                </template>
              </el-table-column>
              <el-table-column
                prop="proceedsAmount"
                align="center"
                label="已收款金额"
                min-width="110"
              >
                <template slot-scope="scope">
                  {{ scope.row.proceedsAmount | thousands }}
                </template>
              </el-table-column>

              <el-table-column
                prop="proceedsDate"
                align="center"
                label="约定收款时间"
                min-width="110"
              >
                <template slot-scope="scope">
                  {{ scope.row.proceedsDate | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column
                prop="actualProceedsDate"
                label="实际收款时间"
                align="center"
                min-width="110"
              >
                <template slot-scope="scope">
                  {{ scope.row.actualProceedsDate | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column prop="taxesFee" align="center" label="总税费" min-width="110">
                <template slot-scope="scope">
                  {{ scope.row.taxesFee | thousands }}
                </template>
              </el-table-column>
              <el-table-column prop="proceedsStatus" align="center" label="收款状态" min-width="80">
                <template slot-scope="scope">
                  {{ scope.row.proceedsStatus | dict(paymentStatus) }}
                </template>
              </el-table-column>
              <el-table-column prop="proceedsRemark" width="200" label="备注"> </el-table-column>
              <!-- <el-table-column
            align="center"
            label="操作"
            width="220"
            v-if="originalForm.isProjectIncomeEditMonitoring"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.proceedsStatus == 10">
                <el-button
                  @click="addFn(scope.row)"
                  type="text"
                  class="addProceeds_Bgc operate"
                  size="mini"
                  >增加收款</el-button
                >
              </template>
              <el-button
                type="text"
                class="isProceeds_Bgc operate"
                @click="handleClick(scope.row)"
                :disabled="
                  !permission(['AGREEMENT_INCOME_EDIT']) ||
                  scope.row.proceedsStatus != 10
                "
                size="small"
              >
                <span v-if="scope.row.proceedsStatus == 10"> 确认收款 </span>
                <span v-else> 已收款 </span>
              </el-button>
              <el-button
                type="text"
                class="isProceeds_Bgc operate"
                @click="handleClick(scope.row)"
                size="small"
                >编辑
              </el-button>
            </template>
          </el-table-column> -->
            </el-table>
          </div>
        </div>
        
      </template></template
    >

    <!-- 阶段收款 -->
    <EditDialog
      :isReturn="true"
      @closeFn="cancelFn"
      :isShow.sync="isShow"
      width="37%"
      id="editDaalog"
    >
      <template v-slot:title>
        <span style="color: #409eff"> 添加收款信息</span>
      </template>
      <template v-slot:content>
        <el-form :model="form" ref="form" :rules="rules" style="max-height: 450px; overflow: auto">
          <el-form-item label="阶段:" prop="stageName" label-width="110px">
            <el-input disabled v-model="form.stageName"></el-input>
          </el-form-item>
          <el-form-item label="合同阶段金额：" prop="paymentAmount" label-width="110px">
            <el-input disabled v-model="form.paymentAmount"></el-input>
          </el-form-item>
          <el-form-item label="阶段内容：" prop="paymentStandard" label-width="110px">
            <el-input disabled v-model="form.paymentStandard" type="textarea" autosize></el-input>
          </el-form-item>
          <el-form-item label="收款金额：" prop="proceedsAmount" label-width="110px">
            <el-input
              type="number"
              class="numrule"
              @blur="blurAmount('proceedsAmount', 'form')"
              v-model="form.proceedsAmount"
            ></el-input>
          </el-form-item>
          <el-form-item label="收款时间：" prop="proceedsDate" label-width="110px">
            <el-date-picker
              value-format="timestamp"
              type="date"
              :clearable="false"
              v-model="form.proceedsDate"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="备注：" prop="remark" label-width="110px">
            <el-input v-model.trim="form.remark" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="附件" label-width="110px">
            <div>
              <Upload
                style="font-size: 34px; color: #409eff; margin-left: 91%"
                v-if="disabled"
                :type="type"
                :fields="fields"
                @upload="upload"
              ></Upload>
            </div>
            <div style="height: 110px; overflow: auto; width: 430px">
              <template v-for="(n, i) in form.fileList">
                <div :key="i" style="margin-top: 5px" class="fj-box">
                  <a
                    class="fj-text"
                    target="_blank"
                    style="text-decoration: revert; color: #409eff"
                    :href="`${filepath}${n.filePath}`"
                  >
                    {{ n.fileName }}
                  </a>

                  <i
                    style="font-size: 34px; color: red"
                    @click="del(n, i)"
                    class="iconfont iconerror"
                  ></i>
                </div>
              </template>
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button type="primary" @click="increaseFn">确 定</el-button>
        <el-button type="info" plain @click="cancelFn">返 回</el-button>
      </template>
    </EditDialog>

    <!-- 开票期数添加收款 -->
    <EditDialog
      :isShow.sync="PaymentIsShow"
      :isReturn="true"
      @closeFn="cancelFn"
      width="37%"
      id="PaymentIsShow"
    >
      <template v-slot:title>
        <span style="color: #409eff">{{ PaymentForm.id ? '编辑收款信息' : '添加收款信息' }}</span>
      </template>
      <template v-slot:content>
        <el-form
          :model="PaymentForm"
          ref="PaymentForm"
          :rules="rules"
          style="max-height: 450px; overflow: auto"
        >
          <el-form-item label="阶段:" prop="stageName" label-width="110px">
            <el-input disabled v-model="PaymentForm.stageName"></el-input>
          </el-form-item>
          <el-form-item label="合同阶段金额：" prop="paymentAmount" label-width="110px">
            <el-input disabled v-model="PaymentForm.paymentAmount"></el-input>
          </el-form-item>
          <el-form-item label="阶段内容：" prop="paymentStandard" label-width="110px">
            <el-input
              disabled
              v-model="PaymentForm.paymentStandard"
              type="textarea"
              autosize
            ></el-input>
          </el-form-item>
          <el-form-item label="开票金额：" prop="invoiceAmount" label-width="110px">
            <el-input disabled v-model="PaymentForm.invoiceAmount"></el-input>
          </el-form-item>
          <el-form-item label="开票时间：" prop="invoiceDate" label-width="110px">
            <el-date-picker
              value-format="timestamp"
              type="date"
              disabled
              :clearable="false"
              v-model="PaymentForm.invoiceDate"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="收款金额：" prop="proceedsAmount" label-width="110px">
            <el-input
              type="number"
              class="numrule"
              @blur="blurAmount('proceedsAmount')"
              v-model="PaymentForm.proceedsAmount"
            ></el-input>
          </el-form-item>
          <el-form-item label="收款时间：" prop="proceedsDate" label-width="110px">
            <el-date-picker
              value-format="timestamp"
              type="date"
              :clearable="false"
              v-model="PaymentForm.proceedsDate"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="备注：" prop="remark" label-width="110px">
            <el-input v-model.trim="PaymentForm.remark" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="附件" label-width="110px">
            <template slot="label"> <span style="color: red">*</span> 附件 </template>
            <div>
              <!-- v-if="disabled" -->
              <Upload
                style="font-size: 34px; color: #409eff; margin-left: 91%"
                :type="type"
                :fields="fields"
                @upload="taskUpload"
              ></Upload>
            </div>
            <div style="height: 110px; overflow: auto; width: 430px">
              <template v-for="(n, i) in PaymentForm.fileList">
                <div :key="i" style="margin-top: 5px" class="fj-box">
                  <a
                    class="fj-text"
                    target="_blank"
                    style="text-decoration: revert; color: #409eff"
                    :href="`${filepath}${n.filePath}`"
                  >
                    {{ n.fileName }}
                  </a>

                  <i
                    style="font-size: 34px; color: red"
                    @click="taskDel(n, i)"
                    class="iconfont iconerror"
                  ></i>
                </div>
              </template>
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button type="primary" @click="paymentIncreaseFn">确 定</el-button>
        <el-button type="info" plain @click="cancelFn">返 回</el-button>
      </template>
    </EditDialog>

    <EditDialog :isReturn="true" @closeFn="cancel" :isShow.sync="submitDialog" width="30%">
      <template v-slot:title>
        <h4
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            color: #409eff;
            fontsize: 20px;
            font-weight: 600;
          "
        >
          <span>确认已完成收款</span>
        </h4>
      </template>
      <template v-slot:content>
        <el-form :model="formData" ref="formData">
          <el-form-item label="最后收款时间：" prop="actualProceedsDate" label-width="110px">
            <el-date-picker
              value-format="timestamp"
              type="date"
              :clearable="false"
              v-model="formData.actualProceedsDate"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="备注：" prop="proceedsRemark" label-width="100px">
            <el-input v-model.trim="formData.proceedsRemark" type="textarea" autosize></el-input>
          </el-form-item>
        </el-form>
        <div style="color: red; margin-left: 16%">
          * PS：请核对该阶段的合同信息
          <span style="color: #1ab119"> 开票 收款 </span> 等信息是否已补充完毕
        </div>
      </template>
      <template v-slot:footer>
        <el-button type="primary" @click="defineFn">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </template>
    </EditDialog>

    <!-- 内容展示 -->
    <EditDialog
      :isReturn="true"
      @closeFn="contentDialogVisible = false"
      :isShow.sync="contentDialogVisible"
      width="50%"
    >
      <template v-slot:title>
        <span style="color: #409eff">{{ contentTitle }}</span>
      </template>
      <template v-slot:content>
        <div class="dialogContentHeight">
          <div v-if="contentTitle != '税费内容展示'">
            {{ contentDialog }}
          </div>
          <el-table v-else :data="taxesFeeArr" border>
            <el-table-column prop="vatType" align="center" label="增值税类型">
              <template slot-scope="scope">
                {{ scope.row.vatType | transitionType(zzsList) }}
              </template>
            </el-table-column>
            <el-table-column prop="vatData" align="center" label="增值税类型详细">
              <template slot-scope="scope">
                {{ scope.row.vatData | transitionData(VatDataAll) }}
              </template>
            </el-table-column>
            <el-table-column prop="vatFee" align="center" label="增值税" width="80">
              <template slot-scope="scope">
                {{ scope.row.vatFee | thousands }}
              </template>
            </el-table-column>
            <el-table-column prop="additionalTaxFee" align="center" label="附加税" width="80">
              <template slot-scope="scope">
                {{ scope.row.additionalTaxFee | thousands }}
              </template>
            </el-table-column>
            <el-table-column prop="stampTaxFee" align="center" label="印花税" width="80">
              <template slot-scope="scope">
                {{ scope.row.stampTaxFee | thousands }}
              </template>
            </el-table-column>
            <el-table-column prop="taxesFee" align="center" label="总税费" width="80">
              <template slot-scope="scope">
                {{ scope.row.taxesFee | thousands }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <template v-slot:footer>
        <el-button type="primary" plain @click="contentDialogVisible = false">返 回</el-button>
      </template>
    </EditDialog>
    <!-- 阶段编辑 -->
    <EditDialog
      :isReturn="true"
      @closeFn="stageEditIsShow = false"
      :isShow.sync="stageEditIsShow"
      width="37%"
      id="stageEditInfo"
    >
      <template v-slot:title>
        <span style="color: #409eff"> {{ stageEditInfo.id ? '编辑' : '新增' }}信息</span>
      </template>
      <template v-slot:content>
        <el-form
          :model="stageEditInfo"
          ref="stageEditInfo"
          :rules="stageEditRules"
          style="max-height: 450px; overflow: auto"
        >
          <el-form-item
            v-if="stageEditInfo.type == 'NO_CONTRACT_INVOICE'"
            label="已开票金额："
            prop="invoiceAmount"
            label-width="110px"
          >
            <el-input readonly v-model="stageEditInfo.invoiceAmount"></el-input>
          </el-form-item>
          <el-form-item label="已收款金额：" prop="proceedsAmount" label-width="110px">
            <el-input
              :readonly="stageEditInfo.type == 'NO_CONTRACT_INVOICE'"
              @blur="blurAmountFn('proceedsAmount')"
              type="number"
              class="numrule"
              v-model="stageEditInfo.proceedsAmount"
            ></el-input>
          </el-form-item>

          <el-form-item label="实际收款时间：" prop="actualProceedsDate" label-width="110px">
            <el-date-picker
              value-format="timestamp"
              type="date"
              placeholder="选择时间"
              :clearable="false"
              v-model="stageEditInfo.actualProceedsDate"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="备注：" prop="proceedsRemark" label-width="110px">
            <el-input v-model.trim="stageEditInfo.proceedsRemark" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button type="primary" @click="increaseStageEditFn">确 定</el-button>
        <el-button type="info" plain @click="stageEditIsShow = false">返 回</el-button>
      </template>
    </EditDialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
    UploadList: () => import('@/components/upload/List.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    introduce: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isShow: false,
      form: {
        //开票明细
        stageName: null,
        paymentStandard: null,
        paymentAmount: null,
        proceedsAmount: null,
        proceedsDate: Date.now(),
        agreementStagePlanId: null,
        fileList: [],
      },
      PaymentForm: {
        //付款明细
        proceedsDate: Date.now(),
        proceedsAmount: null,
        agreementStagePlanId: null, //合同阶段id
        agreementStageTaskId: null, //开票明细期数id
        fileList: [],
        fileDeleteList: [],
        // 开票期数内容
        invoiceAmount: null,
        readonlyProceedsAmount: null,
        invoiceDate: Date.now(),
        // 阶段内容
        stageName: null,
        paymentStandard: null,
        paymentAmount: null,
      },
      PaymentIsShow: false, //开票明细添加收款
      formData: {
        //确认收款表单
        proceedsRemark: '',
        agreementStagePlanId: '',
        actualProceedsDate: Date.now(),
      },
      rules: {
        proceedsAmount: [{ required: true, message: '请输入收款金额', trigger: 'blur' }],
      },
      submitDialog: false,

      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'filType',
      },
      type: 'HTFKtype',
      tableData: [],
      disabled: false, //
      paymentStatus: [],
      invoiceStatus: [],
      zzsList: [],
      VatDataAll: [],
      // expends: [],
      // TaskExpends: [],
      contentDialog: '', //阶段内容/备注/阶段
      taxesFeeArr: [],
      contentTitle: '',
      contentDialogVisible: false,
      isExceed: false,
      stageEditIsShow: false,
      stageEditInfo: {},
      stageEditRules: {
        // actualProceedsDate: [{ required: true, message: '请输入收款金额', trigger: 'blur' }],
        // proceedsRemark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
      },
    }
  },
  inject: ['refresh'],
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
      originalForm: state => state.project.originalForm,
    }),
    agreement() {
      // 实际合同金额 - 取关联合同的销售合同金额合计
      let num = 0
      if (this.originalForm && this.originalForm.agreementList) {
        this.originalForm.agreementList.forEach(v => {
          if (v.agreementAttribute == 'xiao_shou_he_tong') {
            num += v.actualContractAmount
          }
        })
      }
      return num
    },
    stageAgreement() {
      // 合同阶段金额
      if (
        this.projectInformation.projectCategory === 'MULTI_CONTRACT' &&
        this.originalForm &&
        this.originalForm.agreementList
      ) {
        return this.originalForm.agreementList.reduce((total, item) => {
          if (item.agreementAttribute === 'cai_gou_he_tong') {
            return total;
          }
          return (
            total +
            item.agreementStageList.reduce((stageTotal, stageItem) => {
              return stageTotal + Number(stageItem.paymentAmount)
            }, 0)
          )
        }, 0)
      } else {
        return this.tableData.reduce((total, v) => {
          return total + Number(v.paymentAmount)
        }, 0)
      }
    },
    Invoiced() {
      //已开票金额
      if (this.projectInformation.projectCategory === 'MULTI_CONTRACT') {
        return this.originalForm.agreementList.reduce((total, item) => {
          if (item.agreementAttribute === 'cai_gou_he_tong') {
            return total;
          }
          return (
            total +
            item.agreementStageList.reduce((stageTotal, stageItem) => {
              return stageTotal + Number(stageItem.invoiceAmount)
            }, 0)
          )
        }, 0)
      } else {
        return this.tableData.reduce((total, v) => {
          return total + Number(v.invoiceAmount)
        }, 0)
      }
    },
    Received() {
      //已收款金额
      if (this.projectInformation.projectCategory === 'MULTI_CONTRACT') {
        return this.originalForm.agreementList.reduce((total, item) => {
          if (item.agreementAttribute === 'cai_gou_he_tong') {
            return total;
          }
          return (
            total +
            item.agreementStageList.reduce((stageTotal, stageItem) => {
              return stageTotal + Number(stageItem.proceedsAmount)
            }, 0)
          )
        }, 0)
      } else {
        return this.tableData.reduce((total, v) => {
          return total + Number(v.proceedsAmount)
        }, 0)
      }
    },
    InvoicedUncollected() {
      //已开票未收款 = 已开票金额 - 已收款金额
      let num = 0
      num = this.Invoiced - this.Received
      return num
    },
    notInvoicedReceived() {
      //未开票已收款
      let num = 0
      if (this.projectInformation.projectCategory == 'MULTI_CONTRACT') {
        this.originalForm?.agreementList.forEach(item => {
          if (item.agreementAttribute !== 'cai_gou_he_tong'){
            item.agreementStageList.forEach(v => {
              v.agreementStageTaskList.forEach(n => {
                if (n.proceedsStatus == 20 && n.invoiceStatus == 10) {
                  num += Number(n.proceedsAmount)
                }
              })
            })
          }
        })
      } else {
        this.tableData.forEach(v => {
          v.agreementStageTaskList.forEach(n => {
            if (n.proceedsStatus == 20 && n.invoiceStatus == 10) {
              num += Number(n.proceedsAmount)
            }
          })
        })
      }
      return num
    },
    notInvoicedMoney() {
      // 大包合同子项目 不签合同要开票项目、多合同项目  项目实际金额- 已开票金额 = 未开票金额
      //未开票金额-合同阶段金额-已开票金额
      let num = 0
      const actualAmount = this.originalForm?.projectInformation?.actualAmount || 0
      const arr = ['LARGE_PACKAGE_SUB_PROJECT', 'NO_CONTRACT_INVOICE', 'MULTI_CONTRACT']
      if (arr.indexOf(this.projectInformation.projectCategory) != -1) {
        num = actualAmount - this.Invoiced
      } else {
        num = this.stageAgreement - this.Invoiced
      }
      return num
    },
    // 总税费
    taxesFee() {
      let num = 0
      this.tableData.forEach(v => {
        v.agreementStageTaskList.forEach(n => {
          num += Number(n.taxesFee)
        })
      })
      return num
    },
    // 项目截止时间
    deadline() {
      let timestamp = this.projectInformation.requireAccomplishDate // 项目要求完结时间
      // 获取当前日期，设置为00:00:00以去掉时分秒
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0) // 将当前时间设置为00:00:00
      const currentTimestamp = currentDate.getTime() // 当前日期的时间戳（毫秒）
      // 转换为天数
      let daysDifference = (timestamp - currentTimestamp) / (1000 * 60 * 60 * 24)
      daysDifference = Math.floor(daysDifference)
      if (daysDifference > 0) {
        this.isExceed = false
      } else {
        this.isExceed = true
      }
      return daysDifference
    },
  },
  created() {
    this.getType()
  },
  watch: {
    originalForm: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        if (this.permission(['AGREEMENT_INCOME_EDIT'])) {
          this.disabled = true
        } else {
          this.disabled = false
        }
        console.log(newVal)
        if (newVal.agreementStageList) {
          this.tableData = newVal.agreementStageList.deepClone()
          this.tableData.forEach(item => {
            item.taxesFee = item.agreementStageTaskList.reduce((prev, cur, index, arr) => {
              return prev + cur.taxesFee
            }, 0)
          })
          // let Id = []
          // let Expends = []
          // this.tableData.forEach(v => {
          //   if (v.agreementStageTaskList && v.agreementStageTaskList.length > 0) {
          //     Id.push(v.id)
          //     v.agreementStageTaskList.forEach(item => {
          //       if (item.agreementStagePaymentList && item.agreementStagePaymentList.length > 0) {
          //         Expends.push(item.id)
          //       }
          //     })
          //   }
          // })
          // this.expends = Id
          // this.TaskExpends = Expends
        }
      },
    },
  },
  filters: {
    thousands(a) {
      if (a) {
        a = Number(a).toLocaleString()
        return a
      } else {
        return 0
      }
    },
    transitionType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        if (item) {
          str = item.vatName
        }
      }
      return str
    },
    transitionData(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatData == val)
        str = item?.vatName
      }
      return str
    },
  },
  methods: {
    deleteStageFn(row) {
      this.$api.agreement
        .deletePaymentDetail(row.id)
        .then(res => {
          this.cancel()
          this.$emit('updateData')
          this.$message.success('删除成功')
        })
        .catch(err => {
          console.log(err)
        })
    },
    onProceedsFn(row) {
      this.$api.project
        .paymentPass({
          agreementStagePlanId: row.id,
        })
        .then(res => {
          this.cancel()
          this.$emit('updateData')
          this.$message.success('操作成功')
        })
        .catch(err => {
          console.log(err)
        })
    },
    blurAmountFn(e) {
      this.stageEditInfo[e] = Number(this.stageEditInfo[e]).toFixed(2)
    },
    addStageFn(row = {}) {
      this.stageEditInfo = {
        proceedsAmount: row.proceedsAmount ? row.proceedsAmount : 0,
        actualProceedsDate: row.actualProceedsDate ? row.actualProceedsDate : new Date().getTime(),
        proceedsRemark: row.proceedsRemark ? row.proceedsRemark : null,
        type: 'NO_CONTRACT_NO_INVOICE',
        projectId: this.projectInformation.id,
      }
      if (row.id) {
        this.stageEditInfo.id = row.id
      }
      this.stageEditIsShow = true
    },
    editClick(row) {
      this.stageEditInfo = {
        invoiceAmount: row.invoiceAmount,
        proceedsAmount: row.proceedsAmount,
        actualProceedsDate: new Date().getTime(),
        proceedsRemark: null,
        id: row.id,
        type: 'NO_CONTRACT_INVOICE',
      }
      this.stageEditIsShow = true
    },
    increaseStageEditFn() {
      if (this.stageEditInfo.type == 'NO_CONTRACT_INVOICE') {
        const obj = {
          agreementStagePlanId: this.stageEditInfo.id,
          actualProceedsDate: this.stageEditInfo.actualProceedsDate,
          proceedsRemark: this.stageEditInfo.proceedsRemark,
        }
        this.$api.agreement
          .agreementStagePlanEdit(obj)
          .then(res => {
            this.$emit('updateData')
            this.$message.success('编辑成功!')
            this.stageEditIsShow = false
          })
          .catch(err => {
            console.log(err)
            this.stageEditIsShow = false
          })
      } else if (this.stageEditInfo.type == 'NO_CONTRACT_NO_INVOICE') {
        delete this.stageEditInfo.type
        this.$api.agreement
          .noInvoicePayment(this.stageEditInfo)
          .then(res => {
            this.$emit('updateData')
            this.$message.success('操作成功!')
            this.stageEditIsShow = false
          })
          .catch(err => {
            console.log(err)
            this.stageEditIsShow = false
          })
      }
    },
    // 冲红高亮
    tableRowClassName({ row, column, rowIndex, columnIndex }) {
      if (row.invoiceStatus == 30) {
        return ['tableRowClassName']
      }
      return []
    },

    // 阶段表头
    tableOneClassName() {
      return 'tableOneClassName'
    },
    // 开票表头
    tableTwoClassName() {
      return 'tableTwoClassName'
    },
    // 收款表头
    tableThreeClassName() {
      return 'tableThreeClassName'
    },
    // 付款明细编辑
    editPaymentFn(row, propsPayment) {
      this.PaymentForm = row.deepClone()
      this.PaymentForm.fileDeleteList = []
      // 开票期数内容
      this.PaymentForm.invoiceAmount = propsPayment.invoiceAmount
      this.PaymentForm.readonlyProceedsAmount = propsPayment.proceedsAmount
      this.PaymentForm.invoiceDate = propsPayment.invoiceDate
      //  阶段内容
      this.tableData.forEach(v => {
        if (v.id == propsPayment.agreementStagePlanId) {
          this.PaymentForm.stageName = v.stageName
          this.PaymentForm.paymentAmount = v.paymentAmount
          this.PaymentForm.paymentStandard = v.paymentStandard
        }
      })
      this.PaymentIsShow = true
    },
    // 付款明细删除
    delPaymentFn(row, propsPayment) {
      this.$confirm('是否删除该付款明细数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
      })
        .then(() => {
          this.$api.agreement
            .deleteTaskPayment(row.id)
            .then(res => {
              this.$emit('updateData')
              this.$message.success('删除成功!')
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    // 打开阶段内容
    handleCellClick(row, column) {
      this.contentDialog = ''
      this.taxesFeeArr = []
      if (column.property == 'paymentStandard') {
        this.contentDialog = row.paymentStandard
        this.contentTitle = '阶段内容展示'
        this.contentDialogVisible = true
      } else if (column.property == 'proceedsRemark') {
        this.contentDialog = row.proceedsRemark
        this.contentTitle = '备注展示'
        this.contentDialogVisible = true
      } else if (column.property == 'stageName') {
        this.contentDialog = row.stageName
        this.contentTitle = '阶段展示'
        this.contentDialogVisible = true
      } else if (column.property == 'taxesFee') {
        this.taxesFeeArr = [row]
        this.contentTitle = '税费内容展示'
        this.contentDialogVisible = true
      } else if (column.property == 'remark') {
        this.contentDialog = row.remark
        this.contentTitle = '备注展示'
        this.contentDialogVisible = true
      }
    },
    //过滤
    transitionVatType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        str = item?.vatName
      }
      return str
    },
    // 确认收款-保存
    defineFn() {
      this.$api.project
        .paymentPass(this.formData)
        .then(res => {
          this.cancel()
          this.$emit('updateData')
          this.$message.success('收款成功')
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 确认收款取消
    cancel() {
      this.submitDialog = false
      this.formData = {
        proceedsRemark: '',
        agreementStagePlanId: '',
        actualProceedsDate: Date.now(),
      }
    },
    // 添加收款取消
    cancelFn() {
      this.isShow = false
      this.PaymentIsShow = false
      this.form = {
        //开票明细
        stageName: null,
        paymentStandard: null,
        paymentAmount: null,
        proceedsAmount: null,
        proceedsDate: Date.now(),
        agreementStagePlanId: null,
        fileList: [],
      }
      this.PaymentForm = {
        //付款明细
        proceedsDate: Date.now(),
        proceedsAmount: null,
        agreementStagePlanId: null, //合同阶段id
        agreementStageTaskId: null, //开票明细期数id
        fileList: [],
        fileDeleteList: [],
        // 开票期数内容
        invoiceAmount: null,
        readonlyProceedsAmount: null,
        invoiceDate: Date.now(),
        // 阶段内容
        stageName: null,
        paymentStandard: null,
        paymentAmount: null,
      }
      if (this.$refs.PaymentIsShow) {
        this.$refs.PaymentIsShow.resetFields()
      }
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
    },
    // 阶段收款添加
    increaseFn() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const item = this.tableData.find(v => v.id == this.form.agreementStagePlanId)
          if (item) {
            this.form.numberNo = item.agreementStageTaskList.length + 1
          }

          this.$api.agreement.addPayment(this.form).then(res => {
            this.$message.success('添加收款信息成功!')
            this.cancelFn()
            this.$emit('updateData')
          })
        }
      })
    },
    // 期数收款添加
    paymentIncreaseFn() {
      if (this.PaymentForm.fileList.length <= 0) {
        this.$message.warning('请上传收款附件')
        return
      }
      this.$refs.PaymentForm.validate(valid => {
        if (valid) {
          if (this.PaymentForm.id) {
            this.$api.agreement.editTask(this.PaymentForm).then(res => {
              this.$message.success('编辑收款信息成功!')
              this.cancelFn()
              this.$emit('updateData')
            })
          } else {
            this.$api.agreement.perfectionPayment(this.PaymentForm).then(res => {
              this.$message.success('添加收款信息成功!')
              this.cancelFn()
              this.$emit('updateData')
            })
          }
        } else {
        }
      })
    },
    // 添加开票收款
    addFn(row) {
      this.form.stageName = row.stageName
      this.form.paymentStandard = row.paymentStandard
      this.form.paymentAmount = row.paymentAmount
      this.form.agreementStagePlanId = row.id
      this.form.fileList = []
      this.isShow = true
    },
    // 开票明细-收款删除
    delFn(row) {
      this.$confirm('是否删除该开票明细数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
      })
        .then(() => {
          this.$api.agreement
            .deleteTask(row.id)
            .then(res => {
              this.$emit('updateData')
              this.$message.success('删除成功!')
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    // editFn(n, row) {
    //   if (n == 'ch') {
    //     this.$confirm('是否把这条发票数据冲红', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'success',
    //     })
    //       .then(() => {
    //         this.$api.agreement
    //           .offset(row.id)
    //           .then(res => {
    //             this.$emit('updateData')
    //             this.$message.success('发票冲红成功!')
    //           })
    //           .catch(err => {
    //             console.log(err)
    //           })
    //       })
    //       .catch(() => {
    //         //用户取消
    //       })
    //     return
    //   }
    //   this.form = row.deepClone()
    //   this.tableData.forEach(v => {
    //     if (v.id == row.agreementStagePlanId) {
    //       this.form.stageName = v.stageName
    //       this.form.paymentStandard = v.paymentStandard
    //       this.form.paymentAmount = v.paymentAmount
    //     }
    //   })
    //   this.editTitle = n
    //   this.isShow = true
    // },
    // 确认收款
    handleClick(row) {
      this.formData.agreementStagePlanId = row.id
      this.submitDialog = true
    },
    upload(r) {
      r[0].type = this.type
      this.form.fileList.push(...r)
    },
    del(row, index) {
      this.form.fileList.splice(index, 1)
    },
    taskUpload(r) {
      r[0].type = this.type
      this.PaymentForm.fileList.push(...r)
    },
    taskDel(row, index) {
      this.PaymentForm.fileList.splice(index, 1)
      if (row.id) {
        this.PaymentForm.fileDeleteList.push(row.id)
      }
    },
    /** 失去焦点触发 "申请金额" 文本框 **/
    blurAmount(e, type) {
      if (type == 'form') {
        this.form[e] = Number(this.form[e]).toFixed(2)
      } else {
        this.PaymentForm[e] = Number(this.PaymentForm[e]).toFixed(2)
      }
    },
    // 期数添加收款
    perfectFn(row) {
      // 开票期数内容
      this.PaymentForm.invoiceAmount = row.invoiceAmount
      this.PaymentForm.proceedsAmount = row.invoiceAmount
      this.PaymentForm.invoiceDate = row.invoiceDate
      this.PaymentForm.agreementStageTaskId = row.id //开票明细期数id
      this.PaymentForm.agreementStagePlanId = row.agreementStagePlanId //合同阶段id
      //  阶段内容
      this.tableData.forEach(v => {
        if (v.id == row.agreementStagePlanId) {
          this.PaymentForm.stageName = v.stageName
          this.PaymentForm.paymentAmount = v.paymentAmount
          this.PaymentForm.paymentStandard = v.paymentStandard
        }
      })
      this.PaymentIsShow = true
    },
    getType() {
      this.$api.dict.listSysDictData('AGREEMENT_PAYMENT_STATUS', true).then(res => {
        this.paymentStatus = res.data
      })
      this.$api.dict.listSysDictData('AGREEMENT_TICKET', true).then(res => {
        this.invoiceStatus = res.data
      })

      this.$api.vat
        .getVatTypeList()
        .then(res => {
          this.zzsList = res?.data?.records
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.vat
        .getVatDataList({ pageNow: 1, pageSize: 99999 })
        .then(res => {
          if (res.data?.records) {
            this.VatDataAll = res.data.records
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
  beforeDestroy() {},
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.annex {
  /deep/.el-card {
    //width:50%;
    .annex-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    + .el-card {
      margin-top: 20px;
    }
    .el-card__header {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }
  }
  //冲红高亮
  /deep/.tableRowClassName {
    background-color: #fce3e3;
    // color: red;
  }
  // 表头颜色
  /deep/ .tableOneClassName th {
    background: #ddeeff;
  }

  /deep/ .tableTwoClassName th {
    background: #edf3b7cc;
  }

  /deep/ .tableThreeClassName th {
    background: #cbfad9;
  }
  .leftTable {
    margin-left: 30px;
  }
  .colorTable {
    display: inline-block;
    width: 30px;
    height: 10px;
  }
  .tableOneClassName {
    background: #ddeeff;
  }
  .tableTwoClassName {
    background: #edf3b7cc;
  }
  .tableThreeClassName {
    background: #cbfad9;
  }
  /deep/ .undefined {
    border-bottom: 0px;
  }
  /deep/.el-table__cell {
    border-left: 1px solid #ebeef5 !important;
  }

  /deep/.el-table--mini {
    .el-table__cell {
      height: 32px;
    }
    .cell {
      padding: 0;
    }
  }
}
.fj-box {
  display: flex;
  justify-content: space-evenly;
  .fj-text {
    //超出一行省略号
    white-space: nowrap; //禁止换行
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; //...
  }
}
#editDaalog,
#PaymentIsShow {
  /deep/.el-dialog__body {
    padding: 0px 10px;
    .el-form-item {
      margin-bottom: 12px;
    }
  }
}
.footer-statistics {
  display: flex;
  flex-wrap: wrap;
  line-height: 44px;
  margin-top: 15px;
  .footer-statistics-total {
    color: #000;
    font-weight: 700;
  }
  .footer-statistics-ccenter {
    margin-left: 20px;
  }
}
.dialogContentHeight {
  height: 250px;
  overflow: auto;
  white-space: pre-wrap;
  padding: 0px 20px;
}
.operate {
  padding: 5px;
  color: #fff;
}
.emove_Bgc {
  background-color: #f78888;
}
.Edit_Bgc {
  background-color: #e6a23c;
}
.blush_Bgc {
  background-color: #6391f3c2;
}
.proceeds_Bgc {
  background-color: #85ce61c2;
}
.addProceeds_Bgc {
  background-color: #6391f3c2;
}
.isProceeds_Bgc {
  background-color: #409eff;
}
/deep/.el-button.is-disabled.el-button--text {
  background-color: #409eff;
}
// input type为number，只输入数字
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}

// 还原 input 组件禁用后的置灰效果
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #f5f7fa !important;
  border-color: #e4e7ed !important;
  color: #c0c4cc !important;
  cursor: not-allowed;
}
.project-jincome-top {
  margin-bottom: 10px;
  color: #303133;
  display: flex;
  justify-content: space-between;
}
.project-jincome-fw {
  font-size: 18px;
  font-weight: 700;
}
.deadline-color-red {
  color: red;
}
.deadline-color-green {
  color: green;
}
</style>
