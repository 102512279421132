<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">公司收支趋势</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'MoneyHistogram'"
            :introductionType="'companyType'"
            :yearIsShow="true"
            :list="dictData.yearList"
            v-model="sectionInfo.companyMoneyYear"
            :twoValue="sectionInfo.companyType"
            @change="onMoneyHistogramChange"
            @changeData="onMoneyHistogramDataChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :title="'公司：'"
            :introductionType="'companyType'"
            :type="'MoneyHistogram'"
            :clearable="true"
            :list="dictData.companyType"
            :yearValue="sectionInfo.companyMoneyYear"
            v-model="sectionInfo.companyType"
            @change="onMoneyHistogramChange"
            @changeData="onMoneyHistogramDataChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="isDialog = true"
          >数据来源说明</el-button
        >
      </div>
      <MoneyHistogram :companyMoneyList="companyMoneyList" />

      <el-table height="190px" :data="companyMoneyData">
        <el-table-column
          prop="contractAmountName"
          label="金额类型"
          align="center"
          fixed="left"
          min-width="120"
        ></el-table-column>
        <el-table-column prop="januaryAmount" min-width="120" label="一月" align="right">
          <template slot-scope="scope">
            {{ scope.row.januaryAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="februaryAmount" min-width="120" label="二月" align="right">
          <template slot-scope="scope">
            {{ scope.row.februaryAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="marchAmount" min-width="120" label="三月" align="right">
          <template slot-scope="scope">
            {{ scope.row.marchAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="aprilAmount" min-width="120" label="四月" align="right">
          <template slot-scope="scope">
            {{ scope.row.aprilAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="mayAmount" min-width="120" label="五月" align="right">
          <template slot-scope="scope">
            {{ scope.row.mayAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="juneAmount" min-width="120" label="六月" align="right">
          <template slot-scope="scope">
            {{ scope.row.juneAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="julyAmount" min-width="120" label="七月" align="right">
          <template slot-scope="scope">
            {{ scope.row.julyAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="augustAmount" min-width="120" label="八月" align="right">
          <template slot-scope="scope">
            {{ scope.row.augustAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="septemberAmount" min-width="120" label="九月" align="right">
          <template slot-scope="scope">
            {{ scope.row.septemberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="octoberAmount" min-width="120" label="十月" align="right">
          <template slot-scope="scope">
            {{ scope.row.octoberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="novemberAmount" min-width="120" label="十一月" align="right">
          <template slot-scope="scope">
            {{ scope.row.novemberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="decemberAmount" min-width="120" label="十二月" align="right">
          <template slot-scope="scope">
            {{ scope.row.decemberAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="accumulative"
          min-width="140"
          fixed="right"
          label="累计"
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.accumulative | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img src="@/assets/财务统计-公司收支趋势图.png" alt="数据来源说明" width="100%" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    MoneyHistogram: () => import('./moneyHistogram.vue'),
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      dictData: {
        yearList: [],
        companyType: [],
      },
      companyMoneyList: [], // 公司收支趋势图
      companyMoneyData: [],
      sectionInfo: {
        // 条件筛选
        // 年份
        companyMoneyYear: null,
        companyType: '',
      },
      titleYear: '统计时间：',
      isDialog: false,
    }
  },
  provide() {
    return {}
  },
  computed: {},
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    onMoneyHistogramChange(arr) {
      this.companyMoneyList = arr
    },
    onMoneyHistogramDataChange(arr) {
      this.companyMoneyData = arr
      this.companyMoneyData.forEach(item => {
        item.accumulative = Object.values(item)
          .filter(value => typeof value === 'number' && !isNaN(value)) // 仅累加数值类型的数据
          .reduce((acc, value) => acc + value, 0)
      })
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(async res => {
          this.loading = true
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.companyMoneyYear = dictVal

          const financiaCompanyRevenueExpenseTrend =
            await this.$api.statistics.financiaCompanyRevenueExpenseTrend(`date=${dictVal}`)
          const { companyExpenseResponseVo = {} } = financiaCompanyRevenueExpenseTrend.data
          const nameMapping = {
            actualReceiptsList: '收款',
            actualExpenditureList: '支出',
            fundBalanceList: '现金流',
          }
          this.companyMoneyList = Object.keys(companyExpenseResponseVo)
            .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
            .map((key, index) => ({
              name: nameMapping[key], // 根据键获取名称
              data: companyExpenseResponseVo[key],
            }))
          this.companyMoneyData = companyExpenseResponseVo.contractPaymentListVoList || []
          this.companyMoneyData.forEach(item => {
            item.accumulative = Object.values(item)
              .filter(value => typeof value === 'number' && !isNaN(value)) // 仅累加数值类型的数据
              .reduce((acc, value) => acc + value, 0)
          })
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res?.data

          const item = this.dictData.companyType.some(v => v.id === '1')
          if (!item) {
            this.dictData.companyType.unshift({
              id: '1',
              dictName: '全部',
              dictVal: '',
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
</style>
