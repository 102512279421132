<template>
  <div>
    {{ title }}
    <el-select
      v-model="searchValue"
      :placeholder="placeholder"
      :filterable="filterable"
      :clearable="clearable"
      :multiple="multiple"
      :collapse-tags="multiple"
      :disabled="disabled"
      :size="size"
      @clear="onClear"
      @change="onChange"
      :style="{ width: width + 'px' }"
    >
      <el-option v-for="item in searchArr" :key="item.id" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },
    width: {
      type: [String, Number],
      default: '130',
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    type: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    value: {
      type: [String, Array],
      default: '',
    },
    introductionType: {
      type: String,
      default: '',
    },
    twoIntroductionType: {
      type: String,
      default: '',
    },
    threeIntroductionType: {
      type: String,
      default: '',
    },
    fourIntroductionType: {
      type: String,
      default: '',
    },
    sixIntroductionType: {
      type: String,
      default: '',
    },
    yearValue: {
      type: [String, Array],
      default: () => {
        return null
      },
    },
    twoValue: {
      type: [String, Array],
      default: () => {
        return null
      },
    },
    threeValue: {
      type: [String, Array],
      default: () => {
        return null
      },
    },
    fourValue: {
      type: [String, Array],
      default: () => {
        return null
      },
    },
    fiveValue: {
      type: [String, Array],
      default: () => {
        return null
      },
    },
    sixValue: {
      type: [String, Array],
      default: () => {
        return null
      },
    },
    yearIsShow: {
      type: Boolean,
      default: false,
    },
    threeIsShow: {
      type: Boolean,
      default: false,
    },
    fourIsShow: {
      type: Boolean,
      default: false,
    },
    fiveIsShow: {
      type: Boolean,
      default: false,
    },
    sixIsShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchArr: [],
      searchValue: null,
      dictData: {
        businessLines: [],
        yearList: [],
        projectSource: [],
        businessType: [],
      },
    }
  },
  created() {
    this.getType()
  },
  watch: {
    value: {
      immediate: true,
      handler: async function (val) {
        this.searchValue = val
        if (this.type === 'OverViewDeptId' && val !== null) {
          // 项目总览
          this.onChange(val)
        } else if (this.type === 'ProjectStatisticsDeptId' && val !== null) {
          // 项目统计表
          this.onChange(val)
        }
      },
    },
    list: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.searchArr = val.map(v => {
          return { id: v.id, label: v.dictName, value: v.dictVal }
        })
      },
    },
  },
  methods: {
    async onChange(value) {
      this.$emit('onChangeLoading', true)
      if (this.yearIsShow && value.length == 0) {
        value = [this.dictData.yearList[0].dictVal]
      }
      this.$emit('input', value)
      if (!this.type) return
      let res = {}
      let arr = []
      let projectPaymentAmountObj = {}
      let queryParams = this.queryParamsFn(value)

      if (this.type === 'projectQuantit') {
        // 项目数量统计
        res = await this.$api.statistics.queryProjectCountSummary(queryParams)
        const { projectCountStatisticsVo = [] } = res.data
        const keyMappings = {
          notStarted: '未启动',
          inprogress: '正常实施中',
          delayed: '进度滞后',
          maintenance: '运维服务期(质保期)',
          temporarilyPaused: '临时暂停',
          terminatedPendingPayment: '终止(待收款)',
          terminatedNoPayment: '终止(无待收款)',
          completedPaid: '已完结已收款',
          completedPendingPayment: '已完结待收款',
          uncompletedPaid: '未完结已收款',
          archived: '归档',
        }
        arr = Object.keys(projectCountStatisticsVo)
          .filter(key => keyMappings.hasOwnProperty(key)) // 只处理有映射的键
          .map(key => ({
            name: keyMappings[key], // 根据键获取名称
            value: projectCountStatisticsVo[key],
          }))

        this.$emit('change', arr)
      } else if (this.type === 'projectStatistics' || this.type === 'ProjectStatisticsDeptId') {
        // 项目统计表
        res = await this.$api.statistics.queryProjectSummary(queryParams)
        projectPaymentAmountObj = res.data.projectStatisticsView || {}
        this.$emit('change', projectPaymentAmountObj)
      } else if (this.type === 'Histogram') {
        // 项目年度收入统计
        res = await this.$api.statistics.queryProjectIncomeStatistics(queryParams)
        const { projectIncomeStatisticsVo = [] } = res.data
        projectIncomeStatisticsVo.forEach(item => {
          const businessLinesItem = this.dictData.businessLines.find(
            v => v.dictVal === item.businessCategory
          )
          item.businessCategoryName = businessLinesItem
            ? businessLinesItem.dictName
            : item.businessCategory
        })
        arr = projectIncomeStatisticsVo
        const nameMapping = {
          actualAmount: '项目实际金额',
          contractAmount: '合同金额',
          invoicedAmount: '已开票金额',
          receivedAmount: '已收款金额',
          unreceivedInvoicedAmount: '已开票未收款金额',
          unreceivedUnissuedAmount: '未开票已收款',
          unissuedAmount: '未开票金额',
          totalTax: '总税费',
        }
        const businessLinesInfo = {
          nameArr: [],
          data: [],
        }
        businessLinesInfo.nameArr = projectIncomeStatisticsVo.map(v => v.businessCategoryName)
        // 根据 nameMapping 填充数据
        Object.entries(nameMapping).forEach(([field, fieldName]) => {
          const fieldData = {
            name: fieldName,
            data: [],
          }
          // 对每个业务分类进行数据填充
          businessLinesInfo.nameArr.forEach(category => {
            // 查找该类别对应的项目
            const categoryData = projectIncomeStatisticsVo.find(
              item => item.businessCategoryName === category
            )
            // 根据字段获取对应的值，若不存在则默认为 0
            fieldData.data.push(categoryData ? categoryData[field] : 0)
          })
          // 将字段数据推入 businessLinesInfo.data
          businessLinesInfo.data.push(fieldData)
        })
        this.$emit('change', businessLinesInfo)
        this.$emit('changeData', arr)
      } else if (this.type === 'HistogramMonth') {
        // 项目月度收入统计
        res = await this.$api.statistics.queryMonthlyProjectIncomeStatistics(queryParams)
        const { projectMonthlyIncomeStatisticsVo = {} } = res.data
        const nameMapping = {
          invoicedAmount: '已开票金额',
          proceedsAmount: '已收款金额',
        }
        arr = Object.keys(projectMonthlyIncomeStatisticsVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map(key => ({
            name: nameMapping[key], // 根据键获取名称
            data: projectMonthlyIncomeStatisticsVo[key],
          }))
        const contractPaymentListVoList =
          projectMonthlyIncomeStatisticsVo?.contractPaymentListVoList || []
        this.$emit('changeData', contractPaymentListVoList)
        this.$emit('change', arr)
      } else if (this.type === 'BusinessLines') {
        // 业务种类
        res = await this.$api.statistics.projectBusinessCategorySummary(queryParams)
        arr = res.data?.businessCategoryResponseList.map(item => {
          const lines = this.dictData.businessLines.find(v => v.dictVal === item.categoryName)
          return {
            categoryPaymentAmount: item.categoryPaymentAmount,
            categoryAmount: item.categoryAmount,
            percentage: Number((item.percentage * 100).toFixed(2)),
            paymentPercentage: Number((item.paymentPercentage * 100).toFixed(2)),
            categoryCount: item.categoryCount,
            name: lines ? lines.dictName : item.categoryName, // 如果找不到则使用原名称
          }
        })
        this.$emit('change', arr)
      } else if (this.type === 'BusinessLinesData') {
        // 业务种类统计
        res = await this.$api.statistics.projectQueryBusinessCategorySummaryList(queryParams)
        projectPaymentAmountObj = res.data.businessCategoryResponseView || {}
        this.$emit('change', projectPaymentAmountObj)
      } else if (this.type === 'BusinessType') {
        // 商务类型
        res = await this.$api.statistics.projecBusinessTypeSummary(queryParams)
        arr = res.data?.businessTypeResponse.map(item => {
          const typeItem = this.dictData.businessType.find(v => v.dictVal === item.businessTypeName)
          return {
            businessTypePaymentAmount: item.businessTypePaymentAmount,
            businessTypeAmount: item.businessTypeAmount,
            businessPercentage: Number((item.businessPercentage * 100).toFixed(2)),
            paymentBusinessPercentage: Number((item.paymentBusinessPercentage * 100).toFixed(2)),
            businessTypeCount: item.businessTypeCount,
            name: typeItem ? typeItem.dictName : item.businessTypeName, // 如果找不到则使用原名称
          }
        })
        this.$emit('change', arr)
      } else if (this.type === 'BusinessTypeData') {
        // 商务类型统计
        res = await this.$api.statistics.projectQueryBusinessTypeSummaryList(queryParams)
        projectPaymentAmountObj = res.data.businessTypeResponseView || {}
        this.$emit('change', projectPaymentAmountObj)
      } else if (this.type === 'ProjectSource') {
        // 项目来源
        res = await this.$api.statistics.queryProjectSourceSummary(queryParams)
        arr = res.data?.projectSourceResponse.map(item => {
          const typeItem = this.dictData.projectSource.find(
            v => v.dictVal === item.projectSourceName
          )
          return {
            projectSourcePaymentAmount: item.projectSourcePaymentAmount,
            projectSourceAmount: item.projectSourceAmount,
            projectPercentage: Number((item.projectPercentage * 100).toFixed(2)),
            paymentProjectPercentage: Number((item.paymentProjectPercentage * 100).toFixed(2)),
            projectSourceCount: item.projectSourceCount,
            name: typeItem ? typeItem.dictName : item.projectSourceName, // 如果找不到则使用原名称
          }
        })
        this.$emit('change', arr)
      } else if (this.type === 'ProjectSourceData') {
        // 项目来源统计
        res = await this.$api.statistics.projectQueryProjectSourceSummaryList(queryParams)
        projectPaymentAmountObj = res.data.projectSourceResponseView || {}
        this.$emit('change', projectPaymentAmountObj)
      } else if (this.type === 'ProjectHourHistogram') {
        // 项目工时统计
        res = await this.$api.statistics.queryProjectWorkHourSummary(queryParams)
        arr = res.data?.projectWorkHourResponseList || []
        this.$emit('change', arr)
      } else if (this.type === 'PersonageHuorHistogram') {
        // 个人项目工时统计
        res = await this.$api.statistics.queryPersonalProjectWorkHourSummary(queryParams)
        const { personProjectWorkHourList = [] } = res.data?.projectWorkHourResponseVo
        this.$emit('change', personProjectWorkHourList)
      } else if (this.type === 'OverView' || this.type === 'OverViewDeptId') {
        // 项目总览
        res = await this.$api.statistics.queryProjectCountData(queryParams)
        const { projectDataView = {} } = res.data
        this.$emit('change', projectDataView)
      }

      this.$emit('onChangeLoading', false)
    },

    onClear(value) {
      this.$emit('input', value)
    },
    queryParamsFn(value) {
      let queryParams = `date=${this.yearIsShow ? value : this.yearValue}`
      // 添加查询参数的函数
      const addParam = (param, paramValue) => {
        if (paramValue) {
          queryParams += `&${param}=${paramValue}`
        }
      }
      // 父项切换
      if (this.yearIsShow) {
        // 父项的情况下，依次添加二级、三级、四级参数
        if (this.twoValue) addParam(this.introductionType, this.twoValue)
        if (this.threeValue) addParam(this.twoIntroductionType, this.threeValue)
        if (this.fourValue) addParam(this.threeIntroductionType, this.fourValue)
        if (this.fiveValue) addParam(this.fourIntroductionType, this.fiveValue)
        if (this.sixValue) addParam(this.sixIntroductionType, this.sixValue)
        return queryParams
      }
      // 子项切换
      if (
        value &&
        !this.threeIsShow &&
        !this.threeValue &&
        !this.fourIsShow &&
        !this.fourValue &&
        !this.fiveIsShow &&
        !this.fiveValue &&
        !this.sixIsShow &&
        !this.sixValue
      ) {
        // 正常二级关联，没有三级和四级
        addParam(this.introductionType, value)
      } else {
        // 如果有三级或四级选择，分别处理
        if (this.threeIsShow) {
          // 三级选择的情况
          if (this.twoValue) addParam(this.introductionType, this.twoValue)
          if (value) addParam(this.twoIntroductionType, value)
          if (this.fourValue) addParam(this.threeIntroductionType, this.fourValue)
          if (this.fiveValue) addParam(this.fourIntroductionType, this.fiveValue)
          if (this.sixValue) addParam(this.sixIntroductionType, this.sixValue)
        } else if (this.fourIsShow) {
          // 四级选择的情况
          if (this.twoValue) addParam(this.introductionType, this.twoValue)
          if (this.threeValue) addParam(this.twoIntroductionType, this.threeValue)
          if (value) addParam(this.threeIntroductionType, value)
          if (this.fiveValue) addParam(this.fourIntroductionType, this.fiveValue)
          if (this.sixValue) addParam(this.sixIntroductionType, this.sixValue)
        } else if (this.fiveIsShow) {
          // 五级选择的情况
          if (this.twoValue) addParam(this.introductionType, this.twoValue)
          if (this.threeValue) addParam(this.twoIntroductionType, this.threeValue)
          if (this.fourValue) addParam(this.threeIntroductionType, this.fourValue)
          if (value) addParam(this.fourIntroductionType, value)
          if (this.sixValue) addParam(this.sixIntroductionType, this.sixValue)
        } else if (this.sixIsShow) {
          // 六级选择的情况
          if (this.twoValue) addParam(this.introductionType, this.twoValue)
          if (this.threeValue) addParam(this.twoIntroductionType, this.threeValue)
          if (this.fourValue) addParam(this.threeIntroductionType, this.fourValue)
          if (this.fiveValue) addParam(this.fourIntroductionType, this.fiveValue)
          if (value) addParam(this.sixIntroductionType, value)
        } else {
          // 仅二级选择
          if (value) addParam(this.introductionType, value)
          if (this.threeValue) addParam(this.twoIntroductionType, this.threeValue)
          if (this.fourValue) addParam(this.threeIntroductionType, this.fourValue)
          if (this.fiveValue) addParam(this.fourIntroductionType, this.fiveValue)
          if (this.sixValue) addParam(this.sixIntroductionType, this.sixValue)
        }
      }
      return queryParams
    },

    getType() {
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.fr {
  float: right;
  padding: 3px 0;
}
</style>
