<template>
  <div id="histogramId" style="width: 100%; height: 600px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'Histogram',
  props: {
    contractAmountList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {}
  },
  computed: {},

  watch: {
    contractAmountList: {
      deep: true,
      immediate: true,
      handler: function (list) {
        if (list.length > 0) {
          this.init(list)
        } else {
          this.init([])
        }
      },
    },
  },
  async created() {},
  mounted() {},
  methods: {
    // 合同、回款计划、开票及回款统计
    init(arr = []) {
      this.$nextTick(() => {
        function truncateToOneDecimal(num) {
          // 判断是否大于等于10亿
          if (num >= 1e9) {
            // 如果是10亿及以上，保留前两位数字
            return num.toString().slice(0, 2) // 直接返回前两位数字
          }

          // 否则返回数字的第一位
          return num.toString()[0] // 返回第一位数字
        }
        var chartDom = document.getElementById('histogramId')
        var myChart = echarts.init(chartDom)
        var option
        const nameArr = arr.map(v => v.name)
        option = {
          tooltip: {
            show: true,
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            formatter: function (params) {
              // 获取当前月份
              const currentMonth = params[0].name // 假设第一个参数的 name 是月份
              // 定义提示框的内容
              let tooltipContent = currentMonth + '<br/>' // 添加当前月份
              params.forEach(function (item) {
                const value = Number(item.data).toLocaleString()
                tooltipContent += item.seriesName + ': ' + value + '元' + '<br/>'
              })
              return tooltipContent || '无数据' // 如果没有要显示的数据，则返回“无数据”
            },
          },

          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          legend: {
            data: nameArr,
          },
          xAxis: [
            {
              type: 'category',
              data: [
                '1月',
                '2月',
                '3月',
                '4月',
                '5月',
                '6月',
                '7月',
                '8月',
                '9月',
                '10月',
                '11月',
                '12月',
              ],
              axisPointer: {
                type: 'shadow', // 鼠标悬停时显示的指示线类型
              },
            },
          ],
          yAxis: [
            {
              //   type: 'log',
              //   min: 1000000, // 设置y轴最小值
              //   interval: 2000000, // 设置每个间隔
              //   axisLabel: {
              //     formatter: val => {
              //       if (val === 1) {
              //         return 0
              //       } else if (val >= 100000000) {
              //         return val / 100000000 + '亿'
              //       } else if (val >= 10000) {
              //         return val / 10000 + 'W'
              //       }
              //       return val // 其他情况下直接返回原数值
              //     },
              //   },
              // },
              type: 'value',
              min: 1000000, // 设置y轴最小值
              interval: 2000000, // 设置每个间隔
              max: 50000000,
              axisLabel: {
                formatter: val => {
                  if (val === 1) {
                    return 0
                  } else if (val >= 100000000) {
                    return val / 100000000 + '亿'
                  } else if (val >= 10000) {
                    return val / 10000 + 'W'
                  }
                  return val // 其他情况下直接返回原数值
                },
              },
            },
          ],
          series: [
            {
              name: arr[0]?.name,
              type: 'bar',
              barWidth: '20%',
              barGap: '0%', // 中间不间隔
              data: arr[0]?.data,
              label: {
                show: true, // 显示标签
                position: 'top', // 标签位置在柱子内部
                color: '#000', // 标签文字颜色
                fontSize: 14, // 标签文字大小
                formatter: function (params) {
                  if (params.value >= 100000000) {
                    return truncateToOneDecimal(params.value) + '亿'
                  } else if (params.value >= 50000000) {
                    return truncateToOneDecimal(params.value) + '千万'
                  } else {
                    return ''
                  }
                },
              },
            },
            // {
            //   name: arr[1]?.name,
            //   type: 'bar',
            //   barWidth: '20%',
            //   barGap: '0%', // 中间不间隔
            //   data: arr[1]?.data,
            //   label: {
            //     show: true, // 显示标签
            //     position: 'top', // 标签位置在柱子内部
            //     color: '#000', // 标签文字颜色
            //     fontSize: 14, // 标签文字大小
            //     formatter: function (params) {
            //       if (params.value >= 100000000) {
            //         return truncateToOneDecimal(params.value) + '亿'
            //       } else if (params.value >= 50000000) {
            //         return truncateToOneDecimal(params.value) + '千万'
            //       } else {
            //         return ''
            //       }
            //     },
            //   },
            // },
            {
              name: arr[2]?.name,
              type: 'bar',
              barWidth: '20%',
              barGap: '0%', // 中间不间隔
              data: arr[2]?.data,
              label: {
                show: true, // 显示标签
                position: 'top', // 标签位置在柱子内部
                color: '#000', // 标签文字颜色
                fontSize: 14, // 标签文字大小
                formatter: function (params) {
                  if (params.value >= 100000000) {
                    return truncateToOneDecimal(params.value) + '亿'
                  } else if (params.value >= 50000000) {
                    return truncateToOneDecimal(params.value) + '千万'
                  } else {
                    return ''
                  }
                },
              },
            },
            {
              name: arr[3]?.name,
              type: 'bar',
              barWidth: '20%',
              barGap: '0%', // 中间不间隔
              data: arr[3]?.data,
              label: {
                show: true, // 显示标签
                position: 'top', // 标签位置在柱子内部
                color: '#000', // 标签文字颜色
                fontSize: 14, // 标签文字大小
                formatter: function (params) {
                  if (params.value >= 100000000) {
                    return truncateToOneDecimal(params.value) + '亿'
                  } else if (params.value >= 50000000) {
                    return truncateToOneDecimal(params.value) + '千万'
                  } else {
                    return ''
                  }
                },
              },
            },
          ],
        }
        option && myChart.setOption(option)
        myChart.setOption(option, true) // 第二个参数设置为 true，表示完全更新配置项
        //监听窗口变化
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      })
    },
  },
}
</script>
<style scoped></style>
