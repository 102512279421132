<template>
  <div class="">
    <div style="height: 100%" v-loading="loading">
      <template v-if="isDisable">
        <el-descriptions
          style="margin-bottom: 10px"
          title="项目基础信息"
          direction="vertical"
          :column="7"
          border
        >
          <el-descriptions-item label="项目名称" :span="3">
            {{ projectInformation.projectName }}
          </el-descriptions-item>
          <el-descriptions-item
            label="项目来源"
            :span="1"
            label-class-name="type-label"
            content-class-name="type-content"
          >
            {{ projectInformation.projectSource | dict(dictData.projectSource) }}
          </el-descriptions-item>
          <el-descriptions-item
            label="业务种类"
            :span="1"
            label-class-name="type-label"
            content-class-name="type-content"
          >
            {{ projectInformation.businessLines | dict(dictData.businessLines) }}
          </el-descriptions-item>
          <el-descriptions-item
            label="部门"
            :span="1"
            label-class-name="type-label"
            content-class-name="type-content"
          >
            {{ projectInformation.theirDeptName }}
          </el-descriptions-item>
          <el-descriptions-item
            label="项目经理"
            :span="1"
            label-class-name="type-label"
            content-class-name="type-content"
          >
            {{ projectInformation.managerName }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
      <template v-else>
        <el-descriptions
          style="margin-bottom: 10px"
          title="项目基础信息"
          direction="vertical"
          :column="5"
          border
        >
          <el-descriptions-item label="项目名称" :span="3">
            {{ projectInformation.projectName }}
          </el-descriptions-item>
          <el-descriptions-item
            label="项目来源"
            :span="1"
            label-class-name="type-label"
            content-class-name="type-content"
          >
            {{ projectInformation.projectSource | dict(dictData.projectSource) }}
          </el-descriptions-item>
          <el-descriptions-item
            label="业务种类"
            :span="1"
            label-class-name="type-label"
            content-class-name="type-content"
          >
            {{ projectInformation.businessLines | dict(dictData.businessLines) }}
          </el-descriptions-item>
        </el-descriptions>
      </template>

      <el-descriptions
        style="margin-top: 10px"
        title="项目费用结算"
        direction="vertical"
        :column="5"
        border
      >
        <template slot="extra">
          <span style="font-size: 17px; color: gray">单位：元</span>
        </template>
        <el-descriptions-item label="项目实际金额" :span="1">
          {{ projectDisposableExpenses.actualAmount | thousands }}
        </el-descriptions-item>
        <el-descriptions-item :span="1" label="税费">
          {{ projectDisposableExpenses.taxesFee | thousands }}
        </el-descriptions-item>
        <el-descriptions-item label="招标费" :span="1">
          {{ projectDisposableExpenses.tenderFee | thousands }}
        </el-descriptions-item>

        <el-descriptions-item :span="1" label-class-name="my-label" content-class-name="my-content">
          <template slot="label">
            <div>项目可支配费用</div>
            <div style="font-size: 12px; color: red; margin-top: 10px">
              项目可支配费用 = 项目实际金额 - 税费 - 招投标费用 - 预计外部协作费用
            </div>
          </template>
          {{ projectDisposableExpenses.disposableProjectExpense | thousands }}
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <div>
              内部生产费用
              <el-button
                style="margin-left: 20px"
                type="primary"
                size="mini"
                @click="showNBSCFY"
                :loading="isNBSCFYButtonLoading"
                >查看比例</el-button
              >
            </div>
            <div style="margin-top: 5px">
              <span style="font-size: 12px; color: red">
                内部生产费用 = 项目可支配费用 X 生产费用比例
              </span>
            </div>
          </template>
          {{ projectDisposableExpenses.productionCost | thousands }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="5" border direction="vertical">
        <el-descriptions-item label-class-name="detail-label" content-class-name="detail-content">
          <template slot="label">
            <div>（1）部门管理绩效</div>
            <div style="margin-top: 5px">
              <span style="font-size: 12px; color: red"> 部门管理绩效 = 内部生产费用 X 25% </span>
            </div>
          </template>

          {{
            projectDisposableExpenses.deptManagementPerformance | thousands
          }}</el-descriptions-item
        >
        <el-descriptions-item label-class-name="detail-label" content-class-name="detail-content">
          <template slot="label">
            <div>（2）报销费用合计</div>
            <div class="cost-btn">
              <el-button
                type="primary"
                size="mini"
                v-if="
                  options.operatingButton &&
                  options.operatingButton.some(r => {
                    return r == 'EXPENSES'
                  })
                "
                @click="showProjectCost"
                :loading="isProjectCostLoading"
                >查看明细</el-button
              >
            </div>
          </template>
          {{ projectDisposableExpenses.sumExpenseCost | thousands }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="detail-label" content-class-name="detail-content">
          <template slot="label">
            <div>（3）人员成本合计</div>
            <div class="cost-btn">
              <el-button
                type="primary"
                size="mini"
                @click="showHumanCost"
                v-if="permission(['STAFF_VOST_VIEW'])"
                :loading="isHumanCostLoading"
                >查看明细</el-button
              >
            </div>
          </template>
          {{ projectDisposableExpenses.sumStaffCost | thousands }}</el-descriptions-item
        >
        <el-descriptions-item label-class-name="detail-label " content-class-name="detail-content ">
          <template slot="label">
            <div>（4）外协生产费用合计</div>
            <div class="cost-btn">
              <el-button
                type="primary"
                size="mini"
                @click="showAssistCost"
                :loading="isWXSCFYButtonLoading"
                >查看明细</el-button
              >
            </div>
          </template>
          {{ projectDisposableExpenses.outsourceSumCost | thousands }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="detail-label" content-class-name="detail-content">
          <template slot="label">
            <div>（5）设备折旧</div>
            <div class="cost-btn"></div> </template
          >{{
            projectDisposableExpenses.depreciationEquipmentFee | thousands
          }}</el-descriptions-item
        >
      </el-descriptions>
      <el-descriptions :column="3" style="margin-bottom: 10px" border direction="vertical">
        <el-descriptions-item label-class-name="detail-label" content-class-name="detail-content">
          <template slot="label"> 预估公司利润 </template>
          {{ projectDisposableExpenses.corporateProfit | thousands }}</el-descriptions-item
        >
        <el-descriptions-item label-class-name="detail-label" content-class-name="detail-content">
          <template slot="label">
            <div>
              生产绩效
              <span style="font-size: 12px; color: red; margin-left: 20px">
                生产绩效 = 内部生产费用 - 部门管理绩效 - 报销费用合计 - 人员成本合计 -
                外协生产费用合计 - 设备折旧
              </span>
            </div>
          </template>
          {{ projectDisposableExpenses.productPerformance | thousands }}</el-descriptions-item
        >
        <el-descriptions-item
          label-class-name="detail-label projectBGC"
          content-class-name="detail-content projectBGC"
        >
          <template slot="label"> 实际生产绩效 </template>
          {{ projectDisposableExpenses.actualProductPerformance | thousands }}</el-descriptions-item
        >
      </el-descriptions>

      <el-descriptions
        style="margin-bottom: 10px"
        title="公司奖惩信息"
        direction="vertical"
        :column="3"
        border
      >
        <el-descriptions-item
          label="团队奖惩"
          :span="2"
          label-class-name="rewards-label"
          content-class-name="rewards-content"
        >
          <el-form :model="projectDisposableExpenses" label-width="110px" label-position="right">
            <div class="rp-inline">
              <el-form-item label="团队奖惩金额：">
                <el-input
                  style="width: 130px"
                  :disabled="!permission(['EXPENSESEDIT']) || isDisable"
                  v-model="projectDisposableExpenses.teamSumAmount"
                  @focus="focusAmount('teamSumAmount')"
                  @input="inputAmount('teamSumAmount')"
                  @blur="blurAmount('teamSumAmount')"
                  placeholder="请输入金额"
                ></el-input>
              </el-form-item>
              <div class="symbol">=</div>
              <el-form-item label="团队奖励金额">
                <el-input
                  style="width: 130px"
                  :disabled="!permission(['EXPENSESEDIT']) || isDisable"
                  v-model="projectDisposableExpenses.teamBonusAmount"
                  @focus="focusAmount('teamBonusAmount')"
                  @input="inputAmount('teamBonusAmount')"
                  @blur="blurAmount('teamBonusAmount')"
                  placeholder="请输入金额"
                ></el-input>
              </el-form-item>
              <div class="symbol">
                <div style="width: 8px; border-bottom: 1.5px solid #000; margin: 20px"></div>
              </div>
              <el-form-item label="团队惩罚金额">
                <el-input
                  style="width: 130px"
                  :disabled="!permission(['EXPENSESEDIT']) || isDisable"
                  v-model="projectDisposableExpenses.teamPenaltyAmount"
                  @focus="focusAmount('teamPenaltyAmount')"
                  @input="inputAmount('teamPenaltyAmount')"
                  @blur="blurAmount('teamPenaltyAmount')"
                  placeholder="请输入金额"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </el-descriptions-item>

        <el-descriptions-item
          :span="1"
          label-class-name="remark-label"
          content-class-name="remark-content"
        >
          <template slot="label"> 团队金额备注 </template>
          <el-input
            type="textarea"
            v-model="projectDisposableExpenses.teamAmountRemark"
            :disabled="!permission(['EXPENSESEDIT']) || isDisable"
            :autosize="{ minRows: 2, maxRows: 999 }"
          >
          </el-input
        ></el-descriptions-item>

        <el-descriptions-item
          label="个人奖惩"
          label-class-name="rewards-label"
          content-class-name="rewards-content"
          :span="2"
        >
          <el-form :model="projectDisposableExpenses" label-width="110px" label-position="right">
            <div class="rp-inline">
              <el-form-item label="个人奖惩金额：">
                <el-input
                  style="width: 130px"
                  :disabled="!permission(['EXPENSESEDIT']) || isDisable"
                  v-model="projectDisposableExpenses.staffSumAmount"
                  @focus="focusAmount('staffSumAmount')"
                  @input="inputAmount('staffSumAmount')"
                  @blur="blurAmount('staffSumAmount')"
                  placeholder="请输入金额"
                ></el-input>
              </el-form-item>
              <div class="symbol">=</div>
              <el-form-item label="个人奖励金额">
                <el-input
                  style="width: 130px"
                  :disabled="!permission(['EXPENSESEDIT']) || isDisable"
                  v-model="projectDisposableExpenses.staffBonusAmount"
                  @focus="focusAmount('staffBonusAmount')"
                  @input="inputAmount('staffBonusAmount')"
                  @blur="blurAmount('staffBonusAmount')"
                  placeholder="请输入金额"
                ></el-input>
              </el-form-item>
              <div class="symbol">
                <div style="width: 8px; border-bottom: 1.5px solid #000; margin: 20px"></div>
              </div>
              <el-form-item label="个人惩罚金额">
                <el-input
                  style="width: 130px"
                  :disabled="!permission(['EXPENSESEDIT']) || isDisable"
                  v-model="projectDisposableExpenses.staffPenaltyAmount"
                  @focus="focusAmount('staffPenaltyAmount')"
                  @input="inputAmount('staffPenaltyAmount')"
                  @blur="blurAmount('staffPenaltyAmount')"
                  placeholder="请输入金额"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="remark-label"
          content-class-name="remark-content"
          :span="1"
        >
          <template slot="label"> 成员金额备注 </template>
          <el-input
            type="textarea"
            :disabled="!permission(['EXPENSESEDIT']) || isDisable"
            v-model="projectDisposableExpenses.staffAmountRemark"
            :autosize="{ minRows: 2, maxRows: 999 }"
          >
          </el-input
        ></el-descriptions-item>
      </el-descriptions>
    </div>

    <!-- 内部生产费用历史记录 -->
    <EditDialog width="60%" :isShow.sync="NBSCFY_ratio_show">
      <div slot="title" class="title">内部生产费用比例</div>
      <div slot="content" class="content" style="min-height: 400px">
        <div style="font-size: 18px; color: black; font-weight: 700; margin-bottom: 5px">
          当前生产费用比例 ：
          <span style="color: red"
            >{{ projectDisposableExpenses.productionCostRatio * 100 || 0 }}%</span
          >
        </div>
        <div style="margin-left: 10px">更改记录：</div>
        <div class="below-content">
          <div style="width: 50%">
            <el-table
              :data="NBSCFY_ratio_data.productionCostRatioLogList"
              border
              style="width: 96%; margin-bottom: 10px; margin-left: 10px"
              :cell-style="nowStyle"
            >
              <el-table-column
                type="index"
                align="center"
                width="30"
                :show-overflow-tooltip="false"
              ></el-table-column>
              <el-table-column
                label="业务种类"
                prop="businessLines"
                align="center"
                width="105"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  {{ scope.row.businessLines | dict(dictData.businessLines) }}
                </template>
              </el-table-column>
              <el-table-column
                label="项目来源"
                prop="projectSource"
                align="center"
                width="85"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  {{ scope.row.projectSource | dict(dictData.projectSource) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="productionCostRatio"
                label="比例"
                width="70"
                align="center"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope"> {{ scope.row.productionCostRatio * 100 }}% </template>
              </el-table-column>
              <el-table-column prop="createdDate" label="更改日期" align="center">
                <template slot-scope="scope">
                  {{ scope.row.createdDate | dateFormat }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="padding: 0 0 0 5px; flex: 1">
            <img src="@/assets/简化生产费用比例.png" alt="生产费用比例说明" width="96%" />
          </div>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="NBSCFY_ratio_show = false">返回</el-button>
      </div>
    </EditDialog>

    <EditDialog
      width="80%"
      :isReturn="true"
      v-if="costDialog_show"
      @closeFn="costDialog_show = false"
      :isShow.sync="costDialog_show"
    >
      <div slot="title" class="title">项目报销合计信息</div>
      <div slot="content" class="content" style="min-height: 400px">
        <el-card>
          <div class="cost-dialog-title">
            <el-descriptions border size="medium">
              <el-descriptions-item label="项目名称" :span="2" labelStyle="width: 90px;">{{
                projectInformation.projectName
              }}</el-descriptions-item>
              <el-descriptions-item
                label="项目经理"
                labelStyle="width: 100px;"
                contentStyle="min-width: 90px;"
                >{{ projectInformation.managerName }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="search_cost_title">
            <div class="search_center">
              <span>编号：</span>
              <el-input
                v-model="leftParams.expenseNumber"
                @change="$forceUpdate()"
                style="width: 180px"
                placeholder="请输入编号"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center" style="margin-left: 8px">
              <span>姓名：</span>
              <el-input
                v-model="leftParams.userName"
                @change="$forceUpdate()"
                style="width: 120px"
                placeholder="请输入姓名"
                clearable
              >
              </el-input>
            </div>

            <div class="search_center" style="margin-left: 8px">
              <span>申请时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>

            <el-button
              style="margin-left: 8px"
              type="primary"
              size="small"
              icon="el-icon-search"
              @click="handleLeftCurrentChange(1)"
              >搜索</el-button
            >
            <el-button
              type="warning"
              size="small"
              icon="el-icon-refresh"
              @click="costDialog_dataHandleReset"
              >清空</el-button
            >
          </div>

          <el-tabs v-model="activeCost">
            <el-tab-pane label="报销" name="COST"></el-tab-pane>
            <el-tab-pane label="付款申请" name="PAYMENT"></el-tab-pane>
            <el-tab-pane label="库管/厨师登记" name="EXPENDITURE"></el-tab-pane>
          </el-tabs>
          <el-table
            :data="costDialog_data.leftTableData"
            border
            ref="leftTableRef"
            height="350"
            :key="tableIndex"
            style="width: 100%"
            v-loading="leftCostLoading"
            :highlight-current-row="false"
          >
            <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>
            <el-table-column prop="expenseNumber" min-width="160" align="center" label="编号">
            </el-table-column>
            <el-table-column
              prop="createName"
              v-if="activeCost != 'EXPENDITURE'"
              min-width="100"
              align="center"
              label="姓名"
            >
            </el-table-column>
            <el-table-column
              prop="applyName"
              v-if="activeCost === 'EXPENDITURE'"
              min-width="100"
              align="center"
              label="确认人"
            >
            </el-table-column>
            <el-table-column align="center" min-width="130" prop="companyType" label="费用所属单位">
              <template slot-scope="scope">
                {{ scope.row.companyType | dict(companyTypeList) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              v-if="activeCost === 'EXPENDITURE'"
              min-width="140"
              prop="registrationType"
              label="登记类型"
            >
              <template slot-scope="scope">
                {{ scope.row.registrationType | dict(registrationTypeList) }}
              </template></el-table-column
            >
            <el-table-column prop="sumCost" align="center" min-width="100" label="报销费用">
              <template slot-scope="scope">
                {{ scope.row.sumCost | thousands }}
              </template>
            </el-table-column>
            <el-table-column prop="taskDate" min-width="150" align="center" label="申请日期">
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="statusName" min-width="110" align="center" label="流程进度">
            </el-table-column>
            <el-table-column width="100" align="center" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="showCostDetail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px">
            <el-pagination
              @size-change="handleLeftSizeChange"
              @current-change="handleLeftCurrentChange"
              :current-page="leftParams.pageNow"
              :page-sizes="[5, 10, 50, 100]"
              :page-size="10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="leftParams.total"
            >
            </el-pagination>
          </div>
        </el-card>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="costDialog_show = false">返回</el-button>
      </div>
    </EditDialog>

    <EditDialog
      width="90%"
      :isReturn="true"
      @closeFn="humanDialog_show = false"
      :isShow.sync="humanDialog_show"
    >
      <div slot="title" class="title">
        <el-row>
          <el-col :span="12"><div>人员成本信息</div></el-col>
          <el-col :span="12"> <div>员工工时明细</div></el-col>
        </el-row>
      </div>
      <div slot="content" class="content" style="min-height: 400px">
        <el-row>
          <el-descriptions border :column="3" size="medium" style="width: 50%">
            <el-descriptions-item label="项目名称" :span="2" labelStyle="width: 90px;">{{
              projectInformation.projectName
            }}</el-descriptions-item>
            <el-descriptions-item
              label="项目经理"
              labelStyle="width: 100px;"
              contentStyle="min-width: 90px;"
              >{{ projectInformation.managerName }}
            </el-descriptions-item>
          </el-descriptions>
          <div style="display: flex">
            <div id="search_center_title" class="search_center_title">
              <div class="search_center">
                <span>姓名：</span>
                <el-input
                  v-model="humanLeftParams.userName"
                  @change="$forceUpdate()"
                  style="width: 120px"
                  placeholder="请输入姓名"
                  clearable
                >
                </el-input>
              </div>
              <div class="search_center" style="margin-left: 8px">
                <span>工时日期：</span>
                <el-date-picker
                  class="date_width"
                  v-model="humanCreatedDate"
                  :pickerOptions="humanPickerOptions"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="工时日期（始）"
                  end-placeholder="工时日期（末）"
                >
                </el-date-picker>
              </div>

              <el-button
                style="margin-left: 8px"
                type="primary"
                size="small"
                icon="el-icon-search"
                @click="humanLeftCurrentChange(1)"
                >搜索</el-button
              >
              <el-button
                type="warning"
                size="small"
                icon="el-icon-refresh"
                @click="humanDialog_dataHandleReset"
                >清空</el-button
              >
            </div>
            <div :style="{ width: '50%', 'padding-top': searchHright }">
              <el-descriptions border :column="3" size="medium" id="search_center_right">
                <el-descriptions-item
                  label="成员"
                  labelStyle="width: 100px;"
                  :span="1"
                  :content-class-name="[this.highlightedCell ? 'highlight' : '']"
                >
                  {{ humanDialog_data.selectName }}</el-descriptions-item
                >
                <el-descriptions-item labelStyle="width: 100px;" label="部门名称" :span="1">
                  {{ humanDialog_data.deptName }}</el-descriptions-item
                >
                <el-descriptions-item labelStyle="width: 100px;" label="岗位角色" :span="1">
                  {{ humanDialog_data.selectRole }}</el-descriptions-item
                >
              </el-descriptions>
            </div>
          </div>
          <el-col :span="12">
            <el-card>
              <el-table
                :data="humanDialog_data.leftTableData"
                border
                ref="leftTableRef"
                height="392"
                style="width: 100%"
                v-loading="humanLeftCostLoading"
                :highlight-current-row="false"
                :cell-class-name="setCellClass"
              >
                <!-- <el-table-column type="index" align="center" label="序号" width="50">
                </el-table-column> -->
                <el-table-column prop="staffName" fixed align="center" label="姓名" width="90">
                </el-table-column>
                <el-table-column prop="deptName" align="center" label="部门" min-width="90">
                </el-table-column>
                <el-table-column prop="postName" align="center" label="岗位" min-width="100">
                </el-table-column>
                <el-table-column prop="sumHour" align="center" label="填报工时" width="85">
                </el-table-column>
                <el-table-column prop="sumAuditHour" align="center" label="审核工时" width="85">
                </el-table-column>
                <el-table-column
                  prop="sumPerformanceHour"
                  align="center"
                  label="总绩效工时"
                  width="85"
                >
                </el-table-column>
                <el-table-column
                  prop="sumStaffCost"
                  align="center"
                  label="合计时薪(元)"
                  width="100"
                >
                  <template slot-scope="scope">
                    {{ scope.row.sumStaffCost | thousands }}
                  </template>
                </el-table-column>

                <el-table-column width="80" fixed="right" align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="getHourDetail(scope.row, scope.$index)"
                      >明细</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <div style="text-align: right; margin-top: 10px">
                <!-- <el-pagination
                  @size-change="humanLeftSizeChange"
                  @current-change="humanLeftCurrentChange"
                  :current-page="humanLeftParams.pageNow"
                  :page-sizes="[5, 10, 50, 100]"
                  :page-size="10"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="humanLeftParams.total">
                </el-pagination> -->
                共 {{ humanLeftParams.total }} 条
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card v-loading="humanRightCostLoading" v-if="humanDialog_data.selectId">
              <el-table
                :data="humanDialog_data.rightTableData"
                border
                height="381"
                style="width: 100%"
              >
                <el-table-column type="index" align="center" label="序号" width="50">
                </el-table-column>
                <el-table-column prop="manhourDate" align="center" label="工时日期" width="100">
                </el-table-column>
                <el-table-column
                  prop="workContent"
                  align="center"
                  :show-overflow-tooltip="false"
                  nim-width="220"
                  label="工作内容"
                >
                </el-table-column>
                <el-table-column prop="hour" align="center" label="填报工时(h)" width="110">
                </el-table-column>
                <el-table-column prop="auditHour" align="center" label="审核工时(h)" width="110">
                </el-table-column>
                <!-- <el-table-column
                    prop="staffBusinessPerformance"
                    width="120"
                    align="center"
                    label="操作">
                    <template slot-scope="scope">
                      <el-button
                        type="primary"
                        size="mini"
                        @click="showCostDetail(scope.row)"
                        >详情</el-button
                      >
                    </template>
                  </el-table-column> -->
              </el-table>
              <div style="text-align: right; margin-top: 10px">
                <el-pagination
                  @size-change="humanRightSizeChange"
                  @current-change="humanRightCurrentChange"
                  :current-page="humanRightParams.pageNow"
                  :page-sizes="[5, 10, 50, 100]"
                  :page-size="10"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="humanRightParams.total"
                >
                </el-pagination>
              </div>
            </el-card>
            <div class="empty-detail" v-else><span>点击左侧明细</span></div>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="humanDialog_show = false">返回</el-button>
      </div>
    </EditDialog>

    <!-- 外协生产费用弹框 -->
    <EditDialog width="60%" :isShow.sync="WXSCFY_detail_show">
      <div slot="title" class="title">外协生产费用</div>
      <div slot="content" class="content" style="min-height: 400px">
        <div style="font-size: 18px; color: black; font-weight: 700; margin-bottom: 5px">
          目前外协生产总费用 ：
          <span style="color: red">{{ projectDisposableExpenses.outsourceSumCost }}元</span>
        </div>
        <el-table
          :data="WXSCFY_detail_data.projectOutsourceLogByName"
          border
          style="width: 96%; margin-bottom: 10px"
        >
          <el-table-column type="expand" width="30">
            <template slot-scope="props">
              <div
                style="
                  font-size: 14px;
                  color: black;
                  font-weight: 700;
                  margin-bottom: 5px;
                  margin-left: 20px;
                  margin-top: 10px;
                "
              >
                历史记录：
              </div>
              <el-table
                :data="props.row.projectOutsourceLogRespList"
                border
                :cell-style="outSourceNowStyle"
                style="width: 96%; margin-bottom: 10px; margin-left: 10px"
              >
                <el-table-column
                  label="序号"
                  type="index"
                  align="center"
                  width="100"
                  :show-overflow-tooltip="false"
                ></el-table-column>
                <el-table-column prop="cost" label="外协生产费用" align="center" width="180">
                  <template slot-scope="scope"> {{ scope.row.outsourceCost }}元 </template>
                </el-table-column>
                <el-table-column prop="date" label="更改日期" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.createdDate | dateFormat }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="outsourceName" label="外协单位" align="center"> </el-table-column>
          <el-table-column
            prop="outsourceSumCostByName"
            label="外协生产费用"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.outsourceSumCostByName | thousands }}元
            </template>
          </el-table-column>
          <el-table-column width="60" align="center">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.isOutsourcingExpense"
                type="text"
                size="small"
                class="text_Details_Bgc"
                @click.stop="onOutsourceDetail(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="WXSCFY_detail_show = false">返回</el-button>
      </div>
    </EditDialog>

    <!-- :type="costDialog_data.moduleType" -->
    <ProjectPerformanceDetailDialog
      :type="activeCost"
      v-if="costDetailDialog_show"
      :costDetailDialog_show.sync="costDetailDialog_show"
      :options="costDialog_data.costDetail"
      @closeDialog="closeDialogFn"
    ></ProjectPerformanceDetailDialog>

    <!-- 单据详情 -->
    <CostDetailDialog
      v-if="outsoureceDetailDialog"
      :costDialog.sync="outsoureceDetailDialog"
      :options="outsoureceDetailInfo"
      @returnDialogFn="outsoureceDetailDialog = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getInputValue, delcommafy, numberToCurrencyNo } from '@/util/jsencrypt'
export default {
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    ProjectPerformanceDetailDialog: () => import('@/components/ProjectPerformanceDetailDialog.vue'),
    CostDetailDialog: () => import('@/components/costDetailDialog.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    projectSource: {
      type: Array,
      default: function () {
        return []
      },
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      dictData: {
        projectSource: [],
        businessLines: [],
      },
      NBSCFY_ratio_show: false, // 内部生产费用弹框显示隐藏
      isNBSCFYButtonLoading: false,
      NBSCFY_ratio_data: {},
      projectList: [],
      isProjectCostLoading: false, //项目报销-查看明细button按钮的loading
      leftCostLoading: false, //报销弹框表格loading
      costDialog_show: false, //报销信息弹框显示隐藏
      costDetailDialog_show: false, //报销右侧详情弹框显示隐藏
      leftParams: {
        //报销弹窗请求参数
        pageSize: 10,
        pageNow: 1,
        total: 0,
        userName: null,
        expenseNumber: null,
      },
      costDialog_data: {
        //报销
        leftTableData: [],
        costDetail: {},
      },
      activeCost: 'COST',
      registrationTypeList: [],
      companyTypeList: [],
      createdDate: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      isHumanCostLoading: false, //人力成本-查看明细button按钮的loading
      humanDialog_show: false, //人工信息弹框显示隐藏
      humanDialog_data: {
        selectName: '', //点击左侧明细后右侧显示姓名
        selectRole: '', //点击左侧明细后右侧显示角色
        selectId: '', //点击左侧明细后右侧显示id
        deptName: '', //点击左侧明细后右侧显示部门
        leftTableData: [], // 左侧工时数组
        rightTableData: [], //右侧员工工时数组
        costDetail: {}, //报销明细
      },
      humanCreatedDate: [],
      humanPickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.humanCreatedDate, 0, v.minDate.getTime())
            this.$set(this.humanCreatedDate, 1, '')
          }
        },
      },
      humanLeftParams: {
        //工时弹框的请求参数
        // pageSize: 10,
        // pageNow: 1,
        total: 0,
        userName: null,
      },
      humanRightParams: {
        pageSize: 10,
        pageNow: 1,
        total: 0,
        userName: null,
      },
      humanLeftCostLoading: false, //人工弹框左侧表格loading
      humanRightCostLoading: false, //人工弹框右侧表格loading
      isWXSCFYButtonLoading: false, //外协成本-查看明细button按钮的loading
      WXSCFY_detail_show: false, //外协生产费用详情弹框显示隐藏
      WXSCFY_detail_data: {},
      highlightedCell: null, //工时表格选中变色
      searchHright: 0, //员工明细内边距
      tableIndex: 0, //报销弹窗key
      outsoureceDetailDialog: false,
      outsoureceDetailInfo: {},
    }
  },
  inject: ['refresh'],
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
      projectDisposableExpenses: state => state.project.projectDisposableExpenses,
    }),
  },
  created() {
    this.getType()
  },
  filters: {
    thousands(a) {
      if (!a) {
        return a
      } else {
        a = String(a).replace(/,/g, '')
        a = Number(a).toLocaleString()

        return a
      }
    },
  },
  watch: {
    costDialog_show: {
      // deep: true,
      handler: function (val) {
        if (!val) {
          this.costReturn()
        }
      },
    },
    humanDialog_show: {
      // deep: true,
      handler: function (val) {
        if (!val) {
          this.emptyFn(3)
          this.searchHright = 0 //初始化内边距
        } else {
          this.$nextTick(() => {
            this.searchHright = 0 //初始化内边距
            let dom = document.getElementById('search_center_title')
            let right = document.getElementById('search_center_right')
            if (dom && right) {
              this.searchHright = dom.clientHeight - right.clientHeight
              this.searchHright = this.searchHright + 'px'
            }
          })
        }
      },
    },
    activeCost: {
      // deep: true,
      handler: function (val) {
        this.tableIndex++
        this.leftParams.pageNow = 1
        if (val === 'COST') {
          this.projectCostFn(this.leftParams)
        } else if (val === 'PAYMENT') {
          this.paymentFn(this.leftParams)
        } else if (val === 'EXPENDITURE') {
          this.registerFn(this.leftParams)
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
    humanCreatedDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.humanCreatedDate = []
        }
      },
    },
  },
  methods: {
    onOutsourceDetail(row) {
      const obj = {}
      if (row.expenseType == 'PROJECT_PAYMENT_APPLICATION') {
        obj.projectPaymentApplicationId = row.expenseId
        obj.costType = '项目付款申请'
      } else if (row.expenseType == 'PROJECT_EXPENSE') {
        obj.projectExpenseId = row.expenseId
        obj.costType = '项目报销'
      }
      this.outsoureceDetailInfo = obj
      this.outsoureceDetailDialog = true
    },
    closeDialogFn() {
      this.costDetailDialog_show = false
    },
    // 外协弹窗
    showAssistCost() {
      this.isWXSCFYButtonLoading = true
      this.WXSCFY_detail_data = {}
      this.$api.productionCostRatioLog
        .projectOutsourceLogByProject({ projectId: this.options.id })
        .then(res => {
          this.isWXSCFYButtonLoading = false
          this.WXSCFY_detail_data = res.data
          if (
            this.WXSCFY_detail_data.projectOutsourceLogByName &&
            this.WXSCFY_detail_data.projectOutsourceLogByName.length > 0
          ) {
            this.WXSCFY_detail_data.projectOutsourceLogByName.forEach(item => {
              if (item.projectOutsourceLogRespList && item.projectOutsourceLogRespList.length > 0) {
                item.projectOutsourceLogRespList.forEach(historyItem => {
                  historyItem.isNow = false
                })
                item.projectOutsourceLogRespList[
                  item.projectOutsourceLogRespList.length - 1
                ].isNow = true
              }
            })
          }
          this.WXSCFY_detail_show = true
        })
        .catch(err => {
          this.isWXSCFYButtonLoading = false
          console.log(err)
        })
    },
    // 获取个人工时明细
    getHourDetail(row, index) {
      this.humanRightCostLoading = true
      this.highlightedCell = { rowIndex: index }
      this.humanLeftParams.pageNow = 1
      this.humanRightParams.pageNow = 1
      if (this.humanCreatedDate && this.humanCreatedDate != []) {
        this.humanLeftParams.beginDate = new Date(this.humanCreatedDate[0]).getTime()
        this.humanLeftParams.endDate = new Date(this.humanCreatedDate[1]).getTime()
      } else if (this.humanCreatedDate == null) {
        this.humanLeftParams.beginDate = null
        this.humanLeftParams.endDate = null
      }
      this.$api.hour
        .getAllMahhoursByUser({
          projectId: this.options.id,
          ...this.humanLeftParams,
          userName: row.staffName,
        })
        .then(res => {
          if (res.data == null) {
            this.humanDialog_data.rightTableData = []
          } else {
            this.humanDialog_data.selectName = row.staffName
            this.humanDialog_data.selectId = row.staffId
            this.humanDialog_data.selectRole = row.postName
            this.humanDialog_data.deptName = row.deptName
            let arr = []
            for (let i = 0; i < res.data.records.length; i++) {
              const e = res.data.records[i]
              e.manhourDate = new Date(e.manhourDate).format('yyyy-MM-dd')
              arr.push(e)
            }
            this.humanDialog_data.rightTableData = arr
            this.humanRightParams.total = res.data.total
            this.humanRightCostLoading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.humanRightCostLoading = false
        })
    },
    // 员工明细-分页
    humanRightCurrentChange(val) {
      this.humanRightParams.pageNow = val
      this.humanRightCostLoading = true
      this.$api.hour
        .getAllMahhoursByUser({
          projectId: this.options.id,
          ...this.humanRightParams,
          userName: this.humanDialog_data.selectName,
        })
        .then(res => {
          if (res.data == null) {
            this.humanDialog_data.rightTableData = []
          } else {
            let arr = []
            for (let i = 0; i < res.data.records.length; i++) {
              const e = res.data.records[i]
              e.manhourDate = new Date(e.manhourDate).format('yyyy-MM-dd')
              arr.push(e)
            }
            this.humanDialog_data.rightTableData = arr
            this.humanRightParams.total = res.data.total
            this.humanRightCostLoading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.humanRightCostLoading = false
        })
    },
    // 员工明细-条数
    humanRightSizeChange(val) {
      this.humanRightParams.pageSize = val
      this.humanRightParams.pageNow = 1
      this.humanRightCostLoading = true
      this.$api.hour
        .getAllMahhoursByUser({
          projectId: this.options.id,
          ...this.humanRightParams,
          userName: this.humanDialog_data.selectName,
        })
        .then(res => {
          if (res.data == null) {
            this.humanDialog_data.rightTableData = []
          } else {
            let arr = []
            for (let i = 0; i < res.data.records.length; i++) {
              const e = res.data.records[i]
              e.manhourDate = new Date(e.manhourDate).format('yyyy-MM-dd')
              arr.push(e)
            }
            this.humanDialog_data.rightTableData = arr
            this.humanRightParams.total = res.data.total
            this.humanRightCostLoading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.humanRightCostLoading = false
        })
    },
    // 工时弹窗-搜索
    humanLeftCurrentChange(val) {
      this.humanLeftParams.pageNow = val
      this.emptyFn(1)
      this.getHuman(this.humanLeftParams)
    },
    // humanLeftSizeChange(val) {
    //   this.humanLeftParams.pageSize = val
    //   this.humanLeftParams.pageNow = 1
    // },
    // 工时弹窗-清空
    humanDialog_dataHandleReset() {
      this.emptyFn(3)
      this.getHuman(this.humanLeftParams)
    },
    // 弹窗工时清空函数
    /*
     * i 数值，1 清除右侧内容，3 清除所有
     */
    emptyFn(i) {
      if (i === 1) {
        this.humanDialog_data.selectName = null
        this.humanDialog_data.selectId = null
        this.humanDialog_data.selectRole = null
        this.humanDialog_data.deptName = null
        this.humanDialog_data.rightTableData = []
        this.highlightedCell = null
      } else if (i === 3) {
        this.highlightedCell = null
        this.humanDialog_data.selectName = null
        this.humanDialog_data.selectId = null
        this.humanDialog_data.selectRole = null
        this.humanDialog_data.deptName = null
        this.humanLeftParams.userName = null
        this.humanLeftParams.beginDate = null
        this.humanLeftParams.endDate = null
        this.humanCreatedDate = []
        this.humanDialog_data.rightTableData = []
      }
    },
    // 打开工时弹窗
    async showHumanCost() {
      this.isHumanCostLoading = true
      // await this.getHuman()
      if (this.humanCreatedDate && this.humanCreatedDate != []) {
        this.humanLeftParams.beginDate = new Date(this.humanCreatedDate[0]).getTime()
        this.humanLeftParams.endDate = new Date(this.humanCreatedDate[1]).getTime()
      } else if (this.humanCreatedDate == null) {
        this.humanLeftParams.beginDate = null
        this.humanLeftParams.endDate = null
      }
      try {
        const res = await this.$api.hour.selectStaffCostByProjectId({
          ...this.humanLeftParams,
          projectId: this.options.id,
        })
        this.humanDialog_data.leftTableData = res?.data
        this.humanLeftParams.total = this.humanDialog_data.leftTableData?.length
        this.humanDialog_show = true
      } catch (error) {
        this.humanDialog_show = false
      }

      this.isHumanCostLoading = false
    },
    // 获取工时信息
    async getHuman(parameter) {
      if (this.humanCreatedDate && this.humanCreatedDate != []) {
        this.humanLeftParams.beginDate = new Date(this.humanCreatedDate[0]).getTime()
        this.humanLeftParams.endDate = new Date(this.humanCreatedDate[1]).getTime()
      } else if (this.humanCreatedDate == null) {
        this.humanLeftParams.beginDate = null
        this.humanLeftParams.endDate = null
      }
      this.loading = true
      try {
        const res = await this.$api.hour.selectStaffCostByProjectId({
          ...parameter,
          ...this.humanLeftParams,
          projectId: this.options.id,
        })
        this.humanDialog_data.leftTableData = res?.data
        this.humanLeftParams.total = this.humanDialog_data.leftTableData?.length
      } catch (error) {}
      this.loading = false
    },
    // 报销返回
    costReturn() {
      this.leftParams.userName = null
      this.leftParams.startDate = null
      this.leftParams.endDate = null
      this.leftParams.expenseNumber = null
      this.createdDate = []
      this.activeCost = 'COST'
    },
    // 报销详细弹窗
    showCostDetail(row) {
      this.costDialog_data.costDetail = row
      this.costDetailDialog_show = true
    },
    // 报销弹窗-清空
    costDialog_dataHandleReset() {
      this.leftParams.userName = null
      this.leftParams.startDate = null
      this.leftParams.endDate = null
      this.leftParams.expenseNumber = null
      this.createdDate = []
      if (this.activeCost === 'COST') {
        this.projectCostFn(this.leftParams)
      } else if (this.activeCost === 'PAYMENT') {
        this.paymentFn(this.leftParams)
      } else if (this.activeCost === 'EXPENDITURE') {
        this.registerFn(this.leftParams)
      }
    },
    // 报销弹窗-搜索
    handleLeftCurrentChange(val) {
      this.leftParams.pageNow = val
      if (this.createdDate && this.createdDate != []) {
        this.leftParams.startDate = new Date(this.createdDate[0]).getTime()
        this.leftParams.endDate = new Date(this.createdDate[1]).getTime()
      } else {
        this.leftParams.startDate = null
        this.leftParams.endDate = null
      }
      if (this.activeCost === 'COST') {
        this.projectCostFn(this.leftParams)
      } else if (this.activeCost === 'PAYMENT') {
        this.paymentFn(this.leftParams)
      } else if (this.activeCost === 'EXPENDITURE') {
        this.registerFn(this.leftParams)
      }
    },
    handleLeftSizeChange(val) {
      this.leftParams.pageSize = val
      this.leftParams.pageNow = 1
      if (this.activeCost === 'COST') {
        this.projectCostFn(this.leftParams)
      } else if (this.activeCost === 'PAYMENT') {
        this.paymentFn(this.leftParams)
      } else if (this.activeCost === 'EXPENDITURE') {
        this.registerFn(this.leftParams)
      }
    },
    // 显示报销弹窗
    async showProjectCost() {
      this.isProjectCostLoading = true
      if (this.createdDate && this.createdDate != []) {
        this.leftParams.startDate = new Date(this.createdDate[0]).getTime()
        this.leftParams.endDate = new Date(this.createdDate[1]).getTime()
      } else {
        this.leftParams.startDate = null
        this.leftParams.endDate = null
      }
      this.leftParams.pageNow = 1
      this.projectCostFn(this.leftParams)
      this.costDialog_show = true
      this.isProjectCostLoading = false
    },
    // 内部生产费用弹窗
    showNBSCFY() {
      this.isNBSCFYButtonLoading = true
      this.NBSCFY_ratio_data = {}
      this.$api.productionCostRatioLog
        .productionCostRatioLogByProject({ projectId: this.options.id })
        .then(res => {
          this.isNBSCFYButtonLoading = false
          this.NBSCFY_ratio_data = res.data
          this.NBSCFY_ratio_show = true
        })
        .catch(err => {
          this.isNBSCFYButtonLoading = false
          console.log(err)
        })
    },
    // 付款申请
    paymentFn(pageSize) {
      this.leftCostLoading = true
      let obj = {
        pageSize: pageSize.pageSize,
        pageNow: pageSize.pageNow,
        userName: pageSize.userName,
        startDate: pageSize.startDate,
        endDate: pageSize.endDate,
        expenseNumber: pageSize.expenseNumber,
        projectId: this.options.id,
        notStatusList: [0],
      }
      this.$api.payment
        .projectPaymentApplicationAllList(obj)
        .then(res => {
          if (res.data == []) {
            this.costDialog_data.leftTableData = res.data
          } else {
            this.costDialog_data.leftTableData = res.data
              ? res.data.records
                ? res.data.records
                : []
              : []
            this.leftParams.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          }
          this.leftCostLoading = false
        })
        .catch(err => {
          console.log(err)
          this.leftCostLoading = false
        })
    },
    // 库管/厨师登记
    registerFn(pageSize) {
      this.leftCostLoading = true
      let obj = {
        expenseType: 'PROJECT',
        registrationTypeList: ['KITCHEN', 'INVENTORY_KEEPER'],
        pageSize: pageSize.pageSize,
        pageNow: pageSize.pageNow,
        userName: pageSize.userName,
        startDate: pageSize.startDate,
        endDate: pageSize.endDate,
        expenseNumber: pageSize.expenseNumber,
        expenseBelongId: this.options.id,
        notStatusList: [0],
      }
      this.$api.register
        .registrationExpenditureAllList(obj)
        .then(res => {
          if (res.data == []) {
            this.costDialog_data.leftTableData = res.data
          } else {
            this.costDialog_data.leftTableData = res.data
              ? res.data.records
                ? res.data.records
                : []
              : []
            this.leftParams.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          }
          this.leftCostLoading = false
        })
        .catch(err => {
          this.leftCostLoading = false
          console.log(err)
        })
    },
    // 报销
    projectCostFn(pageSize) {
      this.leftCostLoading = true
      let obj = {
        pageSize: pageSize.pageSize,
        pageNow: pageSize.pageNow,
        userName: pageSize.userName,
        startDate: pageSize.startDate,
        endDate: pageSize.endDate,
        expenseNumber: pageSize.expenseNumber,
        projectId: this.options.id,
        notStatusList: [0],
      }
      this.$api.projectCost
        .projectExpenseAllList(obj)
        .then(res => {
          if (res.data == []) {
            this.costDialog_data.leftTableData = res.data
          } else {
            this.costDialog_data.leftTableData = res.data
              ? res.data.records
                ? res.data.records
                : []
              : []
            this.leftParams.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          }
          this.leftCostLoading = false
        })
        .catch(err => {
          console.log(err)
          this.leftCostLoading = false
        })
    },

    setCellClass({ rowIndex }) {
      const isHighlighted = this.highlightedCell && this.highlightedCell.rowIndex === rowIndex
      return isHighlighted ? ['highlight'] : []
    },
    blurAmount(e) {
      this.projectDisposableExpenses[e] = Number(this.projectDisposableExpenses[e]).toLocaleString()
    },
    inputAmount(e) {
      this.projectDisposableExpenses[e] = String(this.projectDisposableExpenses[e])
        .replace(/[^\d.]/g, '')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      this.$forceUpdate()
    },
    focusAmount(e) {
      if (
        this.projectDisposableExpenses[e] != null ||
        this.projectDisposableExpenses[e] != '' ||
        this.projectDisposableExpenses[e] != undefined
      ) {
        this.projectDisposableExpenses[e] = String(this.projectDisposableExpenses[e]).replace(
          /,/g,
          ''
        )
      }
      this.$forceUpdate()
    },
    getType() {
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.project
        .projectList()
        .then(res => {
          this.projectList = res.data ? res.data : []
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict.listSysDictData('REGISTRATION_TYPE', true).then(res => {
        this.registrationTypeList = res.data
      })

      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 内部生产费用表格最后一行（当前比例）变色
    nowStyle({ rowIndex }) {
      if (rowIndex == this.NBSCFY_ratio_data.productionCostRatioLogList.length - 1) {
        return { backgroundColor: '#f1d49e' }
      }
    },

    outSourceNowStyle({ row, rowIndex }) {
      if (row.isNow) {
        return { backgroundColor: '#f1d49e' }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.my-label {
  width: 33%;
}
/deep/.my-content {
  width: 33%;
}
/deep/.type-label {
  width: 15%;
}
/deep/.type-content {
  width: 15%;
}
/deep/.detail-label {
  width: 20%;
}
/deep/.detail-content {
  width: 20%;
}
/deep/.projectBGC {
  //背景色
  background-color: #d0f6fd !important;
  border: 1px solid #ddd !important;
}
.rp-inline {
  display: flex;
  justify-content: space-between;
  .symbol {
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}
/deep/.el-form-item {
  margin-bottom: 0;
}
/deep/.rewards-label {
  width: 60%;
}
/deep/.rewards-content {
  width: 60%;
}
/deep/.remark-label {
  width: 40%;
}
/deep/.remark-content {
  width: 40%;
}

.below-content {
  display: flex;
  justify-content: space-between;
}

::v-deep .highlight {
  background: rgb(241, 212, 158) !important;
}

/deep/.el-table tbody tr:hover > td {
  background-color: transparent;
}
.search_cost_title {
  width: 100%;
  padding-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.search_center_title {
  width: 50%;
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .search_center {
    height: 40px;
    display: flex;
    margin: 8px 2px;
    span {
      white-space: nowrap;
      line-height: 40px;
    }
  }
  .el-button {
    height: 40px;
  }
}
.empty-detail {
  height: 350px;
  color: grey;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cost-btn {
  height: 30px;
  margin-top: 5px;
}
.text_Details_Bgc {
  //详情
  background-color: #409eff;
  color: #fff;
  padding: 5px 5px;
}
</style>
