<template>
  <div>
    {{ title }}：
    <el-select
      v-model="searchValue"
      :placeholder="placeholder"
      :filterable="filterable"
      :multiple="multiple"
      :collapse-tags="multiple"
      :clearable="clearable"
      :size="size"
      @clear="onClear"
      @change="onChange"
      :style="{ width: width + 'px' }"
    >
      <el-option v-for="item in searchArr" :key="item.id" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },
    width: {
      type: [String, Number],
      default: '130',
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    value: {
      type: [String, Array],
      default: '',
    },
    introductionType: {
      type: String,
      default: '',
    },
    twoIntroductionType: {
      type: String,
      default: '',
    },
    yearValue: {
      type: [String, Array],
      default: () => {
        return null
      },
    },
    twoValue: {
      type: [String, Array],
      default: () => {
        return null
      },
    },
    threeValue: {
      type: [String, Array],
      default: () => {
        return null
      },
    },
    yearIsShow: {
      type: Boolean,
      default: false,
    },
    threeIsShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchArr: [],
      searchValue: null,
      dictData: {
        businessLines: [],
        yearList: [],
        projectSource: [],
        businessType: [],
      },
    }
  },
  created() {
    this.getType()
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        this.searchValue = val
      },
    },
    list: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.searchArr = val.map(v => {
          return { id: v.id, label: v.dictName, value: v.dictVal }
        })
      },
    },
  },
  methods: {
    async onChange(value) {
      this.$emit('onChangeLoading', true)
      if (this.yearIsShow && value.length == 0) {
        value = [this.dictData.yearList[0].dictVal]
      }
      this.$emit('input', value)
      if (!this.type) return
      let res = {}
      let arr = []
      let queryParams = `date=${this.yearIsShow ? value : this.yearValue}`
      const addParam = (param, value) => {
        if (value) {
          queryParams += `&${param}=${value}`
        }
      }
      // 父项切换
      if (this.yearIsShow) {
        if (this.twoValue) {
          addParam(this.introductionType, this.twoValue)
          if (this.threeValue) {
            addParam(this.twoIntroductionType, this.threeValue)
          }
        } else if (this.threeValue) {
          addParam(this.twoIntroductionType, this.threeValue)
        }
      } else {
        // 子项切换
        // 正常二级关联，没有三级
        if (value && !this.threeIsShow && !this.threeValue) {
          addParam(this.introductionType, value)
        } else {
          // 三级关联
          if (value && !this.twoValue && this.threeIsShow) {
            addParam(this.twoIntroductionType, value)
          }
          if (this.twoValue && this.threeIsShow) {
            addParam(this.introductionType, this.twoValue)
            if (value) {
              addParam(this.twoIntroductionType, value)
            }
          } else if (this.threeValue && !this.threeIsShow) {
            if (value) {
              addParam(this.introductionType, value)
            }
            addParam(this.twoIntroductionType, this.threeValue)
          }
        }
      }
      if (this.type === 'BusinessOverview') {
        // 商务数据总览
        res = await this.$api.statistics.businessDataOverview(queryParams)
        if (res.data.isBoss) {
          arr = res.data.businessDataOverviewResponseList || []
        } else {
          const { businessDataOverviewResponseList = [] } = res.data

          const keyMappings = {
            businessCount: '商务数量',
            projectCount: '已创建项目的商务数量',
            successProjectCount: '成功实施项目数',
            businessEndCount: '商务终结数',
            contractSignedCount: '签约合同数',
            saleAmount: '我方合同金额',
            invoiceAmount: '开票金额',
            invoiceRate: '开票占合同比例',
            repaymentAmount: '回款金额',
            repaymentInvoiceRate: '回款占开票比例',
            saleRepaymentRate: '回款比例',
            businessExpenseTotal: '商务费用总和',
            successBusinessExpense: '成功商务费用',
            failBusinessExpense: '不成功商务费用',
            expenseSaleRate: '费销比',
            businessConversionRate: '商务转化率',
          }
          const newArr = []
          // 如果 businessDataOverviewResponseList 为空，则将所有值赋为 0
          if (businessDataOverviewResponseList.length === 0) {
            for (const key in keyMappings) {
              newArr.push({
                name: keyMappings[key],
                value: 0,
              })
            }
          } else {
            // 遍历 businessDataOverviewResponseList，收集数据
            businessDataOverviewResponseList.forEach(obj => {
              for (const key in obj) {
                if (obj.hasOwnProperty(key) && keyMappings[key]) {
                  const userPrincipalInfo = {
                    name: keyMappings[key],
                    value: obj[key],
                  }
                  newArr.push(userPrincipalInfo)
                }
              }
            })
          }
          // 根据 keyMappings 的顺序进行排序
          const sortedArr = Object.keys(keyMappings)
            .map(key => {
              return newArr.find(item => item.name === keyMappings[key])
            })
            .filter(item => item) // 过滤掉未找到的项

          // 将排序后的数组赋值回 arr
          arr.push(...sortedArr) // 将排序后的元素推入 arr
        }
        this.$emit('change', arr)
      } else if (this.type === 'SpecialBusiness') {
        // 商务费用分析-前期面上沟通
        res = await this.$api.statistics.businessSpecialCost(queryParams)
        arr = res.data.businessSpecialCostResponses || []
        this.$emit('change', arr)
      } else if (this.type === 'Histogram') {
        // 合同、回款计划、开票及回款统计
        res = await this.$api.statistics.queryContractPaymentSummary(queryParams)
        const { contractPaymentStatisticsVo = {} } = res.data
        const nameMapping = {
          actualReceivedAmount: '实际回款金额',
          contractAmount: '合同金额',
          invoicedAmount: '开票金额',
          paymentPlanAmount: '回款计划金额',
        }
        arr = Object.keys(contractPaymentStatisticsVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map(key => ({
            name: nameMapping[key], // 根据键获取名称
            data: contractPaymentStatisticsVo[key],
          }))
        const contractPaymentListVoList =
          contractPaymentStatisticsVo.contractPaymentListVoList || []
        this.$emit('changeData', contractPaymentListVoList)
        this.$emit('change', arr)
      } else if (this.type === 'BusinessLines') {
        // 业务种类
        res = await this.$api.statistics.queryBusinessCategorySummary(queryParams)
        arr = res.data?.businessCategoryResponseList.map(item => {
          const lines = this.dictData.businessLines.find(v => v.dictVal === item.categoryName)
          return {
            categoryPaymentAmount: item.categoryPaymentAmount,
            categoryAmount: item.categoryAmount,
            percentage: Number((item.percentage * 100).toFixed(2)),
            paymentPercentage: Number((item.paymentPercentage * 100).toFixed(2)),
            categoryCount: item.categoryCount,
            name: lines ? lines.dictName : item.categoryName, // 如果找不到则使用原名称
          }
        })
        this.$emit('change', arr)
      } else if (this.type === 'BusinessLinesData') {
        // 业务种类表
        res = await this.$api.statistics.queryBusinessCategorySummaryList(queryParams)
        arr = res.data?.businessCategoryResponseTableList || []
        this.$emit('change', arr)
      } else if (this.type === 'BusinessType') {
        // 商务类型
        res = await this.$api.statistics.queryBusinessTypeSummary(queryParams)
        arr = res.data?.businessTypeResponse.map(item => {
          const typeItem = this.dictData.businessType.find(v => v.dictVal === item.businessTypeName)
          return {
            businessTypePaymentAmount: item.businessTypePaymentAmount,
            businessTypeAmount: item.businessTypeAmount,
            businessPercentage: Number((item.businessPercentage * 100).toFixed(2)),
            paymentBusinessPercentage: Number((item.paymentBusinessPercentage * 100).toFixed(2)),
            businessTypeCount: item.businessTypeCount,
            name: typeItem ? typeItem.dictName : item.businessTypeName, // 如果找不到则使用原名称
          }
        })
        this.$emit('change', arr)
      } else if (this.type === 'BusinessTypeData') {
        // 商务类型表
        res = await this.$api.statistics.queryBusinessTypeSummaryList(queryParams)
        arr = res.data?.businessTypeResponseTableList || []
        this.$emit('change', arr)
      } else if (this.type === 'ProjectSource') {
        // 项目来源
        res = await this.$api.statistics.businessProjectSourceSummary(queryParams)
        arr = res.data?.projectSourceResponse.map(item => {
          const typeItem = this.dictData.projectSource.find(
            v => v.dictVal === item.projectSourceName
          )
          return {
            projectSourcePaymentAmount: item.projectSourcePaymentAmount,
            projectSourceAmount: item.projectSourceAmount,
            projectPercentage: Number((item.projectPercentage * 100).toFixed(2)),
            paymentProjectPercentage: Number((item.paymentProjectPercentage * 100).toFixed(2)),
            projectSourceCount: item.projectSourceCount,
            name: typeItem ? typeItem.dictName : item.projectSourceName, // 如果找不到则使用原名称
          }
        })
        this.$emit('change', arr)
      } else if (this.type === 'ProjectSourceData') {
        // 项目来源表
        res = await this.$api.statistics.businessProjectSourceSummaryList(queryParams)
        arr = res.data?.projectSourceResponseTableList || []
        this.$emit('change', arr)
      }
      // else if (this.type === 'BusinessFunnel') {
      //   // 商务漏斗分析
      //   res = await this.$api.statistics.queryFunnelStatisticsSummary(queryParams)
      //   const { funnelStatisticsResponse = {} } = res.data

      //   const nameMapping = {
      //     newBusinessCount: { name: '新增商务数', value: 952358 },
      //     projectCount: { name: '立项数', value: 394841 },
      //     contractSigningCount: { name: '合同签约数', value: 63497 },
      //   }

      //   arr = Object.keys(funnelStatisticsResponse)
      //     .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
      //     .map(key => ({
      //       name: nameMapping[key].name, // 获取名称
      //       value: nameMapping[key].value, // 获取对应值
      //       values: funnelStatisticsResponse[key], // 原始数据
      //     }))
      //   this.$emit('change', arr)
      // }
      else if (this.type === 'BusinessResponse') {
        // 商务费用分析
        res = await this.$api.statistics.queryBusinessCostAnalysisSummary(queryParams)
        arr = res.data?.businessCostAnalysisResponseList || []

        this.$emit('change', arr)
      }
      this.$emit('onChangeLoading', false)
    },
    onClear(value) {
      this.$emit('input', value)
    },
    getType() {
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.fr {
  float: right;
  padding: 3px 0;
}
</style>
