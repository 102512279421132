<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw"> 项目总览 </span
        ><span>
          <span class="statistics">新增项目数量：{{ projectNum }}</span>
          <span class="statistics">实际回款金额：{{ projectPayment | applyAmount }}</span>
        </span>
        <span class="title_right"
          ><SearchUserDate
            :type="'OverView'"
            :introductionType="'deptId'"
            :yearIsShow="true"
            :multiple="true"
            :title="titleYear"
            :list="dictData.yearList"
            :twoValue="sectionInfo.deptId"
            v-model="sectionInfo.overviewYear"
            @change="onOverViewChange"
            @onChangeLoading="overviewLoading = $event"
        /></span>
        <span class="title_right"
          >部门：
          <DeptCascader
            :placeholder="'请选择部门'"
            :deptList="dictData.deptList"
            :deptIdArr="sectionInfo.deptIdArr"
            v-model="sectionInfo.deptId"
            @changeValueArr="sectionInfo.deptIdArr = $event"
            :size="'small'"
          />
        </span>
        <span class="title_right" style="display: none">
          <SearchUserDate
            :type="'OverViewDeptId'"
            :introductionType="'deptId'"
            :list="dictData.deptAllList"
            :twoValue="sectionInfo.deptId"
            :yearValue="sectionInfo.overviewYear"
            v-model="sectionInfo.deptId"
            @change="onOverViewChange"
            @onChangeLoading="overviewLoading = $event"
          />
        </span>
        <el-button class="title_right" type="primary" size="small" @click="isDialog = true"
          >数据来源说明</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-printer"
          class="title_right"
          :loading="exportingLoading"
          size="small"
          @click="onOverViewExporting"
          >导出</el-button
        >
        <el-radio-group
          v-model="currentView"
          size="small"
          class="title_right"
          @change="handleViewChange"
        >
          <el-radio-button v-for="view in presetViews" :key="view.name" :label="view.name">
            {{ view.name }}
          </el-radio-button>
        </el-radio-group>
        <el-popover placement="bottom" width="300" trigger="click" class="title_right">
          <div class="column-setting-container">
            <div class="column-groups">
              <div v-for="group in visibleColumnGroups" :key="group.name" class="column-group">
                <div class="group-title">
                  <el-checkbox
                    :indeterminate="group.indeterminate"
                    v-model="group.checkAll"
                    @change="handleCheckAllChange(group)"
                  >
                    {{ group.label }}
                  </el-checkbox>
                </div>
                <div class="group-items">
                  <el-checkbox-group v-model="checkedColumns" @change="handleColumnChange">
                    <el-checkbox v-for="col in group.columns" :key="col.prop" :label="col.prop">
                      {{ col.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="column-setting-footer">
              <el-button size="small" @click="resetColumns">重置</el-button>
            </div>
          </div>
          <el-button slot="reference" size="small">
            <i class="el-icon-setting"></i> 列设置
          </el-button>
        </el-popover>
      </div>
      <el-table
        :summary-method="getSummaries"
        v-loading="overviewLoading"
        show-summary
        border
        ref="OverView"
        height="calc(100vh - 190px)"
        style="width: 100%"
        :data="overviewList"
      >
        <el-table-column type="expand" width="50" fixed="left">
          <template slot-scope="props">
            <el-table border class="hide-x-scrollbar" :show-header="false" :data="props.row.dataVo">
              <el-table-column
                v-for="col in visibleColumns"
                :key="col.prop"
                :prop="col.prop"
                :label="col.label"
                :min-width="!col.isWidth ? col.width : undefined"
                :width="col.isWidth ? col.width : undefined"
                :fixed="col.fixed"
                :align="col.align"
              >
                <template slot-scope="scope">
                  <template v-if="col.isAmount">
                    {{ scope.row[col.prop] | applyAmount }}
                  </template>
                  <template v-else> {{ scope.row[col.prop] }} </template>
                </template>
              </el-table-column>
              <el-table-column fixed="right" min-width="70" align="center" label="操作">
                <template slot-scope="scope">
                  <el-button
                    @click="onClickFn(scope.row)"
                    class="text_Details_Bgc"
                    type="text"
                    size="small"
                    >详情</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="80"
        ></el-table-column>
        <template v-for="col in visibleColumns">
          <el-table-column
            v-if="col.prop != 'isThisYear'"
            :key="col.prop"
            :prop="col.prop"
            :label="col.label"
            :min-width="col.width"
            :fixed="col.fixed"
            :sortable="col.sortable"
            :align="col.align"
          >
            <template slot="header" slot-scope="{}">
              <span>{{ col.label }}</span>
              <el-tooltip v-if="col.tooltip" class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div v-html="col.tooltip"></div>
                </div>
              </el-tooltip>
            </template>

            <template slot-scope="scope">
              <template v-if="col.isAmount">
                {{ scope.row[col.prop] | applyAmount }}
              </template>
              <template v-else>
                {{ scope.row[col.prop] }}
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column fixed="right" min-width="70" align="center" label="操作">
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img src="@/assets/项目统计-项目总览.png" alt="数据来源说明" width="100%" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 在 data 外部定义列配置
const COLUMN_DEFINITIONS = {
  // 基本信息列
  basic: [
    {
      prop: 'isThisYear',
      label: '年份',
      width: '129',
      // fixed: 'left',
      sortable: true,
      align: 'center',
      isWidth: true,
    },
    {
      prop: 'hostDepartment',
      label: '主办部门',
      width: '100',
      // fixed: 'left',
      sortable: true,
      align: 'center',
    },
    {
      prop: 'deptFzManagerName',
      label: '分管高管',
      width: '100',
      tooltip: '今年实施项目的分管高管集合',
      sortable: true,
      align: 'center',
    },
    {
      prop: 'projectNum',
      label: '项目数量',
      width: '100',
      tooltip: '今年所实施的项目数量',
      sortable: true,
      align: 'center',
    },
    {
      prop: 'successProjectCount',
      label: '成功实施项目数',
      tooltip: '成功实施项目数 = 总项目数 - 未启动 - 终止（无待收款）',
      width: '90',
      sortable: true,
      align: 'right',
    },
  ],
  // 金额信息列
  amount: [
    {
      prop: 'predictContractAmount',
      label: '我方合同金额',
      width: '130',
      tooltip: '项目详情里的我方合同金额',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
    {
      prop: 'actualAmount',
      label: '项目实际金额',
      width: '130',
      tooltip: '项目详情里的项目实际金额',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
    {
      prop: 'contractAmount',
      label: '合同实际金额',
      width: '150',
      tooltip: '项目详情里的合同实际金额',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
  ],
  // 开票信息列
  invoice: [
    {
      prop: 'invoiceAmountAll',
      label: '总开票金额',
      width: '120',
      tooltip: '项目收入中的已开票金额',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
    // {
    //   prop: 'invoiceAmountHist',
    //   label: '往年项目开票金额',
    //   width: '120',
    //   tooltip: '项目收入中的已开票金额<br/>往年所创建的项目开票统计',
    //   sortable: true,
    //   align: 'right',
    //   isAmount: true,
    // },
    // {
    //   prop: 'invoiceAmount',
    //   label: '今年项目开票金额',
    //   width: '120',
    //   tooltip: '项目收入中的已开票金额<br/>当前年份并且是当前年份所创建的项目开票统计',
    //   sortable: true,
    //   align: 'right',
    //   isAmount: true,
    // },
    // {
    //   prop: 'invoiceAmountAll',
    //   label: '开票金额',
    //   width: '120',
    //   tooltip: '往年项目开票金额 + 今年项目开票金额',
    //   sortable: true,
    //   align: 'right',
    //   isAmount: true,
    // },
  ],
  // 回款信息列
  payment: [
    {
      prop: 'repaymentAmountAll',
      label: '总回款金额',
      width: '120',
      tooltip: '项目收入中的已收款金额',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
    // {
    //   prop: 'repaymentAmountHist',
    //   label: '往年项目回款金额',
    //   width: '120',
    //   tooltip: '项目收入中的已收款金额<br/>往年所创建的项目已收款金额统计',
    //   sortable: true,
    //   align: 'right',
    //   isAmount: true,
    // },
    // {
    //   prop: 'repaymentAmount',
    //   label: '今年项目回款金额',
    //   width: '120',
    //   tooltip: '项目收入中的已收款金额<br/>当前年份并且是当前年份所创建的项目已收款金额统计',
    //   sortable: true,
    //   align: 'right',
    //   isAmount: true,
    // },
    // {
    //   prop: 'proceedsAmountAll',
    //   label: '回款金额',
    //   width: '120',
    //   tooltip: '往年项目回款金额 + 今年项目回款金额',
    //   sortable: true,
    //   align: 'right',
    //   isAmount: true,
    // },
  ],
  // 成本信息列
  cost: [
    // {
    //   prop: 'laborCost',
    //   label: '今年项目工时成本',
    //   width: '120',
    //   tooltip: '项目人员的项目级工时合计，<br/>截至上周的累计人工',
    //   sortable: true,
    //   align: 'right',
    //   isAmount: true,
    // },
    // {
    //   prop: 'laborCostFormerYear',
    //   label: '往年项目工时成本',
    //   width: '120',
    //   tooltip: '项目人员的项目级工时合计，<br/>截至上周的累计人工',
    //   sortable: true,
    //   align: 'right',
    //   isAmount: true,
    // },
    {
      prop: 'projectLaborCost',
      label: '项目工时成本',
      width: '120',
      tooltip: '项目人员的项目级工时，<br/>截至上周的累计人工',
      sortable: true,
      align: 'right',
      isAmount: true,
      requireBoss: true,
    },
    {
      prop: 'manhourCost',
      label: '组织工时成本',
      width: '120',
      tooltip: '公司/部门人员的组织级工时合计，<br/>截至上周的累计人工',
      sortable: true,
      align: 'right',
      isAmount: true,
      requireBoss: true,
    },
    {
      prop: 'otherCost',
      label: '其他成本',
      width: '120',
      tooltip: '项目产生的项目费用、外协生产费用、设备折旧费用合计',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
    {
      prop: 'totalCost',
      label: '总成本',
      width: '140',
      tooltip: '往年项目工时成本 + 今年项目工时成本 + 组织工时成本 + 其他成本',
      sortable: true,
      align: 'right',
      isAmount: true,
    },
  ],
}

export default {
  components: {
    SearchUserDate: () => import('../searchUserDate.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      dictData: {
        yearList: [],
        deptList: [],
        deptAllList: [],
      },
      overviewList: [],
      sectionInfo: {
        // 条件筛选 // 年份
        overviewYear: [],
        deptIdArr: [],
        deptId: null,
      },
      titleYear: '统计时间：',
      isDialog: false,
      isBoss: false,
      columnGroups: [
        {
          name: 'invoice',
          label: '开票信息',
          columns: COLUMN_DEFINITIONS.invoice.slice(0, -1),
          totalColumn: COLUMN_DEFINITIONS.invoice[0],
        },
        {
          name: 'payment',
          label: '回款信息',
          columns: COLUMN_DEFINITIONS.payment.slice(0, -1),
          totalColumn: COLUMN_DEFINITIONS.payment[0],
        },
        {
          name: 'cost',
          label: '成本信息',
          columns: COLUMN_DEFINITIONS.cost.slice(0, -1),
          totalColumn: COLUMN_DEFINITIONS.cost[3],
        },
      ],
      checkedColumns: [], // 默认显示的列
      presetViews: [
        {
          name: '开票信息',
          getColumns: () => {
            const columns = [
              ...COLUMN_DEFINITIONS.basic,
              ...COLUMN_DEFINITIONS.amount,
              ...COLUMN_DEFINITIONS.invoice,
            ]
            return Array.from(new Map(columns.map(col => [col.prop, col])).values())
          },
        },
        {
          name: '回款信息',
          getColumns: () => {
            const columns = [
              ...COLUMN_DEFINITIONS.basic,
              ...COLUMN_DEFINITIONS.amount,
              ...COLUMN_DEFINITIONS.payment,
            ]
            return Array.from(new Map(columns.map(col => [col.prop, col])).values())
          },
        },
        {
          name: '成本信息',
          getColumns: () => {
            const columns = [
              ...COLUMN_DEFINITIONS.basic,
              ...COLUMN_DEFINITIONS.amount,
              ...COLUMN_DEFINITIONS.cost,
            ]
            return Array.from(new Map(columns.map(col => [col.prop, col])).values())
          },
        },
        {
          name: '全部',
          getColumns: () => {
            const columns = Object.values(COLUMN_DEFINITIONS).flat()
            return Array.from(new Map(columns.map(col => [col.prop, col])).values())
          },
        },
      ],
      currentView: '全部', // 当前选中的视图
      overviewLoading: false,
      exportingLoading: false,
    }
  },
  provide() {
    return {}
  },
  computed: {
    projectNum() {
      let num = 0
      if (this.overviewList.length > 0) {
        this.overviewList.forEach(v => {
          num += v.projectNum
        })
      }
      return num
    },
    projectPayment() {
      let num = 0
      if (this.overviewList.length > 0) {
        this.overviewList.forEach(v => {
          num += v.repaymentAmountAll
        })
      }
      return num
    },
    visibleColumns() {
      const currentView = this.presetViews.find(v => v.name === this.currentView)
      if (!currentView) return []

      // 获取当前视图下应该显示的所有列
      let allColumns = currentView.getColumns()

      // 根据 isBoss 过滤列
      allColumns = allColumns.filter(col => !col.requireBoss || this.isBoss)

      if (this.currentView === '全部') {
        // 在全部视图下，基本信息和金额信息始终显示
        const baseColumns = [...COLUMN_DEFINITIONS.basic, ...COLUMN_DEFINITIONS.amount]
        const baseProps = baseColumns.map(col => col.prop)

        // 创建一个映射来存储每个组的列和其对应的合计列
        const groupedColumns = {
          invoice: {
            detail: COLUMN_DEFINITIONS.invoice.slice(0, -1),
            total: COLUMN_DEFINITIONS.invoice[0],
          },
          payment: {
            detail: COLUMN_DEFINITIONS.payment.slice(0, -1),
            total: COLUMN_DEFINITIONS.payment[0],
          },
          cost: {
            // 根据 isBoss 过滤 cost 组的详细列
            detail: COLUMN_DEFINITIONS.cost
              .slice(0, -1)
              .filter(col => !col.requireBoss || this.isBoss),
            total: COLUMN_DEFINITIONS.cost[3],
          },
        }

        // 构建最终的列数组
        const resultColumns = [...baseColumns]

        // 按顺序处理每个组
        Object.values(groupedColumns).forEach(group => {
          // 添加用户选中的详细列
          const selectedDetails = group.detail.filter(col => this.checkedColumns.includes(col.prop))
          if (selectedDetails.length > 0) {
            resultColumns.push(...selectedDetails, group.total)
          } else {
            resultColumns.push(group.total)
          }
        })

        return resultColumns
      }

      // 其他视图直接返回过滤后的列
      return allColumns
    },
    visibleColumnGroups() {
      return this.columnGroups.map(group => ({
        ...group,
        columns:
          group.name === 'cost'
            ? group.columns.filter(col => !col.requireBoss || this.isBoss)
            : group.columns,
      }))
    },
  },
  created() {
    this.getType()
    this.initColumns()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  updated() {
    this.$nextTick(() => {
      if (this.$refs.OverView) {
        this.$refs.OverView.doLayout()
      }
    })
  },
  methods: {
    // 总览
    async onOverViewExporting() {
      this.exportingLoading = true
      const obj = { date: this.sectionInfo.overviewYear.join(', ') }
      if (this.sectionInfo.deptId) {
        obj.deptId = this.sectionInfo.deptId
      }
      try {
        const res = await this.$api.statistics.exportProjectCountData(obj)
        const fileName = '项目总览.xlsx'
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        if (navigator.msSaveBlob) {
          // 针对 IE
          navigator.msSaveBlob(blob, fileName)
        } else {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          URL.revokeObjectURL(link.href)
        }
        this.$message.success('导出成功')
      } catch (err) {
        console.error('导出失败:', err)
        this.$message.error('导出失败，请重试')
      } finally {
        this.exportingLoading = false
      }
    },
    init(val) {
      this.loading = true
      this.$api.statistics
        .queryProjectCountData(`date=${val}`)
        .then(res => {
          const oldIsBoss = this.isBoss
          this.isBoss = res.data?.isBoss

          // 如果 isBoss 状态发生变化，重新初始化列
          if (oldIsBoss !== this.isBoss) {
            this.initColumns()
          }

          // 项目数量统计
          this.overviewList = res.data?.projectDataView?.projectDataVoList || []
          this.overviewList.forEach(v => {
            v.proceedsAmountAll = v.repaymentAmountHist + v.repaymentAmount
            v.invoiceAmountAll = v.invoiceAmountHist + v.invoiceAmount
            v.dataVo = [v.projectThisYearDataVo, v.projectFormerYearDataVo]
          })
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    onOverViewChange(obj) {
      this.overviewList = obj?.projectDataVoList || []
      this.overviewList.forEach(v => {
        v.proceedsAmountAll = v.repaymentAmountHist + v.repaymentAmount
        v.invoiceAmountAll = v.invoiceAmountHist + v.invoiceAmount
        v.dataVo = [v.projectThisYearDataVo, v.projectFormerYearDataVo]
      })
    },
    onClickFn(row) {
      const obj = {
        isThisYear: row.isThisYear,
        statisticsYear: this.sectionInfo.overviewYear,
        deptId: row.hostDepartmentId || null,
        deptIdArr: row.deptIdList || [],
      }
      this.$router.push({
        name: 'XMJSCXMTJ',
        params: { data: obj },
      })
    },

    // 表格合计
    getSummaries(param) {
      const { columns, data } = param
      const sums = []

      // 定义需要合计的列属性
      const summableProps = [
        'repaymentAmountAll',
        'invoiceAmountAll',
        'projectLaborCost',
        'predictContractAmount',
        'projectNum',
        'successProjectCount',
        'actualAmount',
        'contractAmount', // 金额信息
        'invoiceAmountHist',
        'invoiceAmount',
        'invoiceAmountAll', // 开票信息
        'repaymentAmountHist',
        'repaymentAmount',
        'proceedsAmountAll', // 回款信息
        'laborCost',
        'otherCost',
        'totalCost', // 成本信息
      ]

      // manhourCost 只在 isBoss 为 true 时才参与合计
      if (this.isBoss) {
        summableProps.push('manhourCost')
      }

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }

        const prop = column.property
        if (summableProps.includes(prop)) {
          const values = data.map(item => Number(item[prop]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
          } else {
            sums[index] = ''
          }
        } else {
          sums[index] = '--'
        }
      })

      return sums
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.overviewYear = [dictVal]
          this.init(dictVal)
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.dictData.deptList = res.data

          function flattenDepartments(arr) {
            const result = []
            // 递归函数：处理当前部门及其子部门
            function processDepartment(dept) {
              // 创建当前部门的副本，并添加到结果列表
              result.push({
                id: dept.id,
                dictName: dept.deptName,
                dictVal: dept.id,
              })
              // 递归处理所有子部门
              if (dept.children && dept.children.length > 0) {
                dept.children.forEach(child => processDepartment(child))
              }
            }
            // 从根部门开始遍历
            arr.forEach(dept => processDepartment(dept))

            return result
          }
          this.dictData.deptAllList = flattenDepartments(res.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleColumnChange(value) {
      this.currentView = '全部'
      this.checkedColumns = value
      this.updateGroupCheckStatus()
      localStorage.setItem('projectOverview-columns', JSON.stringify(value))
    },
    handleCheckAllChange(group) {
      const visibleGroup = this.visibleColumnGroups.find(g => g.name === group.name)
      const groupColumns = visibleGroup.columns.map(col => col.prop)

      if (group.checkAll) {
        // 选中该组所有列
        this.checkedColumns = [...new Set([...this.checkedColumns, ...groupColumns])]
      } else {
        // 取消选中该组所有列
        this.checkedColumns = this.checkedColumns.filter(col => !groupColumns.includes(col))
      }
      this.handleColumnChange(this.checkedColumns)
    },

    // 更新组的选中状态
    updateGroupCheckStatus() {
      this.visibleColumnGroups.forEach(group => {
        const groupColumns = group.columns.map(col => col.prop)
        const checkedCount = groupColumns.filter(col => this.checkedColumns.includes(col)).length
        group.checkAll = checkedCount === group.columns.length
        group.indeterminate = checkedCount > 0 && checkedCount < group.columns.length
      })
    },
    handleViewChange(viewName) {
      if (viewName === '全部') {
        const savedColumns = localStorage.getItem('projectOverview-columns')
        this.checkedColumns = savedColumns
          ? JSON.parse(savedColumns)
          : this.visibleColumnGroups.flatMap(group => group.columns.map(col => col.prop))
      }
      this.updateGroupCheckStatus()
    },
    resetColumns() {
      this.currentView = '全部'
      // 获取所有可见的列作为默认值
      this.checkedColumns = this.visibleColumnGroups
        .flatMap(group => group.columns)
        .map(col => col.prop)

      this.updateGroupCheckStatus()
      localStorage.setItem('projectOverview-columns', JSON.stringify(this.checkedColumns))

      this.$message({
        type: 'success',
        message: '已重置为默认显示',
      })
    },
    // 获取默认显示的列
    getDefaultColumns() {
      return this.visibleColumnGroups.flatMap(group => group.columns).map(col => col.prop)
    },
    initColumns() {
      // 获取所有可见的列
      const availableColumns = this.visibleColumnGroups
        .flatMap(group => group.columns)
        .map(col => col.prop)

      // 从 localStorage 读取保存的列设置
      const savedColumns = localStorage.getItem('projectOverview-columns')
      if (savedColumns) {
        // 过滤掉没有权限的列
        this.checkedColumns = JSON.parse(savedColumns).filter(col => availableColumns.includes(col))
      } else {
        // 使用所有可见列作为默认值
        this.checkedColumns = availableColumns
      }

      this.updateGroupCheckStatus()
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.text_Details_Bgc {
  //详情
  background-color: #409eff;
  color: #fff;
  padding: 5px 5px;
}
.el-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 400px;
  overflow-y: auto;
}
.column-groups {
  .column-group {
    margin-bottom: 16px;

    .group-title {
      margin-bottom: 8px;
      font-weight: bold;
    }

    .group-items {
      padding-left: 24px;

      .el-checkbox {
        display: block;
        margin-bottom: 8px;
      }
    }
  }
}

.el-checkbox-group {
  display: flex;
  flex-direction: column;

  .el-checkbox {
    margin-left: 0;
    margin-bottom: 8px;
  }
}

.el-radio-group {
  margin-right: 8px;

  .el-radio-button__inner {
    padding: 5px 15px;
  }
}

.column-setting-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .column-groups {
    flex: 1;
    overflow-y: auto;
  }

  .column-setting-footer {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #ebeef5;
    text-align: right;
  }
}

.hide-x-scrollbar {
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-horizontal {
    display: none;
  }
  /* 兼容 Firefox */
  /deep/ .el-table__body-wrapper {
    scrollbar-width: none;
  }
  /* 兼容 IE */
  /deep/ .el-table__body-wrapper {
    -ms-overflow-style: none;
  }
}

.statistics {
  font-size: 100%;
  font-weight: 900;
  color: #f53a3a;
  margin-left: 18px;
}
</style>
