<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">商务类型</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'BusinessType'"
            :introductionType="'projectManager'"
            :yearIsShow="true"
            :multiple="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.businessTypePrincipal"
            v-model="sectionInfo.businessTypeYear"
            @change="onBusinessTypeChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :type="'BusinessType'"
            :introductionType="'projectManager'"
            :title="titlePrincipal"
            :list="userList"
            :yearValue="sectionInfo.businessTypeYear"
            v-model="sectionInfo.businessTypePrincipal"
            @change="onBusinessTypeChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onBusinessTypeImg"
          >数据来源说明</el-button
        >
      </div>
      <div class="statistics_top">
        <el-card style="width: 49.7%"
          ><span class="title_fw">我方合同金额</span>
          <BusinessType :businessTypeList="businessTypeList" />
        </el-card>
        <el-card style="width: 49.7%"
          ><span class="title_fw">收款金额</span>
          <PaymentBusinessType :businessTypeList="businessTypeList" />
        </el-card>
      </div>
    </el-card>
    <el-card class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <span class="title_fw">商务类型统计</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'BusinessTypeData'"
            :introductionType="'projectManager'"
            :twoIntroductionType="'businessType'"
            :yearIsShow="true"
            :multiple="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.businessTypeDataPrincipal"
            :threeValue="sectionInfo.businessType"
            v-model="sectionInfo.businessTypeDataYear"
            @change="onBusinessTypeDataChange"
            @onChangeLoading="businessTypeDataLoading = $event"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :type="'BusinessTypeData'"
            :introductionType="'projectManager'"
            :twoIntroductionType="'businessType'"
            :title="titlePrincipal"
            :list="userList"
            :yearValue="sectionInfo.businessTypeDataYear"
            :twoValue="sectionInfo.businessTypeDataPrincipal"
            :threeValue="sectionInfo.businessType"
            v-model="sectionInfo.businessTypeDataPrincipal"
            @change="onBusinessTypeDataChange"
            @onChangeLoading="businessTypeDataLoading = $event"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :threeIsShow="true"
            :title="'商务类型：'"
            :width="'150'"
            :type="'BusinessTypeData'"
            :introductionType="'projectManager'"
            :twoIntroductionType="'businessType'"
            :list="dictData.businessType"
            :yearValue="sectionInfo.businessTypeDataYear"
            :twoValue="sectionInfo.businessTypeDataPrincipal"
            :threeValue="sectionInfo.businessType"
            v-model="sectionInfo.businessType"
            @change="onBusinessTypeDataChange"
            @onChangeLoading="businessTypeDataLoading = $event"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onBusinessTypeDataImg"
          >数据来源说明</el-button
        >
      </div>
      <el-table
        height="330px"
        :summary-method="getSummaries"
        v-loading="businessTypeDataLoading"
        show-summary
        border
        ref="table"
        row-key="id"
        :data="businessTypeData"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="projectName"
          label="项目名称"
          align="center"
          fixed="left"
          min-width="240"
        ></el-table-column>
        <el-table-column
          prop="projectManager"
          label="项目经理"
          align="center"
          fixed="left"
          min-width="90"
        ></el-table-column>
        <el-table-column
          prop="contractingCompany"
          min-width="110"
          label="承接公司"
          sortable
          align="center"
        >
          <template slot-scope="{}" slot="header">
            <span>承接公司</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的承接项目公司</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.contractingCompany | dict(dictData.companyType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="businessType"
          min-width="110"
          label="商务类型"
          sortable
          align="center"
        >
          <template slot-scope="{}" slot="header">
            <span>商务类型</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的商务类型</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.businessType | dict(dictData.businessType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="predictContractAmount"
          min-width="140"
          label="我方合同金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>我方合同金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的我方合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.predictContractAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="contractAmount"
          min-width="180"
          label="合同实际金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>合同实际金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>金额前面有（大），该项目即为大包合同子项</div>
                <div>项目详情里的合同实际金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.isLargePackage == 1 ? '（大）' : '' }}
            {{ scope.row.contractAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="actualAmount"
          min-width="130"
          label="项目实际金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>项目实际金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的项目实际金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="invoiceAmount"
          min-width="130"
          label="已开票金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>已开票金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目收入中的已开票金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.invoiceAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="repaymentAmount"
          min-width="130"
          label="已收款金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>已收款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目收入中的已收款金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.repaymentAmount | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          v-show="imgUrl == '项目统计-商务类型'"
          src="@/assets/项目统计-商务类型.png"
          alt="数据来源说明"
          width="100%"
        />
        <img
          v-show="imgUrl == '项目统计-商务类型统计'"
          src="@/assets/项目统计-商务类型统计.png"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    BusinessType: () => import('./businessType.vue'),
    PaymentBusinessType: () => import('./paymentBusinessType.vue'),
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      userList: [], // 项目经理
      dictData: {
        yearList: [],
        businessType: [],
        companyType: [],
      },
      businessTypeList: [], // 商务类型
      businessTypeData: [],
      sectionInfo: {
        // 条件筛选 // 年份
        businessTypeYear: [],
        businessTypeDataYear: [],
        // 项目经理
        businessTypePrincipal: '',
        businessTypeDataPrincipal: '',
        businessType: '',
      },
      titleYear: '统计时间',
      titlePrincipal: '项目经理',
      isDialog: false,
      imgUrl: '',
      contractAmount: 0,
      businessTypeDataLoading: false,
    }
  },
  provide() {
    return {}
  },
  computed: {},
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout()
    })
  },
  methods: {
    onBusinessTypeImg() {
      this.imgUrl = '项目统计-商务类型'
      this.isDialog = true
    },
    onBusinessTypeDataImg() {
      this.imgUrl = '项目统计-商务类型统计'
      this.isDialog = true
    },
    onBusinessTypeDataChange(obj) {
      this.businessTypeData = obj?.businessTypeResponseLists || []
      this.contractAmount = obj?.projectPaymentAmount || 0
    },
    onBusinessTypeChange(arr) {
      this.businessTypeList = arr
    },
    async getType() {
      const businessType = await this.$api.dict.listSysDictData('BUSINESS_TYPE', true)
      this.dictData.businessType = businessType.data
      const item = this.dictData.businessType.some(v => v.id === '1')
      if (!item) {
        this.dictData.businessType.unshift({
          id: '1',
          dictName: '全部',
          dictVal: '',
        })
      }
      this.loading = true
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(async res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.businessTypeYear = [dictVal]
          this.sectionInfo.businessTypeDataYear = [dictVal]

          const projecBusinessTypeSummary = await this.$api.statistics.projecBusinessTypeSummary(
            `date=${dictVal}`
          )
          const projectQueryBusinessTypeSummaryList =
            await this.$api.statistics.projectQueryBusinessTypeSummaryList(`date=${dictVal}`)

          this.businessTypeList = projecBusinessTypeSummary.data?.businessTypeResponse.map(item => {
            const businessTypeItem = businessType.data.find(
              v => v.dictVal === item.businessTypeName
            )
            return {
              businessTypePaymentAmount: item.businessTypePaymentAmount,
              businessTypeAmount: item.businessTypeAmount,
              businessPercentage: Number((item.businessPercentage * 100).toFixed(2)),
              paymentBusinessPercentage: Number((item.paymentBusinessPercentage * 100).toFixed(2)),
              businessTypeCount: item.businessTypeCount,
              name: businessTypeItem ? businessTypeItem.dictName : item.businessTypeName, // 如果找不到则使用原名称
            }
          })
          this.businessTypeData =
            projectQueryBusinessTypeSummaryList?.data?.businessTypeResponseView
              ?.businessTypeResponseLists || []
          this.contractAmount =
            projectQueryBusinessTypeSummaryList?.data?.businessTypeResponseView
              ?.projectPaymentAmount || 0
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
      this.$api.project
        .getProjectInfoByStaffId()
        .then(res => {
          this.userList = res.data?.projectManagerNameRespList.map(v => ({
            id: v.id,
            dictName: v.userName,
            dictVal: v.id,
          }))
          this.userList.unshift({
            id: `${Math.random()}`,
            dictName: '全部',
            dictVal: '',
          })
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index > 4) {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            if (index === 6) {
              sums[index] = Number(this.contractAmount.toFixed(2)).toLocaleString()
            } else {
              sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
            }
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        } else {
          sums[index] = '--'
        }
      })

      return sums
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
