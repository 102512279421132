<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <span
            >商务名称：
            <el-autocomplete
              class="ipt_width project_width"
              v-model="parameter.businessName"
              :fetch-suggestions="businessNameQuerySearch"
              placeholder="请输入或选择商务名称"
              @select="onProkectNameSelect"
              :popper-append-to-body="false"
            >
            </el-autocomplete>
          </span>
          <span
            >商务类型：
            <Dictionary
              v-model="parameter.businessType"
              class="ipt_width"
              code="BUSINESS_TYPE"
              placeholder="请选择商务类型"
          /></span>
          <span>
            商务负责人：
            <el-select
              class="ipt_width"
              clearable
              v-model="parameter.principalUserId"
              filterable
              placeholder="请输入或者选择"
            >
              <el-option
                v-for="item in selectedList"
                :key="item.id"
                :label="item.userName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </span>

          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <!-- <el-button type="primary" icon="el-icon-edit" @click="clear_serach()">清空</el-button> -->
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <!-- <el-tabs v-model="activeName" v-if=" role_type== 'jl'" class="fullScreenMainHeader" >
                  <el-tab-pane label="可立项"></el-tab-pane>
                  <el-tab-pane label="已申请"></el-tab-pane>
                </el-tabs> -->

          <el-tabs v-model="activeName" class="fullScreenMainHeader">
            <el-tab-pane label="待处理" name="3"></el-tab-pane>
            <el-tab-pane label="已移交" name="5"></el-tab-pane>
            <el-tab-pane label="已暂停" name="9"></el-tab-pane>
            <el-tab-pane label="已终结" name="10"></el-tab-pane>
          </el-tabs>

          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" width="50" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="businessName"
              min-width="320"
              :show-overflow-tooltip="false"
              label="商务名称"
            ></el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              prop="ownerUnit"
              min-width="220"
              label="业主单位"
            >
            </el-table-column>
            <el-table-column align="center" prop="contacts" label="经办人" width="90">
            </el-table-column>
            <el-table-column align="center" prop="contactsPhone" width="120" label="经办人电话">
            </el-table-column>
            <el-table-column
              align="center"
              prop="principalUserName"
              label="实际商务负责人"
              width="130"
            ></el-table-column>
            <el-table-column
              align="center"
              width="110"
              sortable="custom"
              prop="createdDate"
              label="填报时间"
            >
              <template slot-scope="scope">
                {{ scope.row.createdDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="110"
              sortable="custom"
              prop="businessStartDate"
              label="商务实际开始时间"
            >
              <template slot-scope="scope">
                {{ scope.row.businessStartDate | dateFormat }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              prop="businessType"
              sortable
              width="120"
              label="商务类型"
            >
              <template slot-scope="scope">
                {{ scope.row.businessType | dict(dictData.businessType) }}
              </template>
            </el-table-column>
            <el-table-column prop="title" align="center" fixed="right" width="110" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  :class="activeName == '3' ? 'text_Edit_Bgc' : 'text_Details_Bgc'"
                  size="small"
                  @click.stop="detail(scope.row)"
                  >{{ activeName == '3' ? '编辑' : '详情' }}</el-button
                >
                <!-- <el-button type="text" size="small" @click.stop="shenqing(scope.row)" v-if=" activeName == '0' ">移交计划经营部</el-button> -->
                <el-button
                  type="text"
                  size="small"
                  class="text_Edit_Bgc"
                  @click.stop="create(scope.row)"
                  v-if="role_type == 'fz' && activeName == '3'"
                  >移交</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit v-if="showEditDialog" :isShow.sync="showEditDialog" :options="form"></Edit>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('./Edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        principalUserId: null,
        businessName: null,
        businessType: null,
      },
      pageSize: 0,
      condition: {
        title: '',
      },
      form: {},
      tableData: [],
      // forList:[],
      loading: false,
      activeName: '3',
      role_type: null,
      showEditDialog: false,
      dictData: {
        businessType: [],
      },
      businessList: [],
      selectedList: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      allUserList: state => state.business.allUserList,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.$api.dict
      .listSysDictData('BUSINESS_TYPE', true)
      .then(res => {
        this.dictData.businessType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.businessExpense
      .businessNameList()
      .then(res => {
        this.businessList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    if (this.allUserList.length <= 0) {
      this.getSelectedList()
    } else {
      this.selectedList = this.allUserList
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },
  watch: {
    activeName: {
      handler: function (newVal) {
        if (newVal) {
          this.parameter.pageNow = 1
          this.getData()
        }
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },

  methods: {
    businessNameQuerySearch(queryString, cb) {
      let businessList = this.businessList.map(item => {
        return { value: item.businessName }
      })
      let results = queryString ? this.businessNameCreateFilter(queryString) : businessList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    businessNameCreateFilter(queryString) {
      let results = this.businessList.map(item => {
        return { value: item.businessName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.businessName = e.value
      }
      this.$forceUpdate()
    },
    // 获取所有人员
    getSelectedList() {
      this.$api.businessManage
        .principalUserNameListNoPage()
        .then(res => {
          this.selectedList = res.data
          this.$store.commit({
            type: 'business/SET_ALL_USER_LIST',
            allUserList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    getData() {
      this.loading = true
      this.parameter.status = this.activeName
      this.$api.businessManage
        .getCreateProjectList(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == null) {
            this.tableData = []
            this.parameter.total = 0
          } else {
            this.tableData = res.data.records
            this.role_type = res.data.type
            this.parameter.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    zhipai(row) {
      this.Dispose_data = row
      this.Dispose_show = true
    },
    detail(row) {
      this.form = row
      if (this.activeName == '3') {
        this.form.list_s = false
      } else {
        this.form.list_s = true
      }
      this.showEditDialog = true
    },
    shenqing(row) {
      this.form = row
      this.showEditDialog = true
    },

    create(row) {
      this.$confirm('确认批准该项目立项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loading = true

          let obj = {
            id: row.id,
            status: (row.status += 1),
          }
          this.$api.businessManage
            .updateBusinessManageStatus(obj)
            .then(res => {
              this.loading = false
              this.getData()
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
        })
        .catch(() => {})
    },
    re() {
      this.getData()
      this.showEditDialog = false
    },
    handleReset() {
      this.parameter = {
        pageNow: 1,
        pageSize: this.pageSize,
        total: 0,
        principalUserId: null,
        businessName: null,
        businessType: null,
      }
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.mainList .mainList_content .mainList_content_sub .el-table {
  height: calc(100% - 100px);
}
</style>
