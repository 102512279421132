<template>
  <div v-loading="loading">
    <el-row :gutter="20" class="kpi-cards">
      <el-col :span="6" v-for="(item, index) in kpiData" :key="index">
        <el-card shadow="hover" :body-style="{padding: '20px'}">
          <div class="kpi-item">
            <div class="kpi-title">{{item.title}}</div>
            <div class="kpi-value" :class="item.type">
              {{ item.value | applyAmount }}
              <span class="kpi-unit">{{item.unit}}</span>
            </div>
            <div class="kpi-change" :class="item.trend > 0 ? 'up' : 'down'" v-if="item.trend !== 0">
              {{ item.trend > 0 ? '+' : '' }}{{item.trend}}%
              <i :class="item.trend > 0 ? 'el-icon-top' : 'el-icon-bottom'"></i>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw"> 项目总览 </span>
        <span class="title_right"
          ><SearchUserDate
            :type="'OverView'"
            :introductionType="'deptId'"
            :yearIsShow="true"
            :multiple="true"
            :title="titleYear"
            :list="dictData.yearList"
            :twoValue="sectionInfo.deptId"
            v-model="sectionInfo.overviewYear"
            @change="onOverViewChange"
        /></span>
        <span class="title_right"
          >部门：
          <DeptCascader
            :placeholder="'请选择部门'"
            :deptList="dictData.deptList"
            :deptIdArr="sectionInfo.deptIdArr"
            @changeValue="sectionInfo.deptId = $event"
            v-model="sectionInfo.deptIdArr"
            :size="'small'"
          />
        </span>
        <span class="title_right" style="display: none">
          <SearchUserDate
            :type="'OverViewDeptId'"
            :introductionType="'deptId'"
            :list="dictData.deptAllList"
            :twoValue="sectionInfo.deptId"
            :yearValue="sectionInfo.overviewYear"
            v-model="sectionInfo.deptId"
            @change="onOverViewChange"
          />
        </span>
        <el-button class="title_right" type="primary" size="small" @click="isDialog = true"
          >数据来源说明</el-button
        >
      </div>
      <el-table
        :summary-method="getSummaries"
        show-summary
        border
        ref="OverView"
        height="calc(100vh - 190px)"
        style="width: 100%"
        :data="overviewList"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="hostDepartment"
          min-width="100"
          label="主办部门"
          fixed="left"
          sortable
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="deptFzManagerName"
          min-width="100"
          sortable
          fixed="left"
          align="center"
        >
          <template slot-scope="{}" slot="header">
            <span>分管高管</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的分管高管</div>
              </div>
            </el-tooltip></template
          ></el-table-column
        >
        <el-table-column prop="projectNum" min-width="100" label="项目数量" sortable align="center">
          <template slot-scope="{}" slot="header">
            <span>项目数量</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>当前年份的项目数量</div>
              </div>
            </el-tooltip></template
          >
        </el-table-column>
        <el-table-column prop="predictContractAmount" min-width="140" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>我方合同金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的我方合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.predictContractAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="actualAmount"
          min-width="150"
          label="项目实际金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>项目实际金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的项目实际金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="contractAmount" min-width="180" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>合同实际金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的合同实际金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.contractAmount | applyAmount }}
          </template>
        </el-table-column>

        <el-table-column prop="invoiceAmountHist" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>往年项目开票金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目收入中的已开票金额</div>
                <div>往年所创建的项目开票统计</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.invoiceAmountHist | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="invoiceAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>今年项目开票金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目收入中的已开票金额</div>
                <div>当前年份并且是当前年份所创建的项目开票统计</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.invoiceAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="invoiceAmountAll" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>开票金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>往年项目开票金额 + 今年项目开票金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.invoiceAmountAll | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="repaymentAmountHist" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>往年项目回款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目收入中的已收款金额</div>
                <div>往年所创建的项目已收款金额统计</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.repaymentAmountHist | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="repaymentAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>今年项目回款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目收入中的已收款金额</div>
                <div>当前年份并且是当前年份所创建的项目已收款金额统计</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.repaymentAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="proceedsAmountAll" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>回款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>往年项目回款金额 + 今年项目回款金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.proceedsAmountAll | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="laborCost" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>人工成本</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目人员成本合计，</div>
                <div>截至上周的累计人工</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.laborCost | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="otherCost" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>其他成本</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目产生的项目费用、外协生产费用、设备折旧费用合计</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.otherCost | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="totalCost" min-width="130" label="总成本" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>总成本</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>人员成本+其他成本</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.totalCost | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="70">
          <template slot-scope="scope">
            <el-button
              @click="onClickFn(scope.row)"
              class="text_Details_Bgc"
              type="text"
              size="small"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img src="@/assets/项目统计-项目总览.png" alt="数据来源说明" width="100%" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    SearchUserDate: () => import('../searchUserDate.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      loading: false,
      dictData: {
        yearList: [],
        deptList: [],
        deptAllList: [],
      },
      overviewList: [],
      sectionInfo: {
        overviewYear: [],
        deptIdArr: [],
        deptId: null,
      },
      titleYear: '统计时间：',
      isDialog: false,
      kpiData: [],
    }
  },
  provide() {
    return {}
  },
  computed: {},
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {
    overviewList: {
      handler() {
        this.calculateKPI()
      },
      immediate: true
    }
  },
  methods: {
    init(val) {
      this.loading = true
      this.$api.statistics
        .queryProjectCountData(`date=${val}`)
        .then(res => {
          this.overviewList = res.data?.projectDataView?.projectDataVoList || []
          this.overviewList.forEach(v => {
            v.proceedsAmountAll = v.repaymentAmountHist + v.repaymentAmount
            v.invoiceAmountAll = v.invoiceAmountHist + v.invoiceAmount
          })
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    onOverViewChange(obj) {
      this.overviewList = obj?.projectDataVoList || []
      this.overviewList.forEach(v => {
        v.proceedsAmountAll = v.repaymentAmountHist + v.repaymentAmount
        v.invoiceAmountAll = v.invoiceAmountHist + v.invoiceAmount
      })
    },
    onClickFn(row) {
      const obj = {
        statisticsYear: this.sectionInfo.overviewYear,
        deptId: row.hostDepartmentId || null,
        deptIdArr: row.deptIdList || [],
      }
      this.$router.push({
        name: 'XMJSCXMTJ',
        params: { data: obj },
      })
    },

    // 表格合计
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index > 2 && index != 16) {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        } else {
          sums[index] = '--'
        }
      })

      return sums
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.overviewYear = [dictVal]
          this.init(dictVal)
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.dictData.deptList = res.data

          function flattenDepartments(arr) {
            const result = []
            // 递归函数：处理当前部门及其子部门
            function processDepartment(dept) {
              // 创建当前部门的副本，并添加到结果列表
              result.push({
                id: dept.id,
                dictName: dept.deptName,
                dictVal: dept.id,
              })
              // 递归处理所有子部门
              if (dept.children && dept.children.length > 0) {
                dept.children.forEach(child => processDepartment(child))
              }
            }
            // 从根部门开始遍历
            arr.forEach(dept => processDepartment(dept))

            return result
          }
          this.dictData.deptAllList = flattenDepartments(res.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    calculateKPI() {
      this.kpiData = [
        {
          title: '总项目金额',
          value: this.overviewList.reduce((sum, item) => sum + (item.actualAmount || 0), 0),
          unit: '元',
          type: 'primary',
          trend: 12.5
        },
        {
          title: '总回款金额',
          value: this.overviewList.reduce((sum, item) => sum + (item.proceedsAmountAll || 0), 0),
          unit: '元', 
          type: 'success',
          trend: 8.3
        },
        {
          title: '项目数量',
          value: this.overviewList.reduce((sum, item) => sum + (item.projectNum || 0), 0),
          unit: '个',
          type: 'warning',
          trend: -2.1
        },
        {
          title: '总成本',
          value: this.overviewList.reduce((sum, item) => sum + (item.totalCost || 0), 0),
          unit: '元',
          type: 'danger',
          trend: 5.6
        }
      ]
    }
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.text_Details_Bgc {
  background-color: #409eff;
  color: #fff;
  padding: 5px 5px;
}

.kpi-cards {
  margin-bottom: 20px;
  .kpi-item {
    text-align: center;
    .kpi-title {
      font-size: 14px;
      color: #909399;
    }
    .kpi-value {
      font-size: 24px;
      font-weight: bold;
      margin: 10px 0;
      &.primary { color: #409EFF; }
      &.success { color: #67C23A; }
      &.warning { color: #E6A23C; }
      &.danger { color: #F56C6C; }
    }
    .kpi-unit {
      font-size: 12px;
      margin-left: 4px;
    }
    .kpi-change {
      font-size: 12px;
      &.up { color: #67C23A; }
      &.down { color: #F56C6C; }
    }
  }
}
</style>
