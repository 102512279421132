<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw"> 项目总览 </span>
        <span class="title_right"
          ><SearchUserDate
            :type="'OverView'"
            :introductionType="'deptId'"
            :yearIsShow="true"
            :multiple="true"
            :title="titleYear"
            :list="dictData.yearList"
            :twoValue="sectionInfo.deptId"
            v-model="sectionInfo.overviewYear"
            @change="onOverViewChange"
        /></span>
        <span class="title_right"
          >部门：
          <DeptCascader
            :placeholder="'请选择部门'"
            :deptList="dictData.deptList"
            :deptIdArr="sectionInfo.deptIdArr"
            @changeValue="sectionInfo.deptId = $event"
            v-model="sectionInfo.deptIdArr"
            :size="'small'"
          />
        </span>
        <span class="title_right" style="display: none">
          <SearchUserDate
            :type="'OverViewDeptId'"
            :introductionType="'deptId'"
            :list="dictData.deptAllList"
            :twoValue="sectionInfo.deptId"
            :yearValue="sectionInfo.overviewYear"
            v-model="sectionInfo.deptId"
            @change="onOverViewChange"
          />
        </span>
        <el-button class="title_right" type="primary" size="small" @click="isDialog = true"
          >数据来源说明</el-button
        >
        <el-popover placement="bottom" width="300" trigger="click" class="title_right">
          <div class="column-groups">
            <div v-for="group in columnGroups" :key="group.name" class="column-group">
              <div class="group-title">
                <el-checkbox 
                  :indeterminate="group.indeterminate"
                  v-model="group.checkAll"
                  @change="handleCheckAllChange(group)"
                >
                  {{group.label}}
                </el-checkbox>
              </div>
              <div class="group-items">
                <el-checkbox-group v-model="checkedColumns" @change="handleColumnChange">
                  <el-checkbox 
                    v-for="col in group.columns"
                    :key="col.prop"
                    :label="col.prop"
                  >
                    {{col.label}}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <el-button slot="reference" size="small">
            <i class="el-icon-setting"></i> 列设置
          </el-button>
        </el-popover>
      </div>
      <el-table
        :summary-method="getSummaries"
        show-summary
        border
        ref="OverView"
        height="calc(100vh - 190px)"
        style="width: 100%"
        :data="overviewList"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          v-for="col in visibleColumns"
          :key="col.prop"
          :prop="col.prop"
          :label="col.label"
          :min-width="col.width"
          :fixed="col.fixed"
          :sortable="col.sortable"
          :align="col.align"
        >
          <template slot="header" slot-scope="{}">
            <span>{{col.label}}</span>
            <el-tooltip v-if="col.tooltip" class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div v-html="col.tooltip"></div>
              </div>
            </el-tooltip>
          </template>
          
          <template slot-scope="scope">
            <template v-if="col.formatter">
              {{scope.row[col.prop] | applyAmount}}
            </template>
            <template v-else>
              {{scope.row[col.prop]}}
            </template>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="70">
          <template slot-scope="scope">
            <el-button
              @click="onClickFn(scope.row)"
              class="text_Details_Bgc"
              type="text"
              size="small"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img src="@/assets/项目统计-项目总览.png" alt="数据来源说明" width="100%" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    SearchUserDate: () => import('../searchUserDate.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      dictData: {
        yearList: [],
        deptList: [],
        deptAllList: [],
      },
      overviewList: [],
      sectionInfo: {
        // 条件筛选 // 年份
        overviewYear: [],
        deptIdArr: [],
        deptId: null,
      },
      titleYear: '统计时间：',
      isDialog: false,
      columnGroups: [
        {
          name: 'invoice',
          label: '开票信息',
          checkAll: true,
          indeterminate: false,
          columns: [
            { prop: 'invoiceAmountHist', label: '往年项目开票金额', width: '130', tooltip: '项目收入中的已开票金额<br/>往年所创建的项目开票统计', sortable: true, align: 'right', formatter: true },
            { prop: 'invoiceAmount', label: '今年项目开票金额', width: '130', tooltip: '项目收入中的已开票金额<br/>当前年份并且是当前年份所创建的项目开票统计', sortable: true, align: 'right', formatter: true }
          ]
        },
        {
          name: 'payment',
          label: '回款信息',
          checkAll: true,
          indeterminate: false,
          columns: [
            { prop: 'repaymentAmountHist', label: '往年项目回款金额', width: '130', tooltip: '项目收入中的已收款金额<br/>往年所创建的项目已收款金额统计', sortable: true, align: 'right', formatter: true },
            { prop: 'repaymentAmount', label: '今年项目回款金额', width: '130', tooltip: '项目收入中的已收款金额<br/>当前年份并且是当前年份所创建的项目已收款金额统计', sortable: true, align: 'right', formatter: true }
          ]
        },
        {
          name: 'cost',
          label: '成本信息',
          checkAll: true,
          indeterminate: false,
          columns: [
            { prop: 'laborCost', label: '人工成本', width: '130', tooltip: '项目人员成本合计，<br/>截至上周的累计人工', sortable: true, align: 'right', formatter: true },
            { prop: 'otherCost', label: '其他成本', width: '130', tooltip: '项目产生的项目费用、外协生产费用、设备折旧费用合计', sortable: true, align: 'right', formatter: true }
          ]
        }
      ],
      checkedColumns: [], // 默认显示的列
    }
  },
  provide() {
    return {}
  },
  computed: {
    visibleColumns() {
      // 定义所有列的完整顺序
      const allColumns = [
        // 基本信息
        { prop: 'hostDepartment', label: '主办部门', width: '100', fixed: 'left', sortable: true, align: 'center' },
        { prop: 'deptFzManagerName', label: '分管高管', width: '100', tooltip: '项目详情里的分管高管', sortable: true, align: 'center' },
        { prop: 'projectNum', label: '项目数量', width: '100', tooltip: '当前年份的项目数量', sortable: true, align: 'center' },
        
        // 金额信息
        { prop: 'predictContractAmount', label: '我方合同金额', width: '140', tooltip: '项目详情里的我方合同金额', sortable: true, align: 'right', formatter: true },
        { prop: 'actualAmount', label: '项目实际金额', width: '150', tooltip: '项目详情里的项目实际金额', sortable: true, align: 'right', formatter: true },
        { prop: 'contractAmount', label: '合同实际金额', width: '180', tooltip: '项目详情里的合同实际金额', sortable: true, align: 'right', formatter: true },
        
        // 开票信息（可选）
        { prop: 'invoiceAmountHist', label: '往年项目开票金额', width: '130', tooltip: '项目收入中的已开票金额<br/>往年所创建的项目开票统计', sortable: true, align: 'right', formatter: true, optional: true },
        { prop: 'invoiceAmount', label: '今年项目开票金额', width: '130', tooltip: '项目收入中的已开票金额<br/>当前年份并且是当前年份所创建的项目开票统计', sortable: true, align: 'right', formatter: true, optional: true },
        { prop: 'invoiceAmountAll', label: '开票金额', width: '130', tooltip: '往年项目开票金额 + 今年项目开票金额', sortable: true, align: 'right', formatter: true },
        
        // 回款信息（可选）
        { prop: 'repaymentAmountHist', label: '往年项目回款金额', width: '130', tooltip: '项目收入中的已收款金额<br/>往年所创建的项目已收款金额统计', sortable: true, align: 'right', formatter: true, optional: true },
        { prop: 'repaymentAmount', label: '今年项目回款金额', width: '130', tooltip: '项目收入中的已收款金额<br/>当前年份并且是当前年份所创建的项目已收款金额统计', sortable: true, align: 'right', formatter: true, optional: true },
        { prop: 'proceedsAmountAll', label: '回款金额', width: '130', tooltip: '往年项目回款金额 + 今年项目回款金额', sortable: true, align: 'right', formatter: true },
        
        // 成本信息（可选）
        { prop: 'laborCost', label: '人工成本', width: '130', tooltip: '项目人员成本合计，<br/>截至上周的累计人工', sortable: true, align: 'right', formatter: true, optional: true },
        { prop: 'otherCost', label: '其他成本', width: '130', tooltip: '项目产生的项目费用、外协生产费用、设备折旧费用合计', sortable: true, align: 'right', formatter: true, optional: true },
        { prop: 'totalCost', label: '总成本', width: '130', tooltip: '人员成本+其他成本', sortable: true, align: 'right', formatter: true }
      ]

      // 返回固定列和被选中的可选列
      return allColumns.filter(col => !col.optional || this.checkedColumns.includes(col.prop))
    }
  },
  created() {
    this.getType()
    // 初始化选中所有列
    this.checkedColumns = this.columnGroups
      .flatMap(group => group.columns)
      .map(col => col.prop)
      
    // 从localStorage读取用户的列显示偏好
    const savedColumns = localStorage.getItem('projectOverview-columns')
    if (savedColumns) {
      this.checkedColumns = JSON.parse(savedColumns)
      this.updateGroupCheckStatus()
    }
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  updated() {
    this.$nextTick(() => {
      if (this.$refs.OverView) {
        this.$refs.OverView.doLayout()
      }
    })
  },
  methods: {
    init(val) {
      this.loading = true
      this.$api.statistics
        .queryProjectCountData(`date=${val}`)
        .then(res => {
          // 项目数量统计
          this.overviewList = res.data?.projectDataView?.projectDataVoList || []
          this.overviewList.forEach(v => {
            v.proceedsAmountAll = v.repaymentAmountHist + v.repaymentAmount
            v.invoiceAmountAll = v.invoiceAmountHist + v.invoiceAmount
          })
          // this.contractAmount = res.data?.projectDataView?.projectPaymentAmount || 0
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    onOverViewChange(obj) {
      this.overviewList = obj?.projectDataVoList || []
      this.overviewList.forEach(v => {
        v.proceedsAmountAll = v.repaymentAmountHist + v.repaymentAmount
        v.invoiceAmountAll = v.invoiceAmountHist + v.invoiceAmount
      })
      // this.contractAmount = obj?.projectPaymentAmount || 0
    },
    onClickFn(row) {
      const obj = {
        statisticsYear: this.sectionInfo.overviewYear,
        deptId: row.hostDepartmentId || null,
        deptIdArr: row.deptIdList || [],
      }
      this.$router.push({
        name: 'XMJSCXMTJ',
        params: { data: obj },
      })
    },

    // 表格合计
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index > 2 && index != 16) {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        } else {
          sums[index] = '--'
        }
      })

      return sums
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.overviewYear = [dictVal]
          this.init(dictVal)
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.dictData.deptList = res.data

          function flattenDepartments(arr) {
            const result = []
            // 递归函数：处理当前部门及其子部门
            function processDepartment(dept) {
              // 创建当前部门的副本，并添加到结果列表
              result.push({
                id: dept.id,
                dictName: dept.deptName,
                dictVal: dept.id,
              })
              // 递归处理所有子部门
              if (dept.children && dept.children.length > 0) {
                dept.children.forEach(child => processDepartment(child))
              }
            }
            // 从根部门开始遍历
            arr.forEach(dept => processDepartment(dept))

            return result
          }
          this.dictData.deptAllList = flattenDepartments(res.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleColumnChange(value) {
      this.updateGroupCheckStatus()
      localStorage.setItem('projectOverview-columns', JSON.stringify(value))
    },
    handleCheckAllChange(group) {
      const groupColumns = group.columns.map(col => col.prop)
      if (group.checkAll) {
        // 选中该组所有列
        this.checkedColumns = [...new Set([...this.checkedColumns, ...groupColumns])]
      } else {
        // 取消选中该组所有列
        this.checkedColumns = this.checkedColumns.filter(col => !groupColumns.includes(col))
      }
      this.handleColumnChange(this.checkedColumns)
    },
    
    // 更新组的选中状态
    updateGroupCheckStatus() {
      this.columnGroups.forEach(group => {
        const groupColumns = group.columns.map(col => col.prop)
        const checkedCount = groupColumns.filter(col => this.checkedColumns.includes(col)).length
        
        group.checkAll = checkedCount === group.columns.length
        group.indeterminate = checkedCount > 0 && checkedCount < group.columns.length
      })
    }
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.text_Details_Bgc {
  //详情
  background-color: #409eff;
  color: #fff;
  padding: 5px 5px;
}
.el-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 400px;
  overflow-y: auto;
}
.column-groups {
  .column-group {
    margin-bottom: 16px;
    
    .group-title {
      margin-bottom: 8px;
      font-weight: bold;
    }
    
    .group-items {
      padding-left: 24px;
      
      .el-checkbox {
        display: block;
        margin-bottom: 8px;
      }
    }
  }
}

.el-checkbox-group {
  display: flex;
  flex-direction: column;
  
  .el-checkbox {
    margin-left: 0;
    margin-bottom: 8px;
  }
}
</style>
