<template>
  <div v-loading="loading" id="business_overview">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">商务数据总览</span
        ><span>
          <span class="statistics">新增商务数：{{ newBusinessCount }}</span>
          <span class="statistics">已创建项目数：{{ projectCount }}</span>
          <span class="statistics"> 合同签约数：{{ contractSigningCount }}</span>
        </span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'BusinessOverview'"
            :introductionType="'staffId'"
            :yearIsShow="true"
            :multiple="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.overviewPrincipal"
            v-model="sectionInfo.overviewYear"
            @change="onOverviewChange"
            @onChangeLoading="overviewLoading = $event"
        /></span>
        <span class="title_right" v-if="isBoss"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :type="'BusinessOverview'"
            :introductionType="'staffId'"
            :title="titlePrincipal"
            :list="userList"
            :yearValue="sectionInfo.overviewYear"
            v-model="sectionInfo.overviewPrincipal"
            @change="onOverviewChange"
            @onChangeLoading="overviewLoading = $event"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onBusinessOverviewImg"
          >数据来源说明</el-button
        >
        <el-button
          v-if="isBoss"
          type="primary"
          icon="el-icon-printer"
          class="title_right"
          :loading="exportingLoading"
          size="small"
          @click="onBusinessOverviewExporting"
          >导出</el-button
        >
        <el-radio-group
          v-if="isBoss"
          v-model="currentView"
          size="small"
          class="title_right"
          @change="handleViewChange"
        >
          <el-radio-button v-for="view in presetViews" :key="view.name" :label="view.name">
            {{ view.name }}
          </el-radio-button>
        </el-radio-group>
        <el-popover
          placement="bottom"
          width="300"
          trigger="click"
          class="title_right"
          v-if="isBoss"
        >
          <div class="column-setting-container">
            <div class="column-groups">
              <div v-for="group in columnGroups" :key="group.name" class="column-group">
                <div class="group-title">
                  <el-checkbox
                    :indeterminate="group.indeterminate"
                    v-model="group.checkAll"
                    @change="handleCheckAllChange(group)"
                  >
                    {{ group.label }}
                  </el-checkbox>
                </div>
                <div class="group-items">
                  <el-checkbox-group v-model="checkedColumns" @change="handleColumnChange">
                    <el-checkbox v-for="col in group.columns" :key="col.prop" :label="col.prop">
                      {{ col.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="column-setting-footer">
              <el-button size="small" @click="resetColumns">重置</el-button>
            </div>
          </div>
          <el-button slot="reference" size="small">
            <i class="el-icon-setting"></i> 列设置
          </el-button>
        </el-popover>
      </div>
      <template v-if="isBoss">
        <el-table
          ref="businessTable"
          :key="tableKey"
          :summary-method="getSummaries"
          v-loading="overviewLoading"
          show-summary
          border
          :height="tableHeight"
          style="width: 100%"
          :data="overviewList"
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            fixed="left"
            width="70"
          ></el-table-column>
          <template v-for="col in visibleColumns">
            <el-table-column :key="col.prop" v-bind="col">
              <template slot="header" slot-scope="{}">
                <span>{{ col.label }}</span>
                <el-tooltip v-if="col.tooltip" class="item" effect="dark" placement="top">
                  <i class="el-icon-question" style="font-size: 16px" />
                  <div slot="content">{{ col.tooltip }}</div>
                </el-tooltip>
              </template>

              <template slot-scope="scope">
                <template v-if="col.percentage">
                  {{ Number(scope.row[col.prop] * 100).toFixed(2) }}%
                </template>
                <template v-else-if="col.isAmount">
                  {{ scope.row[col.prop] | applyAmount }}
                </template>
                <template v-else>
                  {{ scope.row[col.prop] }}
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column label="操作" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="onVivewUser(scope.row)"
                class="text_Details_Bgc"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-else>
        <div class="business_individual" v-for="(item, index) in overviewList" :key="item.name">
          <div class="business_individual_title">
            {{ item.name }}
          </div>
          <template v-if="index <= 5">
            <div class="business_individual_center">{{ item.value | thousands }}</div>
          </template>
          <template v-else-if="index != 7 && index != 9 && index != 10 && index < 14">
            <div class="business_individual_center">{{ item.value | applyAmount }}</div>
          </template>
          <template v-else>
            <div class="business_individual_center">{{ Number(item.value * 100).toFixed(2) }}%</div>
          </template>
        </div>
      </template>
    </el-card>
    <el-card class="box-card" style="margin-top: 8px" v-if="!isBoss">
      <div slot="header" class="clearfix">
        <span class="title_fw">商务费用分析</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'BusinessResponse'"
            :introductionType="'staffId'"
            :twoIntroductionType="'businessId'"
            :yearIsShow="true"
            :multiple="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.responsePrincipal"
            :threeValue="sectionInfo.businessResponseId"
            v-model="sectionInfo.responseYear"
            @change="onBusinessResponseChange"
            @onChangeLoading="responseLoading = $event"
        /></span>

        <span class="title_right"
          ><SearchUserDate
            :title="'商务名称'"
            :width="'350'"
            :type="'BusinessResponse'"
            :introductionType="'staffId'"
            :twoIntroductionType="'businessId'"
            :threeIsShow="true"
            :clearable="true"
            :filterable="true"
            :list="businessNameList"
            :yearValue="sectionInfo.responseYear"
            :twoValue="sectionInfo.responsePrincipal"
            :threeValue="sectionInfo.businessResponseId"
            v-model="sectionInfo.businessResponseId"
            @change="onBusinessResponseChange"
            @onChangeLoading="responseLoading = $event"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onBusinessResponseImg"
          >数据来源说明</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-printer"
          class="title_right"
          :loading="exportingLoading"
          size="small"
          @click="onBusinessResponseExporting"
          >导出</el-button
        >
      </div>

      <div class="colorExplain">
        状态颜色说明：
        <span>已创建项目 <i style="background: #d3dffc"></i></span>
        <span>商务终结 <i style="background: #e08477c0"></i></span>
      </div>
      <el-table
        :summary-method="getIndividual"
        v-loading="responseLoading"
        show-summary
        height="calc(100vh - 430px)"
        style="width: 100%"
        ref="BusinessResponse"
        :row-class-name="tableRowClassName"
        border
        :data="responseList"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="businessName"
          label="商务名称"
          align="center"
          fixed="left"
          min-width="240"
        ></el-table-column>
        <el-table-column
          v-if="isBoss && !sectionInfo.responsePrincipal"
          prop="principalUserName"
          label="商务负责人"
          align="center"
          fixed="left"
          min-width="100"
        ></el-table-column>
        <el-table-column prop="status" min-width="100" label="商务状态" sortable align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">暂存</span>
            <span v-if="scope.row.status == 1 && scope.row.type == 'XSH'">商务部高管审核中</span>
            <span v-if="scope.row.status == 1 && scope.row.type == 'ZGH'">董事长审核中</span>
            <span v-if="scope.row.status == 3 && scope.row.type == 'XSH'">商务部高管审核通过</span>
            <span v-if="scope.row.status == 3 && scope.row.type == 'ZGH'">董事长审核通过</span>
            <span v-if="scope.row.status == 5">已移交计划经营部</span>
            <span v-if="scope.row.status == 6">已创建项目</span>
            <span v-if="scope.row.status == 9">商务暂停</span>
            <span v-if="scope.row.status == 10">商务终结</span>
            <span v-if="scope.row.status == 21 && scope.row.type == 'XSH'">高管审核不通过</span>
            <span v-if="scope.row.status == 21 && scope.row.type == 'ZGH'">董事长审核不通过</span>
          </template></el-table-column
        >
        <el-table-column
          prop="isContractSigned"
          min-width="90"
          label="是否签署合同"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.isContractSigned == 1 ? '已签署' : '未签署' }}
          </template></el-table-column
        >
        <el-table-column
          prop="estimatedContractAmount"
          min-width="130"
          label="预计合同金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>预计合同金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务详情的预计合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.estimatedContractAmount | applyAmount }}
          </template></el-table-column
        >
        <el-table-column
          prop="actualContractAmount"
          min-width="130"
          label="我方合同金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>我方合同金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务详情的我方合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualContractAmount | applyAmount }}
          </template></el-table-column
        >
        <el-table-column prop="amountReceived" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>回款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所创建的项目，项目收入中的已收款金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.amountReceived | applyAmount }}
          </template></el-table-column
        >

        <el-table-column prop="returnRatio" min-width="100" label="回款比例" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>回款比例</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>回款金额 / 我方合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ Number(scope.row.returnRatio * 100).toFixed(2) }}%
          </template>
        </el-table-column>
        <el-table-column prop="estimatedCostUpperLimit" min-width="120" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>预计商务费用上限</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务负责人的商务记账上限</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.estimatedCostUpperLimit | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="businessCost" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>商务费用</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所产生的报销费用、外协费用合计</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.businessCost | applyAmount }}
          </template>
        </el-table-column>

        <el-table-column
          prop="estimatedBusinessEffectiveness"
          min-width="130"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>预估商务绩效金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务结算中的实际绩效金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.estimatedBusinessEffectiveness | applyAmount }}
          </template>
        </el-table-column>

        <el-table-column
          prop="salesExpenseRatio"
          min-width="110"
          label="费销比"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>费销比</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务费用 / 我方合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ Number(scope.row.salesExpenseRatio * 100).toFixed(2) }}%
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog width="80%" title="" :visible.sync="costIsShow">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="title_fw">商务费用分析</span>
          <span class="title_right"
            ><SearchUserDate
              :title="titleYear"
              :type="'BusinessResponse'"
              :introductionType="'staffId'"
              :twoIntroductionType="'businessId'"
              :yearIsShow="true"
              :multiple="true"
              :list="dictData.yearList"
              :twoValue="sectionInfo.responsePrincipal"
              :threeValue="sectionInfo.businessResponseId"
              v-model="sectionInfo.responseYear"
              @change="onBusinessResponseChange"
              @onChangeLoading="responseLoading = $event"
          /></span>
          <span class="title_right"
            ><SearchUserDate
              :clearable="true"
              :filterable="true"
              :type="'BusinessResponse'"
              :introductionType="'staffId'"
              :twoIntroductionType="'businessId'"
              :title="titlePrincipal"
              :list="userList"
              :yearValue="sectionInfo.responseYear"
              :twoValue="sectionInfo.responsePrincipal"
              :threeValue="sectionInfo.businessResponseId"
              v-model="sectionInfo.responsePrincipal"
              @change="onBusinessResponseChange"
              @onChangeLoading="responseLoading = $event"
          /></span>
          <span class="title_right"
            ><SearchUserDate
              :title="'商务名称'"
              :width="'350'"
              :type="'BusinessResponse'"
              :introductionType="'staffId'"
              :twoIntroductionType="'businessId'"
              :threeIsShow="true"
              :clearable="true"
              :filterable="true"
              :list="businessNameList"
              :yearValue="sectionInfo.responseYear"
              :twoValue="sectionInfo.responsePrincipal"
              :threeValue="sectionInfo.businessResponseId"
              v-model="sectionInfo.businessResponseId"
              @change="onBusinessResponseChange"
              @onChangeLoading="responseLoading = $event"
          /></span>
          <el-button class="title_right" type="primary" size="small" @click="onBusinessResponseImg"
            >数据来源说明</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-printer"
            class="title_right"
            :loading="exportingLoading"
            size="small"
            @click="onBusinessResponseExporting"
            >导出</el-button
          >
        </div>
        <div class="colorExplain">
          状态颜色说明：
          <span>已创建项目 <i style="background: #d3dffc"></i></span>
          <span>商务终结 <i style="background: #e08477c0"></i></span>
        </div>
        <el-table
          :summary-method="getIndividual"
          show-summary
          v-loading="responseLoading"
          height="450px"
          ref="BusinessResponse"
          :row-class-name="tableRowClassName"
          border
          :key="Math.random()"
          :data="responseList"
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            fixed="left"
            width="70"
          ></el-table-column>
          <el-table-column
            prop="businessName"
            label="商务名称"
            align="center"
            fixed="left"
            min-width="240"
          ></el-table-column>
          <el-table-column
            v-if="isBoss && !sectionInfo.responsePrincipal"
            prop="principalUserName"
            label="商务负责人"
            align="center"
            fixed="left"
            min-width="100"
          ></el-table-column>
          <el-table-column prop="status" min-width="100" label="商务状态" sortable align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0">暂存</span>
              <span v-if="scope.row.status == 1 && scope.row.type == 'XSH'">商务部高管审核中</span>
              <span v-if="scope.row.status == 1 && scope.row.type == 'ZGH'">董事长审核中</span>
              <span v-if="scope.row.status == 3 && scope.row.type == 'XSH'"
                >商务部高管审核通过</span
              >
              <span v-if="scope.row.status == 3 && scope.row.type == 'ZGH'">董事长审核通过</span>
              <span v-if="scope.row.status == 5">已移交计划经营部</span>
              <span v-if="scope.row.status == 6">已创建项目</span>
              <span v-if="scope.row.status == 9">商务暂停</span>
              <span v-if="scope.row.status == 10">商务终结</span>
              <span v-if="scope.row.status == 21 && scope.row.type == 'XSH'">高管审核不通过</span>
              <span v-if="scope.row.status == 21 && scope.row.type == 'ZGH'">董事长审核不通过</span>
            </template></el-table-column
          >
          <el-table-column
            prop="isContractSigned"
            min-width="90"
            label="是否签署合同"
            sortable
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.isContractSigned == 1 ? '已签署' : '未签署' }}
            </template></el-table-column
          >
          <el-table-column
            prop="estimatedContractAmount"
            min-width="130"
            label="预计合同金额"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>预计合同金额</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务详情的预计合同金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.estimatedContractAmount | applyAmount }}
            </template></el-table-column
          >
          <el-table-column
            prop="actualContractAmount"
            min-width="130"
            label="我方合同金额"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>我方合同金额</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务详情的我方合同金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.actualContractAmount | applyAmount }}
            </template></el-table-column
          >
          <el-table-column prop="amountReceived" min-width="130" sortable align="right">
            <template slot-scope="{}" slot="header">
              <span>回款金额</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务所创建的项目，项目收入中的已收款金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.amountReceived | applyAmount }}
            </template></el-table-column
          >

          <el-table-column
            prop="returnRatio"
            min-width="100"
            label="回款比例"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>回款比例</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>回款金额 / 我方合同金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ Number(scope.row.returnRatio * 100).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column prop="estimatedCostUpperLimit" min-width="120" sortable align="right">
            <template slot-scope="{}" slot="header">
              <span>预计商务费用上限</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务负责人的商务记账上限</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.estimatedCostUpperLimit | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column prop="businessCost" min-width="130" sortable align="right">
            <template slot-scope="{}" slot="header">
              <span>商务费用</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务所产生的报销费用、外协费用合计</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.businessCost | applyAmount }}
            </template>
          </el-table-column>

          <el-table-column
            prop="estimatedBusinessEffectiveness"
            min-width="130"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>预估商务绩效金额</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务结算中的实际绩效金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ scope.row.estimatedBusinessEffectiveness | applyAmount }}
            </template>
          </el-table-column>

          <el-table-column
            prop="salesExpenseRatio"
            min-width="110"
            label="费销比"
            sortable
            align="right"
          >
            <template slot-scope="{}" slot="header">
              <span>费销比</span>
              <el-tooltip class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px"></i>
                <div slot="content">
                  <div>商务费用 / 我方合同金额</div>
                </div>
              </el-tooltip></template
            >
            <template slot-scope="scope">
              {{ Number(scope.row.salesExpenseRatio * 100).toFixed(2) }}%
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-dialog>
    <el-dialog width="80%" title="" :visible.sync="specialBusiness">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="title_fw">商务费用分析</span>
          <span class="title_right"
            ><SearchUserDate
              :title="titleYear"
              :type="'SpecialBusiness'"
              :yearIsShow="true"
              :multiple="true"
              :list="dictData.yearList"
              v-model="sectionInfo.specialBusinessYear"
              @change="onSpecialBusinessChange"
              @onChangeLoading="responseLoading = $event"
          /></span>
          <el-button
            type="primary"
            icon="el-icon-printer"
            class="title_right"
            :loading="exportingLoading"
            size="small"
            @click="onSpecialBusinessExporting"
            >导出</el-button
          >
        </div>
        <el-table
          :summary-method="getSpecialBusiness"
          v-loading="responseLoading"
          show-summary
          height="450px"
          ref="SpecialBusiness"
          border
          :data="specialBusinessList"
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            fixed="left"
            width="70"
          ></el-table-column>
          <el-table-column
            align="center"
            min-width="160"
            prop="expenseNumber"
            label="单据编号"
          ></el-table-column>
          <el-table-column
            align="center"
            sortable
            min-width="100"
            prop="applyName"
            label="申请人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="businessName"
            sortable
            min-width="240"
            :show-overflow-tooltip="false"
            label="商务名称"
          ></el-table-column>
          <el-table-column
            align="right"
            min-width="110"
            sortable
            prop="businessCost"
            label="申请金额"
          >
            <template slot-scope="scope">
              {{ scope.row.businessCost | applyAmount }}
            </template></el-table-column
          >

          <el-table-column
            align="center"
            min-width="110"
            sortable
            prop="businessDate"
            label="申请时间"
          >
            <template slot-scope="scope">
              {{ scope.row.businessDate | dateFormat }}
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-dialog>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          v-show="imgUrl == '商务统计-数据总览'"
          src="@/assets/商务统计-数据总览.png"
          alt="数据来源说明"
          width="100%"
        />
        <img
          v-show="imgUrl == '商务统计-商务费用分析'"
          src="@/assets/商务统计-商务费用分析.png"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 首先定义列配置常量
const COLUMN_DEFINITIONS = {
  // 基本信息列
  basic: [
    {
      prop: 'principalUserName',
      label: '商务负责人',
      width: '100',
      fixed: 'left',
      align: 'center',
    },
    {
      prop: 'businessCount',
      label: '商务数量',
      width: '90',
      sortable: true,
      align: 'right',
      tooltip: '新增的商务数量',
    },
    {
      prop: 'projectCount',
      label: '已创建项目的商务数量',
      width: '90',
      sortable: true,
      align: 'right',
    },
    {
      prop: 'businessEndCount',
      label: '商务终结数',
      width: '90',
      sortable: true,
      tooltip: '新建商务，最后终结的商务数量',
      align: 'right',
    },
    {
      prop: 'contractSignedCount',
      label: '签约合同数',
      width: '90',
      sortable: true,
      align: 'right',
      tooltip: '新建商务，已创建项目并且关联合同的商务数量',
    },
  ],
  // 金额信息列
  amount: [
    {
      prop: 'saleAmount',
      label: '我方合同金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务详情里的我方合同金额',
    },
    {
      prop: 'invoiceAmountHist',
      label: '往年商务开票金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所创建的项目，项目收入中的已开票金额，往年所创建的商务开票统计',
    },
    {
      prop: 'invoiceAmount',
      label: '今年商务开票金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip:
        '商务所创建的项目，项目收入中的已开票金额，当前年份并且是当前年份所创建的商务开票统计',
    },
    {
      prop: 'invoiceAmountAll',
      label: '开票金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '往年商务开票金额 + 今年商务开票金额',
    },
    {
      prop: 'invoiceRate',
      label: '开票占合同比例',
      width: '100',
      sortable: true,
      align: 'right',
      percentage: true,
      tooltip: '开票金额 / 我方合同金额',
    },
  ],
  // 回款信息列
  payment: [
    {
      prop: 'proceedsAmountHist',
      label: '往年商务回款金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所创建的项目项目收入中的已收款金额，往年所创建的商务已收款金额统计',
    },
    {
      prop: 'repaymentAmount',
      label: '今年商务回款金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip:
        '商务所创建的项目，项目收入中的已收款金额，当前年份并且是当前年份所创建的商务已收款金额统计',
    },
    {
      prop: 'proceedsAmountAll',
      label: '回款金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '往年商务回款金额 + 今年商务回款金额',
    },
    {
      prop: 'repaymentInvoiceRate',
      label: '回款占开票比例',
      width: '100',
      sortable: true,
      align: 'right',
      percentage: true,
      tooltip: '回款金额 / 开票金额',
    },
    {
      prop: 'saleRepaymentRate',
      label: '回款比例',
      width: '100',
      sortable: true,
      align: 'right',
      percentage: true,
      tooltip: '回款金额 / 我方合同金额',
    },
  ],
  // 费用信息列
  expense: [
    {
      prop: 'businessExpenseTotal',
      label: '商务费用总和',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所产生的报销费用、外协费用合计',
    },
    {
      prop: 'successBusinessExpense',
      label: '成功商务费用',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '指非商务终结的商务所产生的报销费用、外协费用合计',
    },
    {
      prop: 'failBusinessExpense',
      label: '不成功商务费用',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '指商务终结的商务所产生的报销费用、外协费用合计',
    },
    {
      prop: 'expenseSaleRate',
      label: '费销比',
      width: '100',
      sortable: true,
      align: 'right',
      percentage: true,
      tooltip: '成功商务费用 / 我方合同金额',
    },
    {
      prop: 'businessConversionRate',
      label: '商务转化率',
      width: '100',
      sortable: true,
      align: 'right',
      percentage: true,
      tooltip: '已创建项目的商务数量 / 商务数量',
    },
  ],
}

import { mapState } from 'vuex'
export default {
  components: {
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      userList: [], // 用户数组
      dictData: {
        yearList: [],
      },
      overviewList: [], // 数据总览
      responseList: [], // 商务费用分析
      sectionInfo: {
        // 条件筛选
        overviewYear: [], // 年份
        responseYear: [],
        specialBusinessYear: [],
        // 负责人
        overviewPrincipal: '',
        responsePrincipal: '',
        businessResponseId: null, // 商务名称
      },
      isBoss: false, // 是否BOSS
      businessNameList: [], // 商务名称
      titleYear: '统计时间',
      titlePrincipal: '商务负责人',
      isDialog: false,
      imgUrl: '',
      costIsShow: false,
      specialBusiness: false,
      specialBusinessList: [],
      columnGroups: [
        {
          name: 'basic',
          label: '基本信息',
          columns: COLUMN_DEFINITIONS.basic.slice(1), // 不包含商务负责人
        },
        {
          name: 'amount',
          label: '金额信息',
          columns: COLUMN_DEFINITIONS.amount,
        },
        {
          name: 'payment',
          label: '回款信息',
          columns: COLUMN_DEFINITIONS.payment,
        },
        {
          name: 'expense',
          label: '费用信息',
          columns: COLUMN_DEFINITIONS.expense,
        },
      ],
      checkedColumns: [], // 默认显示的列
      currentView: '全部',
      presetViews: [
        {
          name: '金额信息',
          getColumns: () => [...COLUMN_DEFINITIONS.basic, ...COLUMN_DEFINITIONS.amount],
        },
        {
          name: '回款信息',
          getColumns: () => [...COLUMN_DEFINITIONS.basic, ...COLUMN_DEFINITIONS.payment],
        },
        {
          name: '费用信息',
          getColumns: () => [...COLUMN_DEFINITIONS.basic, ...COLUMN_DEFINITIONS.expense],
        },
        {
          name: '全部',
          getColumns: () => Object.values(COLUMN_DEFINITIONS).flat(),
        },
      ],
      tableHeight: 'calc(100vh - 190px)', // 固定表格高度
      tableKey: 0, // 用于强制刷新表格
      responseLoading: false,
      overviewLoading: false,
      exportingLoading: false,
    }
  },
  provide() {
    return {}
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    newBusinessCount() {
      let num = 0
      this.overviewList.forEach(v => {
        if (this.isBoss) {
          num += v.businessCount
        } else if (v.name == '商务数') {
          num += v.value
        }
      })
      return num
    },
    projectCount() {
      let num = 0
      this.overviewList.forEach(v => {
        if (this.isBoss) {
          num += v.projectCount
        } else if (v.name == '已创建项目数') {
          num += v.value
        }
      })
      return num
    },
    contractSigningCount() {
      let num = 0
      this.overviewList.forEach(v => {
        if (this.isBoss) {
          num += v.contractSignedCount
        } else if (v.name == '签约合同数') {
          num += v.value
        }
      })
      return num
    },
    visibleColumns() {
      const currentView = this.presetViews.find(v => v.name === this.currentView)
      if (!currentView) return []

      if (this.currentView === '全部') {
        // 商务负责人列始终显示
        const alwaysShowColumns = COLUMN_DEFINITIONS.basic.slice(0, 1)

        // 其他基本信息列根据选择显示
        const otherBasicColumns = COLUMN_DEFINITIONS.basic
          .slice(1)
          .filter(col => this.checkedColumns.includes(col.prop))

        // 构建最终的列数组
        const resultColumns = [...alwaysShowColumns, ...otherBasicColumns]

        // 添加其他组的列
        const otherGroups = ['amount', 'payment', 'expense']
        otherGroups.forEach(groupName => {
          const groupColumns = COLUMN_DEFINITIONS[groupName].filter(col =>
            this.checkedColumns.includes(col.prop)
          )
          resultColumns.push(...groupColumns)
        })

        return resultColumns
      }

      return currentView.getColumns()
    },
  },
  created() {
    this.getType()
    // 初始化选中所有列
    this.checkedColumns = this.columnGroups.flatMap(group => group.columns).map(col => col.prop)

    // 从localStorage读取用户的列显示偏好
    const savedColumns = localStorage.getItem('businessStatistics-columns')
    if (savedColumns) {
      this.checkedColumns = JSON.parse(savedColumns)
      this.updateGroupCheckStatus()
    }
  },
  filters: {
    thousands(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {
    costIsShow: {
      handler: function (val) {
        if (!val) {
          this.sectionInfo.responsePrincipal = ''
          this.sectionInfo.businessResponseId = ''
        }
      },
    },
  },
  mounted() {},
  updated() {
    this.$nextTick(() => {
      if (this.$refs.BusinessResponse) {
        this.$refs.BusinessResponse.doLayout()
      }
      if (this.$refs.SpecialBusiness) {
        this.$refs.SpecialBusiness.doLayout()
      }
    })
  },
  methods: {
    // 前期面上沟通
    async onSpecialBusinessExporting() {
      this.exportingLoading = true
      const obj = { date: this.sectionInfo.specialBusinessYear.join(', ') }

      try {
        const res = await this.$api.statistics.exportBusinessSpecialCost(obj)
        const fileName = '商务前期面上沟通.xlsx'
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        this.downloadFile(blob, fileName)
        this.$message.success('导出成功')
      } catch (err) {
        console.error('导出失败:', err)
        this.$message.error('导出失败，请重试')
      } finally {
        this.exportingLoading = false
      }
    },
    // 费用分析
    async onBusinessResponseExporting() {
      this.exportingLoading = true
      const obj = { date: this.sectionInfo.responseYear.join(', ') }
      if (this.sectionInfo.responsePrincipal) {
        obj.staffId = this.sectionInfo.responsePrincipal
      }
      if (this.sectionInfo.businessResponseId) {
        obj.businessId = this.sectionInfo.businessResponseId
      }
      try {
        const res = await this.$api.statistics.exportBusinessCostAnalysisSummary(obj)
        const fileName = '商务费用分析.xlsx'
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        this.downloadFile(blob, fileName)
        this.$message.success('导出成功')
      } catch (err) {
        console.error('导出失败:', err)
        this.$message.error('导出失败，请重试')
      } finally {
        this.exportingLoading = false
      }
    },
    // 总览
    async onBusinessOverviewExporting() {
      this.exportingLoading = true
      const obj = { date: this.sectionInfo.overviewYear.join(', ') }
      if (this.sectionInfo.overviewPrincipal) {
        obj.staffId = this.sectionInfo.overviewPrincipal
      }
      try {
        const res = await this.$api.statistics.exportBusinessDataOverview(obj)
        const fileName = '商务数据总览.xlsx'
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        this.downloadFile(blob, fileName)
        this.$message.success('导出成功')
      } catch (err) {
        console.error('导出失败:', err)
        this.$message.error('导出失败，请重试')
      } finally {
        this.exportingLoading = false
      }
    },
    // 提取出下载文件的逻辑
    downloadFile(blob, fileName) {
      if (navigator.msSaveBlob) {
        // 针对 IE
        navigator.msSaveBlob(blob, fileName)
      } else {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
      }
    },
    async init(val) {
      this.loading = true

      // 请求数据的封装函数
      const fetchData = async () => {
        const overviewRes = await this.$api.statistics.businessDataOverview(`date=${val}`)
        const costRes = await this.$api.statistics.queryBusinessCostAnalysisSummary(`date=${val}`)
        return [overviewRes, costRes]
      }

      // 错误处理
      const handleError = err => {
        console.error(err)
        this.loading = false
      }

      try {
        let res
        if (this.userInfo.isBoss) {
          const businessDataOverview = await this.$api.statistics.businessDataOverview(
            `date=${val}`
          )
          this.isBoss = businessDataOverview.data.isBoss
          this.overviewList = businessDataOverview.data.businessDataOverviewResponseList || []
          this.updateProceedsAndInvoice(this.overviewList)
        } else {
          res = await fetchData()
          this.isBoss = res[0].data.isBoss
          this.responseList = res[1].data.businessCostAnalysisResponseList || []

          if (this.isBoss) {
            this.overviewList = res[0].data.businessDataOverviewResponseList || []
            this.updateProceedsAndInvoice(this.overviewList)
          } else {
            const businessDataOverviewResponseList =
              res[0].data.businessDataOverviewResponseList || []
            this.overviewList = this.mapBusinessDataOverview(businessDataOverviewResponseList)
          }
        }
      } catch (err) {
        handleError(err)
      } finally {
        this.loading = false
      }
    },

    // 更新proceedsAmount和invoiceAmount
    updateProceedsAndInvoice(list) {
      list.forEach(v => {
        v.proceedsAmountAll = v.proceedsAmountHist + v.repaymentAmount
        v.invoiceAmountAll = v.invoiceAmountHist + v.invoiceAmount
      })
    },

    // 映射并排序业务数据
    mapBusinessDataOverview(data) {
      const keyMappings = {
        businessCount: '商务数量',
        projectCount: '已创建项目的商务数量',
        successProjectCount: '成功实施项目数',
        businessEndCount: '商务终结数',
        contractSignedCount: '签约合同数',
        saleAmount: '我方合同金额',
        invoiceAmount: '开票金额',
        invoiceRate: '开票占合同比例',
        repaymentAmount: '回款金额',
        repaymentInvoiceRate: '回款占开票比例',
        saleRepaymentRate: '回款比例',
        businessExpenseTotal: '商务费用总和',
        successBusinessExpense: '成功商务费用',
        failBusinessExpense: '不成功商务费用',
        expenseSaleRate: '费销比',
        businessConversionRate: '商务转化率',
      }

      const newArr = []
      if (data.length === 0) {
        // 如果没有数据，直接推入0值
        Object.values(keyMappings).forEach(name => {
          newArr.push({ name, value: 0 })
        })
      } else {
        // 映射数据
        data.forEach(obj => {
          Object.keys(obj).forEach(key => {
            if (keyMappings[key]) {
              newArr.push({ name: keyMappings[key], value: obj[key] })
            }
          })
        })
      }

      // 按照keyMappings顺序排序
      return Object.keys(keyMappings)
        .map(key => newArr.find(item => item.name === keyMappings[key]))
        .filter(Boolean) // 过滤掉未找到的项
    },
    async onVivewUser(row) {
      this.responseLoading = true
      if (row.principalUserId === '0') {
        this.specialBusiness = true
        this.sectionInfo.specialBusinessYear = this.sectionInfo.overviewYear
        const res = await this.$api.statistics.businessSpecialCost(
          `date=${this.sectionInfo.specialBusinessYear}`
        )
        this.specialBusinessList = res.data.businessSpecialCostResponses || []
      } else {
        this.costIsShow = true
        this.sectionInfo.responsePrincipal = row.principalUserId
        this.sectionInfo.responseYear = this.sectionInfo.overviewYear
        const res = await this.$api.statistics.queryBusinessCostAnalysisSummary(
          `date=${this.sectionInfo.responseYear}&staffId=${this.sectionInfo.responsePrincipal}`
        )
        this.responseList = res.data.businessCostAnalysisResponseList || []
      }
      this.responseLoading = false
    },
    onBusinessResponseImg() {
      this.imgUrl = '商务统计-商务费用分析'
      this.isDialog = true
    },
    onBusinessOverviewImg() {
      this.imgUrl = '商务统计-数据总览'
      this.isDialog = true
    },
    onOverviewChange(arr) {
      this.overviewList = arr
      this.overviewList.forEach(v => {
        v.proceedsAmountAll = v.proceedsAmountHist + v.repaymentAmount
        v.invoiceAmountAll = v.invoiceAmountHist + v.invoiceAmount
      })
    },
    onBusinessResponseChange(arr) {
      this.responseList = arr
    },
    onSpecialBusinessChange(arr) {
      this.specialBusinessList = arr
    },
    // 表格合计
    getSpecialBusiness(param) {
      const { columns, data } = param
      const sums = []

      // 定义需要计算合计的属性名
      const calculableProps = ['businessCost']

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }

        // 根据属性名判断是否需要计算
        const needCalculate = calculableProps.includes(column.property)

        if (needCalculate) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
          } else {
            sums[index] = ''
          }
        } else {
          sums[index] = '--'
        }
      })

      return sums
    },
    getIndividual(param) {
      const { columns, data } = param
      const sums = []

      // 定义需要计算合计的属性名
      const calculableProps = [
        'estimatedContractAmount',
        'actualContractAmount',
        'amountReceived',
        'estimatedCostUpperLimit',
        'businessCost',
        'estimatedBusinessEffectiveness',
      ]

      // 累加数值的处理逻辑
      const calculateSum = values => {
        const validValues = values.map(Number).filter(value => !isNaN(value))
        if (validValues.length === values.length) {
          return validValues.reduce((prev, curr) => prev + curr, 0)
        }
        return ''
      }

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }

        // 根据属性名判断是否需要计算
        const needCalculate = calculableProps.includes(column.property)

        if (needCalculate) {
          const values = data.map(item => item[column.property])
          const sum = calculateSum(values)

          if (sum === '') {
            sums[index] = ''
          } else {
            sums[index] = sum.toFixed(2).toLocaleString()
          }
        } else {
          sums[index] = '--'
        }
      })
      return sums
    },

    getSummaries(param) {
      const { columns, data } = param
      const sums = []

      // 定义需要计算合计的属性名
      const calculableProps = [
        'businessCount',
        'projectCount',
        'successProjectCount',
        'businessEndCount',
        'contractSignedCount',
        'saleAmount',
        'invoiceAmountHist',
        'invoiceAmount',
        'invoiceAmountAll',
        'proceedsAmountHist',
        'repaymentAmount',
        'proceedsAmountAll',
        'businessExpenseTotal',
        'successBusinessExpense',
        'failBusinessExpense',
      ]

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }

        // 根据属性名判断是否需要计算
        const needCalculate = calculableProps.includes(column.property)

        if (needCalculate) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              }
              return prev
            }, 0)

            // 格式化数字
            if (typeof sums[index] === 'number') {
              sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
            }
          } else {
            sums[index] = ''
          }
        } else {
          sums[index] = '--'
        }
      })

      return sums
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.overviewYear = [dictVal]
          this.sectionInfo.responseYear = [dictVal]
          this.init(dictVal)
        })
        .catch(err => {
          console.log(err)
        })
      // 获取商务负责人
      this.$api.businessManage
        .principalUserNameListNoPage()
        .then(res => {
          this.userList = res?.data.map(v => ({
            id: v.id,
            dictName: v.userName,
            dictVal: v.id,
          }))
          this.userList.unshift({
            id: '1',
            dictName: '全部',
            dictVal: '',
          })
        })
        .catch(err => {
          console.log(err)
        })
      // 获取商务名称列表
      this.$api.businessManage
        .getBusinessInfo()
        .then(res => {
          this.businessNameList = res?.data.map(v => ({
            id: v.id,
            dictName: v.businessName,
            dictVal: v.id,
          }))
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleColumnChange(value) {
      this.currentView = '全部'
      this.checkedColumns = value
      this.updateGroupCheckStatus()
      localStorage.setItem('businessStatistics-columns', JSON.stringify(value))

      // 使用 nextTick 确保 DOM 更新后再重新计算表格
      this.$nextTick(() => {
        this.refreshTable()
      })
    },

    handleCheckAllChange(group) {
      const groupColumns = group.columns.map(col => col.prop)
      if (group.checkAll) {
        this.checkedColumns = [...new Set([...this.checkedColumns, ...groupColumns])]
      } else {
        this.checkedColumns = this.checkedColumns.filter(col => !groupColumns.includes(col))
      }
      this.handleColumnChange(this.checkedColumns)
    },

    updateGroupCheckStatus() {
      this.columnGroups.forEach(group => {
        const groupColumns = group.columns.map(col => col.prop)
        const checkedCount = groupColumns.filter(col => this.checkedColumns.includes(col)).length
        group.checkAll = checkedCount === group.columns.length
        group.indeterminate = checkedCount > 0 && checkedCount < group.columns.length
      })
    },

    handleViewChange(viewName) {
      if (viewName === '全部') {
        const savedColumns = localStorage.getItem('businessStatistics-columns')
        this.checkedColumns = savedColumns
          ? JSON.parse(savedColumns)
          : this.columnGroups.flatMap(group => group.columns.map(col => col.prop))
      }
      this.updateGroupCheckStatus()

      // 使用 nextTick 确保 DOM 更新后再重新计算表格
      this.$nextTick(() => {
        this.refreshTable()
      })
    },

    resetColumns() {
      this.currentView = '全部'
      this.checkedColumns = this.getDefaultColumns()
      this.updateGroupCheckStatus()
      localStorage.setItem('businessStatistics-columns', JSON.stringify(this.checkedColumns))

      this.$message({
        type: 'success',
        message: '已重置为默认显示',
      })
    },

    getDefaultColumns() {
      return this.columnGroups.flatMap(group => group.columns).map(col => col.prop)
    },
    // 刷新表格方法
    refreshTable() {
      if (this.$refs.businessTable) {
        this.$refs.businessTable.doLayout()
      }
    },
    tableRowClassName({ row, index }) {
      if (row.status === 6) {
        return 'estaBlish'
      } else if (row.status === 10) {
        return 'finality'
      }
      return ''
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.business_individual {
  display: inline-block;
  text-align: center;
  width: 160px;
  font-size: 18px;
  .business_individual_title {
    font-size: 15px;
  }
  .business_individual_center {
    line-height: 35px;
    margin-bottom: 10px;
    font-weight: 900;
    color: #5c5b87;
  }
}
.text_Details_Bgc {
  //详情
  background-color: #409eff;
  color: #fff;
  padding: 5px 5px;
}
.statistics {
  font-size: 100%;
  font-weight: 900;
  color: #f53a3a;
  margin-left: 18px;
}
.column-setting-container {
  .column-groups {
    max-height: 400px;
    overflow-y: auto;
  }

  .column-group {
    margin-bottom: 16px;

    .group-title {
      margin-bottom: 8px;
    }

    .group-items {
      padding-left: 24px;

      .el-checkbox {
        display: block;
        margin-left: 0;
        margin-bottom: 8px;
      }
    }
  }

  .column-setting-footer {
    text-align: center;
    margin-top: 16px;
    border-top: 1px solid #ebeef5;
    padding-top: 16px;
  }
}
.colorExplain {
  color: black;
  font-size: 14px;
  span {
    margin-right: 25px;
    i {
      display: inline-block;
      width: 25px;
      height: 10px;
    }
  }
}
/deep/.el-table .finality {
  //商务终结
  background: #e08477c0;
}
/deep/.el-table .estaBlish {
  //已创建项目
  background: #d3dffc;
}
</style>
