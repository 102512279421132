<template>
  <div class="fullScreen" :class="{ show: showEditDialog }">
    <Crumbs v-if="options.ProjectDisable == 5">
      <el-breadcrumb-item slot="after">合同收款</el-breadcrumb-item>
    </Crumbs>
    <Crumbs v-else>
      <el-breadcrumb-item slot="after">{{ options.id ? '编辑' : '新增' }}合同</el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" v-loading="loading">
      <el-tabs v-model="activeName" class="fullScreenMainCon">
        <el-tab-pane v-if="permission(['AGREEMENT_INFORMATION_VIEW'])" label="基本信息">
          <el-form :model="form.agreementInformation" :rules="rules" ref="form">
            <el-descriptions
              direction="vertical"
              style="width: 100%"
              title="基础信息"
              :column="7"
              border
            >
              <el-descriptions-item :span="2">
                <template slot="label"><i style="color: red">*</i> 合同名称 </template>
                <el-form-item label="" prop="agreementName" ref="agreementName">
                  <el-input
                    :disabled="options.ProjectDisable == 5 || disabled"
                    v-model="form.agreementInformation.agreementName"
                    placeholder="请输入合同名称"
                    @input="forceUpdate"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 合同编号 </template>
                <el-form-item label="" prop="agreementNum" ref="agreementNum">
                  <el-input
                    :disabled="options.ProjectDisable == 5 || disabled || options.Root == 2"
                    v-model="form.agreementInformation.agreementNum"
                    placeholder=""
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 实际合同金额(元) </template>
                <el-form-item label="" prop="actualContractAmount" ref="actualContractAmount">
                  <el-input
                    :disabled="options.ProjectDisable == 5 || disabled"
                    v-model="form.agreementInformation.actualContractAmount"
                    @input="inputAmount('actualContractAmount')"
                    @focus="focusAmount('actualContractAmount')"
                    @blur="blurAmount('actualContractAmount')"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <!-- <el-descriptions-item>
                <template slot="label"> 实际金额(元) </template>
                <el-form-item label="" prop="actualAmount" ref="actualAmount">
                  <el-input
                    :disabled="options.ProjectDisable == 5 || disabled"
                    v-model="form.agreementInformation.actualAmount"
                    @input="inputAmount('actualAmount')"
                    @focus="focusAmount('actualAmount')"
                    @blur="blurAmount('actualAmount')"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item> -->
              <el-descriptions-item>
                <template slot="label"> 签订时间 </template>
                <el-form-item label="" prop="signedDate" ref="signedDate">
                  <el-date-picker
                    :disabled="options.ProjectDisable == 5 || disabled"
                    value-format="timestamp"
                    type="date"
                    v-model="form.agreementInformation.signedDate"
                    placeholder="请选择签订时间"
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 签订地点 </template>
                <el-form-item label="" prop="agreementInformation" ref="agreementInformation">
                  <el-input
                    type="textarea"
                    autosize
                    :disabled="options.ProjectDisable == 5 || disabled"
                    v-model="form.agreementInformation.signedPlace"
                    placeholder="请输入签订地点"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template slot="label"> 业务种类 </template>
                <el-form-item label="" prop="businessLines" ref="businessLines">
                  <Dictionary
                    :disabled="options.ProjectDisable == 5 || disabled"
                    v-model="formInfo.businessLines"
                    code="BUSINESS_LINES"
                    placeholder="请选择业务类型"
                  />
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"><i style="color: red">*</i> 合同属性 </template>
                <el-form-item label="" prop="agreementAttribute" ref="agreementAttribute">
                  <Dictionary
                    :disabled="options.ProjectDisable == 5 || disabled"
                    v-model="form.agreementInformation.agreementAttribute"
                    code="CONTRACT_ATTRIBUTE_TYPE"
                    placeholder="请选择合同属性"
                  />
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="1">
                <template slot="label"
                  ><i
                    style="color: red"
                    v-if="form.agreementInformation.agreementAttribute === 'xiao_shou_he_tong'"
                    >*</i
                  >
                  合同类型
                </template>
                <el-form-item style="width: 250px" prop="agreementType" ref="agreementType">
                  <!-- <Dictionary
                    :disabled="options.ProjectDisable == 5 || disabled"
                    v-model="form.agreementInformation.agreementType"
                    code="TYPE_CONTRACT"
                    placeholder="请选择合同类型"
                  /> -->
                  <el-radio-group v-model="form.agreementInformation.agreementType">
                    <el-radio
                      :disabled="options.ProjectDisable == 5 || disabled"
                      v-for="item in dictData.agreementType"
                      :key="item.id"
                      :label="item.dictVal"
                      style="margin-bottom: 10px"
                      >{{ item.dictName }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
              </el-descriptions-item>
              
              <el-descriptions-item>
                <template slot="label">
                  {{ form.agreementInformation.agreementAttribute === 'xiao_shou_he_tong' ? '项目承接公司' : '外协公司' }}
                </template>
                <el-form-item label="" prop="companyType" ref="companyType" v-if="form.agreementInformation.agreementAttribute === 'xiao_shou_he_tong'">
                  <el-select v-model="form.agreementInformation.companyType"
                   :disabled="options.ProjectDisable == 5 || disabled"
                    placeholder="请选择项目承接公司">
                    <el-option v-for="item in dictData.companyType" :key="item.id" :label="item.dictName" :value="item.dictVal"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="outsourceCompanyId" ref="outsourceCompanyId" v-else-if="form.agreementInformation.agreementAttribute === 'cai_gou_he_tong'">
                  <el-select v-model="form.agreementInformation.outsourceCompanyId"
                   :disabled="options.ProjectDisable == 5 || disabled"
                    placeholder="请选择外协公司">
                    <el-option v-for="item in dictData.companyType"
                      :key="item.id"
                      :label="item.dictName" 
                      :value="item.dictVal">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-descriptions-item>
              <!-- 2024-02-22-隐藏项目种类 -->
              <!-- <el-descriptions-item>
                <template slot="label"> 项目种类 </template>
                <el-form-item label="" prop="projectLines" ref="projectLines">
                  <Dictionary
                    :disabled="options.ProjectDisable == 5 || disabled"
                    v-model="form.agreementInformation.projectLines"
                    :code="formInfo.businessLines"
                    placeholder="请选择项目种类" />
                </el-form-item>
              </el-descriptions-item> -->
              <el-descriptions-item :span="3" v-if="form.agreementInformation.agreementAttribute === 'xiao_shou_he_tong'">
                <template slot="label">
                  项目来源
                  <el-button
                    size="mini"
                    type="warning"
                    style="margin-left: 10%; padding: 5px"
                    @click="projectSourceIsShow = true"
                    >项目来源说明</el-button
                  >
                </template>
                <el-radio-group v-model="form.agreementInformation.projectSource">
                  <div class="radio-group">
                    <div class="radio-label">生产类型:</div>
                    <div class="radio-item">
                      <el-radio
                        :disabled="options.ProjectDisable == 5 || disabled"
                        v-for="item in produceList"
                        :key="item.id"
                        :label="item.dictVal"
                        >{{ item.dictName }}</el-radio
                      >
                    </div>
                  </div>
                  <div class="radio-group">
                    <div class="radio-label">非生产类型:</div>
                    <div class="radio-item">
                      <el-radio
                        :disabled="options.ProjectDisable == 5 || disabled"
                        v-for="item in unProduceList"
                        :key="item.id"
                        :label="item.dictVal"
                        >{{ item.dictName }}</el-radio
                      >
                    </div>
                  </div>
                </el-radio-group>
              </el-descriptions-item>
              <el-descriptions-item :span="7">
                <template slot="label"> 合同备注 </template>
                <el-form-item label="" prop="remark" ref="remark">
                  <el-input
                    type="textarea"
                    :disabled="options.ProjectDisable == 5 || disabled"
                    autosize
                    placeholder="请输入内容"
                    v-model="form.agreementInformation.remark"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
            </el-descriptions>

            <el-descriptions
              title="执行完毕处理信息"
              direction="vertical"
              v-if="form.agreementInformation.status == 30"
              :column="10"
              border
              style="width: 100%; margin-top: 10px"
            >
              <el-descriptions-item label="处理人">{{
                form.agreementInformation.executionCompletedStaff
              }}</el-descriptions-item>
              <el-descriptions-item label="处理时间">{{
                form.agreementInformation.executionCompletedDate | dateFormat
              }}</el-descriptions-item>
              <el-descriptions-item label="处理备注" :span="8">{{
                form.agreementInformation.executionCompletedRemark
              }}</el-descriptions-item>
            </el-descriptions>

            <el-form-item
              label="项目名称列表"
              prop="projectName"
              ref="projectName"
              style="width: 100%"
            >
              <el-table border :data="form.agreementInformation.projectListResponseList">
                <el-table-column
                  align="center"
                  type="index"
                  width="50"
                  label="序号"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="projectName"
                  label="项目名称"
                  :show-overflow-tooltip="false"
                  min-width="400"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="theirDeptName"
                  label="所属部门"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="deptClassifyManagerName"
                  label="项目经理"
                ></el-table-column>
                <el-table-column
                  prop="projectPredictStartDate"
                  align="center"
                  label="项目预计开始时间"
                >
                  <template slot-scope="scope">
                    {{ scope.row.projectPredictStartDate | dateFormat }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="projectPredictEndDate"
                  align="center"
                  label="项目预计结束时间"
                >
                  <template slot-scope="scope">
                    {{ scope.row.projectPredictEndDate | dateFormat }}
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="status" label="项目状态">
                  <template slot-scope="scope">
                    {{ scope.row.status | dict(dictData.projectStatus) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" width="160" label="查看项目">
                  <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click.stop="edit(scope.row)"
                      >详情</el-button
                    >
                    <el-button size="mini" type="success" @click="leaveFor(scope.row)"
                      >前往</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  width="80"
                  v-if="!disabled && options.ProjectDisable != 5"
                >
                  <template slot="header" slot-scope="scope">
                    <ChoiceData
                      :issearch="issearch"
                      :agreementType="form.agreementInformation.agreementType"
                      :configure="configure"
                      @choiceDataChange="choiceProject"
                      :projectListResponseList="form.agreementInformation.projectListResponseList"
                    >
                      <i class="iconfont iconadd-circle deit" slot="button" :readonly="true"></i>
                      <template slot="tableColumn">
                        <el-table-column
                          align="center"
                          prop="projectName"
                          label="项目名称"
                          :show-overflow-tooltip="false"
                          width="450"
                        ></el-table-column>
                        <el-table-column
                          align="center"
                          prop="theirDeptName"
                          label="所属部门"
                        ></el-table-column>
                        <el-table-column align="center" prop="status" label="项目状态">
                          <template slot-scope="scope">
                            {{ scope.row.status | dict(dictData.projectStatus) }}
                          </template>
                        </el-table-column>
                      </template>
                    </ChoiceData>
                  </template>
                  <template slot-scope="scope">
                    <i class="iconfont iconerror" @click="delMultipleItemsList(scope)"></i>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

            <!-- <el-form-item label="所属部门" prop="theirDeptName" ref="theirDeptName">
                <el-input
                  v-if="disabled"
                  :disabled="disabled"
                  :readonly="true"
                  v-model="form.agreementInformation.theirDeptName"
                  placeholder=""
                  @keyup.enter.native="submit"
                ></el-input>
                <ChoiceData v-else :configure="configure" @choiceDataChange="choiceProject">
                  <el-input
                    slot="button"
                    :readonly="true"
                    v-model="form.agreementInformation.theirDeptName"
                    placeholder="请选择所属部门"
                    @keyup.enter.native="submit"
                  ></el-input>
                  <template slot="tableColumn">
                    <el-table-column
                      align="center"
                      prop="projectName"
                      label="项目名称"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      prop="theirDeptName"
                      label="所属部门"
                    ></el-table-column>
                    <el-table-column prop="projectType" align="center" label="项目类型">
                      <template slot-scope="scope">
                        {{ scope.row.projectType | dict(dictData.projectType) }}
                      </template>
                    </el-table-column>
                    <el-table-column align="center" prop="status" label="项目状态">
                      <template slot-scope="scope">
                        {{ scope.row.status | status }}
                      </template>
                    </el-table-column>
                  </template>
                </ChoiceData>
              </el-form-item> -->

            <el-form-item
              prop="contentAbstract"
              ref="contentAbstract"
              style="width: 100%"
            >
              <template slot="label">
                {{ form.agreementInformation.agreementAttribute === 'xiao_shou_he_tong' ? '合同服务内容摘要' : '外协内容摘要' }}
              </template>
              <el-input
                type="textarea"
                :disabled="options.ProjectDisable == 5 || disabled"
                v-model="form.agreementInformation.contentAbstract"
                :placeholder="form.agreementInformation.agreementAttribute === 'xiao_shou_he_tong' ? '请输入合同服务内容摘要' : '请输入外协内容摘要'"
              />
              <!-- @keyup.enter.native="submit" -->
            </el-form-item>
            <template v-if="form.agreementInformation.status == 5">
              <el-form-item
                label="审批不通过意见"
                prop="checkOpinion"
                ref="checkOpinion"
                style="color: red; width: 100%"
                id="checkOpinion"
              >
                <el-input
                  style="font-size: 18px; color: red"
                  :disabled="true"
                  autosize
                  type="textarea"
                  v-model="form.agreementInformation.checkOpinion"
                ></el-input>
              </el-form-item>
            </template>
            <!-- </el-card> -->

            <el-card v-if="form.agreementInformation.agreementAttribute === 'xiao_shou_he_tong'">
              <div slot="header">
                <span>工作内容</span>
              </div>
              <el-table border :data="form.agreementWorkContentList">
                <el-table-column
                  align="center"
                  type="index"
                  label="序号"
                  width="50"
                ></el-table-column>
                <el-table-column label="工作内容" align="center">
                  <template slot-scope="scope">
                    <el-input
                      :disabled="options.ProjectDisable == 5 || disabled"
                      v-model="scope.row.content"
                      placeholder="请输入"
                    ></el-input>
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  width="80"
                  v-if="!disabled && options.ProjectDisable != 5"
                >
                  <template slot="header" slot-scope="scope">
                    <i class="iconfont iconadd-circle" @click="addAgreementWorkContentList"></i>
                  </template>
                  <template slot-scope="scope">
                    <i class="iconfont iconerror" @click="delAgreementWorkContentList(scope)"></i>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>

            <el-card v-if="IsShow">
              <div slot="header">
                <span>费用</span>
              </div>
              <el-table border :data="form.agreementMakeMapsList">
                <el-table-column
                  align="center"
                  type="index"
                  label="序号"
                  width="50"
                ></el-table-column>
                <el-table-column label="项目" align="center">
                  <template slot-scope="scope">
                    <el-input
                      :disabled="options.ProjectDisable == 5 || disabled"
                      v-model="scope.row.project"
                      placeholder="请输入"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="数量" align="center">
                  <template slot-scope="scope">
                    <el-input-number
                      :disabled="options.ProjectDisable == 5 || disabled"
                      size="small"
                      v-model="scope.row.quantity"
                      :min="0"
                    ></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column label="单位" align="center">
                  <template slot-scope="scope">
                    <el-input
                      :disabled="options.ProjectDisable == 5 || disabled"
                      v-model="scope.row.unit"
                      placeholder="请输入"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="单价（元）" align="center">
                  <template slot-scope="scope">
                    <el-input-number
                      :disabled="options.ProjectDisable == 5 || disabled"
                      size="small"
                      v-model="scope.row.unitPrice"
                      :min="0"
                      :precision="2"
                    ></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column label="金额（元）" align="center">
                  <template slot-scope="scope">
                    <el-input-number
                      :disabled="options.ProjectDisable == 5 || disabled"
                      size="small"
                      v-model="scope.row.amount"
                      :precision="2"
                      :min="0"
                    ></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column label="收费依据" align="center">
                  <template slot-scope="scope">
                    <el-input
                      :disabled="options.ProjectDisable == 5 || disabled"
                      v-model="scope.row.feeBasis"
                      placeholder="请输入"
                    ></el-input>
                  </template>
                </el-table-column>
                <!-- <template v-if="!options.ProjectDisable == 5"> -->
                <el-table-column align="center" width="80" v-if="!disabled">
                  <template slot="header" slot-scope="scope">
                    <i class="iconfont iconadd-circle" @click="addAgreementMakeMapsList"></i>
                  </template>
                  <template slot-scope="scope">
                    <i class="iconfont iconerror" @click="delAgreementMakeMapsList(scope)"></i>
                  </template>
                </el-table-column>
                <!-- </template> -->
              </el-table>
            </el-card>

            <el-card v-if="options.ProjectDisable != 5 && form.agreementInformation.agreementAttribute === 'xiao_shou_he_tong'">
              <div slot="header">
                <span id="cost">费用结算与付款方式</span>
              </div>
              <el-table border :data="form.agreementStageList">
                <el-table-column
                  align="center"
                  type="index"
                  label="序号"
                  width="50"
                ></el-table-column>
                <el-table-column label="阶段" min-width="120" align="center">
                  <template slot-scope="scope">
                    <el-input
                      :disabled="editDisabled || disabled"
                      v-model="scope.row.stageName"
                      placeholder="请输入"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="内容" min-width="220" align="center">
                  <template slot-scope="scope">
                    <el-input
                      :disabled="editDisabled || disabled"
                      v-model="scope.row.paymentStandard"
                      placeholder="请输入"
                      type="textarea"
                      :autosize="{
                        minRows: scope.row.paymentStandard
                          ? scope.row.paymentStandard.length / 42
                          : 0,
                        maxRows: 999,
                      }"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :show-overflow-tooltip="false"
                  label="金额（元）"
                  width="200"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-input-number
                      style="width: 100%"
                      :disabled="editDisabled || disabled"
                      size="small"
                      v-model="scope.row.paymentAmount"
                      :precision="2"
                      :min="0"
                    ></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column
                  label="约定付款时间"
                  :show-overflow-tooltip="false"
                  width="180"
                  align="center"
                  v-if="form.agreementInformation.agreementAttribute == 'cai_gou_he_tong'"
                >
                  <template slot-scope="scope">
                    <el-date-picker
                      :disabled="editDisabled || disabled"
                      value-format="timestamp"
                      type="date"
                      v-model="scope.row.paymentDate"
                      placeholder="请选择"
                    ></el-date-picker>
                  </template>
                </el-table-column>
                <el-table-column
                  v-else
                  label="约定收款时间"
                  :show-overflow-tooltip="false"
                  width="180"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-date-picker
                      :disabled="editDisabled || disabled"
                      value-format="timestamp"
                      type="date"
                      v-model="scope.row.proceedsDate"
                      placeholder="请选择"
                    ></el-date-picker>
                  </template>
                </el-table-column>
                <template v-if="!editDisabled">
                  <el-table-column align="center" width="80" v-if="!disabled">
                    <template slot="header" slot-scope="scope">
                      <i class="iconfont iconadd-circle" @click="addAgreementStageList"></i>
                    </template>
                    <template slot-scope="scope">
                      <template v-if="options.id">
                        <i class="iconfont iconerror" @click="delAgreementStageList(scope)"></i>
                      </template>
                      <template v-else>
                        <i
                          class="iconfont iconerror"
                          v-if="!scope.row.projectId"
                          @click="delAgreementStageList(scope)"
                        ></i>
                      </template>
                    </template>
                  </el-table-column>
                </template>
              </el-table>
            </el-card>

            <el-card>
              <div slot="header">
                <span>签署信息</span>
              </div>

              <div class="signatureInformation-group signatureInformation">
                <div class="signatureInformation-item">
                  <div class="label">合同方：</div>
                  <!-- <el-checkbox-group
                    v-model="agreementSignedTypeCheck"
                    :disabled="disabled"
                  >
                    <el-checkbox
                      :disabled="n.dictVal == 'FIRST' || n.dictVal == 'SECOND'"
                      v-for="n in agreementSignedTypeData"
                      :label="n.dictVal"
                      :key="n.dictVal"
                      @change="handleAgreementSignedTypeChange"
                      >{{ n.dictName }}</el-checkbox
                    >
                  </el-checkbox-group> -->
                  <el-select
                    :style="{
                      width: agreementSignedTypeCheck.length * 10 + 5 + '%',
                    }"
                    v-model="agreementSignedTypeCheck"
                    multiple
                    @change="cannotbeRemoved"
                    :disabled="options.ProjectDisable == 5 || disabled"
                  >
                    <el-option
                      v-for="item in agreementSignedTypeData"
                      :key="item.dictVal"
                      :label="item.dictName"
                      :value="item.dictVal"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="signatureInformation-item">
                  <div class="label" style="margin-bottom: 8px">
                    公司所属：
                    <el-cascader
                      :options="optionsData"
                      :disabled="options.ProjectDisable == 5 || options.Root == 2 || disabled"
                      :show-all-levels="false"
                      v-model="optionsValue"
                      placeholder="请先选择公司所属"
                      clearable
                      @change="optionsFn"
                    ></el-cascader>
                  </div>
                  <el-radio-group
                    v-model="form.agreementInformation.signedType"
                    :disabled="options.ProjectDisable == 5 || disabled"
                    @change="signedTypeChange"
                  >
                    <template v-for="(n, i) in agreementSignedTypeData">
                      <el-radio
                        v-if="
                          agreementSignedTypeCheck.some(r => {
                            return r == n.dictVal
                          })
                        "
                        :key="i"
                        :label="n.dictVal"
                        >{{ n.dictName }}</el-radio
                      >
                    </template>
                  </el-radio-group>
                </div>
              </div>
              <div style="width: 100%">
                <el-form :model="form" :rules="rulesAgreement" ref="formAgreement">
                  <el-table :data="form.agreementSignedList" border>
                    <el-table-column label="合同方" fixed="left" width="100">
                      <template slot-scope="scope">
                        {{ scope.row.signedType | dict(agreementSignedTypeData) }}
                      </template>
                    </el-table-column>

                    <el-table-column label="选择业主/外协公司" align="center" fixed width="140">
                      <template slot-scope="scope">
                        <el-button
                          :disabled="options.ProjectDisable == 5 || options.Root == 2 || disabled"
                          type="primary"
                          @click="onClickSelectCompany(scope.row)"
                          >选择</el-button
                        >
                      </template>
                    </el-table-column>

                    <el-table-column label="单位名称" prop="party" width="200">
                      <template slot-scope="scope">
                        <el-form-item
                          style="width: 100%"
                          :prop="'agreementSignedList.' + scope.$index + '.party'"
                          :rules="rulesAgreement.party"
                        >
                          <el-input
                            :disabled="true"
                            v-model="scope.row.party"
                            placeholder=""
                          ></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>

                    <el-table-column label="电话" prop="partyPhone" width="200">
                      <template slot-scope="scope">
                        <el-form-item
                          style="width: 100%"
                          :prop="'agreementSignedList.' + scope.$index + '.partyPhone'"
                          :rules="rulesAgreement.partyPhone"
                        >
                          <el-input
                            :disabled="options.ProjectDisable == 5 || options.Root == 2 || disabled"
                            v-model="scope.row.partyPhone"
                            placeholder="请输入"
                            @mousedown.stop
                            @click.stop
                          ></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>

                    <el-table-column label="传真" prop="partyFax" width="200">
                      <template slot-scope="scope">
                        <el-form-item
                          style="width: 100%"
                          :prop="'agreementSignedList.' + scope.$index + '.partyFax'"
                          :rules="rulesAgreement.partyFax"
                        >
                          <el-input
                            :disabled="options.ProjectDisable == 5 || options.Root == 2 || disabled"
                            v-model="scope.row.partyFax"
                            placeholder="请输入"
                            @mousedown.stop
                            @click.stop
                          ></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>

                    <el-table-column label="邮箱" prop="partyMail" width="200">
                      <template slot-scope="scope">
                        <el-form-item
                          style="width: 100%"
                          :prop="'agreementSignedList.' + scope.$index + '.partyMail'"
                          :rules="rulesAgreement.partyMail"
                        >
                          <el-input
                            :disabled="options.ProjectDisable == 5 || options.Root == 2 || disabled"
                            v-model="scope.row.partyMail"
                            placeholder="请输入"
                            @mousedown.stop
                            @click.stop
                          ></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>

                    <el-table-column label="地址" prop="partyAddress" width="200">
                      <template slot-scope="scope">
                        <el-form-item
                          style="width: 100%"
                          :prop="'agreementSignedList.' + scope.$index + '.partyAddress'"
                          :rules="rulesAgreement.partyAddress"
                        >
                          <el-input
                            :disabled="options.ProjectDisable == 5 || options.Root == 2 || disabled"
                            type="textarea"
                            autosize
                            v-model="scope.row.partyAddress"
                            placeholder="请输入"
                            @mousedown.stop
                            @click.stop
                          ></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>

                    <el-table-column label="开户银行" prop="partyDepositBank" width="220">
                      <template slot-scope="scope">
                        <el-form-item
                          style="width: 100%"
                          :prop="'agreementSignedList.' + scope.$index + '.partyDepositBank'"
                          :rules="rulesAgreement.partyDepositBank"
                        >
                          <el-input
                            :disabled="options.ProjectDisable == 5 || options.Root == 2 || disabled"
                            type="textarea"
                            autosize
                            v-model="scope.row.partyDepositBank"
                            placeholder="请输入"
                            @mousedown.stop
                            @click.stop
                          ></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>

                    <el-table-column label="银行账号" prop="partyAccountBank" width="250">
                      <template slot-scope="scope">
                        <el-form-item
                          style="width: 100%"
                          :prop="'agreementSignedList.' + scope.$index + '.partyAccountBank'"
                          :rules="rulesAgreement.partyAccountBank"
                        >
                          <el-input
                            :disabled="options.ProjectDisable == 5 || options.Root == 2 || disabled"
                            v-model="scope.row.partyAccountBank"
                            placeholder="请输入"
                            @mousedown.stop
                            @click.stop
                            @input="handleCompanyInput(scope.row, scope.$index, 'partyAccountBank')"
                          ></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form>
              </div>

              <!-- <table class="table signatureInformation">
                <tr>
                  <td colspan="4" style="width:50%">
                    <div class="signatureInformation-item">
                      <div class="label">合同方：</div>
                      <el-checkbox-group v-model="agreementSignedTypeCheck" :disabled="disabled">
                        <el-checkbox
                          :disabled="n.dictVal == 'FIRST' || n.dictVal == 'SECOND'"
                          v-for="n in agreementSignedTypeData"
                          :label="n.dictVal"
                          >{{ n.dictName }}</el-checkbox
                        >
                      </el-checkbox-group>
                    </div>
                  </td>
                  <td colspan="4" style="width:50%">
                    <div class="signatureInformation-item">
                      <div class="label">公司所属：</div>
                      <el-radio-group
                        v-model="form.agreementInformation.signedType"
                        :disabled="disabled"
                        @change="signedTypeChange"
                      >
                        <template v-for="n in agreementSignedTypeData">
                          <el-radio
                            v-if="
                              agreementSignedTypeCheck.some(r => {
                                return r == n.dictVal
                              })
                            "
                            :label="n.dictVal"
                            >{{ n.dictName }}</el-radio
                          >
                        </template>
                      </el-radio-group>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th style="width:120px;">合同方</th>
                  <th>单位名称</th>
                  <th>电话</th>
                  <th>传真</th>
                  <th>邮箱</th>
                  <th>地址</th>
                  <th>开户银行</th>
                  <th>银行账号</th>
                </tr>
                <template v-for="item in agreementSignedTypeData">
                  <template
                    v-for="n in form.agreementSignedList"
                    v-if="n.signedType == item.dictVal"
                  >
                    <tr
                      v-if="
                        agreementSignedTypeCheck.some(r => {
                          return r == n.signedType
                        })
                      "
                    >
                      <td>
                        {{ n.signedType | dict(agreementSignedTypeData) }}
                      </td>
                      <td>
                        <el-input
                          :disabled="disabled"
                          v-model="n.party"
                          placeholder="请输入"
                        ></el-input>
                      </td>
                      <td>
                        <el-input
                          :disabled="disabled"
                          v-model="n.partyPhone"
                          placeholder="请输入"
                        ></el-input>
                      </td>
                      <td>
                        <el-input
                          :disabled="disabled"
                          v-model="n.partyFax"
                          placeholder="请输入"
                        ></el-input>
                      </td>
                      <td>
                        <el-input
                          :disabled="disabled"
                          v-model="n.partyMail"
                          placeholder="请输入"
                        ></el-input>
                      </td>
                      <td>
                        <el-input
                          :disabled="disabled"
                          v-model="n.partyAddress"
                          placeholder="请输入"
                        ></el-input>
                      </td>
                      <td>
                        <el-input
                          :disabled="disabled"
                          v-model="n.partyDepositBank"
                          placeholder="请输入"
                        ></el-input>
                      </td>
                      <td>
                        <el-input
                          :disabled="disabled"
                          v-model="n.partyAccountBank"
                          placeholder="请输入"
                        ></el-input>
                      </td>
                    </tr>
                  </template>
                </template>
              </table> -->
            </el-card>
          </el-form>
        </el-tab-pane>

        <el-tab-pane v-if="permission(['AGREEMENT_FILE_VIEW'])" label="附件"
          ><Annex name="annex" ref="annex" :value="form.agreementFileList" :options="options"
        /></el-tab-pane>
        <template v-if="options.collection != 1">
          <el-tab-pane
            name="Proceeds"
            v-if="permission(['AGREEMENT_INCOME_VIEW'])"
            :label="form.agreementInformation.agreementAttribute === 'xiao_shou_he_tong' ? '合同收款' : '合同付款'"
          >
            <Proceeds v-if="form.agreementInformation.agreementAttribute === 'xiao_shou_he_tong'" ref="Proceeds" :options="options" @getData="init"></Proceeds>
            <ProceedsPayment v-else ref="ProceedsPayment" :options="options" @getData="init"></ProceedsPayment>
          </el-tab-pane>
        </template>
      </el-tabs>

      <div class="fullScreenOperation">
        <template v-if="mode != 'view'">
          <template v-if="options.id">
            <template v-if="options.status == 0 || options.status == 5">
              <el-button type="primary" :loading="submitLoading" @click="submit('undeterminedEdit')"
                >暂存</el-button
              >
              <el-button
                type="success"
                :loading="submitLoading"
                @click="submit('undeterminedEditOrSubmit')"
                >保存并提交</el-button
              >
            </template>

            <template
              v-if="
                options.operatingButton.some(r => {
                  return r == 'APPROVE'
                })
              "
            >
              <template v-if="options.Root == 2 && options.status == 10">
                <el-button type="success" :loading="submitLoading" @click="approve(2)"
                  >通过</el-button
                >
                <el-button type="danger" :loading="submitLoading" @click="approve(1)"
                  >不通过</el-button
                >
              </template>
            </template>
            <template v-if="options.Root == 1 && options.status != 0 && options.status != 5">
              <el-button type="success" :loading="submitLoading" @click="submit('edit')"
                >保存</el-button
              >
            </template>

            <el-button
              type="primary"
              v-if="
                options.Root == 1 &&
                form.agreementInformation.status != 30 &&
                form.agreementInformation.status == 20
              "
              @click="executeFinshShow = true"
              >执行完毕</el-button
            >
          </template>

          <template v-else>
            <el-button type="primary" :loading="submitLoading" @click="submit('creation')"
              >暂存</el-button
            >
            <el-button type="success" :loading="submitLoading" @click="submit('creationOrSubmit')"
              >保存并提交</el-button
            >
          </template>
        </template>
        <el-button type="info" @click="returnFn">返回</el-button>
      </div>
    </div>
    <!-- 选择合作公司弹框 -->
    <el-dialog
      title="选择业主方/外协公司"
      id="selectCompanyDialogVisible"
      :visible.sync="selectCompanyDialogVisible"
      width="60%"
      @closed="handleSelectPartnerCompanyDialogClose"
      :append-to-body="true"
    >
      <el-tabs v-model="selectCompanyActiveName" @tab-click="selectCompanyHandleClick">
        <el-tab-pane label="业主方公司" name="proprietor"></el-tab-pane>
        <el-tab-pane label="外协公司" name="outsource"></el-tab-pane>
      </el-tabs>
      <div v-if="selectCompanyActiveName === 'proprietor'">
        <span style="margin-right: 10px"
          >公司名称:
          <el-input
            placeholder="请输入公司名称"
            clearable
            v-model="selectPage.name"
            class="ipt_width"
            style="width: 70%; margin-bottom: 10px"
          >
          </el-input
        ></span>
        <el-button type="primary" icon="el-icon-search" @click="handlePageNowChange(1)"
          >搜索</el-button
        >
        <el-button type="warning" icon="el-icon-refresh" @click="selectCompanyHandleReset"
          >清空</el-button
        >

        <!-- 内容渲染单选表 -->
        <el-table
          :data="selectPartnerCompanyList"
          max-height="350"
          border
          @row-click="choiceRow"
          style="width: 100%"
        >
          <el-table-column fixed="left" label="操作" align="center" width="90">
            <template slot-scope="scope">
              <el-button
                type="primary"
                :disabled="agreementSelectId == scope.row.id"
                @click.native.prevent="choiceRow(scope.row)"
                size="small"
              >
                {{ agreementSelectId == scope.row.id ? '已选' : '选择' }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="公司名称">
            <!-- <template slot-scope="scope">
              <el-radio v-model="selectPartnerCompanyRadio" :label="scope.row.id">{{
                scope.row.name
              }}</el-radio>
            </template> -->
          </el-table-column>
          <el-table-column prop="phone" label="电话"></el-table-column>
          <el-table-column prop="fax" label="传真"></el-table-column>
          <el-table-column prop="mail" label="邮箱"></el-table-column>
          <el-table-column prop="address" label="地址"></el-table-column>
          <el-table-column prop="depositBank" label="开户银行"></el-table-column>
          <el-table-column prop="accountBank" label="银行账户"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handlePagSizeChange"
          @current-change="handlePageNowChange"
          :current-page="selectPage.pageNow"
          :page-sizes="[5, 10]"
          :page-size="selectPage.pageSize"
          layout="-> ,total, sizes, prev, pager, next, jumper"
          :total="selectPage.total"
        >
        </el-pagination>
      </div>
      <div v-if="selectCompanyActiveName === 'outsource'">
        <span style="margin-right: 10px"
          >公司名称：
          <el-input
            placeholder="请输入公司名称"
            clearable
            v-model="outsourceSelectPage.name"
            class="ipt_width"
            style="width: 70%; margin-bottom: 10px"
          >
          </el-input
        ></span>
        <el-button type="primary" icon="el-icon-search" @click="outsourceHandlePageNowChange(1)"
          >搜索</el-button
        >
        <el-button type="warning" icon="el-icon-refresh" @click="outsourceSelectCompanyHandleReset"
          >清空</el-button
        >
        <!-- 内容渲染单选表 -->
        <el-table
          :data="outsourceSelectPartnerCompanyList"
          border
          @row-click="choiceRow"
          style="width: 100%"
          max-height="350"
        >
          <el-table-column fixed="left" label="操作" align="center" width="90">
            <template slot-scope="scope">
              <el-button
                type="primary"
                :disabled="agreementSelectId == scope.row.id"
                @click.native.prevent="choiceRow(scope.row)"
                size="small"
              >
                {{ agreementSelectId == scope.row.id ? '已选' : '选择' }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="name" align="center" label="公司名称"> </el-table-column>
          <el-table-column prop="phone" align="center" label="电话"></el-table-column>
          <el-table-column prop="fax" align="center" label="传真"></el-table-column>
          <el-table-column prop="mail" align="center" label="邮箱"></el-table-column>
          <el-table-column prop="address" align="center" label="地址"></el-table-column>
          <el-table-column prop="depositBank" align="center" label="开户银行"></el-table-column>
          <el-table-column prop="accountBank" align="center" label="银行账户"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="outsourceHandlePagSizeChange"
          @current-change="outsourceHandlePageNowChange"
          :current-page="outsourceSelectPage.pageNow"
          :page-sizes="[10, 20]"
          :page-size="outsourceSelectPage.pageSize"
          layout="-> ,total, sizes, prev, pager, next, jumper"
          :total="outsourceSelectPage.total"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="selectCompanyDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="60%"
      :before-close="handleClose"
      id="approveTxet"
    >
      <div slot="title" class="header-title" style="color: #47a6fb; font-size: 15px">
        <span v-if="approveTxet == 1" class="title-name"> {{ '审批不通过' }}</span>
        <span v-else class="title-age"> {{ '审批通过' }}</span>
      </div>
      <el-form :model="formData" ref="rulesApproveTxet">
        <el-form-item
          prop="checkOpinion"
          :rules="[
            {
              required: true,
              message: '请输入不通过的意见内容',
              trigger: 'blur',
            },
            {
              min: 0,
              max: 500,
              message: '意见内容在0~500个字符',
              trigger: 'blur',
            },
          ]"
          v-if="approveTxet == 1"
        >
          <el-input
            label=""
            v-model="formData.checkOpinion"
            type="textarea"
            placeholder="请输入原因"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="approveTxet == 2" prop="checkOpinion">
          <el-input
            label=""
            v-model="formData.checkOpinion"
            type="textarea"
            placeholder="通过"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          :type="approveTxet == 2 ? 'success' : 'danger'"
          style="width: 120px"
          @click="addApprove"
          >{{ approveTxet == 2 ? '审批通过' : '审批不通过' }}</el-button
        >
        <el-button
          type="warning"
          plain
          style="margin-right: 37%; margin-left: 12%; width: 120px"
          @click="handleClose"
          >取 消</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="项目基本详情"
      :visible.sync="ProjectEditDialogVisible"
      width="80%"
      append-to-body
      :before-close="handleCloseFn"
    >
      <div class="el-dialog-div">
        <ProjectEdit :ProjectEditId="ProjectEditId" />
      </div>
    </el-dialog>

    <el-dialog width="50%" title="项目来源说明" append-to-body :visible.sync="projectSourceIsShow">
      <div class="imgDialog">
        <img :src="`${imgProjectSource}?${new Date().getTime()}`" alt="项目来源说明" width="100%" />
      </div>
    </el-dialog>

    <!-- 执行完毕 -->
    <ExecuteDinshDialog
      v-if="executeFinshShow"
      :agreementShow.sync="executeFinshShow"
      @submitFinshFn="submitFinshFn"
    />
  </div>
</template>
<script>
import { getInputValue, delcommafy, numberToCurrencyNo } from '@/util/jsencrypt'
import { initTreeData } from '@/util/common'
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    Annex: () => import('./Annex.vue'),
    ProjectEdit: () => import('./ProjectEdit.vue'),
    ChoiceData: () => import('@/components/ChoiceData.vue'),
    Proceeds: () => import('./proceeds.vue'),
    ProceedsPayment: () => import('./proceedsPayment.vue'),
    ExecuteDinshDialog: () => import('./execute-finsh-dialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    mode: {
      type: String,
      default: 'edit',
    }
  },
  data() {
    return {
      ProjectEditId: null,
      ProjectEditDialogVisible: false,
      IsShow: false,
      showEditDialog: false,
      loading: true,
      disabled: true,
      submitLoading: false,
      originalForm: {},
      form: {
        agreementInformation: {
          projectName: null,
          theirDeptName: null,
          signedPlace: '广州市',
          agreementName: null,
          agreementAttribute: null,
          actualContractAmount: 0,
          actualAmount: 0,
        },
        agreementFileList: [],
        agreementWorkContentList: [],
        agreementMakeMapsList: [],
        agreementStageList: [],
        agreementSignedList: [{ signedType: 'FIRST' }, { signedType: 'SECOND' }],
      },
      rules: {
        agreementName: [
          {
            required: true,
            message: '请输入合同名称',
            // trigger: "blur",
            trigger: ['blur', 'change'],
          },
        ],
        // agreementType: [
        //   {
        //     required: true,
        //     message: '请选择合同类型',
        //     trigger: ['blur', 'change'],
        //   },
        // ],
        agreementAttribute: [
          {
            required: true,
            message: '请选择合同属性',
            trigger: ['blur', 'change'],
          },
        ],
      },
      dictData: {
        projectType: [],
        projectStatus: [],
        agreementType: [],
        companyType: [],
      },
      configure: {
        request: this.$api.project.listProject,
        type: 'radio',
      },
      agreementSignedTypeData: [],
      agreementSignedTypeCheck: ['FIRST', 'SECOND'],
      agreementCompany: {},
      nowParty: '', //记录当前点击按钮的合同方
      selectCompanyDialogVisible: false, //控制选择合作公司弹框显示和隐藏
      selectCompanyActiveName: 'proprietor',
      outsourceSelectPartnerCompanyList: [], //外协公司列表
      selectPartnerCompanyList: [], //存储服务器返回的合作公司列表
      selectPartnerCompanyRadio: '', //单选框选中项
      partnerCompanyInfo: {}, //通过单选框选中的公司信息
      agreementSelectId: null, //记录当前选中
      selectPage: { pageNow: 1, pageSize: 10, total: 0, name: null },
      outsourceSelectPage: { pageNow: 1, pageSize: 10, total: 0, name: null },
      editDisabled: false,
      approveTxet: '',
      dialogVisible: false,
      formData: {
        id: null,
        checkOpinion: '',
      },
      rulesFormData: {
        checkOpinion: [
          {
            required: true,
            message: '请输入不通过的意见内容',
            trigger: 'blur',
          },
          {
            min: 0,
            max: 500,
            message: '意见内容在0~500个字符',
            trigger: 'blur',
          },
        ],
      },
      optionsData: [],
      optionsValue: null,
      issearch: true,
      formInfo: {
        businessLines: null,
      },
      temporaryList: [],
      rulesAgreement: {
        partyPhone: [
          {
            // required: true,
            validator: this.partyPhone,
            trigger: 'change',
          },
        ],
        partyFax: [
          {
            // required: true,
            validator: this.partyFax,
            trigger: 'change',
          },
        ],
        partyMail: [
          {
            // required: true,
            validator: this.partyMail,
            trigger: 'change',
          },
        ],
        partyAccountBank: [
          {
            // required: true,
            validator: this.partyAccountBank,
            trigger: 'change',
          },
        ],
      },
      projectSourceIsShow: false,
      produceList: [],
      unProduceList: [],
      imgProjectSource: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/生产费用.png',
      executeFinshShow: false, //执行完毕弹窗
    }
  },
  provide() {
    return {
      form: this.form,
    }
  },
  inject: ['refresh'],
  computed: {
    ...mapState({
      projectFileList: state => state.project.projectFileList,
      delAgreementFileList: state => state.project.delAgreementFileList,
      addAgreementFileList: state => state.project.addAgreementFileList,
    }),
  },
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.activeName = '0'
        this.showEditDialog = newVal
      },
    },
    agreementSignedTypeCheck: {
      deep: true, //对象深度监测
      handler: function (res) {
        res.some(r => {
          if (
            !this.form.agreementSignedList.some(n => {
              return n.signedType == r
            })
          ) {
            this.form.agreementSignedList.push({
              signedType: r,
              party: null,
              partyAccountBank: null,
              partyAddress: null,
              partyDepositBank: null,
              partyFax: null,
              partyMail: null,
              partyPhone: null,
              cooperativeCorporationId: null,
              readonly: true,
              refresh: true,
            })
            return
          }
        })
      },
    },
    formInfo: {
      deep: true, //对象深度监测
      immediate: true, //初始化立即执行
      handler: function (val) {
        this.form.agreementInformation.businessLines = val.businessLines
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (options) {
        this.disabled = true
        if (options.ProjectDisable == 5) {
          this.disabled = true
        }
        if (options.id) {
          if (options.Root == 2) {
            this.disabled = true
          } else if (options.Root == 1 && this.permission(['AGREEMENT_INFORMATION_EDIT'])) {
            this.disabled = false
          }
          if (options.status == 20) {
            this.editDisabled = true
          } else {
            this.editDisabled = false
          }
          if (
            options.status == 0 ||
            (options.status == 5 &&
              options.operatingButton.some(r => {
                return r == 'EDIT'
              }))
          ) {
            this.disabled = false
          }
          if (this.permission(['AGREEMENT_INFORMATION_EDIT']) && options.Root != 2) {
            this.disabled = false
            this.editDisabled = false
          }
        } else {
          this.disabled = false
        }
        if(this.mode === 'view'){
          this.disabled = true
        }
        this.init()
      },
    },
    'form.agreementInformation.agreementAttribute': {
      handler(val, oldVal) {
        if (val == 'xiao_shou_he_tong') {
          this.$api.dict
            .listSysDictData('COMPANY_TYPE', true)
            .then(res => {
              this.dictData.companyType = res.data.map(item => ({
                id: item.id,
                dictName: item.dictName,
                dictVal: item.dictVal
              }));
            })
            .catch(err => {
              console.error(err);
            });
          this.rules.agreementType = [
            {
              required: true,
              message: '请选择合同类型',
              trigger: ['blur', 'change'],
            },
          ]
        } else if (this.form.agreementInformation.agreementAttribute == 'cai_gou_he_tong') {
          this.rules.agreementType = []
          this.$refs.form.clearValidate('agreementType')
          this.form.agreementInformation.projectSource = null
          this.$api.outsource
            .getOutsourceData({ 
              pageNow: 1,
              pageSize: 9999,
             })
            .then(res => {
              this.dictData.companyType = res.data.records.map(item => ({
                id: item.id,
                dictName: item.name,
                dictVal: item.id
              }));
            })
            .catch(err => {
              console.error(err);
            });
        } else {
          this.rules.agreementType = []
          this.$refs.form.clearValidate('agreementType')
        }
      },
    },
  },
  created() {
    // this.$api.dict
    //   .listSysDictData("huian_agreement_company", true)
    //   .then((res) => {
    //     res.data.some((r) => {
    //       this.companyMessage2[r.dictVal] = r.dictName;
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // this.$api.dict
    //   .listSysDictData("agreement_company", true)
    //   .then((res) => {
    //     res.data.some((r) => {
    //       this.companyMessage1[r.dictVal] = r.dictName;
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    this.$api.dict
      .listSysDictData('company_agreement_information', true)
      .then(res => {
        res.data.some(r => {
          this.optionsData.push({
            value: r.dictVal,
            label: r.dictName,
          })
        })
      })
      .catch(err => {
        console.log(err)
      })
    // this.$api.dict
    //   .listSysDictData("AGREEMENT_SIGNED_TYPE", true)
    //   .then((res) => {
    //     this.agreementSignedTypeData = res.data;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    this.$api.dict
      .listSysDictData('AGREEMENT_SIGNED_TYPE_MULTIPLE', true)
      .then(res => {
        this.agreementSignedTypeData = res.data
        res.data.forEach(v => {
          if (v.dictVal == 'FIRST' || v.dictVal == 'SECOND') {
            v.disabled = true
            this.temporaryList.push(v)
          }
        })
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('PROJECT_TYPE', true)
      .then(res => {
        this.dictData.projectType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('PROJECT_STATUS', true)
      .then(res => {
        this.dictData.projectStatus = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('TYPE_CONTRACT', true)
      .then(res => {
        this.dictData.agreementType = res.data
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.dict
      .listSysDictData('PROJECT_SOURCE', true)
      .then(res => {
        const produceList = []
        const unProduceList = []
        res.data.forEach(item => {
          switch (item.dictVal) {
            case 'yi_shou_zhi_qian':
              produceList.push(item)
              break
            case 'zi_zhi_he_zuo':
              produceList.push(item)
              break
            case 'fen_bao_zhuan_bao':
              produceList.push(item)
              break
            case 'zi_zhi_ti_gong':
              unProduceList.push(item)
              break
            case 'zheng_ti_wai_xie':
              unProduceList.push(item)
              break
            case 'shang_wu_ju_jian':
              unProduceList.push(item)
              break
          }
        })
        this.produceList = produceList
        this.unProduceList = unProduceList
      })
      .catch(err => {
        console.log(err)
      })
  },
  mounted() {},
  methods: {
    changeCompanyType(val) {
      console.log('val', val)
    },
    async submitFinshFn(info) {
      const obj = {
        ...info,
        id: this.options.id,
      }
      const res = await this.$api.agreement.executionCompleted(obj)
      if (res.code === 200) {
        this.$message.success('操作成功')
        this.refresh(1)
      }
      this.executeFinshShow = false
    },
    // 前往项目
    async leaveFor(row) {
      const res = await this.$api.project.listProject({ id: row.id })
      if (res.data) {
        const obj = {
          operatingButton: res.data.records[0].operatingButton,
          id: res.data.records[0].id,
          status: res.data.records[0].status,
          itMonitoring: res.data.records[0].itMonitoring,
          isHaveChild: res.data.records[0].isHaveChild,
          isViewMonitoring: res.data.records[0].isViewMonitoring,
          performanceStatus: res.data.records[0].performanceStatus,
          postAttribute: res.data.records[0].postAttribute,
          staffAttribute: res.data.records[0].staffAttribute,
          residualWorkRatioContent: res.data.records[0].residualWorkRatioContent,
        }
        const { href } = this.$router.resolve({
          path: '/project/ProjectList',
          query: {
            data: JSON.stringify(obj),
          },
        })
        window.open(href, '_blank')
      }
    },
    selectCompanyHandleReset() {
      this.selectPage.name = null
      this.handlePageNowChange(1)
    },
    handleCloseFn() {
      this.ProjectEditDialogVisible = false
    },
    edit(row) {
      this.ProjectEditId = row.id
      this.ProjectEditDialogVisible = true
    },
    delMultipleItemsList(scope) {
      this.form.agreementInformation.projectListResponseList.some(v => {
        if (v.id == scope.row.id) {
          v.Selected = 0
        }
      })
      this.form.agreementInformation.projectListResponseList.splice(scope.$index, 1)
      if (!this.options.id) {
        this.form.agreementStageList.forEach((v, i) => {
          if (v.id && v.projectId == scope.row.id) {
            this.form.agreementStageList.splice(i, 1)
          }
        })
      }
    },
    cannotbeRemoved(value) {
      if (value.indexOf('FIRST') == -1) {
        this.agreementSignedTypeCheck.unshift('FIRST')
      }
      if (value.indexOf('SECOND') == -1) {
        this.agreementSignedTypeCheck.unshift('SECOND')
      }
      for (let i = 0; i < this.form.agreementSignedList.length; i++) {
        let flag = false
        for (let j = 0; j < this.agreementSignedTypeCheck.length; j++) {
          if (this.agreementSignedTypeCheck[i] == this.form.agreementSignedList[j].signedType) {
            flag = true
            break
          }
        }
        if (flag == false) {
          this.form.agreementSignedList.splice(i, 1)
          i--
        }
      }
    },
    /** 值改变触发
     *  "申请金额" 文本框 **/
    inputAmount(e) {
      this.form.agreementInformation[e] = String(this.form.agreementInformation[e])
        .replace(/[^\d.]/g, '')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')

      this.$forceUpdate()
    },
    inputagreementStageList(id) {
      let agreementStageList = this.form.agreementStageList
      agreementStageList.forEach((v, i) => {
        if (i == id) {
          v.paymentAmount = String(v.paymentAmount)
            .replace(/[^\d.]/g, '')
            .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
          console.log(id, 'id')
        }
      })
    },
    /** 获得焦点触发 "申请金额" 文本框 **/
    focusAmount(e) {
      if (
        this.form.agreementInformation[e] != null ||
        this.form.agreementInformation[e] != '' ||
        this.form.agreementInformation[e] != undefined
      ) {
        this.form.agreementInformation[e] = String(this.form.agreementInformation[e]).replace(
          /,/g,
          ''
        )
        this.$forceUpdate()
      }
    },
    focusagreementStageList(id) {
      let agreementStageList = this.form.agreementStageList
      agreementStageList.forEach((v, i) => {
        if (i == id) {
          if (v.paymentAmount != null || v.paymentAmount != '' || v.outsourceCost != undefined) {
            v.paymentAmount = String(v.paymentAmount).replace(/,/g, '')
          }
        }
      })
    },
    /** 失去焦点触发 "申请金额" 文本框 **/
    blurAmount(e) {
      this.form.agreementInformation[e] = Number(this.form.agreementInformation[e]).toLocaleString()
      this.$forceUpdate()
    },
    bluragreementStageList(id) {
      let agreementStageList = this.form.agreementStageList
      agreementStageList.forEach((v, i) => {
        if (i == id) {
          v.paymentAmount = Number(v.paymentAmount).toLocaleString()
        }
      })
    },
    returnFn() {
      this.$emit('update:isShow', false)
      this.$store.commit({
        type: 'project/SET_PROJECTFILELIST',
        projectFileList: [],
      })
      this.$store.commit({
        type: 'project/ADD_PROJECTFILELIST',
        addAgreementFileList: [],
      })
      this.$store.commit({
        type: 'project/DEL_PROJECTFILELIST',
        delAgreementFileList: [],
      })
    },
    forceUpdate() {
      this.$forceUpdate()
    },
    optionsFn(r) {
      this.$api.dict
        .listSysDictData(r[0], true)
        .then(res => {
          res.data.some(r => {
            this.agreementCompany[r.dictVal] = r.dictName
          })

          if (this.form.agreementInformation.signedType) {
            for (let i = 0; i < this.form.agreementSignedList.length; i++) {
              let r = this.form.agreementSignedList[i]
              if (r.signedType == this.form.agreementInformation.signedType) {
                this.$set(this.form.agreementSignedList, i, { ...r, ...this.agreementCompany })
              }
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
      // if (this.optionsValue[0] == "agreement_company") {
      //   this.$api.dict
      //     .listSysDictData("agreement_company", true)
      //     .then((res) => {
      //       res.data.some((r) => {
      //         this.agreementCompany[r.dictVal] = r.dictName;
      //       });
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      // } else if (this.optionsValue[0] == "huian_agreement_company") {
      //   this.$api.dict
      //     .listSysDictData("huian_agreement_company", true)
      //     .then((res) => {
      //       res.data.some((r) => {
      //         this.agreementCompany[r.dictVal] = r.dictName;
      //       });
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      // } else if (this.optionsValue[0] == "hong_sen_gong_si") {
      //   this.$api.dict
      //     .listSysDictData("hong_sen_gong_si", true)
      //     .then((res) => {
      //       res.data.some((r) => {
      //         this.agreementCompany[r.dictVal] = r.dictName;
      //       });
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      // }
    },
    signedTypeChange(v) {
      for (let i = 0; i < this.form.agreementSignedList.length; i++) {
        let r = this.form.agreementSignedList[i]
        if (r.signedType == v) {
          this.$set(
            this.form.agreementSignedList,
            i,
            { ...r, ...this.agreementCompany }
            // r.combination(this.agreementCompany)
          )
        }
      }
    },
    addAgreementWorkContentList() {
      this.form.agreementWorkContentList.push({})
    },
    delAgreementWorkContentList(scope) {
      this.form.agreementWorkContentList.splice(scope.$index, 1)
    },
    addAgreementMakeMapsList() {
      this.form.agreementMakeMapsList.push({})
    },
    delAgreementMakeMapsList(scope) {
      this.form.agreementMakeMapsList.splice(scope.$index, 1)
    },
    addAgreementStageList() {
      this.form.agreementStageList.push({
        stageName: null,
        paymentStandard: null,
        paymentAmount: 0,
        proceedsDate: null,
        paymentDate: null,
      })
    },
    delAgreementStageList(scope) {
      this.form.agreementStageList.splice(scope.$index, 1)
    },
    init() {
      this.agreementSignedTypeCheck = ['FIRST', 'SECOND']
      if (this.options.id) {
        this.loading = true
        this.$api.agreement
          .selectDetailById(this.options.id)
          .then(res => {
            this.$store.commit({
              type: 'project/SET_ORIGINALFORM',
              originalForm: res.data.deepClone(),
            })
            this.originalForm = res.data.deepClone()
            this.form = res.data.deepClone()
            this.form.agreementInformation.projectListResponseList.some(v => {
              v.Selected = 1
            })
            this.formInfo.businessLines = res.data.agreementInformation.businessLines

            if (this.form.agreementInformation.actualContractAmount) {
              this.form.agreementInformation.actualContractAmount = Number(
                this.form.agreementInformation.actualContractAmount
              ).toLocaleString()
              this.$forceUpdate()
            }
            if (this.form.agreementInformation.actualAmount) {
              this.form.agreementInformation.actualAmount = Number(
                this.form.agreementInformation.actualAmount
              ).toLocaleString()
              this.$forceUpdate()
            }
            this.loading = false

            if (res.data.agreementSignedList) {
              res.data.agreementSignedList.forEach(v => {
                if (this.agreementSignedTypeCheck.indexOf(v.signedType) == -1) {
                  this.agreementSignedTypeCheck.push(v.signedType)
                }
              })
            }
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      } else {
        this.form = {
          agreementInformation: {
            agreementName: null,
            projectName: null,
            remark: null,
            theirDeptName: null,
            agreementAttribute: null,
            projectSource: null,
            signedPlace: '广州市',
            actualContractAmount: 0,
            actualAmount: 0,
            projectListResponseList: [],
          },
          agreementFileList: [],
          agreementWorkContentList: [],
          agreementMakeMapsList: [],
          agreementStageList: [],
          agreementSignedList: [{ signedType: 'FIRST' }, { signedType: 'SECOND' }],
        }
        this.loading = false
      }
    },
    async choiceProject(r) {
      if (!Array.isArray(this.form.agreementInformation.projectListResponseList)) {
        this.form.agreementInformation.projectListResponseList = []
      }
      r.Selected = 1
      this.form.agreementInformation.projectListResponseList.push(r)
      if (this.formInfo.businessLines == '') {
        this.form.agreementInformation.projectId = r.id
        this.form.agreementInformation.projectName = r.projectName
        this.form.agreementInformation.theirDeptName = r.theirDeptName
        this.form.agreementInformation.agreementName = r.projectName
        this.formInfo.businessLines = r.businessLines
        this.form.agreementInformation.projectLines = r.projectLines

        this.form.agreementInformation.actualContractAmount = Number(
          r.projectBudget
        ).toLocaleString()

        if (this.options.id) {
          this.form.agreementInformation.signedPlace = r.signedPlace
        }
      }
      if (!this.options.id && !this.form.agreementStageList.some(v => v.projectId)) {
        const res = await this.$api.agreement.selectAgreementStageListByProjectId(r.id)
        if (res.data) {
          res.data.forEach(v => {
            this.form.agreementStageList.push({
              id: v.id,
              projectId: v.projectId,
              stageName: v.stageName,
              paymentStandard: v.paymentStandard,
              paymentAmount: v.paymentAmount,
              proceedsDate: v.proceedsDate,
              paymentDate: v.paymentDate,
            })
          })
        }
      }
      if (
        !this.form.agreementInformation.projectSource &&
        this.form.agreementInformation.projectListResponseList.length == 1
      ) {
        this.form.agreementInformation.projectSource = r.projectSource
      }
    },
    getForm() {
      var obj = this.form.deepClone()
      var agreementSignedList = []
      obj.agreementInformation.projectCorrelationIdList = []
      if (obj.agreementInformation.projectListResponseList) {
        obj.agreementInformation.projectListResponseList.some(v => {
          obj.agreementInformation.projectCorrelationIdList.push(v.id)
        })
      }

      obj?.agreementSignedList.some(res => {
        if (
          this.agreementSignedTypeCheck.some(r => {
            return r == res.signedType
          })
        ) {
          agreementSignedList.push(res)
        }
      })
      obj.agreementSignedList = agreementSignedList

      if (this.options.id) {
        obj.agreementFileList = []
        this.addAgreementFileList.forEach(v => {
          obj.agreementFileList.push(v)
        })
        this.$store.commit({
          type: 'project/ADD_PROJECTFILELIST',
          addAgreementFileList: [],
        })

        obj.agreementFileDeleteList = []
        this.delAgreementFileList.forEach(v => {
          obj.agreementFileDeleteList.push(v.id)
        })
        this.$store.commit({
          type: 'project/DEL_PROJECTFILELIST',
          delAgreementFileList: [],
        })

        // obj.agreementFileDeleteList = [];
        // this.originalForm.agreementFileList.some((r) => {
        //   if (
        //     !obj.agreementFileList.some((n) => {
        //       return n.id == r.id;
        //     })
        //   ) {
        //     obj.agreementFileDeleteList.push(r.id);
        //     console.log(
        //       obj.agreementFileDeleteList,
        //       "obj.agreementFileDeleteList"
        //     );
        //   }
        // });

        obj.agreementMakeMapsDeleteList = []
        this.originalForm.agreementMakeMapsList.some(r => {
          if (
            !obj.agreementMakeMapsList.some(n => {
              return n.id == r.id
            })
          ) {
            obj.agreementMakeMapsDeleteList.push(r.id)
          }
        })

        obj.agreementSignedDeleteList = []
        this.originalForm.agreementSignedList.some(r => {
          if (
            !obj.agreementSignedList.some(n => {
              return n.id == r.id
            })
          ) {
            obj.agreementSignedDeleteList.push(r.id)
          }
        })

        obj.agreementStageDeleteList = []
        this.originalForm.agreementStageList.some(r => {
          if (
            !obj.agreementStageList.some(n => {
              return n.id == r.id
            })
          ) {
            obj.agreementStageDeleteList.push(r.id)
          }
        })

        obj.agreementWorkContentDeleteList = []
        this.originalForm.agreementWorkContentList.some(r => {
          if (
            !obj.agreementWorkContentList.some(n => {
              return n.id == r.id
            })
          ) {
            obj.agreementWorkContentDeleteList.push(r.id)
          }
        })
      }
      return obj
    },
    submit(key) {
      if (key.key === 'Enter') return
      if (this.form.agreementStageList.length == 0 && this.form.agreementInformation.agreementAttribute == 'xiao_shou_he_tong') {
        this.$alert('费用结算与付款方式还未填写, 请填写费用结算与付款方式', '', {
          confirmButtonText: '确定',
          callback: action => {},
        })
        return
      }
      if (this.form.agreementInformation.agreementAttribute == 'cai_gou_he_tong') {
        this.$refs.form.validateField('agreementType')
        this.form.agreementInformation.companyType = ''
      }else if(this.form.agreementInformation.agreementAttribute == 'xiao_shou_he_tong'){
        this.form.agreementInformation.outsourceCompanyId = '' // 如果outsourceCompanyId为0，则表示没有外协
      }

      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true
          this.form.agreementInformation.actualContractAmount = Number.parseFloat(
            delcommafy(this.form.agreementInformation.actualContractAmount)
          )
          this.form.agreementInformation.actualAmount = Number.parseFloat(
            delcommafy(this.form.agreementInformation.actualAmount)
          )
          this.form.agreementStageList.forEach(v => {
            if (v.paymentAmount) {
              v.paymentAmount = Number.parseFloat(delcommafy(v.paymentAmount))
            }
          })
          console.log('this.getForm()', this.getForm())
          this.$api.agreement[key](this.getForm())
            .then(res => {
              this.submitLoading = false
              this.refresh(1)
              this.$message.success('操作成功！')
              this.$store.commit({
                type: 'project/SET_PROJECTFILELIST',
                projectFileList: [],
              })
              this.$store.commit({
                type: 'project/ADD_PROJECTFILELIST',
                addAgreementFileList: [],
              })
              this.$store.commit({
                type: 'project/DEL_PROJECTFILELIST',
                delAgreementFileList: [],
              })
            })
            .catch(err => {
              this.submitLoading = false
              console.log(err)
            })
        } else {
          this.activeName = '0'
          this.$nextTick(() => {
            this.scrollView(obj)
          })
        }
      })
    },
    approvePass(obj) {
      this.submitLoading = true
      if (obj.checkOpinion == '') {
        obj.checkOpinion = '通过'
      }
      // console.log(obj)
      // this.handleClose()
      // this.submitLoading = false
      // return
      this.$api.agreement
        .approvePass(obj)
        .then(res => {
          this.handleClose()
          this.submitLoading = false
          this.refresh(1)
          this.$message.success('操作成功！')
        })
        .catch(err => {
          this.submitLoading = false
          console.log(err)
        })
    },
    approveReject(obj) {
      this.submitLoading = true
      this.$api.agreement
        .approveReject(obj)
        .then(res => {
          this.handleClose()
          this.submitLoading = false
          this.refresh(1)
          this.$message.success('操作成功！')
        })
        .catch(err => {
          this.submitLoading = false
          console.log(err)
        })
    },
    approve(a) {
      this.approveTxet = a
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.rulesApproveTxet.resetFields()
      this.formData.checkOpinion = ''
      this.approveTxet = ''
    },
    addApprove() {
      this.$refs.rulesApproveTxet.validate(valid => {
        if (valid) {
          this.formData.id = this.options.id
          if (this.approveTxet == 1) {
            this.approveReject(this.formData)
          } else if (this.approveTxet == 2) {
            this.approvePass(this.formData)
          }
        }
      })
    },

    getPartnerCompany() {
      this.$api.partnerCompany // 先从服务器中获取所有合作公司信息
        .getPartnerCompanyDataList(this.selectPage)
        .then(res => {
          if (res.code !== 200) return this.$message.error('数据请求失败')
          this.selectPartnerCompanyList = res.data.records
          this.selectPage.total = res.data.total
        })
    },

    // 点击选择按钮弹出选择框选择合作公司
    onClickSelectCompany(data) {
      // 记录当前点击按钮的行
      this.nowParty = data.signedType
      this.agreementSelectId = data.cooperativeCorporationId
      this.selectPage.name = ''
      this.getPartnerCompany()
      this.selectCompanyDialogVisible = true
    },

    // 监听弹框关闭清空里面数据
    handleSelectPartnerCompanyDialogClose() {
      this.selectPartnerCompanyList = []
      this.outsourceSelectPartnerCompanyList = []
      this.selectPartnerCompanyRadio = ''
      this.selectCompanyActiveName = 'proprietor'
    },
    // 外协-清空
    outsourceSelectCompanyHandleReset() {
      this.outsourceSelectPage.name = ''
      this.outsourceHandlePageNowChange(1)
    },
    outsourceHandlePageNowChange(current) {
      this.outsourceSelectPage.pageNow = current
      this.getOutsourceData()
    },
    outsourceHandlePagSizeChange(size) {
      this.outsourceSelectPage.pageSize = size
      this.getOutsourceData()
    },
    getOutsourceData() {
      // 先从服务器中获取所有外协公司信息
      this.$api.outsource
        .getOutsourceData(this.outsourceSelectPage)
        .then(res => {
          if (res.code !== 200) {
            this.$message.error('数据列表请求失败')
            return
          }
          this.outsourceSelectPartnerCompanyList = res.data.records
          this.outsourceSelectPage.total = res.data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 选择合作公司切换tabs
    selectCompanyHandleClick(tab, event) {
      if (this.selectCompanyActiveName === 'outsource') {
        this.outsourceSelectCompanyHandleReset()
      } else if (this.selectCompanyActiveName === 'proprietor') {
        this.selectPage.pageNow = 1
        this.selectPage.name = ''
        this.getPartnerCompany()
      }
    },
    // 选中
    choiceRow(row) {
      this.agreementSelectId = row.id
      // 关闭对话框后使数据回显到对应行输入框
      const item = this.form.agreementSignedList.find(v => v.signedType == this.nowParty)
      if (item) {
        item.party = row.name
        item.partyAccountBank = row.accountBank
        item.partyAddress = row.address
        item.partyDepositBank = row.depositBank
        item.partyFax = row.fax
        item.partyMail = row.mail
        item.partyPhone = row.phone
        item.cooperativeCorporationId = row.id
        item.readonly = true
        item.refresh = true
      }
      this.selectCompanyDialogVisible = false
    },

    handleCompanyInput(row, index, key) {
      if (key) {
        this.form.agreementSignedList.forEach((v, i) => {
          if (index == i) {
            v.partyAccountBank = v.partyAccountBank.replace(/\s*/g, '')
            var result = []
            for (var i = 0; i < v.partyAccountBank.length; i++) {
              if (i % 4 == 0 && i != 0) {
                result.push(' ' + v.partyAccountBank.charAt(i))
              } else {
                result.push(v.partyAccountBank.charAt(i))
              }
            }
            v.partyAccountBank = result.join('')
          }
        })
      }
      // if (!row.refresh) return
      row.refresh = !row.refresh
      this.$forceUpdate()
    },

    // checkbox的change事件监听
    handleAgreementSignedTypeChange(e) {
      if (!e) {
        for (let i = 0; i < this.form.agreementSignedList.length; i++) {
          let flag = false
          for (let j = 0; j < this.agreementSignedTypeCheck.length; j++) {
            if (this.agreementSignedTypeCheck[i] == this.form.agreementSignedList[j].signedType) {
              flag = true
              break
            }
          }
          if (flag == false) {
            this.form.agreementSignedList.splice(i, 1)
            i--
          }
        }
      }
    },

    handlePagSizeChange(size) {
      this.selectPage.pageSize = size
      this.getPartnerCompany()
    },

    handlePageNowChange(current) {
      this.selectPage.pageNow = current
      this.getPartnerCompany()
    },
    partyPhone(rule, value, callback) {
      if (!value) {
        callback()
      } else {
        const reg =
          /^(((\d{3,4}-)?[0-9]{5,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/ //手机号正则表达式或座机号
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号或座机号'))
        }
      }
    },
    partyFax(rule, value, callback) {
      if (!value) {
        callback()
      } else {
        const reg = /^(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/ //传真正则表达式

        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的传真号'))
        }
      }
    },
    partyMail(rule, value, callback) {
      if (!value) {
        callback()
      } else {
        const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/ //邮箱正则表达式

        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的邮箱地址'))
        }
      }
    },
    partyAccountBank(rule, value, callback) {
      if (!value) {
        callback()
      } else {
        const reg = /^\d{7,30}$/ //通用银行账号正则表达式
        value = value.replace(/\s/g, '')
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的银行账号'))
        }
      }
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.fullScreen .fullScreenMain .fullScreenMainCon .el-tab-pane > .el-form {
  padding-top: 0;
}
.fullScreen {
  .cost {
    padding: 20px 0;
    /deep/.el-timeline-item__timestamp {
      font-size: 16px;
      font-weight: bold;
      background: #fff;
    }
    /deep/.el-card {
      .el-card__body {
      }
    }
  }
  /deep/#checkOpinion {
    .el-form-item__label {
      font-size: 16px !important;
      color: red !important;
    }
  }
  /deep/#cost {
    position: relative;
  }
  /deep/#cost::before {
    content: '*';
    color: red;
    position: absolute;
    left: -10px;
    top: 0;
  }
  .signatureInformation {
    font-size: 14px;
    /deep/.el-table__header {
      tr {
        &:nth-child(1) {
          th {
            background: #fff;
          }
        }
      }
    }
    .el-checkbox-group {
      line-height: 28px;
      .el-checkbox {
        margin-right: 8px;
      }
    }
    .el-radio-group {
      line-height: 28px;
      .el-radio {
        position: relative;
        top: 5px;
        margin-right: 8px;
      }
    }
    .signatureInformation-item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // justify-content: center;
      line-height: 38px;
      .label {
        font-weight: bold;
        color: #000;
        margin-right: 10px;
      }
    }
  }
}
.signatureInformation-group {
  width: 100%;
  display: flex;
  // justify-content: space-around;
}
.el-pagination {
  margin-top: 15px;
}
.el-dialog-div {
  height: 70vh;
  overflow: auto;
}

.radio-group {
  display: flex;
  align-items: center;
  height: 30px;
}
.radio-label {
  width: 100px;
  font-size: 14px;
}
/deep/.el-form-item {
  margin-bottom: 9px;
}
#selectCompanyDialogVisible {
  /deep/.el-dialog {
    .el-dialog__body {
      padding: 5px 20px !important;
    }
    .dialog-footer {
      width: 100%;
      padding-right: 35%;
      text-align: center;
      .el-button {
        min-width: 120px;
        margin: 0 25px;
      }
    }
  }
}
</style>
