<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw"
          >项目年度收入统计
          <span style="margin-left: 10px; font-size: 14px; color: #409eff"
            >以项目实际开始时间来统计相应数据</span
          ></span
        >
        <span class="title_right"
          ><SearchUserDate
            :type="'Histogram'"
            :title="'统计季度：'"
            :introductionType="'quarter'"
            :clearable="true"
            :list="quarterGenerate"
            :yearValue="sectionInfo.incomeYear"
            v-model="sectionInfo.yearManageQuarter"
            @change="onHistogramChange"
            @changeData="onHistogramChangeData"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :yearIsShow="true"
            :introductionType="'projectId'"
            :type="'Histogram'"
            :list="dictData.yearList"
            :twoValue="sectionInfo.yearManageQuarter"
            v-model="sectionInfo.incomeYear"
            @change="onHistogramChange"
            @changeData="onHistogramChangeData"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="isDialog = true"
          >数据来源说明</el-button
        >
      </div>
      <Histogram :incomeInfo="incomeInfo" />

      <el-table height="240px" border row-key="id" :data="incomeInData">
        <el-table-column
          prop="businessCategoryName"
          label="业务种类"
          align="center"
          fixed="left"
          min-width="120"
        >
        </el-table-column>
        <el-table-column prop="actualAmount" min-width="120" label="项目实际金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.actualAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="contractAmount" min-width="120" label="合同金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.contractAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="invoicedAmount" min-width="120" label="已开票金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.invoicedAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="receivedAmount" min-width="120" label="已收款金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.receivedAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="unreceivedInvoicedAmount"
          min-width="120"
          label="已开票未收款金额"
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.unreceivedInvoicedAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="unreceivedUnissuedAmount"
          min-width="120"
          label="未开票已收款"
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.unreceivedUnissuedAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="unissuedAmount" min-width="120" label="未开票金额" align="right">
          <template slot-scope="scope">
            {{ scope.row.unissuedAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="totalTax" min-width="120" label="总税费" align="right">
          <template slot-scope="scope">
            {{ scope.row.totalTax | applyAmount }}
          </template>
        </el-table-column>
      </el-table> </el-card
    ><el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img src="@/assets/项目统计-项目年度收入统计.png" alt="数据来源说明" width="100%" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    Histogram: () => import('./histogram.vue'),
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      projectList: [],
      dictData: {
        yearList: [],
        businessLines: [],
      },
      incomeInfo: {}, // 项目收入统计
      incomeInData: [],
      sectionInfo: {
        // 条件筛选 // 年份
        incomeYear: null,
        yearManageQuarter: '',
      },
      titleYear: '统计时间',
      isDialog: false,
    }
  },
  provide() {
    return {}
  },
  computed: {
    quarterGenerate() {
      const arr = []
      for (let index = 0; index < 4; index++) {
        arr.push({ id: index + 1, dictName: `第${index + 1}季度`, dictVal: `Q${index + 1}` })
      }
      arr.unshift({
        id: '0',
        dictName: '全部',
        dictVal: '',
      })
      return arr
    },
  },
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    onHistogramChange(obj) {
      this.incomeInfo = obj
    },
    onHistogramChangeData(arr) {
      this.incomeInData = arr
    },
    async getType() {
      this.loading = true
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(async res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.incomeYear = dictVal
          const businessLines = await this.$api.dict.listSysDictData('BUSINESS_LINES', true)
          this.dictData.businessLines = businessLines
          const queryProjectIncomeStatistics =
            await this.$api.statistics.queryProjectIncomeStatistics(`date=${dictVal}`)
          const { projectIncomeStatisticsVo = [] } = queryProjectIncomeStatistics.data

          projectIncomeStatisticsVo.forEach(item => {
            const businessLinesItem = businessLines.data.find(
              v => v.dictVal === item.businessCategory
            )
            item.businessCategoryName = businessLinesItem
              ? businessLinesItem.dictName
              : item.businessCategory
          })
          this.incomeInData = projectIncomeStatisticsVo
          const nameMapping = {
            actualAmount: '项目实际金额',
            contractAmount: '合同金额',
            invoicedAmount: '已开票金额',
            receivedAmount: '已收款金额',
            unreceivedInvoicedAmount: '已开票未收款金额',
            unreceivedUnissuedAmount: '未开票已收款',
            unissuedAmount: '未开票金额',
            totalTax: '总税费',
          }
          const businessLinesInfo = {
            nameArr: [],
            data: [],
          }
          businessLinesInfo.nameArr = projectIncomeStatisticsVo.map(v => v.businessCategoryName)
          // 根据 nameMapping 填充数据
          Object.entries(nameMapping).forEach(([field, fieldName]) => {
            const fieldData = {
              name: fieldName,
              data: [],
            }
            // 对每个业务分类进行数据填充
            businessLinesInfo.nameArr.forEach(category => {
              // 查找该类别对应的项目
              const categoryData = projectIncomeStatisticsVo.find(
                item => item.businessCategoryName === category
              )
              // 根据字段获取对应的值，若不存在则默认为 0
              fieldData.data.push(categoryData ? categoryData[field] : 0)
            })
            // 将字段数据推入 businessLinesInfo.data
            businessLinesInfo.data.push(fieldData)
          })
          this.incomeInfo = businessLinesInfo
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
      this.$api.project
        .getProjectInfoByStaffId()
        .then(res => {
          this.projectList = res.data?.projectNameRespList.map(v => ({
            id: v.id,
            dictName: v.projectName,
            dictVal: v.id,
          }))
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
