<template>
  <div id="histogramId" style="width: 100%; height: 600px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'Histogram',
  props: {
    incomeInfo: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return { coordinate: null }
  },
  computed: {},

  watch: {
    incomeInfo: {
      deep: true,
      immediate: true,
      handler: function (obj) {
        if (obj.data) {
          this.init(obj)
        } else {
          this.init()
        }
      },
    },
  },
  async created() {},
  mounted() {},
  methods: {
    // 项目收入统计
    init(obj = {}) {
      const nameArr = obj.nameArr || []
      if (obj.data) {
        this.$nextTick(() => {
          var chartDom = document.getElementById('histogramId')
          var myChart = echarts.init(chartDom)
          var option
          option = {
            tooltip: {
              trigger: 'item', // 确保触发方式是单个项
              formatter: function (params) {
                let result = ` 类型: ${params.name}<br/>`
                obj.data.forEach((item, index) => {
                  if (params.seriesName == item.name) {
                    result += `${params.seriesName}: ${Number(
                      params.value
                    ).toLocaleString()}<span style="color:red">（当前选中）</span><br/>`
                  } else {
                    if (obj.data[index] && obj.data[index].data) {
                      result += `${item.name}: ${Number(
                        obj.data[index].data[params.dataIndex]
                      ).toLocaleString()}<br/>`
                    }
                  }
                })
                return result
              },
            },
            legend: {},
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true,
            },
            xAxis: [
              {
                type: 'category',
                data: nameArr,
                axisLabel: {
                  textStyle: {
                    fontSize: 12,
                  },
                  // 核心代码 默认展示4个字超出显示...
                  formatter: function (value) {
                    //x轴的文字改为竖版显示
                    var str = value.split('')
                    return str.slice(0, 5).join('') + (str.length > 5 ? '...' : '')
                  },
                },
              },
            ],
            yAxis: [
              {
                type: 'log',
                min: 100000, // 设置y轴最小值
                interval: 10000000,
                max: 100000000,
                show: false,
              },
            ],
            series: [
              {
                name: obj?.data[0]?.name,
                type: 'bar',
                barGap: '0%', // 中间不间隔
                // stack: 'a',
                emphasis: {
                  focus: 'series',
                },
                data: obj?.data[0]?.data,
              },
              {
                name: obj?.data[1]?.name,
                type: 'bar',
                barGap: '0%', // 中间不间隔
                // stack: 'a',
                emphasis: {
                  focus: 'series',
                },
                data: obj?.data[1]?.data,
              },
              {
                name: obj?.data[2]?.name,
                type: 'bar',
                barGap: '0%', // 中间不间隔
                stack: 'b',
                emphasis: {
                  focus: 'series',
                },
                data: obj?.data[2]?.data,
              },
              {
                name: obj?.data[3]?.name,
                type: 'bar',
                barGap: '0%', // 中间不间隔
                stack: 'b',
                emphasis: {
                  focus: 'series',
                },
                data: obj?.data[3]?.data,
              },
              {
                name: obj?.data[4]?.name,
                type: 'bar',
                barGap: '0%', // 中间不间隔

                stack: 'c',
                emphasis: {
                  focus: 'series',
                },
                data: obj?.data[4]?.data,
              },
              {
                name: obj?.data[5]?.name,
                type: 'bar',
                barGap: '0%', // 中间不间隔
                stack: 'c',
                emphasis: {
                  focus: 'series',
                },
                data: obj?.data[5]?.data,
              },
              {
                name: obj?.data[6]?.name,
                type: 'bar',
                barGap: '0%', // 中间不间隔
                stack: 'b',
                emphasis: {
                  focus: 'series',
                },
                data: obj?.data[6]?.data,
              },
              {
                name: obj?.data[7]?.name,
                type: 'bar',
                barGap: '0%', // 中间不间隔
                stack: 'c',
                emphasis: {
                  focus: 'series',
                },
                data: obj?.data[7]?.data,
              },
            ],
            dataZoom: {
              type: 'slider', // 滑动条类型
              show: nameArr?.length > 10 ? true : false, // 是否显示滑动条,在这里可以根据自己的需求做判断，超过时显示进度条
              startValue: 0, // 展示区域内容的起始数值
              endValue: 10, // 展示区域内容的结束数值
              height: 10, // 滑动条组件高度
              bottom: 5, // 距离图表区域下边的距离
              showDetail: false, // 拖拽时是否显示详情
              showDataShadow: false, // 是否在组件中显示数据阴影
              fillerColor: '#c1c1c1', // 平移条的填充颜色
              borderColor: 'transparent', // 边框颜色
              zoomLock: true, // 锁定视图
              brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
              // 通过该属性可以只滑动，不显示缩放功能
              handleStyle: {
                // 手柄样式
                opacity: 0,
              },
            },
          }
          option && myChart.setOption(option)
          myChart.setOption(option, true) // 第二个参数设置为 true，表示完全更新配置项
          //监听窗口变化
          window.addEventListener('resize', function () {
            myChart.resize()
          })
        })
      }
    },
  },
}
</script>
<style scoped></style>
